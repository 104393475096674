export function alertMapper(doc) {
    var _a, _b, _c, _d;
    var _e = doc.data(), data = _e.data, description = _e.description, from = _e.from, seen = _e.seen, timestamp = _e.timestamp, to = _e.to;
    return {
        documentID: doc.id,
        data: data,
        description: description,
        from: ((_a = from) === null || _a === void 0 ? void 0 : _a.id) || "",
        seen: seen,
        timestamp: ((_b = timestamp) === null || _b === void 0 ? void 0 : _b.seconds) * 1000 || 0,
        to: ((_c = to) === null || _c === void 0 ? void 0 : _c.id) || "",
        type: (_d = data) === null || _d === void 0 ? void 0 : _d.type
    };
}
