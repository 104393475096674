var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { FormControl } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import useStyles from "../views/Tasks/styles/styles";
// @ts-ignore
export default function FilterByGroups(props) {
    var groupFilter = props.groupFilter, setGroupFilter = props.setGroupFilter, _a = props.isDisabled, isDisabled = _a === void 0 ? false : _a;
    var classes = useStyles();
    var userGroups = useSelector(function (state) { return state.userGroupManagement; }).userGroups;
    return (React.createElement(FormControl, { className: classes.formStatusControl }, React.createElement(Autocomplete, { value: groupFilter ? userGroups.filter(function (el) { return el.id === groupFilter; })[0] : null, getOptionSelected: function (option, value) { return option.id === value.id; }, disabled: isDisabled, id: "filter-by-groups", options: userGroups, getOptionLabel: function (option) { return option.name || ""; }, onChange: function (_, value) {
            var _a;
            if (value) {
                setGroupFilter((_a = value) === null || _a === void 0 ? void 0 : _a.id);
            }
            else {
                setGroupFilter("");
            }
        }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true }), placeholder: "Filter by Group", variant: "standard" }))); } })));
}
