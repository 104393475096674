import React, { useEffect, useState } from "react";
import "./App.css";
import { Redirect, Route, Switch } from "react-router";
import { useDispatch } from "react-redux";
import Login from "./views/Login/Login";
import NotFound from "./views/NotFound/NotFound";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import Dashboard from "./views/Home/Dashboard";
import UserContext from "./views/Login/UserProvider";
import { auth, fetchAndActivateRemoteConfig } from "./utils/firebaseConnector";
import ChangePassword from "./views/Login/ChangePassword";
import { setSignInAction } from "./views/Login/action";
import history from "./utils/history";
function onAuthStateChange(setUser) {
    return auth.onAuthStateChanged(function (user) {
        if (user) {
            setUser(user);
        }
        else {
            setUser(null);
            if (history.location.pathname !== "/privacy") {
                history.push("/login");
            }
        }
    });
}
function App() {
    var dispatch = useDispatch();
    var _a = useState(null), user = _a[0], setUser = _a[1];
    useEffect(function () {
        var unsubscribe = onAuthStateChange(setUser);
        return function () {
            unsubscribe();
        };
    }, []);
    useEffect(function () {
        fetchAndActivateRemoteConfig();
        if (user !== null) {
            dispatch(setSignInAction());
        }
    }, [user, dispatch]);
    return (
    // @ts-ignore
    React.createElement(UserContext.Provider, { value: user }, React.createElement("div", { className: "App" }, React.createElement(Switch, null, React.createElement(Route, { exact: true, path: "/login", component: Login }), React.createElement(Route, { exact: true, path: "/change-password", component: ChangePassword }), React.createElement(Route, { exact: true, path: "/privacy", component: PrivacyPolicy }), React.createElement(Route, { path: "/dashboard", component: Dashboard }), React.createElement(Route, { exact: true, path: "/" }, React.createElement(Redirect, { to: "/login" })), React.createElement(Route, { component: NotFound })))));
}
export default App;
