/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { alertActionTypes } from "./actions";
var initialState = {
    singleAlert: undefined,
    alertList: [],
    fetchAlertsIsLoading: false,
    fetchAlertsError: { hasError: false, description: "" },
    createAlertsApiSuccess: true,
    createAlertsApiIsLoading: false,
    createAlertsApiError: { hasError: false, description: "" },
    updateAlertsApiSuccess: true,
    updateAlertsApiIsLoading: false,
    updateAlertsApiError: { hasError: false, description: "" },
    deleteAlertsApiSuccess: true,
    deleteAlertsApiIsLoading: false,
    deleteAlertsApiError: { hasError: false, description: "" }
};
export default function smartAlertReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case alertActionTypes.FETCH_ALERTS: {
                draft.fetchAlertsIsLoading = true;
                break;
            }
            case alertActionTypes.FETCH_ALERTS_SUCCESS: {
                draft.alertList = action.alertList;
                draft.fetchAlertsIsLoading = false;
                draft.fetchAlertsError = { hasError: false, description: "" };
                break;
            }
            case alertActionTypes.FETCH_ALERTS_FAILURE: {
                draft.fetchAlertsIsLoading = false;
                draft.fetchAlertsError = { hasError: true, description: action.err };
                break;
            }
            case alertActionTypes.CREATE_SMART_ALERT_API: {
                draft.createAlertsApiIsLoading = true;
                break;
            }
            case alertActionTypes.CREATE_SMART_ALERT_API_SUCCESS: {
                draft.createAlertsApiIsLoading = false;
                draft.createAlertsApiSuccess = true;
                draft.createAlertsApiError = { hasError: false, description: "" };
                break;
            }
            case alertActionTypes.CREATE_SMART_ALERT_API_FAILURE: {
                draft.createAlertsApiIsLoading = false;
                draft.createAlertsApiSuccess = false;
                draft.createAlertsApiError = { hasError: true, description: action.err };
                break;
            }
            case alertActionTypes.UPDATE_SMART_ALERT_API: {
                draft.updateAlertsApiIsLoading = true;
                break;
            }
            case alertActionTypes.UPDATE_SMART_ALERT_API_SUCCESS: {
                draft.updateAlertsApiIsLoading = false;
                draft.updateAlertsApiSuccess = true;
                draft.updateAlertsApiError = { hasError: false, description: "" };
                break;
            }
            case alertActionTypes.UPDATE_SMART_ALERT_API_FAILURE: {
                draft.updateAlertsApiIsLoading = false;
                draft.updateAlertsApiSuccess = false;
                draft.updateAlertsApiError = { hasError: true, description: action.err };
                break;
            }
            case alertActionTypes.DELETE_SMART_ALERT_API: {
                draft.deleteAlertsApiIsLoading = true;
                break;
            }
            case alertActionTypes.DELETE_SMART_ALERT_API_SUCCESS: {
                draft.deleteAlertsApiIsLoading = false;
                draft.deleteAlertsApiSuccess = true;
                draft.deleteAlertsApiError = { hasError: false, description: "" };
                break;
            }
            case alertActionTypes.DELETE_SMART_ALERT_API_FAILURE: {
                draft.deleteAlertsApiIsLoading = false;
                draft.deleteAlertsApiSuccess = false;
                draft.deleteAlertsApiError = { hasError: true, description: action.err };
                break;
            }
            default:
                break;
        }
    });
}
