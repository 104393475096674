import * as React from "react";
import { Box, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { PRIMARY_BLUE, PRIMARY_PINK } from "../utils/colorCodes";
import useStyles from "../views/Tasks/styles/styles";
// @ts-ignore
export default function FilterBySearchQuery(props) {
    var handleSearchTextChange = props.handleSearchTextChange, searchText = props.searchText, setSearchText = props.setSearchText;
    var classes = useStyles();
    return (React.createElement(FormControl, { className: classes.formStatusControl }, React.createElement("form", { noValidate: true, autoComplete: "off" }, React.createElement(Box, { display: "flex", alignItems: "center", flexDirection: "row", style: {
            backgroundColor: PRIMARY_PINK,
            borderRadius: 4,
            paddingRight: 10
        } }, React.createElement(TextField, { InputProps: { disableUnderline: true }, fullWidth: true, value: searchText, onChange: function (e) {
            if (setSearchText) {
                setSearchText(e.target.value);
            }
            handleSearchTextChange(e.target.value);
        }, onKeyPress: function (ev) {
            if (ev.key === "Enter") {
                ev.preventDefault();
            }
        }, id: "filled-basic", placeholder: "Search" }), React.createElement(SearchIcon, { style: {
            color: PRIMARY_BLUE
        } })))));
}
