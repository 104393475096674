/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { userGroupsActionTypes } from "./actions";
var initialState = {
    userGroups: [],
    subordinatorGroupIDs: [],
    createUserGroupSuccess: false,
    updateUserGroupSuccess: false,
    archiveUserGroupSuccess: false,
    createUserGroupIsLoading: false,
    updateUserGroupIsLoading: false,
    archiveUserGroupIsLoading: false,
    fetchUserGroupsFailure: { hasError: false, description: "" },
    createUserGroupError: { hasError: false, description: "" },
    updateUserGroupError: { hasError: false, description: "" },
    archiveUserGroupError: { hasError: false, description: "" }
};
export default function userGroupsReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case userGroupsActionTypes.FETCH_USER_GROUPS: {
                break;
            }
            case userGroupsActionTypes.FETCH_USER_GROUPS_SUCCESS: {
                draft.userGroups = action.userGroups;
                draft.subordinatorGroupIDs = action.subordinatorGroupIDs;
                draft.fetchUserGroupsFailure = { hasError: false, description: "" };
                break;
            }
            case userGroupsActionTypes.FETCH_USER_GROUPS_FAILURE: {
                draft.fetchUserGroupsFailure = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case userGroupsActionTypes.CREATE_USER_GROUP:
                draft.createUserGroupIsLoading = true;
                break;
            case userGroupsActionTypes.CREATE_USER_GROUP_SUCCESS:
                draft.createUserGroupSuccess = true;
                draft.createUserGroupIsLoading = false;
                draft.createUserGroupError = { hasError: false, description: "" };
                break;
            case userGroupsActionTypes.CREATE_USER_GROUP_FAILURE:
                draft.createUserGroupIsLoading = false;
                draft.createUserGroupError = {
                    hasError: true,
                    description: action.err
                };
                break;
            case userGroupsActionTypes.UPDATE_USER_GROUP:
                draft.updateUserGroupIsLoading = true;
                break;
            case userGroupsActionTypes.UPDATE_USER_GROUP_SUCCESS:
                draft.updateUserGroupSuccess = true;
                draft.updateUserGroupIsLoading = false;
                draft.updateUserGroupError = { hasError: false, description: "" };
                break;
            case userGroupsActionTypes.UPDATE_USER_GROUP_FAILURE:
                draft.updateUserGroupIsLoading = false;
                draft.updateUserGroupError = {
                    hasError: true,
                    description: action.err
                };
                break;
            case userGroupsActionTypes.TOGGLE_ARCHIVE_USER_GROUP:
                draft.archiveUserGroupIsLoading = true;
                break;
            case userGroupsActionTypes.TOGGLE_ARCHIVE_USER_GROUP_SUCCESS:
                draft.archiveUserGroupSuccess = true;
                draft.archiveUserGroupIsLoading = false;
                draft.archiveUserGroupError = { hasError: false, description: "" };
                break;
            case userGroupsActionTypes.TOGGLE_ARCHIVE_USER_GROUP_FAILURE:
                draft.archiveUserGroupIsLoading = false;
                draft.archiveUserGroupError = {
                    hasError: true,
                    description: action.err
                };
                break;
            case userGroupsActionTypes.RESET_SUCCESS_STATE:
                draft.createUserGroupSuccess = false;
                draft.updateUserGroupSuccess = false;
                draft.archiveUserGroupSuccess = false;
                break;
            default:
                break;
        }
    });
}
