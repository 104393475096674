import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import useStyles from "../../styles/styles";
export default function InfoViewColored(props) {
    var title = props.title, value = props.value, _a = props.boxLonger, boxLonger = _a === void 0 ? false : _a;
    var classes = useStyles();
    return (React.createElement(Grid, { item: true, lg: boxLonger ? 12 : 6 }, React.createElement("div", { className: classes.viewTaskBody }, React.createElement(Typography, { className: classes.taskDetailCardTitle }, title), React.createElement(Typography, { className: classes.taskDetailCardBody }, value))));
}
export function InfoViewColoredMultiLine(props) {
    var title = props.title, value = props.value, value2 = props.value2, _a = props.boxLonger, boxLonger = _a === void 0 ? false : _a;
    var classes = useStyles();
    return (React.createElement(Grid, { item: true, lg: boxLonger ? 12 : 6 }, React.createElement("div", { className: classes.viewTaskBody }, React.createElement(Typography, { className: classes.taskDetailCardTitle }, title), React.createElement(Typography, { className: classes.taskDetailCardBody }, value), React.createElement(Typography, { className: classes.taskDetailCardBody }, value2))));
}
