/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { taskStatisticsActionTypes } from "./actions";
var initialState = {
    tasks: {
        completed: 0,
        completedPercentage: 0,
        total: 0,
        totalPercentage: 0,
        overdueNotCompleted: 0,
        overdueNotCompletedPercentage: 0,
        overdueCompleted: 0,
        overdueCompletedPercentage: 0,
        overdueForDispatch: 0,
        overdueForDispatchPercentage: 0,
        taskStatus: {
            queued: 1,
            pending: 0,
            dispatched: 0,
            travel: 0,
            started: 0,
            completed: 0,
            cancelled: 0
        }
    },
    fetchTaskStatisticsIsLoading: false,
    fetchTaskStatisticsError: { hasError: false, description: "" },
    cancellationRequest: {
        total: 0,
        totalPercentage: 0,
        pending: 0,
        pendingPercentage: 0,
        approved: 0,
        approvedPercentage: 0,
        rejected: 0,
        rejectedPercentage: 0
    },
    fetchCancelRequestsStatisticsIsLoading: false,
    fetchCancelRequestsStatisticsError: { hasError: false, description: "" },
    fetchUnusualStatisticsIsLoading: false,
    noAttendance: 0,
    noAttendancePercentage: 0,
    fetchNoAttendanceStatisticsIsLoading: false,
    fetchNoAttendanceStatisticsError: { hasError: false, description: "" },
    noCoverage: 0,
    noCoveragePercentage: 0,
    fetchNoCoverageStatisticsIsLoading: false,
    fetchNoCoverageStatisticsError: { hasError: false, description: "" },
    noMovement: 0,
    noMovementPercentage: 0,
    fetchNoMovementStatisticsIsLoading: false,
    fetchNoMovementStatisticsError: { hasError: false, description: "" },
    taskCompletion: {
        supervisors: [],
        assignees: [],
        completed: 0,
        notCompleted: 0,
        totalTaskRelativeGrowth: 0,
        completedTaskRelativeGrowth: 0
    },
    taskOverdue: {
        supervisors: [],
        assignees: [],
        totalPending: 0,
        totalCompleted: 0,
        totalDispatched: 0,
        overdueCompletedRelativeGrowth: 0,
        overdueDispatchedRelativeGrowth: 0,
        overduePendingRelativeGrowth: 0
    },
    taskStatusBreakdown: {
        supervisors: [],
        assignees: [],
        totalQueued: 0,
        totalPending: 0,
        totalDispatched: 0,
        totalTraveling: 0,
        totalStarted: 0,
        totalCompleted: 0,
        totalCancelled: 0
    },
    unusualActivity: {
        users: [],
        totalNoAttendance: 0,
        totalNoMovement: 0,
        totalNoCoverage: 0,
        noAttendanceRelativeGrowth: 0,
        noMovementRelativeGrowth: 0,
        noCoverageRelativeGrowth: 0
    },
    fetchTaskCompletionIsLoading: false,
    fetchTaskStatusBreakdownIsLoading: false,
    fetchTaskOverdueIsLoading: false,
    fetchUnusualActivitiesIsLoading: false,
    fetchTaskCompletionError: { hasError: false, description: "" },
    fetchTaskOverdueError: { hasError: false, description: "" },
    fetchTaskStatusBreakdownStatisticsError: { hasError: false, description: "" },
    fetchUnusualActivityError: { hasError: false, description: "" },
    taskStatisticFilters: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
        endDate: new Date(),
        groupFilter: "",
        userFilter: "",
        loggedInUserRole: "",
        taskTypeFilter: "",
        priorityFilter: 0,
        sourceFilter: "",
        daysFilter: "7_days",
        creatorFilter: []
    }
};
export default function taskStatisticsReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case taskStatisticsActionTypes.SET_STATISTICS_FILTERS: {
                draft.taskStatisticFilters = {
                    startDate: action.startDate,
                    endDate: action.endDate,
                    groupFilter: action.groupFilter,
                    userFilter: action.userFilter,
                    loggedInUserRole: action.loggedInUserRole,
                    taskTypeFilter: action.taskTypeFilter,
                    priorityFilter: action.priorityFilter,
                    sourceFilter: action.sourceFilter,
                    daysFilter: action.daysFilter,
                    creatorFilter: action.creatorFilter
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATISTICS: {
                draft.fetchTaskStatisticsIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATISTICS_CLOUD: {
                draft.fetchUnusualStatisticsIsLoading = true;
                draft.fetchTaskStatisticsIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATISTICS_SUCCESS: {
                draft.tasks = action.tasks;
                draft.fetchTaskStatisticsIsLoading = false;
                draft.fetchTaskStatisticsError = { hasError: false, description: "" };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATISTICS_SUCCESS_CLOUD: {
                draft.tasks = action.tasks;
                draft.cancellationRequest = action.cancellationRequest;
                /*        draft.noAttendance = action.noAttendance;
                draft.noAttendancePercentage = action.noAttendancePercentage;
                draft.noCoverage = action.noCoverage;
                draft.noCoveragePercentage = action.noCoveragePercentage;
                draft.noMovement = action.noMovement;
                draft.noMovementPercentage = action.noMovementPercentage; */
                draft.fetchTaskStatisticsIsLoading = false;
                draft.fetchTaskStatisticsError = { hasError: false, description: "" };
                break;
            }
            case taskStatisticsActionTypes.FETCH_UNUSUAL_STATISTICS_SUCCESS_CLOUD: {
                draft.fetchUnusualStatisticsIsLoading = false;
                draft.noAttendance = action.noAttendance;
                draft.noAttendancePercentage = action.noAttendancePercentage;
                draft.noCoverage = action.noCoverage;
                draft.noCoveragePercentage = action.noCoveragePercentage;
                draft.noMovement = action.noMovement;
                draft.noMovementPercentage = action.noMovementPercentage;
                break;
            }
            case taskStatisticsActionTypes.FETCH_UNUSUAL_STATISTICS_FAILURE_CLOUD: {
                draft.fetchUnusualStatisticsIsLoading = false;
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATISTICS_FAILURE_CLOUD: {
                draft.fetchTaskStatisticsIsLoading = false;
                draft.fetchTaskStatisticsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATISTICS_FAILURE: {
                draft.fetchTaskStatisticsIsLoading = false;
                draft.fetchTaskStatisticsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_COMPLETION_STATISTICS: {
                draft.fetchTaskCompletionIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_COMPLETION_STATISTICS_SUCCESS: {
                draft.taskCompletion = action.taskCompletion;
                draft.fetchTaskCompletionIsLoading = false;
                draft.fetchTaskCompletionError = { hasError: false, description: "" };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_COMPLETION_STATISTICS_FAILURE: {
                draft.fetchTaskCompletionIsLoading = false;
                draft.fetchTaskCompletionError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_OVERDUE_STATISTICS: {
                draft.fetchTaskOverdueIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_OVERDUE_STATISTICS_SUCCESS: {
                draft.taskOverdue = action.taskOverdue;
                draft.fetchTaskOverdueIsLoading = false;
                draft.fetchTaskOverdueError = { hasError: false, description: "" };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_OVERDUE_STATISTICS_FAILURE: {
                draft.fetchTaskOverdueIsLoading = false;
                draft.fetchTaskOverdueError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATUS_BREAKDOWN_STATISTICS: {
                draft.fetchTaskOverdueIsLoading = true;
                draft.fetchTaskStatusBreakdownIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATUS_BREAKDOWN_STATISTICS_SUCCESS: {
                draft.taskStatusBreakdown = action.taskStatusBreakdown;
                draft.fetchTaskStatusBreakdownIsLoading = false;
                draft.fetchTaskStatusBreakdownStatisticsError = { hasError: false, description: "" };
                break;
            }
            case taskStatisticsActionTypes.FETCH_TASK_STATUS_BREAKDOWN_STATISTICS_FAILURE: {
                draft.fetchTaskStatusBreakdownIsLoading = false;
                draft.fetchTaskStatusBreakdownStatisticsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_UNUSUAL_ACTIVITIES_STATISTICS: {
                draft.fetchUnusualActivitiesIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_UNUSUAL_ACTIVITIES_STATISTICS_SUCCESS: {
                draft.unusualActivity = action.unusualActivity;
                draft.fetchUnusualActivitiesIsLoading = false;
                draft.fetchUnusualActivityError = { hasError: false, description: "" };
                break;
            }
            case taskStatisticsActionTypes.FETCH_UNUSUAL_ACTIVITIES_STATISTICS_FAILURE: {
                draft.fetchUnusualActivitiesIsLoading = false;
                draft.fetchUnusualActivityError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_CANCEL_REQUESTS_STATISTICS: {
                draft.fetchCancelRequestsStatisticsIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_CANCEL_REQUESTS_STATISTICS_SUCCESS: {
                draft.cancellationRequest = action.cancellationRequest;
                draft.fetchCancelRequestsStatisticsIsLoading = false;
                draft.fetchCancelRequestsStatisticsError = {
                    hasError: false,
                    description: ""
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_CANCEL_REQUESTS_STATISTICS_FAILURE: {
                draft.fetchCancelRequestsStatisticsIsLoading = false;
                draft.fetchCancelRequestsStatisticsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_ATTENDANCE_STATISTICS: {
                draft.fetchNoAttendanceStatisticsIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_ATTENDANCE_STATISTICS_SUCCESS: {
                draft.noAttendance = action.noAttendance;
                draft.noAttendancePercentage = action.noAttendancePercentage;
                draft.fetchNoAttendanceStatisticsIsLoading = false;
                draft.fetchNoAttendanceStatisticsError = {
                    hasError: false,
                    description: ""
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_ATTENDANCE_STATISTICS_FAILURE: {
                draft.fetchNoAttendanceStatisticsIsLoading = false;
                draft.fetchNoAttendanceStatisticsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_COVERAGE_STATISTICS: {
                draft.fetchNoCoverageStatisticsIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_COVERAGE_STATISTICS_SUCCESS: {
                draft.noCoverage = action.noCoverage;
                draft.noCoveragePercentage = action.noCoveragePercentage;
                draft.fetchNoCoverageStatisticsIsLoading = false;
                draft.fetchNoCoverageStatisticsError = {
                    hasError: false,
                    description: ""
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_COVERAGE_STATISTICS_FAILURE: {
                draft.fetchNoCoverageStatisticsIsLoading = false;
                draft.fetchNoCoverageStatisticsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_MOVEMENT_STATISTICS: {
                draft.fetchNoMovementStatisticsIsLoading = true;
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_MOVEMENT_STATISTICS_SUCCESS: {
                draft.noMovement = action.noMovement;
                draft.noMovementPercentage = action.noMovementPercentage;
                draft.fetchNoMovementStatisticsIsLoading = false;
                draft.fetchNoMovementStatisticsError = {
                    hasError: false,
                    description: ""
                };
                break;
            }
            case taskStatisticsActionTypes.FETCH_NO_MOVEMENT_STATISTICS_FAILURE: {
                draft.fetchNoMovementStatisticsIsLoading = false;
                draft.fetchNoMovementStatisticsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskStatisticsActionTypes.RESET_STATISTICS: {
                draft.tasks = {
                    completed: 0,
                    completedPercentage: 0,
                    total: 0,
                    totalPercentage: 0,
                    overdueNotCompleted: 0,
                    overdueNotCompletedPercentage: 0,
                    overdueCompleted: 0,
                    overdueCompletedPercentage: 0,
                    overdueForDispatch: 0,
                    overdueForDispatchPercentage: 0,
                    taskStatus: {
                        queued: 1,
                        pending: 0,
                        dispatched: 0,
                        travel: 0,
                        started: 0,
                        completed: 0,
                        cancelled: 0
                    }
                };
                draft.fetchTaskStatisticsIsLoading = false;
                draft.fetchTaskStatisticsError = { hasError: false, description: "" };
                draft.cancellationRequest = {
                    total: 0,
                    totalPercentage: 0,
                    pending: 0,
                    pendingPercentage: 0,
                    approved: 0,
                    approvedPercentage: 0,
                    rejected: 0,
                    rejectedPercentage: 0
                };
                draft.fetchCancelRequestsStatisticsIsLoading = false;
                draft.fetchCancelRequestsStatisticsError = { hasError: false, description: "" };
                draft.noAttendance = 0;
                draft.noAttendancePercentage = 0;
                draft.fetchNoAttendanceStatisticsIsLoading = false;
                draft.fetchNoAttendanceStatisticsError = { hasError: false, description: "" };
                draft.noCoverage = 0;
                draft.noCoveragePercentage = 0;
                draft.fetchNoCoverageStatisticsIsLoading = false;
                draft.fetchNoCoverageStatisticsError = { hasError: false, description: "" };
                draft.noMovement = 0;
                draft.noMovementPercentage = 0;
                draft.fetchNoMovementStatisticsIsLoading = false;
                draft.fetchNoMovementStatisticsError = { hasError: false, description: "" };
                break;
            }
            default:
                break;
        }
    });
}
