export var FIRESTORE_ALERTS_COLLECTION = "Alerts";
export var FIRESTORE_SMART_ALERTS_COLLECTION = "SmartAlerts";
export var FIRESTORE_ANNOUNCEMENTS_COLLECTION = "Announcements";
export var FIRESTORE_CANCEL_REQUESTS_COLLECTION = "CancelRequests";
export var FIRESTORE_SKILLS_COLLECTION = "Skills";
export var FIRESTORE_TASKS_COLLECTION = "Tasks";
export var FIRESTORE_USER_GROUPS_COLLECTION = "UserGroups";
export var FIRESTORE_USERS_COLLECTION = "Users";
export var FIRESTORE_TASK_SCHEDULES_COLLECTION = "TaskSchedules";
export var FIRESTORE_ABSENCES_SUB_COLLECTION = "Absences";
export var FIRESTORE_HISTORIES_SUB_COLLECTION = "Histories";
export var FIRESTORE_LOCATIONS_SUB_COLLECTION = "Locations";
