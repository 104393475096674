import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY_BLUE } from "../../utils/colorCodes";

const useStyles = makeStyles(() => ({
  main: {
    paddingTop: 50,
    paddingBottom: 75,
    color: PRIMARY_BLUE
  },
  footer: {
    display: "flex",
    alignItems: "center",
    marginTop: "36px"
  },
  backButton: {
    position: "absolute",
    left: "0%"
  },
  subTitle: {
    color: "#969696B1",
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.429,
    marginRight: 16
  },
  footerLogo: {
    width: 64
  },
  paragraphText: {
    textAlign: "justify"
  },
  listItem: {
    marginLeft: 40
  },
  topic: {
    marginTop: 25,
    marginBottom: 15,
    textAlign: "left"
  }
}));

export default useStyles;
