import React, { useContext, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Redirect, Route, Switch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DrawerPanel from "./DrawerPanel";
import useStyles from "./styles";
import NotFound from "../NotFound/NotFound";
import AppHeaderBar from "./AppHeaderBar";
import UserContext from "../Login/UserProvider";
import { fetchLocationsAction } from "../../utils/remoteConfig/locations";
import { fetchRolesAction } from "../../utils/remoteConfig/roles";
import { fetchSkillsAction } from "../Skills/actions";
import { fetchUserGroupsAction } from "../UserGroups/actions";
import { fetchTaskStatesAction } from "../../utils/remoteConfig/taskStates";
import { fetchTaskTypesAction } from "../../utils/remoteConfig/taskTypes";
import { fetchFurtherActionsAction } from "../../utils/remoteConfig/furtherActions";
import { fetchLoggedInUserAction } from "../Login/action";
import { fetchUpdateUserLoginAction, fetchUserAction, findSubordinateAndCascadeUserAction } from "../Users/action";
import AccessDeniedDialog from "./AccessDeniedDialog";
import ViewUser from "../Users/ViewUser";
import { fetchAccessControlAction } from "../../utils/remoteConfig/accessControl";
import { isUserHasPermission } from "../../utils/isUserHasPermission";
import Tasks from "../Tasks/Pages/Tasks";
import Users from "../Users/Users";
import UserProfile from "../Users/UserProfile";
import ViewTask from "../Tasks/Pages/ViewTask";
import { listenAlertAction } from "../Alerts/actions";
import { fetchAnnouncementAction } from "../Announcements/actions";
export default function Dashboard() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43;
    var dispatch = useDispatch();
    var classes = useStyles();
    var user = useContext(UserContext);
    React.useEffect(function () {
        if (user === null) {
            // history.push('/login');
        }
        else {
            /* remote config */
            dispatch(fetchTaskStatesAction());
            dispatch(fetchTaskTypesAction());
            dispatch(fetchLocationsAction());
            dispatch(fetchRolesAction());
            dispatch(fetchAccessControlAction());
            dispatch(fetchFurtherActionsAction());
            /* fetch from firestore */
            dispatch(fetchSkillsAction());
            dispatch(fetchUserAction());
        }
    }, [dispatch, user]);
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    var accessControl = useSelector(function (state) { return state.accessControlManagement; }).accessControl;
    var _44 = useSelector(function (state) { return state.userManagement; }), userHierarchy = _44.userHierarchy, userList = _44.userList;
    useEffect(function () {
        var _a, _b;
        if (userList.length > 0) {
            // @ts-ignore
            dispatch(fetchLoggedInUserAction((_a = user) === null || _a === void 0 ? void 0 : _a.uid, (_b = user) === null || _b === void 0 ? void 0 : _b.email));
        }
    }, [dispatch, (_a = user) === null || _a === void 0 ? void 0 : _a.email, (_b = user) === null || _b === void 0 ? void 0 : _b.uid, userList]);
    useEffect(function () {
        var _a;
        if ((_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.documentID) {
            dispatch(fetchUserGroupsAction(loggedInUser));
            if (userHierarchy.length > 0) {
                dispatch(findSubordinateAndCascadeUserAction(loggedInUser.documentID, userHierarchy));
            }
            var d = new Date();
            var startDate = new Date(d.setMonth(d.getMonth() - 1));
            var endDate = new Date();
            dispatch(listenAlertAction(startDate, endDate, loggedInUser.documentID));
            dispatch(fetchAnnouncementAction(startDate, endDate));
        }
    }, [dispatch, loggedInUser, userHierarchy]);
    // --------------Use Effect to update doc when user logged in ------------------
    var UserLoginMarked = useSelector(function (state) { return state.userManagement; }).UserLoginMarked;
    useEffect(function () {
        if (!UserLoginMarked && loggedInUser) {
            dispatch(fetchUpdateUserLoginAction(loggedInUser));
        }
    }, [dispatch, loggedInUser, userHierarchy, UserLoginMarked]);
    // const LoadableTasks = React.lazy(() => import('../Tasks/Tasks'));
    // const LoadableViewTask = React.lazy(() => import('../Tasks/ViewTask'));
    var LoadableCancellationRequests = React.lazy(function () { return import("../CancellationRequests/CancellationRequests"); });
    var LoadableAnnouncements = React.lazy(function () { return import("../Announcements/Announcements"); });
    var LoadableViewAnnouncement = React.lazy(function () { return import("../Announcements/ViewAnnouncement"); });
    // const LoadableUsers = React.lazy(() => import('../Users/Users'));
    // const LoadableViewUser = React.lazy(() => import('../Users/ViewUser'));
    var LoadableUserGroups = React.lazy(function () { return import("../UserGroups/UserGroups"); });
    var LoadableSkills = React.lazy(function () { return import("../Skills/Skills"); });
    var LoadableNoAttendance = React.lazy(function () { return import("../NoAttendance/NoAttendance"); });
    var LoadableNoCoverage = React.lazy(function () { return import("../NoCoverage/NoCoverage"); });
    var LoadableNoMovement = React.lazy(function () { return import("../NoMovement/NoMovement"); });
    var LoadableRouteHistory = React.lazy(function () { return import("../RouteHistory/RouteHistory"); });
    var LoadableLiveLocations = React.lazy(function () { return import("../LiveLocations/LiveLocations"); });
    var LoadableTaskStatistics = React.lazy(function () { return import("../TaskStatistics/TaskStatistics"); });
    var LoadableTaskCompletions = React.lazy(function () { return import("../TaskStatistics/TaskCompletion"); });
    var LoadableOverdueTasks = React.lazy(function () { return import("../TaskStatistics/OverdueTasks"); });
    var LoadableUnusualActivities = React.lazy(function () { return import("../TaskStatistics/UnusualActivities"); });
    var LoadableTaskStatusBreakdown = React.lazy(function () { return import("../TaskStatistics/TaskStatusBreakdown"); });
    var LoadableNotifications = React.lazy(function () { return import("../Alerts/Alerts"); });
    var LoadableAlertManager = React.lazy(function () { return import("../AlertsManager/Pages/Alerts"); });
    var LoadableLiveMap = React.lazy(function () { return import("../LiveMap/LiveMap"); });
    var location = useLocation();
    return (React.createElement("div", { className: classes.root }, React.createElement(AccessDeniedDialog, null), React.createElement(CssBaseline, null), React.createElement(AppHeaderBar, null), location.pathname === "/dashboard/live-map" ? null : React.createElement(DrawerPanel, null), React.createElement("main", { className: classes.content }, React.createElement("div", { className: classes.appBarSpacer }), React.createElement(Container, { maxWidth: "lg" }, React.createElement(React.Suspense, { fallback: React.createElement("div", null) }, React.createElement(Switch, null, React.createElement(Route, { exact: true, path: "/dashboard/tasks", component: isUserHasPermission((_d = (_c = accessControl) === null || _c === void 0 ? void 0 : _c.tasks) === null || _d === void 0 ? void 0 : _d.view_tab, (_e = loggedInUser) === null || _e === void 0 ? void 0 : _e.role) ? Tasks : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/tasks/:id", component: isUserHasPermission((_g = (_f = accessControl) === null || _f === void 0 ? void 0 : _f.tasks) === null || _g === void 0 ? void 0 : _g.view_individual, (_h = loggedInUser) === null || _h === void 0 ? void 0 : _h.role) ? ViewTask : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/cancellation-requests", component: isUserHasPermission((_k = (_j = accessControl) === null || _j === void 0 ? void 0 : _j.cancellation_requests) === null || _k === void 0 ? void 0 : _k.view_tab, (_l = loggedInUser) === null || _l === void 0 ? void 0 : _l.role)
            ? LoadableCancellationRequests
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/announcements", component: isUserHasPermission((_o = (_m = accessControl) === null || _m === void 0 ? void 0 : _m.announcements) === null || _o === void 0 ? void 0 : _o.view_tab, (_p = loggedInUser) === null || _p === void 0 ? void 0 : _p.role)
            ? LoadableAnnouncements
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/announcements/:id", component: isUserHasPermission((_r = (_q = accessControl) === null || _q === void 0 ? void 0 : _q.announcements) === null || _r === void 0 ? void 0 : _r.view_individual, (_s = loggedInUser) === null || _s === void 0 ? void 0 : _s.role)
            ? LoadableViewAnnouncement
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/users", component: isUserHasPermission((_u = (_t = accessControl) === null || _t === void 0 ? void 0 : _t.users) === null || _u === void 0 ? void 0 : _u.view_tab, (_v = loggedInUser) === null || _v === void 0 ? void 0 : _v.role) ? Users : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/user-profile", component: UserProfile }), React.createElement(Route, { exact: true, path: "/dashboard/users/:id", component: isUserHasPermission((_x = (_w = accessControl) === null || _w === void 0 ? void 0 : _w.users) === null || _x === void 0 ? void 0 : _x.view_individual, (_y = loggedInUser) === null || _y === void 0 ? void 0 : _y.role) ? ViewUser : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/user-groups", component: isUserHasPermission((_0 = (_z = accessControl) === null || _z === void 0 ? void 0 : _z.user_groups) === null || _0 === void 0 ? void 0 : _0.view_tab, (_1 = loggedInUser) === null || _1 === void 0 ? void 0 : _1.role)
            ? LoadableUserGroups
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/skills", component: isUserHasPermission((_3 = (_2 = accessControl) === null || _2 === void 0 ? void 0 : _2.skills) === null || _3 === void 0 ? void 0 : _3.view_tab, (_4 = loggedInUser) === null || _4 === void 0 ? void 0 : _4.role) ? LoadableSkills : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/no-attendance", component: isUserHasPermission((_6 = (_5 = accessControl) === null || _5 === void 0 ? void 0 : _5.no_attendance) === null || _6 === void 0 ? void 0 : _6.view_tab, (_7 = loggedInUser) === null || _7 === void 0 ? void 0 : _7.role)
            ? LoadableNoAttendance
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/no-coverage", component: isUserHasPermission((_9 = (_8 = accessControl) === null || _8 === void 0 ? void 0 : _8.no_coverage) === null || _9 === void 0 ? void 0 : _9.view_tab, (_10 = loggedInUser) === null || _10 === void 0 ? void 0 : _10.role)
            ? LoadableNoCoverage
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/no-movement", component: isUserHasPermission((_12 = (_11 = accessControl) === null || _11 === void 0 ? void 0 : _11.no_movement) === null || _12 === void 0 ? void 0 : _12.view_tab, (_13 = loggedInUser) === null || _13 === void 0 ? void 0 : _13.role)
            ? LoadableNoMovement
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/live-locations", component: isUserHasPermission((_15 = (_14 = accessControl) === null || _14 === void 0 ? void 0 : _14.live_locations) === null || _15 === void 0 ? void 0 : _15.view_tab, (_16 = loggedInUser) === null || _16 === void 0 ? void 0 : _16.role)
            ? LoadableLiveLocations
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/live-map", component: isUserHasPermission((_18 = (_17 = accessControl) === null || _17 === void 0 ? void 0 : _17.live_locations) === null || _18 === void 0 ? void 0 : _18.view_tab, (_19 = loggedInUser) === null || _19 === void 0 ? void 0 : _19.role)
            ? LoadableLiveMap
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/route-history/:id", component: isUserHasPermission((_21 = (_20 = accessControl) === null || _20 === void 0 ? void 0 : _20.route_history) === null || _21 === void 0 ? void 0 : _21.view_tab, (_22 = loggedInUser) === null || _22 === void 0 ? void 0 : _22.role)
            ? LoadableRouteHistory
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/tasks-statistics", component: isUserHasPermission((_24 = (_23 = accessControl) === null || _23 === void 0 ? void 0 : _23.tasks_statistics) === null || _24 === void 0 ? void 0 : _24.view_tab, (_25 = loggedInUser) === null || _25 === void 0 ? void 0 : _25.role)
            ? LoadableTaskStatistics
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/tasks-completions", component: isUserHasPermission((_27 = (_26 = accessControl) === null || _26 === void 0 ? void 0 : _26.tasks_statistics) === null || _27 === void 0 ? void 0 : _27.view_tab, (_28 = loggedInUser) === null || _28 === void 0 ? void 0 : _28.role)
            ? LoadableTaskCompletions
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/overdue-tasks", component: isUserHasPermission((_30 = (_29 = accessControl) === null || _29 === void 0 ? void 0 : _29.tasks_statistics) === null || _30 === void 0 ? void 0 : _30.view_tab, (_31 = loggedInUser) === null || _31 === void 0 ? void 0 : _31.role)
            ? LoadableOverdueTasks
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/unusual-activities", component: isUserHasPermission((_33 = (_32 = accessControl) === null || _32 === void 0 ? void 0 : _32.tasks_statistics) === null || _33 === void 0 ? void 0 : _33.view_tab, (_34 = loggedInUser) === null || _34 === void 0 ? void 0 : _34.role)
            ? LoadableUnusualActivities
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/task-status-breakdown", component: isUserHasPermission((_36 = (_35 = accessControl) === null || _35 === void 0 ? void 0 : _35.tasks_statistics) === null || _36 === void 0 ? void 0 : _36.view_tab, (_37 = loggedInUser) === null || _37 === void 0 ? void 0 : _37.role)
            ? LoadableTaskStatusBreakdown
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/alerts-manager", component: isUserHasPermission((_39 = (_38 = accessControl) === null || _38 === void 0 ? void 0 : _38.alerts) === null || _39 === void 0 ? void 0 : _39.view_tab, (_40 = loggedInUser) === null || _40 === void 0 ? void 0 : _40.role)
            ? LoadableAlertManager
            : NotFound }), React.createElement(Route, { exact: true, path: "/dashboard/notifications", component: isUserHasPermission((_42 = (_41 = accessControl) === null || _41 === void 0 ? void 0 : _41.notifications) === null || _42 === void 0 ? void 0 : _42.view_tab, (_43 = loggedInUser) === null || _43 === void 0 ? void 0 : _43.role)
            ? LoadableNotifications
            : NotFound }), React.createElement(Route, { path: "/" }, React.createElement(Redirect, { to: "/dashboard/tasks" })), React.createElement(Route, { component: NotFound }))), React.createElement("div", { style: { height: 30 } })))));
}
