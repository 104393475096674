import firebase from "firebase";
import { firestore } from "../firebaseConnector";
import { FIRESTORE_TASKS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../firestoreCollections";
export function cancelRequestMapper(doc) {
    var _a, _b, _c, _d;
    var _e = doc.data(), task = _e.task, taskType = _e.taskType, reason = _e.reason, requestedBy = _e.requestedBy, requestedAt = _e.requestedAt, handledBy = _e.handledBy, state = _e.state;
    return {
        id: doc.id,
        task: (_a = task) === null || _a === void 0 ? void 0 : _a.id,
        taskType: taskType,
        reason: reason,
        requestedBy: (_b = requestedBy) === null || _b === void 0 ? void 0 : _b.id,
        requestedAt: ((_c = requestedAt) === null || _c === void 0 ? void 0 : _c.seconds) * 1000,
        handledBy: (_d = handledBy) === null || _d === void 0 ? void 0 : _d.id,
        state: state
    };
}
export function cancelRequestCreator(cancellationRequest) {
    return {
        handledBy: null,
        reason: cancellationRequest.reason,
        requestedAt: firebase.firestore.Timestamp.fromMillis(cancellationRequest.requestedAt),
        requestedBy: firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + cancellationRequest.requestedBy),
        task: firestore.doc(FIRESTORE_TASKS_COLLECTION + "/" + cancellationRequest.task),
        taskType: cancellationRequest.taskType,
        state: 0
    };
}
