/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { announcementsActionTypes } from "./actions";
var initialState = {
    announcementList: [],
    fetchAnnouncementIsLoading: false,
    fetchAnnouncementError: { hasError: false, description: "" },
    singleAnnouncement: undefined,
    fetchSingleAnnouncementIsLoading: false,
    fetchSingleAnnouncementError: { description: "", hasError: false },
    createAnnouncementSuccess: false,
    createAnnouncementIsLoading: false,
    createAnnouncementError: { description: "", hasError: false },
    archiveAnnouncementSuccess: false,
    archiveAnnouncementIsLoading: false,
    archiveAnnouncementError: { description: "", hasError: false }
};
export default function announcementsReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case announcementsActionTypes.FETCH_ANNOUNCEMENTS: {
                draft.fetchAnnouncementIsLoading = true;
                break;
            }
            case announcementsActionTypes.FETCH_ANNOUNCEMENTS_SUCCESS: {
                draft.announcementList = action.announcements;
                draft.fetchAnnouncementIsLoading = false;
                draft.fetchAnnouncementError = { hasError: false, description: "" };
                break;
            }
            case announcementsActionTypes.FETCH_ANNOUNCEMENTS_FAILURE: {
                draft.fetchAnnouncementIsLoading = false;
                draft.fetchAnnouncementError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case announcementsActionTypes.FETCH_SINGLE_ANNOUNCEMENT: {
                draft.fetchSingleAnnouncementIsLoading = true;
                break;
            }
            case announcementsActionTypes.FETCH_SINGLE_ANNOUNCEMENT_SUCCESS: {
                draft.singleAnnouncement = action.announcement;
                draft.fetchSingleAnnouncementIsLoading = false;
                draft.fetchSingleAnnouncementError = {
                    hasError: false,
                    description: ""
                };
                break;
            }
            case announcementsActionTypes.FETCH_SINGLE_ANNOUNCEMENT_FAILURE: {
                draft.fetchSingleAnnouncementIsLoading = false;
                draft.fetchSingleAnnouncementError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case announcementsActionTypes.CREATE_ANNOUNCEMENT: {
                draft.createAnnouncementIsLoading = true;
                break;
            }
            case announcementsActionTypes.CREATE_ANNOUNCEMENT_SUCCESS: {
                draft.createAnnouncementSuccess = true;
                draft.createAnnouncementIsLoading = false;
                draft.createAnnouncementError = { hasError: false, description: "" };
                break;
            }
            case announcementsActionTypes.CREATE_ANNOUNCEMENT_FAILURE: {
                draft.createAnnouncementIsLoading = false;
                draft.createAnnouncementError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case announcementsActionTypes.ARCHIVE_ANNOUNCEMENT: {
                draft.archiveAnnouncementIsLoading = true;
                break;
            }
            case announcementsActionTypes.ARCHIVE_ANNOUNCEMENT_SUCCESS: {
                draft.archiveAnnouncementSuccess = true;
                draft.archiveAnnouncementIsLoading = false;
                draft.archiveAnnouncementError = { hasError: false, description: "" };
                break;
            }
            case announcementsActionTypes.ARCHIVE_ANNOUNCEMENT_FAILURE: {
                draft.archiveAnnouncementIsLoading = false;
                draft.archiveAnnouncementError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case announcementsActionTypes.RESET_SUCCESS_STATE: {
                draft.createAnnouncementSuccess = false;
                draft.archiveAnnouncementSuccess = false;
                draft.singleAnnouncement = undefined;
                break;
            }
            default:
                break;
        }
    });
}
