import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { acceptCancellationRequestAction, rejectCancellationRequestAction } from "./action";
import { ERROR_HIGH, LIGHT_BLUE, PRIMARY_BLUE } from "../../utils/colorCodes";
import { TASK_STATE_QUEUED } from "../../utils/remoteConfig/taskStates/taskStateEnum";
import { changeTaskStatusApiAction } from "../Tasks/redux/actions";
export default function AcceptOrRejectRequestModal(props) {
    var currentCancellationRequest = props.currentCancellationRequest, currentTask = props.currentTask, dialogType = props.dialogType, isAcceptOrRejectRequestModalOpen = props.isAcceptOrRejectRequestModalOpen, setAcceptOrRejectRequestModalOpen = props.setAcceptOrRejectRequestModalOpen;
    var dispatch = useDispatch();
    var handleCloseAcceptOrRejectRequestModal = function () {
        setAcceptOrRejectRequestModalOpen(false);
    };
    return (React.createElement(Dialog
    // disableBackdropClick
    , {
        // disableBackdropClick
        open: isAcceptOrRejectRequestModalOpen, onClose: handleCloseAcceptOrRejectRequestModal, scroll: "body", maxWidth: "xs", fullWidth: true, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray"
            }
        }
    }, React.createElement(DialogTitle, { id: "scroll-dialog-title" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { style: { fontSize: 17, fontWeight: "bold", color: PRIMARY_BLUE } }, "Are you sure?"), React.createElement(Button, { onClick: handleCloseAcceptOrRejectRequestModal, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null)))), React.createElement(DialogContent, null, React.createElement(Grid, { container: true, justify: "flex-start" }, dialogType === "accept" ? (React.createElement(Typography, null, "This will cancel the task. You can keep it cancelled or release this task back to the queue. Cancellation notification will be sent any assigned user")) : (React.createElement(Typography, null, "This will reject the cancellation request.")))), React.createElement(DialogActions, { style: { padding: 24 } }, dialogType === "accept" ? (React.createElement(Grid, { container: true }, React.createElement("div", { style: {
            flex: 1,
            marginRight: 15
        } }, React.createElement(Button, { type: "submit", onClick: function () {
            handleCloseAcceptOrRejectRequestModal();
            dispatch(acceptCancellationRequestAction(currentCancellationRequest));
            // dispatch(changeTaskStatusApiAction(currentTask, TASK_STATE_CANCELLED, ""));
        }, color: "primary", fullWidth: true, style: {
            textTransform: "none",
            backgroundColor: ERROR_HIGH,
            color: "white"
        } }, "Cancel the Task")), React.createElement("div", { style: {
            flex: 1
        } }, React.createElement(Button, { type: "submit", onClick: function () {
            handleCloseAcceptOrRejectRequestModal();
            dispatch(acceptCancellationRequestAction(currentCancellationRequest));
            dispatch(changeTaskStatusApiAction(currentTask, TASK_STATE_QUEUED, ""));
        }, color: "primary", fullWidth: true, style: {
            textTransform: "none",
            backgroundColor: LIGHT_BLUE,
            color: "white"
        } }, "Send to Queue")))) : (React.createElement(Button, { type: "submit", onClick: function () {
            handleCloseAcceptOrRejectRequestModal();
            dispatch(rejectCancellationRequestAction(currentCancellationRequest));
        }, color: "primary", fullWidth: true, style: {
            textTransform: "none",
            backgroundColor: ERROR_HIGH,
            color: "white"
        } }, "Reject the request")))));
}
