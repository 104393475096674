/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { liveLocationActionTypes } from "./actions";
var initialState = {
    liveLocations: [],
    liveHistories: [],
    fetchLiveLocationsIsLoading: false,
    fetchLiveHistoriesIsLoading: false,
    fetchLiveLocationsError: { hasError: false, description: "" },
    fetchLiveHistoriesError: { hasError: false, description: "" }
};
export default function liveLocationReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case liveLocationActionTypes.FETCH_LIVE_LOCATIONS: {
                draft.fetchLiveLocationsIsLoading = true;
                break;
            }
            case liveLocationActionTypes.FETCH_LIVE_LOCATIONS_SUCCESS: {
                draft.liveLocations = action.liveLocations;
                draft.fetchLiveLocationsIsLoading = false;
                draft.fetchLiveLocationsError = { hasError: false, description: "" };
                break;
            }
            case liveLocationActionTypes.FETCH_LIVE_LOCATIONS_FAILURE: {
                draft.fetchLiveLocationsIsLoading = false;
                draft.fetchLiveLocationsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case liveLocationActionTypes.FETCH_LIVE_HISTORIES: {
                draft.fetchLiveHistoriesIsLoading = true;
                break;
            }
            case liveLocationActionTypes.FETCH_LIVE_HISTORIES_SUCCESS: {
                draft.liveHistories = action.liveHistories;
                draft.fetchLiveHistoriesIsLoading = false;
                draft.fetchLiveHistoriesError = { hasError: false, description: "" };
                break;
            }
            case liveLocationActionTypes.FETCH_LIVE_HISTORIES_FAILURE: {
                draft.fetchLiveHistoriesIsLoading = false;
                draft.fetchLiveHistoriesError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            default:
                break;
        }
    });
}
