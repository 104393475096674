var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeEvery } from "redux-saga/effects";
import { firestore } from "../../utils/firebaseConnector";
import { getFilteredDaysArray, getFormattedDate } from "../../utils/dateOperations";
import { getGoogleLocationLink } from "../../utils/getGoogleLocationLink";
import { fetchNoAttendanceActivityFailureAction, fetchNoAttendanceActivitySuccessAction, noAttendanceActivityActionTypes } from "./actions";
import { FIRESTORE_ABSENCES_SUB_COLLECTION } from "../../utils/firestoreCollections";
// eslint-disable-next-line @typescript-eslint/no-var-requires
var _ = require("lodash");
// ------------------------------------------------------saga-1----------------------------------------------------------
export function fetchNoAttendanceActivityFromApi(daysArray) {
    return __awaiter(this, void 0, Promise, function () {
        var promiseArray;
        return __generator(this, function (_a) {
            promiseArray = _.chunk(daysArray, 10).map(function (subArray) {
                return firestore
                    .collectionGroup(FIRESTORE_ABSENCES_SUB_COLLECTION)
                    .where("date", "in", subArray)
                    .get()
                    .then(function (querySnapshot) {
                    return querySnapshot.docs.map(function (doc) {
                        var _a, _b, _c, _d;
                        var parentId = (_a = doc.ref.parent.parent) === null || _a === void 0 ? void 0 : _a.id;
                        var _e = doc.data(), date = _e.date, lastCheckOutAt = _e.lastCheckOutAt, lastCheckOutLocation = _e.lastCheckOutLocation;
                        return {
                            parentId: parentId,
                            date: new Date(date).getTime(),
                            lastCheckOutAt: ((_b = lastCheckOutAt) === null || _b === void 0 ? void 0 : _b.seconds) * 1000 || 0,
                            lastCheckOutLocation: {
                                latitude: ((_c = lastCheckOutLocation) === null || _c === void 0 ? void 0 : _c.latitude) || 0,
                                longitude: ((_d = lastCheckOutLocation) === null || _d === void 0 ? void 0 : _d.longitude) || 0
                            }
                        };
                    });
                })
                    .catch(function (e) {
                    throw e;
                });
            });
            return [2 /*return*/, Promise.all(promiseArray)
                    .then(function (values) {
                    return values.map(function (ob) { return ob; });
                })
                    .then(function (r) { return r.flatMap(function (e) { return e; }); })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callNoAttendanceActivity(action) {
    var filteredDaysArray, userList, result, sortedResult, records_1, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                filteredDaysArray = getFilteredDaysArray(action.startDate, action.endDate, action.excludeDates, action.selectedWeekDays);
                return [4 /*yield*/, select(function (state) { return state.userManagement.userList; })];
            case 1:
                userList = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 7]);
                return [4 /*yield*/, call(fetchNoAttendanceActivityFromApi, filteredDaysArray)];
            case 3:
                result = _a.sent();
                sortedResult = result.sort(function (a, b) { return a.date - b.date; }).reverse();
                records_1 = [];
                sortedResult.forEach(function (el) {
                    var _a;
                    records_1.push({
                        userDocumentID: el.parentId,
                        userGroups: (_a = userList.filter(function (ul) { return ul.documentID === el.parentId; })[0]) === null || _a === void 0 ? void 0 : _a.groups,
                        date: getFormattedDate(el.date),
                        lastAttendanceOut: el.lastCheckOutAt === 0 ? "" : getFormattedDate(el.lastCheckOutAt),
                        lastUpdateLocation: el.lastCheckOutLocation.latitude === 0 || el.lastCheckOutLocation.longitude === 0
                            ? ""
                            : getGoogleLocationLink(el.lastCheckOutLocation.latitude, el.lastCheckOutLocation.longitude)
                    });
                });
                return [4 /*yield*/, put(fetchNoAttendanceActivitySuccessAction(records_1))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_1 = _a.sent();
                return [4 /*yield*/, put(fetchNoAttendanceActivityFailureAction(e_1.toString()))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function watchNoAttendanceActivitySagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(noAttendanceActivityActionTypes.FETCH_NO_ATTENDANCE_ACTIVITY, callNoAttendanceActivity)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var noAttendanceActivitySagas = [watchNoAttendanceActivitySagas];
