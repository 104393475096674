import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import * as React from "react";
import { PRIMARY_BLUE, PRIMARY_PINK } from "../../../../utils/colorCodes";
import useStyles from "../../styles/styles";
export default function PrioritySelector(props) {
    var id = props.id, label = props.label, defaultValue = props.defaultValue, onChange = props.onChange, onBlur = props.onBlur, errors = props.errors, touched = props.touched;
    var classes = useStyles();
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement(FormControl, { variant: "filled", className: classes.formControl100 }, React.createElement(InputLabel, { id: "filter-by-task-type-label" }, label), React.createElement(Select, { disableUnderline: true, id: id, defaultValue: defaultValue, onChange: onChange, onBlur: onBlur, style: {
            backgroundColor: PRIMARY_PINK,
            textAlign: "left",
            borderRadius: 4
        } }, React.createElement(MenuItem, { value: 1, style: { color: PRIMARY_BLUE } }, "High"), React.createElement(MenuItem, { value: 2, style: { color: PRIMARY_BLUE } }, "Medium"), React.createElement(MenuItem, { value: 3, style: { color: PRIMARY_BLUE } }, "Low"))), errors.priority && touched.priority && React.createElement("div", { className: classes.error }, errors.priority)));
}
