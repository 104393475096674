import produce from "immer";
import { liveLocationActionTypes } from "./actions";
var initialState = {
    UpdatedLiveLocations: [],
    UpdatedLiveHistories: [],
    fetchUpdatedLiveLocationsIsLoading: false,
    fetchUpdatedLiveHistoriesIsLoading: false,
    fetchUpdatedLiveLocationsError: { hasError: false, description: "" },
    fetchUpdatedLiveHistoriesError: { hasError: false, description: "" }
};
export default function updatedLiveLocationReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case liveLocationActionTypes.FETCH_UPDATED_LIVE_LOCATIONS: {
                draft.fetchUpdatedLiveLocationsIsLoading = true;
                break;
            }
            case liveLocationActionTypes.FETCH_UPDATED_LIVE_LOCATIONS_SUCCESS: {
                draft.UpdatedLiveLocations = action.liveLocations;
                draft.fetchUpdatedLiveLocationsIsLoading = false;
                draft.fetchUpdatedLiveLocationsError = { hasError: false, description: "" };
                break;
            }
            case liveLocationActionTypes.FETCH_UPDATED_LIVE_LOCATIONS_FAILURE: {
                draft.fetchUpdatedLiveLocationsIsLoading = false;
                draft.fetchUpdatedLiveLocationsError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case liveLocationActionTypes.FETCH_UPDATED_LIVE_HISTORIES: {
                draft.fetchUpdatedLiveHistoriesIsLoading = true;
                break;
            }
            case liveLocationActionTypes.FETCH_UPDATED_LIVE_HISTORIES_SUCCESS: {
                draft.UpdatedLiveHistories = action.liveHistories;
                draft.fetchUpdatedLiveHistoriesIsLoading = false;
                draft.fetchUpdatedLiveHistoriesError = { hasError: false, description: "" };
                break;
            }
            case liveLocationActionTypes.FETCH_UPDATED_LIVE_HISTORIES_FAILURE: {
                draft.fetchUpdatedLiveHistoriesIsLoading = false;
                draft.fetchUpdatedLiveHistoriesError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            default:
                break;
        }
    });
}
