import * as React from "react";
import { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { LIGHT_BLUE } from "../../../utils/colorCodes";
import { getFormattedDateTime, getLocalDate } from "../../../utils/dateOperations";
import { fetchSingleCancellationRequestFromFirestore } from "../redux/sagas/callFetchSingleTaskSaga";
var getPriority = function (priority) {
    switch (priority) {
        case 1:
            return "HIGH";
        case 2:
            return "MEDIUM";
        default:
            return "LOW";
    }
};
function getCsvBody(taskList, userList, taskTypes, taskStates, locations, fields) {
    return taskList.map(function (task) {
        var _a;
        return fetchSingleCancellationRequestFromFirestore((_a = task) === null || _a === void 0 ? void 0 : _a.cancelRequest).then(function (r) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
            return [
                fields.id && ((_a = task) === null || _a === void 0 ? void 0 : _a.id),
                fields.source && (((_b = task) === null || _b === void 0 ? void 0 : _b.sourceERP) === null ? "Non ERP" : "ERP"),
                fields.createdOn && getFormattedDateTime((_c = task) === null || _c === void 0 ? void 0 : _c.created),
                fields.createdBy && ((_d = userList.filter(function (el) { var _a; return el.documentID === ((_a = task) === null || _a === void 0 ? void 0 : _a.createdBy); })[0]) === null || _d === void 0 ? void 0 : _d.name),
                fields.type && ((_e = taskTypes.filter(function (el) { var _a; return el.id === ((_a = task) === null || _a === void 0 ? void 0 : _a.taskType); })[0]) === null || _e === void 0 ? void 0 : _e.name),
                fields.refId && (((_f = task) === null || _f === void 0 ? void 0 : _f.refId) || ""),
                fields.desc && ((_g = task) === null || _g === void 0 ? void 0 : _g.description.replace(/"/g, '""')),
                fields.address && ((_h = task) === null || _h === void 0 ? void 0 : _h.address.replace(/"/g, '""')),
                fields.telephone && ((_j = task) === null || _j === void 0 ? void 0 : _j.tp.join(", ")),
                fields.location && ((_k = locations.filter(function (el) { var _a; return el.id === ((_a = task) === null || _a === void 0 ? void 0 : _a.location); })[0]) === null || _k === void 0 ? void 0 : _k.name),
                fields.locationLink && ((_l = task) === null || _l === void 0 ? void 0 : _l.mapLink),
                fields.assignee && ((_m = userList.filter(function (el) { var _a; return el.documentID === ((_a = task) === null || _a === void 0 ? void 0 : _a.assignedTo); })[0]) === null || _m === void 0 ? void 0 : _m.name),
                fields.supervisor && ((_o = userList.filter(function (el) { var _a; return el.documentID === ((_a = task) === null || _a === void 0 ? void 0 : _a.supervisor); })[0]) === null || _o === void 0 ? void 0 : _o.name),
                fields._startDate && getFormattedDateTime((_p = task) === null || _p === void 0 ? void 0 : _p.start),
                fields.dueDate && getFormattedDateTime((_q = task) === null || _q === void 0 ? void 0 : _q.due),
                fields.priority && getPriority((_r = task) === null || _r === void 0 ? void 0 : _r.priority),
                fields.travelReq && (((_s = task) === null || _s === void 0 ? void 0 : _s.travellingRequired) ? "True" : "False"),
                fields.taskState && ((_t = taskStates.filter(function (el) { var _a; return el.id === ((_a = task) === null || _a === void 0 ? void 0 : _a.state); })[0]) === null || _t === void 0 ? void 0 : _t.name),
                fields.cancellationRemark && (((_u = r) === null || _u === void 0 ? void 0 : _u.reason) || ""),
                fields.cancellationReq && getFormattedDateTime((_v = r) === null || _v === void 0 ? void 0 : _v.requestedAt),
                fields.rating && ((_w = task) === null || _w === void 0 ? void 0 : _w.completionRating),
                fields.remarks && ((_x = task) === null || _x === void 0 ? void 0 : _x.completionRemarks),
                fields.nextUser && ((_z = (_y = task) === null || _y === void 0 ? void 0 : _y.nextUser) === null || _z === void 0 ? void 0 : _z.name),
                fields.nextDate && ((_0 = task) === null || _0 === void 0 ? void 0 : _0.nextDate) ? getFormattedDateTime(((_2 = (_1 = task) === null || _1 === void 0 ? void 0 : _1.nextDate) === null || _2 === void 0 ? void 0 : _2.seconds) * 1000) : "-",
                fields.amount && ((_3 = task) === null || _3 === void 0 ? void 0 : _3.amount),
                fields.furtherAction && ((_5 = (_4 = task) === null || _4 === void 0 ? void 0 : _4.furtherAction) === null || _5 === void 0 ? void 0 : _5.split("|").join(","))
            ].filter(function (x) {
                return x !== false;
            });
        });
    });
}
export default function TasksCsvDownload(taskList, startDate, endDate, fields) {
    var csvLink = useRef(null);
    var _a = React.useState([]), data = _a[0], setData = _a[1];
    var userList = useSelector(function (state) { return state.userManagement; }).userList;
    var taskTypes = useSelector(function (state) { return state.taskTypeManagement; }).taskTypes;
    var taskStates = useSelector(function (state) { return state.taskStateManagement; }).taskStates;
    var locations = useSelector(function (state) { return state.locationManagement; }).locations;
    var csvData = [
        [
            fields.id && "Id",
            fields.source && "Task Source",
            fields.createdOn && "Task Created On",
            fields.createdBy && "Task Created by",
            fields.type && "Task Type",
            fields.refId && "Ref ID",
            fields.desc && "Short Description",
            fields.address && "Address",
            fields.telephone && "Telephone Number",
            fields.location && "Location",
            fields.locationLink && "Location Link",
            fields.assignee && "Assignee",
            fields.supervisor && "Supervisor",
            fields._startDate && "Start Date & Time",
            fields.dueDate && "Due Date & Time",
            fields.priority && "Priority",
            fields.travelReq && "Travel Required",
            fields.taskState && "Task State",
            fields.cancellationRemark && "Cancellation remarks",
            fields.cancellationReq && "Cancellation Request Date & Time",
            fields.rating && "Rating",
            fields.remarks && "Remark",
            fields.nextUser && "Next User",
            fields.nextDate && "Next date",
            fields.amount && "Amount",
            fields.furtherAction && "Further Action"
        ].filter(function (x) {
            return x !== false;
        })
    ];
    Promise.all(getCsvBody(taskList, userList, taskTypes, taskStates, locations, fields)).then(function (r) {
        return r.flatMap(function (e) { return csvData.push(e); });
    });
    useEffect(function () {
        // @ts-ignore
        if (data && csvLink && csvLink.current && csvLink.current.link) {
            setTimeout(function () {
                // @ts-ignore
                csvLink.current.link.click();
                setData([]);
            });
        }
    }, [data]);
    return (React.createElement(React.Fragment, null, data.length > 0 && (React.createElement(CSVLink, { data: data, filename: "Tasks (from " + getLocalDate(startDate) + " to " + getLocalDate(endDate) + ").csv", target: "_blank", ref: csvLink })), React.createElement(Button, { style: {
            borderColor: LIGHT_BLUE,
            borderWidth: 2,
            boxShadow: "none",
            textTransform: "none",
            height: 40,
            width: 160,
            color: LIGHT_BLUE,
            fontSize: 16,
            fontWeight: 600,
            marginTop: 10
        }, fullWidth: true,
        /*
          // @ts-ignore */
        onClick: function () { return setData(csvData); }, variant: "outlined" }, "Download CSV")));
}
