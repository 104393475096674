export function getPriorityKey(p) {
    switch (p) {
        case 1:
            return "H";
        case 2:
            return "M";
        default:
            return "L";
    }
}
export function getPriorityFullKey(p) {
    switch (p) {
        case 1:
            return "High";
        case 2:
            return "Medium";
        default:
            return "Low";
    }
}
