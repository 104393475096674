import { NotificationManager } from "react-notifications";
export var alertsActionTypes;
(function (alertsActionTypes) {
    alertsActionTypes["FETCH_ALERTS"] = "field-workforce-management/cancellation_requests/fetch_alerts";
    alertsActionTypes["FETCH_ALERTS_SUCCESS"] = "field-workforce-management/cancellation_requests/fetch_alerts_success";
    alertsActionTypes["FETCH_ALERTS_FAILURE"] = "field-workforce-management/cancellation_requests/fetch_alerts_failure";
    alertsActionTypes["LISTEN_ALERTS"] = "field-workforce-management/cancellation_requests/listen_alerts";
    alertsActionTypes["LISTEN_ALERTS_SUCCESS"] = "field-workforce-management/cancellation_requests/listen_alerts_success";
    alertsActionTypes["LISTEN_ALERTS_FAILURE"] = "field-workforce-management/cancellation_requests/listen_alerts_failure";
    alertsActionTypes["MARK_AS_READ"] = "field-workforce-management/cancellation_requests/mark_as_read";
    alertsActionTypes["MARK_AS_READ_SUCCESS"] = "field-workforce-management/cancellation_requests/mark_as_read_success";
    alertsActionTypes["MARK_AS_READ_FAILURE"] = "field-workforce-management/cancellation_requests/mark_as_read_failure";
})(alertsActionTypes || (alertsActionTypes = {}));
export function fetchAlertAction(startDate, endDate, loggedInUserID) {
    return {
        type: alertsActionTypes.FETCH_ALERTS,
        startDate: startDate,
        endDate: endDate,
        loggedInUserID: loggedInUserID
    };
}
export function fetchAlertSuccessAction(alerts) {
    return {
        type: alertsActionTypes.FETCH_ALERTS_SUCCESS,
        alerts: alerts
    };
}
export function fetchAlertFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchAlertFailureAction", err);
    NotificationManager.error("Error occurred while loading alerts. Please try again later.", "Oops! Error");
    return {
        type: alertsActionTypes.FETCH_ALERTS_FAILURE,
        err: err
    };
}
export function listenAlertAction(startDate, endDate, loggedInUserID) {
    return {
        type: alertsActionTypes.LISTEN_ALERTS,
        startDate: startDate,
        endDate: endDate,
        loggedInUserID: loggedInUserID
    };
}
export function listenAlertSuccessAction(alerts) {
    return {
        type: alertsActionTypes.LISTEN_ALERTS_SUCCESS,
        alerts: alerts
    };
}
export function listenAlertFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("listenAlertFailureAction", err);
    NotificationManager.error("Error occurred while listening to the alerts. Please try again later.", "Oops! Error");
    return {
        type: alertsActionTypes.LISTEN_ALERTS_FAILURE,
        err: err
    };
}
export function markAsReadAlertAction(alertID) {
    return {
        type: alertsActionTypes.MARK_AS_READ,
        alertID: alertID
    };
}
export function markAsReadAlertSuccessAction() {
    // NotificationManager.success(`You have successfully markAsRead the alert.`);
    return {
        type: alertsActionTypes.MARK_AS_READ_SUCCESS
    };
}
export function markAsReadAlertFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("markAsReadAlertFailureAction", err);
    // NotificationManager.error(`Error occurred while creating alert. Please try again later.`, `Oops! Error`);
    return {
        type: alertsActionTypes.MARK_AS_READ_FAILURE,
        err: err
    };
}
