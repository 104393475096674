var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { Checkbox, FormControl, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { PRIMARY_PINK, WHITE } from "../../../utils/colorCodes";
import useStyles from "../styles/styles";
export default function FilterByTaskType(props) {
    var taskTypesFilter = props.taskTypesFilter, setTaskTypesFilter = props.setTaskTypesFilter, hidden = props.hidden;
    var classes = useStyles();
    var taskTypes = useSelector(function (state) { return state.taskTypeManagement; }).taskTypes;
    var _a = React.useState(false), isAllTasksSelected = _a[0], setAllTasksSelected = _a[1];
    function concattasks() {
        var result = ["all"];
        taskTypes.forEach(function (element) {
            result.push(element.id);
        });
        return result;
    }
    return (React.createElement(React.Fragment, null, React.createElement(FormControl, { className: classes.formStatusControl, style: { backgroundColor: hidden ? WHITE : PRIMARY_PINK } }, React.createElement(Autocomplete, { multiple: true, value: taskTypes.length < taskTypesFilter.length
            ? []
            : taskTypes.filter(function (type) { return taskTypesFilter.includes(type.id); }), disableCloseOnSelect: true, getOptionLabel: function (option) { return option.name || ""; }, onChange: function (_, value) {
            if (value.length === 0) {
                setTaskTypesFilter(concattasks());
            }
            else {
                setTaskTypesFilter(value.map(function (el) { return el.id; }));
            }
        }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { placeholder: "Filter by Task", classes: { root: classes.customTextField }, InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true }), variant: "standard" }))); }, classes: { paper: classes.autoCompleteDropDownPaper }, renderTags: function () { return React.createElement(React.Fragment, null); }, options: taskTypes, renderOption: function (option, _a) {
            var selected = _a.selected;
            return (React.createElement(React.Fragment, null, React.createElement(Checkbox, { checked: selected }), React.createElement(Typography, null, option.name)));
        } }))));
}
