import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import useStyles from "../styles/styles";
export default function CreateUpdateModalTitle(props) {
    var classes = useStyles();
    var dialogType = props.dialogType, setCreateUpdateTaskModalOpen = props.setCreateUpdateTaskModalOpen;
    return (React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { className: classes.pageTitle }, dialogType === "create" ? "Add Task" : "Edit Task"), React.createElement(Button, { onClick: function () {
            setCreateUpdateTaskModalOpen(false);
        }, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null))));
}
