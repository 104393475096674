var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { userActionTypes } from "./action";
var initialState = {
    fetchUserIsLoading: true,
    userList: [],
    userHierarchy: [],
    children: [],
    subordinateUserIDs: [],
    cascadeUserIDs: [],
    fetchUserError: { hasError: false, description: "" },
    createUserIsLoading: false,
    createUserSuccess: false,
    createUserError: { hasError: false, description: "" },
    updateUserIsLoading: false,
    updateUserSuccess: false,
    updateUserError: { hasError: false, description: "" },
    archiveUserIsLoading: false,
    archiveUserSuccess: false,
    archiveUserError: { hasError: false, description: "" },
    resetPasswordIsLoading: false,
    resetPasswordSuccess: false,
    resetPasswordError: { hasError: false, description: "" },
    updateUserProfileIsLoading: false,
    updateUserProfileSuccess: false,
    updateUserProfileError: { hasError: false, description: "" },
    UserLoginMarked: false,
    userFilter: {}
};
export default function userReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case userActionTypes.SET_USER_FILTER: {
                draft.userFilter = __assign(__assign({}, draft.userFilter), action.userFilter);
                break;
            }
            case userActionTypes.RESET_USER_FILTER: {
                draft.userFilter = {};
                break;
            }
            case userActionTypes.FETCH_USERS: {
                draft.fetchUserIsLoading = true;
                break;
            }
            case userActionTypes.FETCH_USERS_SUCCESS: {
                draft.userList = action.userList;
                draft.userHierarchy = action.userHierarchy;
                draft.fetchUserIsLoading = false;
                draft.fetchUserError = { hasError: false, description: "" };
                break;
            }
            case userActionTypes.FETCH_USERS_FAILURE: {
                draft.fetchUserIsLoading = false;
                draft.fetchUserError = { hasError: true, description: action.err };
                break;
            }
            case userActionTypes.CREATE_USER: {
                draft.createUserIsLoading = true;
                break;
            }
            case userActionTypes.CREATE_USER_SUCCESS: {
                draft.createUserIsLoading = false;
                draft.createUserSuccess = true;
                draft.createUserError = { hasError: false, description: "" };
                break;
            }
            case userActionTypes.CREATE_USER_FAILURE: {
                draft.createUserIsLoading = false;
                draft.createUserError = { hasError: true, description: action.err };
                break;
            }
            case userActionTypes.UPDATE_USER: {
                draft.updateUserIsLoading = true;
                break;
            }
            case userActionTypes.UPDATE_USER_PROFILE: {
                draft.updateUserProfileIsLoading = true;
                break;
            }
            case userActionTypes.UPDATE_USER_SUCCESS: {
                draft.updateUserIsLoading = false;
                draft.updateUserSuccess = true;
                draft.updateUserError = { hasError: false, description: "" };
                break;
            }
            case userActionTypes.UPDATE_USER_FAILURE: {
                draft.updateUserIsLoading = false;
                draft.updateUserError = { hasError: true, description: action.err };
                break;
            }
            case userActionTypes.ARCHIVE_USER: {
                draft.archiveUserIsLoading = true;
                break;
            }
            case userActionTypes.ARCHIVE_USER_SUCCESS: {
                draft.archiveUserIsLoading = false;
                draft.archiveUserSuccess = true;
                draft.archiveUserError = { hasError: false, description: "" };
                break;
            }
            case userActionTypes.ARCHIVE_USER_FAILURE: {
                draft.archiveUserIsLoading = false;
                draft.archiveUserError = { hasError: true, description: action.err };
                break;
            }
            case userActionTypes.CHANGE_USER_PASSWORD: {
                draft.resetPasswordIsLoading = true;
                break;
            }
            case userActionTypes.CHANGE_USER_PASSWORD_SUCCESS: {
                draft.resetPasswordIsLoading = false;
                draft.resetPasswordSuccess = true;
                draft.resetPasswordError = { hasError: false, description: "" };
                break;
            }
            case userActionTypes.CHANGE_USER_PASSWORD_FAILURE: {
                draft.resetPasswordIsLoading = false;
                draft.resetPasswordError = { hasError: true, description: action.err };
                break;
            }
            case userActionTypes.RESET_USER_LOADING_STATE: {
                draft.createUserSuccess = false;
                draft.updateUserSuccess = false;
                draft.archiveUserSuccess = false;
                draft.resetPasswordSuccess = false;
                break;
            }
            case userActionTypes.FIND_SUBORDINATE_USER_SUCCESS: {
                draft.subordinateUserIDs = action.subordinateUserIDs;
                draft.cascadeUserIDs = action.cascadeUserIDs;
                draft.children = [action.loggedInUserDocumentID].concat(action.cascadeUserIDs.concat(action.subordinateUserIDs));
                break;
            }
            case userActionTypes.UPDATE_USER_PROFILE_SUCCESS: {
                draft.updateUserProfileIsLoading = false;
                draft.updateUserProfileSuccess = true;
                draft.updateUserProfileError = { hasError: false, description: "" };
                break;
            }
            case userActionTypes.UPDATE_USER_PROFILE_FAILURE: {
                draft.updateUserProfileIsLoading = false;
                draft.updateUserProfileSuccess = true;
                draft.updateUserProfileError = { hasError: true, description: action.err };
                break;
            }
            case userActionTypes.UPDATE_USER_LOGIN_SUCCESS: {
                draft.UserLoginMarked = true;
                break;
            }
            case userActionTypes.UPDATE_USER_LOGIN_FAILURE: {
                draft.UserLoginMarked = false;
                break;
            }
            default:
                break;
        }
    });
}
