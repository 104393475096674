import { NotificationManager } from "react-notifications";
export var noAttendanceActivityActionTypes;
(function (noAttendanceActivityActionTypes) {
    noAttendanceActivityActionTypes["FETCH_NO_ATTENDANCE_ACTIVITY"] = "field-workforce-management/no_attendance_activity/fetch_no_attendance_activity";
    noAttendanceActivityActionTypes["FETCH_NO_ATTENDANCE_ACTIVITY_SUCCESS"] = "field-workforce-management/no_attendance_activity/fetch_no_attendance_activity_success";
    noAttendanceActivityActionTypes["FETCH_NO_ATTENDANCE_ACTIVITY_FAILURE"] = "field-workforce-management/no_attendance_activity/fetch_no_attendance_activity_failure";
})(noAttendanceActivityActionTypes || (noAttendanceActivityActionTypes = {}));
export function fetchNoAttendanceActivityAction(startDate, endDate, selectedWeekDays, excludeDates) {
    return {
        type: noAttendanceActivityActionTypes.FETCH_NO_ATTENDANCE_ACTIVITY,
        startDate: startDate,
        endDate: endDate,
        selectedWeekDays: selectedWeekDays,
        excludeDates: excludeDates
    };
}
export function fetchNoAttendanceActivitySuccessAction(activities) {
    return {
        type: noAttendanceActivityActionTypes.FETCH_NO_ATTENDANCE_ACTIVITY_SUCCESS,
        activities: activities
    };
}
export function fetchNoAttendanceActivityFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchNoAttendanceActivityFailureAction", err);
    NotificationManager.error("Error occurred while loading No Attendance Activity. Please try again later.", "Oops! Error");
    return {
        type: noAttendanceActivityActionTypes.FETCH_NO_ATTENDANCE_ACTIVITY_FAILURE,
        err: err
    };
}
