/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Box, FormControl, Link, NativeSelect } from "@material-ui/core";
import { Link as NavigatorLink } from "react-router-dom";
import * as React from "react";
import { useSelector } from "react-redux";
import { ERROR_HIGH, ERROR_MEDIUM, LIGHT_BLUE, OFF_YELLOW } from "../../../utils/colorCodes";
import { getPriorityKey } from "../../../utils/getPriorityKey";
import { allowedTaskStateForAdmin, allowedTaskStateForFreezedUser, allowedTaskStateForSelfAssigneWorker, allowedTaskStateForWorker } from "../../../utils/taskStateHandler";
import useStyles from "../styles/styles";
import { isUserHasPermission } from "../../../utils/isUserHasPermission";
import { getFormattedDate } from "../../../utils/dateOperations";
import isOverDue from "../../../utils/isOverDue";
import { ROLE_ADMIN, ROLE_DIV_ADMIN, ROLE_DIV_ADMIN_CUM_STATION_USER, ROLE_STATION_USER, ROLE_SUPER_ADMIN, ROLE_SUPERVISOR, ROLE_SUPERVISOR_CUM_STATION_USER, ROLE_USER } from "../../../utils/remoteConfig/roles/rolesEnum";
export default function GetTasksPageRows(filteredTasks, setCurrentTask, setNextTaskState, setChangeTaskStateModalOpen) {
    var classes = useStyles();
    var locations = useSelector(function (state) { return state.locationManagement; }).locations;
    var userList = useSelector(function (state) { return state.userManagement; }).userList;
    var taskStates = useSelector(function (state) { return state.taskStateManagement; }).taskStates;
    var taskTypes = useSelector(function (state) { return state.taskTypeManagement; }).taskTypes;
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    var accessControl = useSelector(function (state) { return state.accessControlManagement; }).accessControl;
    var workType = useSelector(function (state) { return state.homePageManagement; }).workType;
    function getPriorityColor(pr) {
        switch (pr) {
            case 1:
                return ERROR_HIGH;
            case 2:
                return ERROR_MEDIUM;
            default:
                return "transparent";
        }
    }
    return filteredTasks.map(function (row) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
        var tempRow = [
            {
                cell: (React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "flex-start" }, React.createElement("div", { style: {
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: 90,
                        overflow: "hidden",
                        color: row.isChild ? "rgb(29, 154, 214)" : "black"
                    } }, row.id), row.sourceERP !== null && (React.createElement("div", { style: {
                        backgroundColor: OFF_YELLOW,
                        paddingLeft: 5,
                        paddingRight: 5,
                        marginLeft: 5,
                        borderRadius: 4
                    } }, "ERP")))),
                alignment: "left",
                className: "classes.tableCell"
            },
            {
                cell: (React.createElement("div", { style: {
                        backgroundColor: getPriorityColor(row.priority),
                        height: 25,
                        width: 25,
                        borderRadius: "50%",
                        paddingTop: 3,
                        paddingLeft: 7
                    } }, getPriorityKey(row.priority))),
                alignment: "left",
                className: "classes.tableCell"
            },
            {
                cell: (React.createElement("div", { style: {
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: 135,
                        overflow: "hidden"
                    } }, (_a = taskTypes.filter(function (tt) { return tt.id === row.taskType; })[0]) === null || _a === void 0 ? void 0 : _a.name)),
                alignment: "left",
                className: "classes.tableCell"
            },
            {
                cell: (_b = locations.filter(function (l) { return l.id === row.location; })[0]) === null || _b === void 0 ? void 0 : _b.name,
                alignment: "left",
                className: "classes.tableCell"
            },
            {
                cell: React.createElement("div", { style: { color: isOverDue(row) ? "red" : undefined } }, getFormattedDate(row.due)),
                alignment: "left",
                className: "classes.tableCell"
            },
            {
                cell: (_c = userList.filter(function (ul) { return ul.documentID === row.assignedTo; })[0]) === null || _c === void 0 ? void 0 : _c.name,
                alignment: "left",
                className: "classes.tableCell"
            }
        ];
        if (isUserHasPermission((_e = (_d = accessControl) === null || _d === void 0 ? void 0 : _d.tasks) === null || _e === void 0 ? void 0 : _e.edit_status, (_f = loggedInUser) === null || _f === void 0 ? void 0 : _f.role)) {
            var optionList = void 0;
            var isChangeTaskStateDisable = void 0;
            switch ((_g = loggedInUser) === null || _g === void 0 ? void 0 : _g.role) {
                case ROLE_USER:
                    if (row.assignedTo === ((_h = loggedInUser) === null || _h === void 0 ? void 0 : _h.documentID)) {
                        var data = allowedTaskStateForWorker(row.state, taskStates);
                        isChangeTaskStateDisable = data.length === 1;
                        optionList = data.map(function (ts) { return (React.createElement("option", { key: ts.id, value: ts.id }, ts.name)); });
                    }
                    else {
                        var data = allowedTaskStateForFreezedUser(row.state, taskStates);
                        isChangeTaskStateDisable = data.length === 1;
                        optionList = data.map(function (ts) { return (React.createElement("option", { key: ts.id, value: ts.id }, ts.name)); });
                    }
                    break;
                case ROLE_STATION_USER:
                    if (row.assignedTo === ((_j = loggedInUser) === null || _j === void 0 ? void 0 : _j.documentID)) {
                        var data = allowedTaskStateForWorker(row.state, taskStates);
                        isChangeTaskStateDisable = data.length === 1;
                        optionList = data.map(function (ts) { return (React.createElement("option", { key: ts.id, value: ts.id }, ts.name)); });
                    }
                    else {
                        var data = allowedTaskStateForFreezedUser(row.state, taskStates);
                        isChangeTaskStateDisable = data.length === 1;
                        optionList = data.map(function (ts) { return (React.createElement("option", { key: ts.id, value: ts.id }, ts.name)); });
                    }
                    break;
                case ROLE_SUPER_ADMIN:
                case ROLE_ADMIN:
                case ROLE_DIV_ADMIN:
                case ROLE_SUPERVISOR:
                    if (row.assignedTo === ((_k = loggedInUser) === null || _k === void 0 ? void 0 : _k.documentID)) {
                        var data = allowedTaskStateForSelfAssigneWorker(row.state, taskStates);
                        isChangeTaskStateDisable = data.length === 1;
                        optionList = data.map(function (ts) { return (React.createElement("option", { key: ts.id, value: ts.id }, ts.name)); });
                    }
                    else {
                        var data = allowedTaskStateForAdmin(row.state, taskStates);
                        isChangeTaskStateDisable = data.length === 1;
                        optionList = data.map(function (ts) { return (React.createElement("option", { key: ts.id, value: ts.id }, ts.name)); });
                    }
                    break;
                case ROLE_SUPERVISOR_CUM_STATION_USER:
                case ROLE_DIV_ADMIN_CUM_STATION_USER:
                default:
                    if (row.assignedTo === ((_l = loggedInUser) === null || _l === void 0 ? void 0 : _l.documentID)) {
                        var data = allowedTaskStateForSelfAssigneWorker(row.state, taskStates);
                        isChangeTaskStateDisable = data.length === 1;
                        optionList = data.map(function (ts) { return (React.createElement("option", { key: ts.id, value: ts.id }, ts.name)); });
                    }
                    else {
                        var data = allowedTaskStateForAdmin(row.state, taskStates);
                        isChangeTaskStateDisable = data.length === 1;
                        optionList = data.map(function (ts) { return (React.createElement("option", { key: ts.id, value: ts.id }, ts.name)); });
                    }
            }
            tempRow.push({
                cell: (React.createElement(FormControl, { className: classes.selectFormControlZ }, React.createElement(NativeSelect, { disabled: isChangeTaskStateDisable, disableUnderline: true, id: "demo-customized-select-native", value: row.state, onChange: function (e) {
                        setCurrentTask(row);
                        setNextTaskState(e.target.value);
                        setChangeTaskStateModalOpen(true);
                    }, style: {
                        border: isChangeTaskStateDisable ? "1px solid gray" : "1px solid " + LIGHT_BLUE,
                        borderRadius: 16,
                        paddingLeft: 10,
                        color: isChangeTaskStateDisable ? "gray" : LIGHT_BLUE
                    } }, optionList))),
                alignment: isUserHasPermission((_o = (_m = accessControl) === null || _m === void 0 ? void 0 : _m.tasks) === null || _o === void 0 ? void 0 : _o.view_individual, (_p = loggedInUser) === null || _p === void 0 ? void 0 : _p.role) ? "left" : "center",
                className: "classes.tableCell"
            });
        }
        if (isUserHasPermission((_r = (_q = accessControl) === null || _q === void 0 ? void 0 : _q.tasks) === null || _r === void 0 ? void 0 : _r.view_individual, (_s = loggedInUser) === null || _s === void 0 ? void 0 : _s.role)) {
            tempRow.push({
                cell: (React.createElement(Link, { component: NavigatorLink, to: "/dashboard/tasks/" + row.id, className: classes.tableCellEnd }, "View")),
                alignment: "center",
                className: "classes.tableCellEnd"
            });
        }
        return tempRow;
    });
}
