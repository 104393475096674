/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, IconButton, Link } from "@material-ui/core";
import { FieldArray } from "formik";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { LIGHT_BLUE } from "../../../../utils/colorCodes";
import useStyles from "../../styles/styles";
export default function TelephoneNoArray(props) {
    var telephoneNumbers = props.telephoneNumbers, onChange = props.onChange, onBlur = props.onBlur, errors = props.errors, touched = props.touched;
    var classes = useStyles();
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement(FieldArray, { name: "tp", render: function (_a) {
            var remove = _a.remove, push = _a.push;
            return (React.createElement("div", null, telephoneNumbers.length > 0 &&
                telephoneNumbers.map(function (number, ithElement) {
                    return (React.createElement("div", { className: "row", key: ithElement }, React.createElement("div", { className: "col" }, React.createElement(Grid, { container: true, justify: "flex-end" }, React.createElement(TextField, { InputProps: { disableUnderline: true }, fullWidth: true, defaultValue: number, onChange: onChange, onBlur: onBlur, name: "tp." + ithElement, label: "Telephone Number", variant: "filled" }), ithElement > 0 && (React.createElement(IconButton, { style: {
                            backgroundColor: "transparent"
                        }, disableRipple: true, type: "button", className: classes.addMoreBtn, onClick: function () { return remove(ithElement); } }, React.createElement(CloseIcon, null)))), errors.tp && errors.tp[ithElement] && touched.tp ? (React.createElement("div", { className: classes.error }, errors.tp[ithElement])) : (React.createElement("div", { style: { marginBottom: 10 } })))));
                }), React.createElement(Link, { type: "button", component: "button", underline: "none", style: {
                    textTransform: "none",
                    color: LIGHT_BLUE,
                    fontWeight: "bold",
                    fontSize: 17
                }, onClick: function () { return push(""); } }, "+ Telephone Number")));
        } })));
}
