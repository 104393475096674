/* eslint no-underscore-dangle: ["error", { "allow": ["_thisMonth", "_thisDay", "_thisDate"] }] */
var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
var _thisDay = weekday[new Date().getDay()];
var dateA = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
    "29th",
    "30th",
    "31st"
];
var _thisDate = dateA[new Date().getDate() - 1];
var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var _thisMonth = month[new Date().getMonth()];
function getWeekOfMonth(date) {
    if (date === void 0) {
        date = new Date();
    }
    var adjustedDate = date.getDate() + date.getDay();
    var prefixes = ["0", "1", "2", "3", "4", "5"];
    return parseInt(prefixes[0 || adjustedDate / 7], 10);
}
var repeatMenu = {
    none: "None",
    daily: "Daily",
    "mon-fri": "Mon - Fri",
    "mon-sat": "Mon - Sat",
    weekly: "Weekly on " + _thisDay,
    custom: "Weekly",
    "monthly-1": "Monthly on " + _thisDate,
    "monthly-2": getWeekOfMonth() >= 4 ? "Monthly on Last " + _thisDay : "Monthly on " + dateA[getWeekOfMonth()] + " " + _thisDay,
    annually: "Annually on " + _thisDate + "-" + _thisMonth
};
export default repeatMenu;
