import firebase from "firebase";
import { firestore } from "../firebaseConnector";
import { FIRESTORE_USER_GROUPS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../firestoreCollections";
export function announcementMapper(doc) {
    var _a, _b;
    var _c = doc.data(), text = _c.text, type = _c.type, sendBy = _c.sendBy, timestamp = _c.timestamp, targetGroups = _c.targetGroups, targetUsers = _c.targetUsers, isRead = _c.isRead, archive = _c.archive;
    return {
        documentID: doc.id,
        type: type,
        text: text,
        sendBy: ((_a = sendBy) === null || _a === void 0 ? void 0 : _a.id) || "",
        timestamp: ((_b = timestamp) === null || _b === void 0 ? void 0 : _b.seconds) * 1000 || 0,
        targetGroups: targetGroups.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; }),
        targetUsers: targetUsers.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; }),
        isRead: isRead.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; }),
        archive: archive
    };
}
export function announcementCreator(announcement) {
    return {
        text: announcement.text,
        type: announcement.type,
        sendBy: firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + announcement.sendBy),
        timestamp: firebase.firestore.Timestamp.fromMillis(announcement.timestamp),
        targetGroups: announcement.targetGroups.map(function (el) { return firestore.doc(FIRESTORE_USER_GROUPS_COLLECTION + "/" + el); }),
        targetUsers: announcement.targetUsers.map(function (el) { return firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + el); }),
        isRead: [],
        archive: false
    };
}
