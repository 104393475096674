import { createStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { PRIMARY_BLUE, PRIMARY_PINK, SECONDARY_PINK } from "../utils/colorCodes";
export var StyledTableCell = withStyles(function () {
    return createStyles({
        head: {
            backgroundColor: PRIMARY_PINK,
            color: PRIMARY_BLUE,
            height: 41
        },
        body: {
            fontSize: 14,
            height: 41,
            paddingRight: 0,
            paddingLeft: 16
        }
    });
})(TableCell);
export var StyledTableRow = withStyles(function () {
    return createStyles({
        root: {
            "&:nth-of-type(even)": {
                backgroundColor: SECONDARY_PINK
            }
        }
    });
})(TableRow);
