import { NotificationManager } from "react-notifications";
export var liveLocationActionTypes;
(function (liveLocationActionTypes) {
    liveLocationActionTypes["FETCH_LIVE_LOCATIONS"] = "field-workforce-management/live_location/fetch_live_locations";
    liveLocationActionTypes["FETCH_LIVE_LOCATIONS_SUCCESS"] = "field-workforce-management/live_location/fetch_live_locations_success";
    liveLocationActionTypes["FETCH_LIVE_LOCATIONS_FAILURE"] = "field-workforce-management/live_location/fetch_live_locations_failure";
    liveLocationActionTypes["FETCH_LIVE_HISTORIES"] = "field-workforce-management/live_location/fetch_live_histories";
    liveLocationActionTypes["FETCH_LIVE_HISTORIES_SUCCESS"] = "field-workforce-management/live_location/fetch_live_histories_success";
    liveLocationActionTypes["FETCH_LIVE_HISTORIES_FAILURE"] = "field-workforce-management/live_location/fetch_live_histories_failure";
})(liveLocationActionTypes || (liveLocationActionTypes = {}));
export function fetchLiveLocationsAction(refreshFrequency) {
    return {
        type: liveLocationActionTypes.FETCH_LIVE_LOCATIONS,
        refreshFrequency: refreshFrequency
    };
}
export function fetchLiveLocationsSuccessAction(liveLocations) {
    return {
        type: liveLocationActionTypes.FETCH_LIVE_LOCATIONS_SUCCESS,
        liveLocations: liveLocations
    };
}
export function fetchLiveLocationsFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchLiveLocationsFailureAction", err);
    NotificationManager.error("Error occurred while fetching user's live locations. Please try again later.", "Oops! Error");
    return {
        type: liveLocationActionTypes.FETCH_LIVE_LOCATIONS_FAILURE,
        err: err
    };
}
export function fetchLiveHistoriesAction(refreshFrequency) {
    return {
        type: liveLocationActionTypes.FETCH_LIVE_HISTORIES,
        refreshFrequency: refreshFrequency
    };
}
export function fetchLiveHistoriesSuccessAction(liveHistories) {
    return {
        type: liveLocationActionTypes.FETCH_LIVE_HISTORIES_SUCCESS,
        liveHistories: liveHistories
    };
}
export function fetchLiveHistoriesFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchLiveHistoriesFailureAction", err);
    NotificationManager.error("Error occurred while fetching user's live histories. Please try again later.", "Oops! Error");
    return {
        type: liveLocationActionTypes.FETCH_LIVE_HISTORIES_FAILURE,
        err: err
    };
}
