import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import repeatMenu from "../../utils/repeatMenuOption";
import { getFormattedDateTime } from "../../../../utils/dateOperations";
import useStyles from "../../styles/styles";
import InfoViewColored from "./InfoViewColored";
export default function RepeatTaskInfoBox() {
    var _a, _b, _c, _d, _e;
    var classes = useStyles();
    var taskScheduleData = useSelector(function (state) { return state.taskManagement; }).taskScheduleData;
    return (React.createElement(React.Fragment, null, React.createElement(InfoViewColored, { title: "Repeat", value: repeatMenu[(_a = taskScheduleData) === null || _a === void 0 ? void 0 : _a.repeat], boxLonger: false }), React.createElement(Grid, { item: true, lg: 6 }), ((_b = taskScheduleData) === null || _b === void 0 ? void 0 : _b.prior) === -1 ? (React.createElement(Grid, { item: true, lg: 6 }, React.createElement("div", { className: classes.viewTaskBody, style: { backgroundColor: "transparent" } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 5,
            textAlign: "initial"
        }, control: React.createElement(Checkbox, { checked: true, id: "travellingRequired", name: "travellingRequired", color: "default" }), label: "New task is visible from the previous due date", labelPlacement: "end" })))) : (React.createElement(InfoViewColored, { title: "Task visible before (day/s)",
        // @ts-ignore
        value: (_c = taskScheduleData) === null || _c === void 0 ? void 0 : _c.prior, boxLonger: false })), React.createElement(Grid, { item: true, lg: 6 }), React.createElement(InfoViewColored, { title: "End Date & Time",
        // @ts-ignore
        value: ((_d = taskScheduleData) === null || _d === void 0 ? void 0 : _d.end) === null ? "-" : getFormattedDateTime((_e = taskScheduleData) === null || _e === void 0 ? void 0 : _e.end), boxLonger: false })));
}
