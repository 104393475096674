import * as React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
// @ts-ignore
export default function FilterByDateRange(props) {
    var startDate = props.startDate, setStartDate = props.setStartDate, startLabel = props.startLabel, endDate = props.endDate, setEndDate = props.setEndDate, endLabel = props.endLabel;
    var _a = React.useState(""), startDateError = _a[0], setStartDateError = _a[1];
    var _b = React.useState(""), endDateError = _b[0], setEndDateError = _b[1];
    return (React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils }, React.createElement("div", { style: { marginRight: 15, marginTop: 10, marginBottom: 10, height: "max-content" } }, React.createElement(Grid, { container: true, direction: "column" }, React.createElement(Grid, { item: true }, React.createElement(KeyboardDatePicker, { autoOk: true, disableFuture: true, disableToolbar: true, inputVariant: "filled", variant: "inline", format: "dd/MM/yyyy", margin: "normal", id: "date-picker-inline", label: startLabel, value: startDate, error: false, helperText: null, onError: setStartDateError, onChange: function (event, newValue, previousValue, name) {
            if (event == "Invalid Date") {
                setStartDateError("Invalid Date");
            }
            else if (event !== null) {
                setStartDateError("");
                setStartDate(event);
            }
            else {
                setStartDateError("Invalid Date");
            }
        }, InputProps: {
            disableUnderline: true
            // readOnly: true,
        } })), React.createElement(Grid, { item: true }, React.createElement("span", { style: { color: "red", textAlign: "left" } }, startDateError.length > 0 ? "Invalid Start date" : null)))), React.createElement("div", { style: { marginRight: 15, marginTop: 10 } }, React.createElement(Grid, { container: true, direction: "column" }, React.createElement(Grid, { item: true }, React.createElement(KeyboardDatePicker, { autoOk: true, disableFuture: true, disableToolbar: true, inputVariant: "filled", variant: "inline", format: "dd/MM/yyyy", margin: "normal", id: "date2-picker-inline", label: endLabel, value: endDate, error: false, helperText: null, onError: setEndDateError, onChange: function (event, newValue, previousValue, name) {
            if (event == "Invalid Date") {
                setEndDateError("Invalid Date");
            }
            else if (event !== null) {
                setEndDateError("");
                setEndDate(event);
            }
            else {
                setEndDateError("Invalid Date");
            }
        }, KeyboardButtonProps: {
            "aria-label": "change date"
        }, InputProps: {
            disableUnderline: true
        } })), React.createElement(Grid, { item: true }, React.createElement("span", { style: { color: "red", textAlign: "left" } }, endDateError.length > 0 ? "Invalid End date" : null))))));
}
