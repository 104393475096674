import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import useStyles from "../../styles/styles";
export default function AvailableGroups(props) {
    var classes = useStyles();
    var availableGroups = props.availableGroups;
    var userGroups = useSelector(function (state) { return state.userGroupManagement; }).userGroups;
    return (React.createElement(Grid, { item: true, lg: 6 }, availableGroups.map(function (gp, i) {
        var _a;
        return (React.createElement("div", { className: classes.viewTaskBody, key: i }, React.createElement(Typography, { className: classes.taskDetailCardTitle }, "Group"), React.createElement(Typography, { className: classes.taskDetailCardBody }, (_a = userGroups.filter(function (el) { return el.id === gp; })[0]) === null || _a === void 0 ? void 0 : _a.name)));
    })));
}
