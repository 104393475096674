import { NotificationManager } from "react-notifications";
export var routeHistoryActionTypes;
(function (routeHistoryActionTypes) {
    routeHistoryActionTypes["FETCH_USER_HISTORIES"] = "field-workforce-management/route_history/fetch_user_histories";
    routeHistoryActionTypes["FETCH_USER_HISTORIES_SUCCESS"] = "field-workforce-management/route_history/fetch_user_histories_success";
    routeHistoryActionTypes["FETCH_USER_HISTORIES_FAILURE"] = "field-workforce-management/route_history/fetch_user_histories_failure";
})(routeHistoryActionTypes || (routeHistoryActionTypes = {}));
export function fetchTableHistoryAction(startDate, endDate) {
    return {
        type: routeHistoryActionTypes.FETCH_USER_HISTORIES,
        startDate: startDate,
        endDate: endDate
    };
}
export function fetchTableHistorySuccessAction(history, locationHistory) {
    return {
        type: routeHistoryActionTypes.FETCH_USER_HISTORIES_SUCCESS,
        history: history,
        locationHistory: locationHistory
    };
}
export function fetchTableHistoryFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchTableHistoryFailureAction", err);
    NotificationManager.error("Error occurred while fetching user's histories. Please try again later.", "Oops! Error");
    return {
        type: routeHistoryActionTypes.FETCH_USER_HISTORIES_FAILURE,
        err: err
    };
}
