export default function getFilteredAnnouncementList(announcementList, loggedInUser) {
    return announcementList.filter(function (el) {
        var _a, _b;
        if (el.sendBy === ((_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.documentID)) {
            return true;
        }
        if (el.type === "by_group") {
            return el.targetGroups.filter(function (x) { var _a; return (_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.groups.includes(x); }).length > 0;
        }
        if (el.type === "by_user") {
            return el.targetUsers.includes(((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.documentID) || "");
        }
        // all_users
        return true;
    });
}
