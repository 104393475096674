var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { routerMiddleware } from "connected-react-router/immutable";
import { connectRouter } from "connected-react-router";
import history from "./utils/history";
import taskStateReducer, { taskStateSagas } from "./utils/remoteConfig/taskStates";
import taskTypeReducer, { taskTypeSagas } from "./utils/remoteConfig/taskTypes";
import locationReducer, { locationSagas } from "./utils/remoteConfig/locations";
import roleReducer, { roleSagas } from "./utils/remoteConfig/roles";
import accessControlReducer, { accessControlSagas } from "./utils/remoteConfig/accessControl";
import furtherActionsReducer, { furtherActionsSagas } from "./utils/remoteConfig/furtherActions";
import { taskSagas } from "./views/Tasks/redux/sagas";
import taskReducer from "./views/Tasks/redux/reducer";
import { cancellationRequestSagas } from "./views/CancellationRequests";
import cancellationRequestReducer from "./views/CancellationRequests/reducer";
import { userSagas } from "./views/Users";
import userReducer from "./views/Users/reducer";
import userGroupSagas from "./views/UserGroups";
import userGroupReducer from "./views/UserGroups/reducer";
import skillSagas from "./views/Skills";
import skillReducer from "./views/Skills/reducer";
import loginSagas from "./views/Login";
import loginReducer from "./views/Login/reducer";
import noMovementActivityReducer from "./views/NoMovement/reducer";
import { noMovementActivitySagas } from "./views/NoMovement";
import noAttendanceActivityReducer from "./views/NoAttendance/reducer";
import { noAttendanceActivitySagas } from "./views/NoAttendance";
import noCoverageActivityReducer from "./views/NoCoverage/reducer";
import { noCoverageActivitySagas } from "./views/NoCoverage";
import routeHistoryReducer from "./views/RouteHistory/reducer";
import routeHistorySagas from "./views/RouteHistory";
import liveLocationReducer from "./views/LiveLocations/reducer";
import liveLocationSagas from "./views/LiveLocations";
import updatedLiveLocationSagas from "./views/LiveMap";
import taskStatisticsReducer from "./views/TaskStatistics/reducer";
import taskStatisticSagas from "./views/TaskStatistics";
import announcementsReducer from "./views/Announcements/reducer";
import announcementsSagas from "./views/Announcements";
import alertsReducer from "./views/Alerts/reducer";
import alertsSagas from "./views/Alerts";
import homePageReducer from "./views/Home/reducer";
import smartAlertReducer from "./views/AlertsManager/redux/reducer";
import homePageSagas from "./views/Home";
import updatedLiveLocationReducer from "./views/LiveMap/reducer";
import { smartAlertManagerSagas } from "./views/AlertsManager/redux/sagas/index";
var rootReducer = combineReducers({
    taskStateManagement: taskStateReducer,
    smartAlertManagement: smartAlertReducer,
    taskTypeManagement: taskTypeReducer,
    furtherActionManagement: furtherActionsReducer,
    locationManagement: locationReducer,
    roleManagement: roleReducer,
    accessControlManagement: accessControlReducer,
    taskManagement: taskReducer,
    cancellationRequestManagement: cancellationRequestReducer,
    userManagement: userReducer,
    userGroupManagement: userGroupReducer,
    skillManagement: skillReducer,
    noAttendanceActivityManagement: noAttendanceActivityReducer,
    noCoverageActivityManagement: noCoverageActivityReducer,
    noMovementActivityManagement: noMovementActivityReducer,
    routeHistoryManagement: routeHistoryReducer,
    liveLocationManagement: liveLocationReducer,
    updatedLiveLocationManagement: updatedLiveLocationReducer,
    taskStatisticManagement: taskStatisticsReducer,
    loginManagement: loginReducer,
    announcementManagement: announcementsReducer,
    alertManagement: alertsReducer,
    homePageManagement: homePageReducer,
    router: connectRouter(history)
});
function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all(taskStateSagas.map(function (s) { return fork(s); }))];
            case 1:
                _a.sent();
                return [4 /*yield*/, all(taskTypeSagas.map(function (s) { return fork(s); }))];
            case 2:
                _a.sent();
                return [4 /*yield*/, all(furtherActionsSagas.map(function (s) { return fork(s); }))];
            case 3:
                _a.sent();
                return [4 /*yield*/, all(locationSagas.map(function (s) { return fork(s); }))];
            case 4:
                _a.sent();
                return [4 /*yield*/, all(roleSagas.map(function (s) { return fork(s); }))];
            case 5:
                _a.sent();
                return [4 /*yield*/, all(accessControlSagas.map(function (s) { return fork(s); }))];
            case 6:
                _a.sent();
                return [4 /*yield*/, all(taskSagas.map(function (s) { return fork(s); }))];
            case 7:
                _a.sent();
                return [4 /*yield*/, all(cancellationRequestSagas.map(function (s) { return fork(s); }))];
            case 8:
                _a.sent();
                return [4 /*yield*/, all(userSagas.map(function (s) { return fork(s); }))];
            case 9:
                _a.sent();
                return [4 /*yield*/, all(userGroupSagas.map(function (s) { return fork(s); }))];
            case 10:
                _a.sent();
                return [4 /*yield*/, all(skillSagas.map(function (s) { return fork(s); }))];
            case 11:
                _a.sent();
                return [4 /*yield*/, all(noAttendanceActivitySagas.map(function (s) { return fork(s); }))];
            case 12:
                _a.sent();
                return [4 /*yield*/, all(noCoverageActivitySagas.map(function (s) { return fork(s); }))];
            case 13:
                _a.sent();
                return [4 /*yield*/, all(noMovementActivitySagas.map(function (s) { return fork(s); }))];
            case 14:
                _a.sent();
                return [4 /*yield*/, all(routeHistorySagas.map(function (s) { return fork(s); }))];
            case 15:
                _a.sent();
                return [4 /*yield*/, all(liveLocationSagas.map(function (s) { return fork(s); }))];
            case 16:
                _a.sent();
                return [4 /*yield*/, all(updatedLiveLocationSagas.map(function (s) { return fork(s); }))];
            case 17:
                _a.sent();
                return [4 /*yield*/, all(taskStatisticSagas.map(function (s) { return fork(s); }))];
            case 18:
                _a.sent();
                return [4 /*yield*/, all(loginSagas.map(function (s) { return fork(s); }))];
            case 19:
                _a.sent();
                return [4 /*yield*/, all(announcementsSagas.map(function (s) { return fork(s); }))];
            case 20:
                _a.sent();
                return [4 /*yield*/, all(alertsSagas.map(function (s) { return fork(s); }))];
            case 21:
                _a.sent();
                return [4 /*yield*/, all(homePageSagas.map(function (s) { return fork(s); }))];
            case 22:
                _a.sent();
                return [4 /*yield*/, all(smartAlertManagerSagas.map(function (s) { return fork(s); }))];
            case 23:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function configureStore() {
    var sagaMiddleware = createSagaMiddleware();
    var middleware = [sagaMiddleware, routerMiddleware(history)];
    // const store = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(...middleware)));
    var store = createStore(rootReducer, {}, compose(applyMiddleware.apply(void 0, middleware)));
    sagaMiddleware.run(rootSaga);
    return store;
}
export default configureStore;
