import firebase from "firebase";
import { firestore } from "../firebaseConnector";
import { FIRESTORE_CANCEL_REQUESTS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../firestoreCollections";
export function alertMapper(doc) {
    var _a, _b;
    var _c = doc.data(), enabled = _c.enabled, name = _c.name, description = _c.description, emailBody = _c.emailBody, startAt = _c.startAt, next = _c.next, previous = _c.previous, preset = _c.preset, picker = _c.picker, count = _c.count, weekdays = _c.weekdays, week = _c.week, dueAt = _c.dueAt, overdue = _c.overdue, overdueDays = _c.overdueDays, taskTypes = _c.taskTypes, allTaskTypes = _c.allTaskTypes, groups = _c.groups, allGroups = _c.allGroups, priorities = _c.priorities, allPriorities = _c.allPriorities, recipientUsers = _c.recipientUsers, recipientGroups = _c.recipientGroups, allAssignees = _c.allAssignees, allSupervisors = _c.allSupervisors, allGrandSupervisors = _c.allGrandSupervisors, ccUsers = _c.ccUsers, ccDivAdmins = _c.ccDivAdmins, ccTaskAdmins = _c.ccTaskAdmins, ccAllAdmins = _c.ccAllAdmins, ccSuperAdmins = _c.ccSuperAdmins, sendIfEmpty = _c.sendIfEmpty, fields = _c.fields, archived = _c.archived, createdBy = _c.createdBy, allStates = _c.allStates, states = _c.states;
    return {
        enabled: enabled === undefined ? true : enabled,
        allStates: allStates === undefined ? true : allStates,
        states: ((_a = states) === null || _a === void 0 ? void 0 : _a.length) > 0 ? states : [""],
        id: doc.id,
        name: name,
        description: description,
        emailBody: emailBody,
        startAt: startAt.toDate().getTime(),
        next: next.toDate().getTime(),
        previous: previous ? previous.toDate().getTime() : null,
        preset: preset,
        picker: picker,
        count: count,
        weekdays: weekdays,
        week: week,
        dueAt: dueAt ? dueAt.toDate().getTime() : null,
        overdue: overdue,
        overdueDays: overdueDays,
        taskTypes: taskTypes,
        groups: groups.length ? groups.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; }) : [""],
        allGroups: allGroups,
        allPriorities: allPriorities,
        allTaskTypes: allTaskTypes,
        priorities: priorities,
        recipientUsers: recipientUsers.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; }) || [""],
        recipientGroups: recipientGroups.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; }) || [""],
        allAssignees: allAssignees,
        allSupervisors: allSupervisors,
        allGrandSupervisors: allGrandSupervisors,
        ccUsers: ccUsers ? ccUsers.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; }) : [],
        ccDivAdmins: ccDivAdmins,
        ccTaskAdmins: ccTaskAdmins,
        ccAllAdmins: ccAllAdmins,
        ccSuperAdmins: ccSuperAdmins,
        sendIfEmpty: sendIfEmpty,
        fields: fields,
        archived: archived,
        createdBy: (_b = createdBy) === null || _b === void 0 ? void 0 : _b.id
    };
}
export function taskUpdater(task) {
    // const groupCriteria = task.criteria.groupCriteria.filter(el => el.length > 0).map(el => ({
    //     type: "GroupCriteria",
    //     group: firestore.doc(FIRESTORE_USER_GROUPS_COLLECTION + '/' + el),
    // }))
    // const locationCriteria = task.criteria.locationCriteria.filter(el => el.length > 0).map(el => ({
    //     type: "LocationCriteria",
    //     location: el,
    // }))
    // const skillCriteria = task.criteria.skillCriteria.filter(el => el.skill.length > 0).map(el => ({
    //     type: "SkillCriteria",
    //     skill: firestore.doc(FIRESTORE_SKILLS_COLLECTION + '/' + el.skill),
    //     level: el.level,
    // }))
    // let cr: any[] = []
    // if (task.supervisor) {
    //     const supervisorSnap = await firestore.collection(FIRESTORE_USERS_COLLECTION).doc(task.supervisor).get();
    //     const supervisor = userMapper(supervisorSnap);
    //     task.supervisorName = supervisor.name;
    //     task.supervisorTp = supervisor.tp;
    // }
    return {
        // address: task.address.length === 0 ? null : task.address,
        // criteria: cr.concat(groupCriteria).concat(locationCriteria).concat(skillCriteria),
        // suggested: null,
        state: task.state,
        stateHistory: task.stateHistory,
        // sourceERP: task.sourceERP,
        // taskType: task.taskType,
        // location: task.location.length === 0 ? null : task.location,
        // mapLink: task.mapLink.length === 0 ? null : task.mapLink,
        // tp: task.tp.filter(el => el.length !== 0),
        // description: task.description,
        // refId: task.refId,
        // due: firebase.firestore.Timestamp.fromMillis(task.due),
        // start: firebase.firestore.Timestamp.fromMillis(task.start),
        // created: firebase.firestore.Timestamp.fromMillis(task.created),
        completed: firebase.firestore.Timestamp.fromMillis(task.completed),
        // createdBy: task.createdBy.length === 0 ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + '/' + task.createdBy),
        // supervisor: task.supervisor.length === 0 ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + '/' + task.supervisor),
        // supervisorName: task.supervisorName.length === 0 ? null : task.supervisorName,
        // supervisorTp: task.supervisorTp.filter(el => el.length !== 0),
        assignedTo: task.assignedTo.length === 0 ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + task.assignedTo),
        // priority: task.priority,
        // travellingRequired: task.travellingRequired,
        cancelRequest: task.cancelRequest.length === 0
            ? null
            : firestore.doc(FIRESTORE_CANCEL_REQUESTS_COLLECTION + "/" + task.cancelRequest),
        completionImage: task.completionImage.length === 0 ? null : task.completionImage,
        completionRemarks: task.completionRemarks ? task.completionRemarks : null,
        completionRating: task.completionRating ? task.completionRating : null,
        nextUser: task.nextUser === null ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + task.nextUser),
        nextDate: task.nextDate === null ? null : firebase.firestore.Timestamp.fromMillis(task.nextDate),
        furtherAction: task.furtherAction,
        amount: task.amount
        // archive: task.archive,
        // isChild: false,
    };
}
