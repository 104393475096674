var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
/* eslint no-throw-literal: "off" */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import firebase from "firebase";
import sha256 from "crypto-js/sha256";
import { firestore } from "../../utils/firebaseConnector";
import { userCreator, userMapper, userUpdater } from "../../utils/mapper/userMapper";
import { findCascadeUserNodes, findSubordinateUserNodes, getAllowedUserHierarchy } from "../../utils/userTree";
import { FIRESTORE_HISTORIES_SUB_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../../utils/firestoreCollections";
import { archiveUserFailureAction, archiveUserSuccessAction, changeUserPasswordFailureAction, changeUserPasswordSuccessAction, createUserFailureAction, createUserSuccessAction, fetchUpdateUserLoginFailureAction, fetchUpdateUserLoginSuccessAction, fetchUserFailureAction, fetchUserSuccessAction, findSubordinateAndCascadeUserSuccessAction, updateUserFailureAction, updateUserProfileFailureAction, updateUserProfileSuccessAction, updateUserSuccessAction, userActionTypes } from "./action";
import { getLocalDate } from "../../utils/dateOperations";
// ------------------------------------------------------saga-1----------------------------------------------------------
function fetchUserFromFirestore() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_USERS_COLLECTION)
                    .orderBy("name")
                    .get()
                    .then(function (querySnapshot) {
                    return querySnapshot.docs.map(function (doc) {
                        return userMapper(doc);
                    });
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callFetchAllUser() {
    var result, userHierarchy, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(fetchUserFromFirestore)];
            case 1:
                result = _a.sent();
                userHierarchy = getAllowedUserHierarchy(result);
                return [4 /*yield*/, put(fetchUserSuccessAction(result, userHierarchy))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_1 = _a.sent();
                return [4 /*yield*/, put(fetchUserFailureAction(e_1.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-2----------------------------------------------------------
export function checkEmail(email) {
    return firestore
        .collection(FIRESTORE_USERS_COLLECTION)
        .where("email", "==", email)
        .get()
        .then(function (snapByEmail) {
        return snapByEmail.size;
    })
        .catch(function (e) {
        console.error("check user email error occurred", e.toString());
        return 1;
    });
}
export function checkId(uid) {
    return firestore
        .collection(FIRESTORE_USERS_COLLECTION)
        .where("uid", "==", uid)
        .get()
        .then(function (snapById) {
        return snapById.size;
    })
        .catch(function (e) {
        console.error("check user id error occurred", e.toString());
        return 1;
    });
}
function createFirestoreUserDocument(user) {
    return __awaiter(this, void 0, void 0, function () {
        var snapSizeById, snapSizeByEmail;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkId(user.uid)];
                case 1:
                    snapSizeById = _a.sent();
                    snapSizeByEmail = 0;
                    if (!(user.email.length > 0))
                        return [3 /*break*/, 3];
                    return [4 /*yield*/, checkEmail(user.email)];
                case 2:
                    snapSizeByEmail = _a.sent();
                    _a.label = 3;
                case 3:
                    if (snapSizeById !== 0) {
                        throw "uid_exist";
                    }
                    else if (snapSizeByEmail !== 0) {
                        throw "email_exist";
                    }
                    else {
                        return [2 /*return*/, firestore
                                .collection(FIRESTORE_USERS_COLLECTION)
                                .doc()
                                .set(userCreator(user))
                                .then(function () {
                                return "S1000";
                            })
                                .catch(function (e) {
                                throw e;
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function createUserWithEmailAndPassword(email, password) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=" + process.env.API_KEY, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            email: email,
                            password: password,
                            returnSecureToken: false
                        })
                    })
                        .then(function (response) {
                        if (response.ok) {
                            return response.json();
                        }
                        console.error(response);
                        throw "create_user_with_email_and_password_error";
                    })
                        .catch(function (e) {
                        throw e;
                    })];
                case 1:
                    // eslint-disable-next-line no-return-await
                    return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function callCreateUser(action) {
    var user, email, responseAuthentication, userUID, e_2;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                user = action.user;
                if (!user.isFirebaseUser)
                    return [3 /*break*/, 2];
                user.lastPasswords = [
                    {
                        timestamp: new Date().getTime(),
                        password: sha256(user.initialPassword).toString()
                    }
                ];
                email = user.email.length === 0 ? user.uid + "@email.com" : user.email;
                return [4 /*yield*/, call(createUserWithEmailAndPassword, email, user.initialPassword)];
            case 1:
                responseAuthentication = _b.sent();
                userUID = (_a = responseAuthentication) === null || _a === void 0 ? void 0 : _a.localId;
                _b.label = 2;
            case 2: return [4 /*yield*/, call(createFirestoreUserDocument, user)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(createUserSuccessAction(user.name))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                e_2 = _b.sent();
                return [4 /*yield*/, put(createUserFailureAction(e_2.toString()))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
function updateFirestoreUserDocument(user) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_USERS_COLLECTION)
                    .doc(user.documentID)
                    .update(userUpdater(user))
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callUpdateUser(action) {
    var e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(updateFirestoreUserDocument, action.user)];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(updateUserSuccessAction(action.user.name))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_3 = _a.sent();
                return [4 /*yield*/, put(updateUserFailureAction(e_3.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
function archiveFirestoreUserDocument(user) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_USERS_COLLECTION)
                    .doc(user.documentID)
                    .update({ archive: !user.archive })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callArchiveUser(action) {
    var e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(archiveFirestoreUserDocument, action.user)];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(archiveUserSuccessAction(action.user.name, !action.user.archive))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_4 = _a.sent();
                return [4 /*yield*/, put(archiveUserFailureAction(e_4.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-4----------------------------------------------------------
export function appendNewPasswordToUserDocument(email, newPassword) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_USERS_COLLECTION)
                    .where("email", "==", email)
                    .get()
                    .then(function (snapByEmail) {
                    if (!snapByEmail.empty) {
                        var lastPasswords = snapByEmail.docs[0].data().lastPasswords;
                        var lp = lastPasswords || [];
                        lp.push({
                            timestamp: firebase.firestore.Timestamp.fromMillis(new Date().getTime()),
                            password: sha256(newPassword).toString()
                        });
                        return firestore
                            .collection(FIRESTORE_USERS_COLLECTION)
                            .doc(snapByEmail.docs[0].id)
                            .set({
                            lastPasswords: lp
                        }, { merge: true })
                            .then(function () {
                            return "S1000";
                        })
                            .catch(function (e) {
                            throw e;
                        });
                    }
                    throw "user_does_not_exist_while_password_update";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function resetPasswordFromFirestore(email, newPassword) {
    return __awaiter(this, void 0, void 0, function () {
        var callable;
        return __generator(this, function (_a) {
            callable = firebase.functions().httpsCallable("changePassword");
            return [2 /*return*/, callable({ email: email, password: newPassword })
                    .then(function (result) {
                    if (result.data.status === "success") {
                        return "success";
                    }
                    console.error(result.data);
                    throw "change_password_error";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callResetPassword(action) {
    var e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4 /*yield*/, call(resetPasswordFromFirestore, action.email, action.newPassword)];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(appendNewPasswordToUserDocument, action.email, action.newPassword)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(changeUserPasswordSuccessAction())];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_5 = _a.sent();
                return [4 /*yield*/, put(changeUserPasswordFailureAction(e_5.toString()))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function callFindSubordinateAndCascadeUsers(action) {
    var subordinates_1, cascades, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(findSubordinateUserNodes, action.userHierarchy, action.loggedInUserDocumentID)];
            case 1:
                subordinates_1 = _a.sent();
                return [4 /*yield*/, call(findCascadeUserNodes, action.userHierarchy, action.loggedInUserDocumentID)];
            case 2:
                cascades = _a.sent();
                return [4 /*yield*/, put(findSubordinateAndCascadeUserSuccessAction(action.loggedInUserDocumentID, subordinates_1, cascades.filter(function (el) { var _a; return !((_a = subordinates_1) === null || _a === void 0 ? void 0 : _a.includes(el)) && el !== action.loggedInUserDocumentID; })))];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_6 = _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-5----------------------------------------------------------
function updateUserProfileAsync(user, picture) {
    return __awaiter(this, void 0, void 0, function () {
        var storageRef, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!picture)
                        return [3 /*break*/, 4];
                    return [4 /*yield*/, firebase.storage().ref("/ProfileImages/Reference: Users/" + user.documentID).put(picture)];
                case 1:
                    storageRef = _a.sent();
                    return [4 /*yield*/, storageRef.ref.getDownloadURL()];
                case 2:
                    url = _a.sent();
                    return [4 /*yield*/, firebase.firestore().collection("Users").doc(user.documentID).update({
                            profileImg: url,
                            name: user.name,
                            location: user.location,
                            tp: user.tp
                        })];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, firebase.firestore().collection("Users").doc(user.documentID).update({
                        name: user.name,
                        location: user.location,
                        tp: user.tp
                    })];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    });
}
function updateUserProfile(action) {
    var e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(updateUserProfileAsync, action.user, action.picture)];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(updateUserProfileSuccessAction())];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_7 = _a.sent();
                return [4 /*yield*/, put(updateUserProfileFailureAction(e_7.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
// --------------------------------Saga-6--------(Updating User Login Document)------------------------------
function createMarkLoginInDocument(loggedInUserID, date) {
    return __awaiter(this, void 0, void 0, function () {
        var relevantDocumentRef, newElement, history_1, history, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firestore
                        .collection(FIRESTORE_USERS_COLLECTION)
                        .doc(loggedInUserID)
                        .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                        .where("date", "==", date)
                        .get()];
                case 1:
                    relevantDocumentRef = _a.sent();
                    newElement = {
                        timestamp: firebase.firestore.Timestamp.fromMillis(new Date().getTime()),
                        location: null,
                        status: "LogIn",
                        data: {
                            device: "web",
                            workType: null
                        }
                    };
                    if (!relevantDocumentRef.empty)
                        return [3 /*break*/, 3];
                    history_1 = [];
                    history_1.push(newElement);
                    return [4 /*yield*/, firestore
                            .collection(FIRESTORE_USERS_COLLECTION)
                            .doc(loggedInUserID)
                            .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                            .doc()
                            .set({
                            checkOut: false,
                            date: date,
                            history: history_1
                        })
                            .then(function () {
                            return "S1000";
                        })
                            .catch(function (e) {
                            throw e;
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
                case 3:
                    history = relevantDocumentRef.docs[0].data().history;
                    result = history.filter(function (x) { return x.status === "LogIn"; });
                    // console.log("result",result)
                    // check doc history array if there is already a login element
                    if (result.length > 0) {
                        // console.log("already login")
                    }
                    // no login element in history array so updating history by adding login state
                    else {
                        // console.log("No login in a doc")
                        history.push(newElement);
                        return [2 /*return*/, firestore
                                .collection(FIRESTORE_USERS_COLLECTION)
                                .doc(loggedInUserID)
                                .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                                .doc(relevantDocumentRef.docs[0].id)
                                .update({
                                checkOut: false,
                                history: history
                            })
                                .then(function () {
                                return "S1000";
                            })
                                .catch(function (e) {
                                throw e;
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function callUpdateUserLogin(action) {
    var date, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                date = getLocalDate(new Date());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(createMarkLoginInDocument, action.user.documentID, date)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(fetchUpdateUserLoginSuccessAction())];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_8 = _a.sent();
                return [4 /*yield*/, put(fetchUpdateUserLoginFailureAction(e_8.toString()))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
// -------------------------End-------(User Login update action)--------------------------------
function watchUserActionSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(userActionTypes.FETCH_USERS, callFetchAllUser)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(userActionTypes.CREATE_USER, callCreateUser)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(userActionTypes.UPDATE_USER, callUpdateUser)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(userActionTypes.ARCHIVE_USER, callArchiveUser)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(userActionTypes.CHANGE_USER_PASSWORD, callResetPassword)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(userActionTypes.FIND_SUBORDINATE_AND_CASCADE_USERS, callFindSubordinateAndCascadeUsers)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(userActionTypes.UPDATE_USER_PROFILE, updateUserProfile)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(userActionTypes.UPDATE_USER_LOGIN, callUpdateUserLogin)];
            case 8:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var userSagas = [watchUserActionSagas];
