import { Dialog } from "@material-ui/core";
import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
export default function OpenImageModal(props) {
    var isModalOpen = props.isModalOpen, setModalOpen = props.setModalOpen;
    var handleCloseModal = function () {
        setModalOpen("");
    };
    return (React.createElement(Dialog
    // disableBackdropClick
    , {
        // disableBackdropClick
        open: isModalOpen.length > 0, onClose: handleCloseModal, scroll: "body", maxWidth: "md", fullWidth: true, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.2)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray"
            }
        }
    }, React.createElement(Button, { onClick: handleCloseModal, style: {
            backgroundColor: "transparent",
            color: "white",
            minWidth: 36,
            position: "absolute",
            right: 5,
            top: 5
        } }, React.createElement(CloseIcon, null)), isModalOpen.length > 0 && (React.createElement("img", { src: isModalOpen, alt: "Not Found", style: {
            width: "100%",
            height: "auto",
            marginBottom: "-6px",
            border: "2px solid gray"
        } }))));
}
