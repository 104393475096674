var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { call, put, select } from "redux-saga/effects";
import { firestore } from "../../utils/firebaseConnector";
import { fetchSingleTaskFromFirestore } from "../Tasks/redux/sagas/callFetchSingleTaskSaga";
import { cancelRequestMapper } from "../../utils/mapper/cancellationRequestsMapper";
import { getPreviousPeriod } from "../../utils/dateOperations";
import { fetchCancelRequestsStatisticsFailureAction, fetchCancelRequestsStatisticsSuccessAction } from "./actions";
import { ROLE_ADMIN, ROLE_DIV_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/remoteConfig/roles/rolesEnum";
import calculatePercentage from "../../utils/percentage";
import { FIRESTORE_CANCEL_REQUESTS_COLLECTION } from "../../utils/firestoreCollections";
// ------------------------------------------------------saga-2----------------------------------------------------------
function fetchFirestoreCancellationRequest(startDate, endDate) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 0);
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_CANCEL_REQUESTS_COLLECTION)
                    .where("requestedAt", ">=", startDate)
                    .where("requestedAt", "<=", endDate)
                    .get()
                    .then(function (querySnapshot) {
                    return querySnapshot.docs.map(function (doc) {
                        return cancelRequestMapper(doc);
                    });
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function cancelRequestWithTask(cancellationRequestResult) {
    var data = cancellationRequestResult.map(function (el) {
        return fetchSingleTaskFromFirestore(el.task)
            .then(function (r) {
            return { cRequest: el, task: r };
        })
            .catch(function () {
            return { cRequest: el, task: undefined };
        });
    });
    return Promise.all(data).then(function (r) { return r.flatMap(function (e) { return e; }); });
}
function applyFiltersForCancellationRequestResult(combinedResult, children, action) {
    return combinedResult
        .filter(function (el) { return el.task !== undefined; })
        .filter(function (el) {
        return [ROLE_SUPER_ADMIN, ROLE_DIV_ADMIN, ROLE_ADMIN].includes(action.loggedInUserRole || "") ||
            children.includes(el.task.assignedTo) ||
            children.includes(el.task.createdBy) ||
            children.includes(el.task.supervisor);
    })
        .filter(function (el) {
        var _a;
        var isValid = true;
        if (action.groupFilter.length !== 0) {
            isValid = isValid && ((_a = el.task.criteria.groupCriteria) === null || _a === void 0 ? void 0 : _a.includes(action.groupFilter));
        }
        if (action.userFilter.length !== 0) {
            isValid = isValid && el.cRequest.requestedBy === action.userFilter;
        }
        if (action.taskTypeFilter.length !== 0) {
            isValid = isValid && el.task.taskType.includes(action.taskTypeFilter);
        }
        if (action.priorityFilter !== 0) {
            isValid = isValid && el.task.priority === action.priorityFilter;
        }
        if (action.sourceFilter.length !== 0) {
            if (action.sourceFilter === "ERP") {
                isValid = isValid && el.task.sourceERP !== null;
            }
            else {
                isValid = isValid && el.task.sourceERP === null;
            }
        }
        return isValid;
    });
}
export default function callFetchCancelRequestsStatistics(action) {
    var startDate, endDate, _a, previousStartDate, previousEndDate, children, cancellationRequestResult, combinedResult, cancellationRequestResultOfPreviousPeriod, combinedResultOfPreviousPeriod, filteredCancellationRequestResult, filteredCancellationRequestResultOfPreviousPeriod, v1, v2, v3, v4, v5, v6, v7, v8, e_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                startDate = action.startDate;
                endDate = action.endDate;
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 0);
                _a = getPreviousPeriod(startDate, endDate), previousStartDate = _a.previousStartDate, previousEndDate = _a.previousEndDate;
                return [4 /*yield*/, select(function (state) { return state.userManagement.children; })];
            case 1:
                children = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(fetchFirestoreCancellationRequest, action.startDate, action.endDate)];
            case 3:
                cancellationRequestResult = _b.sent();
                return [4 /*yield*/, call(cancelRequestWithTask, cancellationRequestResult)];
            case 4:
                combinedResult = _b.sent();
                return [4 /*yield*/, call(fetchFirestoreCancellationRequest, previousStartDate, previousEndDate)];
            case 5:
                cancellationRequestResultOfPreviousPeriod = _b.sent();
                return [4 /*yield*/, call(cancelRequestWithTask, cancellationRequestResultOfPreviousPeriod)];
            case 6:
                combinedResultOfPreviousPeriod = _b.sent();
                filteredCancellationRequestResult = applyFiltersForCancellationRequestResult(combinedResult, children, action);
                filteredCancellationRequestResultOfPreviousPeriod = applyFiltersForCancellationRequestResult(combinedResultOfPreviousPeriod, children, action);
                v1 = filteredCancellationRequestResult.length;
                v2 = filteredCancellationRequestResultOfPreviousPeriod.length;
                v3 = filteredCancellationRequestResult.filter(function (el) { return el.cRequest.state === 0; }).length;
                v4 = filteredCancellationRequestResultOfPreviousPeriod.filter(function (el) { return el.cRequest.state === 0; }).length;
                v5 = filteredCancellationRequestResult.filter(function (el) { return el.cRequest.state === 2; }).length;
                v6 = filteredCancellationRequestResultOfPreviousPeriod.filter(function (el) { return el.cRequest.state === 2; }).length;
                v7 = filteredCancellationRequestResult.filter(function (el) { return el.cRequest.state === 1; }).length;
                v8 = filteredCancellationRequestResultOfPreviousPeriod.filter(function (el) { return el.cRequest.state === 1; }).length;
                return [4 /*yield*/, put(fetchCancelRequestsStatisticsSuccessAction({
                        total: v1,
                        totalPercentage: calculatePercentage(v1, v2),
                        pending: v3,
                        pendingPercentage: calculatePercentage(v3, v4),
                        approved: v5,
                        approvedPercentage: calculatePercentage(v5, v6),
                        rejected: v7,
                        rejectedPercentage: calculatePercentage(v7, v8)
                    }))];
            case 7:
                _b.sent();
                return [3 /*break*/, 10];
            case 8:
                e_1 = _b.sent();
                return [4 /*yield*/, put(fetchCancelRequestsStatisticsFailureAction(e_1.toString()))];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
