var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { auth } from "../../utils/firebaseConnector";
import { API_CLOUD } from "../../utils/apiEndPoints";
import { fetchTaskOverdueFailureAction, fetchTaskOverdueSuccessAction } from "./actions";
function fetchCloudTaskOverdue(start, end, user, group, creators, _type, priority, source) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var params, token, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    params = {
                        start: start
                    };
                    if (end) {
                        params.end = end;
                    }
                    if (user) {
                        params.user = user;
                    }
                    if (group) {
                        params.group = group;
                    }
                    if (creators) {
                        params.creators = creators;
                    }
                    if (_type) {
                        params.type = _type;
                    }
                    if (priority) {
                        params.priority = priority;
                    }
                    if (source) {
                        params.source = source;
                    }
                    return [4 /*yield*/, ((_b = (_a = auth) === null || _a === void 0 ? void 0 : _a.currentUser) === null || _b === void 0 ? void 0 : _b.getIdToken())];
                case 1:
                    token = _c.sent();
                    return [4 /*yield*/, axios.get(API_CLOUD + "/stats/tasks/overdue", {
                            params: params,
                            headers: { Authorization: "Bearer " + token }
                        })];
                case 2:
                    response = _c.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export default function callFetchCloudTaskOverdue(action) {
    var taskOverdue, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(fetchCloudTaskOverdue, action.start, action.end, action.user, action.group, action.creators, 
                    // eslint-disable-next-line no-underscore-dangle
                    action._type, action.priority, action.source)];
            case 1:
                taskOverdue = _a.sent();
                return [4 /*yield*/, put(fetchTaskOverdueSuccessAction(taskOverdue))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_1 = _a.sent();
                return [4 /*yield*/, put(fetchTaskOverdueFailureAction(e_1.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
