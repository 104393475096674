import { NotificationManager } from "react-notifications";
export var liveLocationActionTypes;
(function (liveLocationActionTypes) {
    liveLocationActionTypes["FETCH_UPDATED_LIVE_LOCATIONS"] = "field-workforce-management/live_location/fetch_updated_live_locations";
    liveLocationActionTypes["FETCH_UPDATED_LIVE_LOCATIONS_SUCCESS"] = "field-workforce-management/live_location/fetch_updated_live_locations_success";
    liveLocationActionTypes["FETCH_UPDATED_LIVE_LOCATIONS_FAILURE"] = "field-workforce-management/live_location/fetch_updated_live_locations_failure";
    liveLocationActionTypes["FETCH_UPDATED_LIVE_HISTORIES"] = "field-workforce-management/live_location/fetch_live_histories";
    liveLocationActionTypes["FETCH_UPDATED_LIVE_HISTORIES_SUCCESS"] = "field-workforce-management/live_location/fetch_live_histories_success";
    liveLocationActionTypes["FETCH_UPDATED_LIVE_HISTORIES_FAILURE"] = "field-workforce-management/live_location/fetch_live_histories_failure";
})(liveLocationActionTypes || (liveLocationActionTypes = {}));
export function fetchUpdatedLiveLocationsAction() {
    return {
        type: liveLocationActionTypes.FETCH_UPDATED_LIVE_LOCATIONS
    };
}
export function fetchUpdatedLiveLocationsSuccessAction(liveLocations) {
    return {
        type: liveLocationActionTypes.FETCH_UPDATED_LIVE_LOCATIONS_SUCCESS,
        liveLocations: liveLocations
    };
}
export function fetchUpdatedLiveLocationsFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchLiveLocationsFailureAction", err);
    NotificationManager.error("Error occurred while fetching Live locations. Please try again later.", "Oops! Error");
    return {
        type: liveLocationActionTypes.FETCH_UPDATED_LIVE_LOCATIONS_FAILURE,
        err: err
    };
}
export function fetchUpdatedLiveHistoriesAction() {
    return {
        type: liveLocationActionTypes.FETCH_UPDATED_LIVE_HISTORIES
    };
}
export function fetchUpdatedLiveHistoriesSuccessAction(liveHistories) {
    return {
        type: liveLocationActionTypes.FETCH_UPDATED_LIVE_HISTORIES_SUCCESS,
        liveHistories: liveHistories
    };
}
export function fetchUpdatedLiveHistoriesFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchLiveHistoriesFailureAction", err);
    NotificationManager.error("Error occurred while fetching user's live histories. Please try again later.", "Oops! Error");
    return {
        type: liveLocationActionTypes.FETCH_UPDATED_LIVE_HISTORIES_FAILURE,
        err: err
    };
}
