var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { call, put, take, takeEvery } from "redux-saga/effects";
import firebase from "firebase";
import { eventChannel } from "redux-saga";
import { getAttendanceStatusFailureAction, getAttendanceStatusSuccessAction, homePageActionTypes, markAttendanceCheckInFailureAction, markAttendanceCheckInSuccessAction, markAttendanceCheckOutFailureAction, markAttendanceCheckOutSuccessAction } from "./actions";
import { firestore } from "../../utils/firebaseConnector";
import { FIRESTORE_HISTORIES_SUB_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../../utils/firestoreCollections";
import { getLocalDate } from "../../utils/dateOperations";
import { workTypeEnum } from "./reducer";
// ------------------------------------------------------saga-1----------------------------------------------------------
function createMarkAttendanceCheckOutDocument(loggedInUserID, workType, date) {
    return __awaiter(this, void 0, void 0, function () {
        var relevantDocumentRef, history;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firestore
                        .collection(FIRESTORE_USERS_COLLECTION)
                        .doc(loggedInUserID)
                        .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                        .where("date", "==", date)
                        .get()];
                case 1:
                    relevantDocumentRef = _a.sent();
                    if (relevantDocumentRef.empty) {
                        // eslint-disable-next-line no-throw-literal
                        throw "could_not_find_document";
                    }
                    else {
                        history = relevantDocumentRef.docs[0].data().history;
                        history.push({
                            timestamp: firebase.firestore.Timestamp.fromMillis(new Date().getTime()),
                            location: null,
                            status: "CheckOut",
                            data: {
                                device: "web",
                                workType: workType === workTypeEnum.HOME ? "WFH" : workType === workTypeEnum.OFFICE ? "WFO" : "WFF"
                            }
                        });
                        return [2 /*return*/, firestore
                                .collection(FIRESTORE_USERS_COLLECTION)
                                .doc(loggedInUserID)
                                .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                                .doc(relevantDocumentRef.docs[0].id)
                                .update({
                                checkOut: true,
                                history: history
                            })
                                .then(function () {
                                return "S1000";
                            })
                                .catch(function (e) {
                                throw e;
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function callMarkAttendanceCheckOut(action) {
    var date, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                date = getLocalDate(new Date());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(createMarkAttendanceCheckOutDocument, action.loggedInUserID, action.workType, date)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(markAttendanceCheckOutSuccessAction())];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_1 = _a.sent();
                return [4 /*yield*/, put(markAttendanceCheckOutFailureAction(e_1.toString()))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-2----------------------------------------------------------
function createMarkAttendanceCheckInDocument(loggedInUserID, workType, date) {
    return __awaiter(this, void 0, void 0, function () {
        var relevantDocumentRef, newElement, history_1, history;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firestore
                        .collection(FIRESTORE_USERS_COLLECTION)
                        .doc(loggedInUserID)
                        .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                        .where("date", "==", date)
                        .get()];
                case 1:
                    relevantDocumentRef = _a.sent();
                    newElement = {
                        timestamp: firebase.firestore.Timestamp.fromMillis(new Date().getTime()),
                        location: null,
                        status: "CheckIn",
                        data: {
                            device: "web",
                            workType: workType === workTypeEnum.HOME ? "WFH" : workType === workTypeEnum.OFFICE ? "WFO" : "WFF"
                        }
                    };
                    if (relevantDocumentRef.empty) {
                        history_1 = [];
                        history_1.push(newElement);
                        return [2 /*return*/, firestore
                                .collection(FIRESTORE_USERS_COLLECTION)
                                .doc(loggedInUserID)
                                .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                                .doc()
                                .set({
                                checkOut: false,
                                date: date,
                                history: history_1
                            })
                                .then(function () {
                                return "S1000";
                            })
                                .catch(function (e) {
                                throw e;
                            })];
                    }
                    history = relevantDocumentRef.docs[0].data().history;
                    history.push(newElement);
                    return [2 /*return*/, firestore
                            .collection(FIRESTORE_USERS_COLLECTION)
                            .doc(loggedInUserID)
                            .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                            .doc(relevantDocumentRef.docs[0].id)
                            .update({
                            checkOut: false,
                            history: history
                        })
                            .then(function () {
                            return "S1000";
                        })
                            .catch(function (e) {
                            throw e;
                        })];
            }
        });
    });
}
function callMarkAttendanceCheckIn(action) {
    var date, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                date = getLocalDate(new Date());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(createMarkAttendanceCheckInDocument, action.loggedInUserID, action.workType, date)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(markAttendanceCheckInSuccessAction())];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_2 = _a.sent();
                return [4 /*yield*/, put(markAttendanceCheckInFailureAction(e_2.toString()))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
function getAttendanceState(querySnapshot) {
    var _a, _b;
    if (querySnapshot.size > 0) {
        var _c = querySnapshot.docs[0].data(), checkOut = _c.checkOut, history = _c.history;
        if (checkOut) {
            return undefined;
        }
        var historyElements = history
            .filter(function (el) { return el.status === "CheckIn"; })
            .sort(function (a, b) { return a.timestamp.seconds - b.timestamp.seconds; })
            .reverse();
        if (historyElements.length > 0) {
            var latestElement = historyElements[0];
            switch ((_b = (_a = latestElement) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.workType) {
                case "WFH":
                    return workTypeEnum.HOME;
                case "WFO":
                    return workTypeEnum.OFFICE;
                case "WFF":
                    return workTypeEnum.FIELD;
                default:
                    return workTypeEnum.UNKNOWN;
            }
        }
        else {
            // checkout false & no checkIn
            return undefined;
        }
    }
    else {
        // couldn't find histories element
        return undefined;
    }
}
function callGetAttendanceState(action) {
    var date, ref, channel, querySnapshot, result, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                date = getLocalDate(new Date());
                ref = firestore
                    .collection(FIRESTORE_USERS_COLLECTION)
                    .doc(action.loggedInUserID)
                    .collection(FIRESTORE_HISTORIES_SUB_COLLECTION)
                    .where("date", "==", date);
                channel = eventChannel(function (emit) { return ref.onSnapshot(emit); });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                _a.label = 2;
            case 2:
                if (!true)
                    return [3 /*break*/, 5];
                return [4 /*yield*/, take(channel)];
            case 3:
                querySnapshot = _a.sent();
                result = getAttendanceState(querySnapshot);
                return [4 /*yield*/, put(getAttendanceStatusSuccessAction(result))];
            case 4:
                _a.sent();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                err_1 = _a.sent();
                return [4 /*yield*/, put(getAttendanceStatusFailureAction(err_1.toString()))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function getHomePageManagementSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(homePageActionTypes.MARK_ATTENDANCE_CHECK_IN, callMarkAttendanceCheckIn)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(homePageActionTypes.MARK_ATTENDANCE_CHECK_OUT, callMarkAttendanceCheckOut)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(homePageActionTypes.GET_ATTENDANCE_STATUS, callGetAttendanceState)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var homePageSagas = [getHomePageManagementSagas];
export default homePageSagas;
