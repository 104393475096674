var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { firestore } from "../../utils/firebaseConnector";
import { alertsActionTypes, fetchAlertFailureAction, fetchAlertSuccessAction, listenAlertFailureAction, listenAlertSuccessAction, markAsReadAlertFailureAction, markAsReadAlertSuccessAction } from "./actions";
import { alertMapper } from "../../utils/mapper/alertMapper";
import { FIRESTORE_ALERTS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../../utils/firestoreCollections";
// ------------------------------------------------------saga-1----------------------------------------------------------
function callFetchAlerts(action) {
    var userDocRef, startDate, endDate, ref, channel, data, result, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userDocRef = firestore.collection(FIRESTORE_USERS_COLLECTION).doc(action.loggedInUserID);
                startDate = action.startDate;
                endDate = action.endDate;
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 0);
                ref = firestore
                    .collection(FIRESTORE_ALERTS_COLLECTION)
                    .where("to", "==", userDocRef)
                    .where("timestamp", ">=", startDate)
                    .where("timestamp", "<=", endDate)
                    .orderBy("timestamp", "desc");
                channel = eventChannel(function (emit) { return ref.onSnapshot(emit); });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                _a.label = 2;
            case 2:
                if (!true)
                    return [3 /*break*/, 5];
                return [4 /*yield*/, take(channel)];
            case 3:
                data = _a.sent();
                result = data.docs.map(function (doc) {
                    return alertMapper(doc);
                });
                return [4 /*yield*/, put(fetchAlertSuccessAction(result))];
            case 4:
                _a.sent();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                err_1 = _a.sent();
                return [4 /*yield*/, put(fetchAlertFailureAction(err_1.toString()))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-1----------------------------------------------------------
function callListenAlerts(action) {
    var userDocRef, startDate, endDate, ref, channel, data, result, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userDocRef = firestore.collection(FIRESTORE_USERS_COLLECTION).doc(action.loggedInUserID);
                startDate = action.startDate;
                endDate = action.endDate;
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 0);
                ref = firestore
                    .collection(FIRESTORE_ALERTS_COLLECTION)
                    .where("to", "==", userDocRef)
                    .where("timestamp", ">=", startDate)
                    .where("timestamp", "<=", endDate)
                    .orderBy("timestamp", "desc");
                channel = eventChannel(function (emit) { return ref.onSnapshot(emit); });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                _a.label = 2;
            case 2:
                if (!true)
                    return [3 /*break*/, 5];
                return [4 /*yield*/, take(channel)];
            case 3:
                data = _a.sent();
                result = data.docs.map(function (doc) {
                    return alertMapper(doc);
                });
                return [4 /*yield*/, put(listenAlertSuccessAction(result))];
            case 4:
                _a.sent();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                err_2 = _a.sent();
                return [4 /*yield*/, put(listenAlertFailureAction(err_2.toString()))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-2----------------------------------------------------------
function markAsReadAlertDocument(alertID) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_ALERTS_COLLECTION)
                    .doc(alertID)
                    .set({
                    seen: true
                }, { merge: true })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callMarkAsReadAlert(action) {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(markAsReadAlertDocument, action.alertID)];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(markAsReadAlertSuccessAction())];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_1 = _a.sent();
                return [4 /*yield*/, put(markAsReadAlertFailureAction(e_1.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getAlertManagementSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(alertsActionTypes.FETCH_ALERTS, callFetchAlerts)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(alertsActionTypes.LISTEN_ALERTS, callListenAlerts)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(alertsActionTypes.MARK_AS_READ, callMarkAsReadAlert)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var alertsSagas = [getAlertManagementSagas];
export default alertsSagas;
