/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { skillsActionTypes } from "./actions";
var initialState = {
    skills: [],
    createSkillSuccess: false,
    updateSkillSuccess: false,
    archiveSkillSuccess: false,
    createSkillIsLoading: false,
    updateSkillIsLoading: false,
    archiveSkillIsLoading: false,
    fetchSkillsFailure: { hasError: false, description: "" },
    createSkillError: { hasError: false, description: "" },
    updateSkillError: { hasError: false, description: "" },
    archiveSkillError: { hasError: false, description: "" }
};
export default function skillsReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case skillsActionTypes.FETCH_SKILLS: {
                break;
            }
            case skillsActionTypes.FETCH_SKILLS_SUCCESS: {
                draft.skills = action.skills;
                draft.fetchSkillsFailure = { hasError: false, description: "" };
                break;
            }
            case skillsActionTypes.FETCH_SKILLS_FAILURE: {
                draft.fetchSkillsFailure = { hasError: true, description: action.err };
                break;
            }
            case skillsActionTypes.CREATE_SKILL:
                draft.createSkillIsLoading = true;
                break;
            case skillsActionTypes.CREATE_SKILL_SUCCESS:
                draft.createSkillSuccess = true;
                draft.createSkillIsLoading = false;
                draft.createSkillError = { hasError: false, description: "" };
                break;
            case skillsActionTypes.CREATE_SKILL_FAILURE:
                draft.createSkillIsLoading = false;
                draft.createSkillError = { hasError: true, description: action.err };
                break;
            case skillsActionTypes.UPDATE_SKILL:
                draft.updateSkillIsLoading = true;
                break;
            case skillsActionTypes.UPDATE_SKILL_SUCCESS:
                draft.updateSkillSuccess = true;
                draft.updateSkillIsLoading = false;
                draft.updateSkillError = { hasError: false, description: "" };
                break;
            case skillsActionTypes.UPDATE_SKILL_FAILURE:
                draft.updateSkillIsLoading = false;
                draft.updateSkillError = { hasError: true, description: action.err };
                break;
            case skillsActionTypes.TOGGLE_ARCHIVE_SKILL:
                draft.archiveSkillIsLoading = true;
                break;
            case skillsActionTypes.TOGGLE_ARCHIVE_SKILL_SUCCESS:
                draft.archiveSkillSuccess = true;
                draft.archiveSkillIsLoading = false;
                draft.archiveSkillError = { hasError: false, description: "" };
                break;
            case skillsActionTypes.TOGGLE_ARCHIVE_SKILL_FAILURE:
                draft.archiveSkillIsLoading = false;
                draft.archiveSkillError = { hasError: true, description: action.err };
                break;
            case skillsActionTypes.RESET_SUCCESS_STATE:
                draft.createSkillSuccess = false;
                draft.updateSkillSuccess = false;
                draft.archiveSkillSuccess = false;
                break;
            default:
                break;
        }
    });
}
