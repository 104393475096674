/* eslint-disable  @typescript-eslint/no-explicit-any */
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import * as React from "react";
import { convertUTCDateToLocalDate } from "../../../../utils/dateOperations";
import useStyles from "../../styles/styles";
export default function DateSelector(props) {
    var id = props.id, label = props.label, defaultValue = props.defaultValue, setFieldValue = props.setFieldValue, onBlur = props.onBlur, errors = props.errors, touched = props.touched;
    var classes = useStyles();
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.datetimeContainer }, React.createElement(TextField, { InputProps: { disableUnderline: true }, inputProps: {
            min: new Date().toISOString().slice(0, 16)
        }, fullWidth: true, defaultValue: convertUTCDateToLocalDate(defaultValue), onChange: function (el) {
            setFieldValue(id, new Date(el.target.value).getTime());
        }, onBlur: onBlur, id: id, label: label, type: "datetime-local", className: classes.datetimeTextField, onKeyDown: function (e) { return e.preventDefault(); }, InputLabelProps: {
            shrink: true
            // min: new Date().toISOString().slice(0, 16),
        } })), errors[id] && touched[id] && React.createElement("div", { className: classes.error }, errors[id])));
}
