import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as NavigatorLink } from "react-router-dom";
import loadingIcon from "../../assets/images/loadingBox.gif";
import { signOutAction } from "../Login/action";
import useStyles from "./styles";
import { LIGHT_BLUE } from "../../utils/colorCodes";
import { isCurrentPasswordOlderThan45Days } from "../../utils/passwordValidation";
export default function AccessDeniedDialog() {
    var _a, _b, _c, _d;
    var dispatch = useDispatch();
    var classes = useStyles();
    var _e = useSelector(function (state) { return state.loginManagement; }), loggedInUser = _e.loggedInUser, fetchLoggedInUserIsLoading = _e.fetchLoggedInUserIsLoading;
    var notWithin45Days = isCurrentPasswordOlderThan45Days(((_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.lastPasswords) || [], ((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.isFirebaseUser) || false);
    var unauthorizedUser = fetchLoggedInUserIsLoading || loggedInUser === undefined || ((_c = loggedInUser) === null || _c === void 0 ? void 0 : _c.archive) || notWithin45Days;
    var accessDeniedView = (React.createElement(React.Fragment, null, React.createElement(Typography, { className: classes.accessDeniedDialogTitle }, "Access Denied!"), React.createElement(Button, { component: "button", style: {
            backgroundColor: LIGHT_BLUE,
            color: "white",
            fontSize: 16,
            fontWeight: 600,
            marginTop: 19,
            width: 300,
            height: 50,
            textTransform: "none"
        }, type: "submit", fullWidth: true, variant: "contained", onClick: function () { return dispatch(signOutAction()); } }, "Logout")));
    var loadingGifView = React.createElement("img", { src: loadingIcon, alt: "Not Found", className: classes.accessDeniedDialogLoadingIcon });
    var passwordExpiredView = (React.createElement(React.Fragment, null, React.createElement(Typography, { className: classes.accessDeniedDialogTitle }, "Your password expired and must be changed!"), React.createElement(Button, { component: NavigatorLink, to: "/change-password", style: {
            backgroundColor: LIGHT_BLUE,
            color: "white",
            fontSize: 16,
            fontWeight: 600,
            marginTop: 19,
            width: 300,
            height: 50,
            textTransform: "none"
        }, type: "submit", fullWidth: true, variant: "contained" }, "Change Password")));
    return (React.createElement(Dialog, { fullScreen: true, open: unauthorizedUser, scroll: "body", fullWidth: true, BackdropProps: {
            style: {
                backgroundColor: "transparent"
            }
        }, PaperProps: {
            style: {
                backgroundColor: "rgba(255, 255, 255, 0.7)"
            }
        } }, React.createElement("div", { className: classes.accessDeniedDialogBody }, fetchLoggedInUserIsLoading
        ? loadingGifView
        : loggedInUser === undefined || ((_d = loggedInUser) === null || _d === void 0 ? void 0 : _d.archive)
            ? accessDeniedView
            : notWithin45Days && passwordExpiredView)));
}
