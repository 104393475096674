/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { routeHistoryActionTypes } from "./actions";
var initialState = {
    tableHistory: [],
    locationHistory: [],
    fetchTableHistoryIsLoading: false,
    fetchTableHistoryError: { hasError: false, description: "" }
};
export default function routeHistoryReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case routeHistoryActionTypes.FETCH_USER_HISTORIES: {
                draft.fetchTableHistoryIsLoading = true;
                break;
            }
            case routeHistoryActionTypes.FETCH_USER_HISTORIES_SUCCESS: {
                draft.tableHistory = action.history;
                draft.locationHistory = action.locationHistory;
                draft.fetchTableHistoryIsLoading = false;
                draft.fetchTableHistoryError = { hasError: false, description: "" };
                break;
            }
            case routeHistoryActionTypes.FETCH_USER_HISTORIES_FAILURE: {
                draft.fetchTableHistoryIsLoading = false;
                draft.fetchTableHistoryError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            default:
                break;
        }
    });
}
