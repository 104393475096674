var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { FormControl } from "@material-ui/core";
import { useSelector } from "react-redux";
import useStyles from "../views/Tasks/styles/styles";
import { endAdornmentStyles } from "../utils/endAdornmentStyles";
// @ts-ignore
export default function FilterByUser(props) {
    var _a;
    var userFilter = props.userFilter, setUserFilter = props.setUserFilter, groupFilter = props.groupFilter, _b = props.defaultId, defaultId = _b === void 0 ? "" : _b, _c = props.isDisabled, isDisabled = _c === void 0 ? false : _c;
    var classes = useStyles();
    var endAdornmentClasses = endAdornmentStyles();
    var userList = useSelector(function (state) { return state.userManagement; }).userList;
    var defaultName = (_a = userList.filter(function (ul) { return ul.documentID === defaultId; })[0]) === null || _a === void 0 ? void 0 : _a.name;
    return (React.createElement(FormControl, { className: classes.formStatusControlUserList }, React.createElement(Autocomplete, { value: userFilter
            ? userList
                .filter(function (el) { return el.documentID === userFilter; })
                .map(function (el) { return ({ documentID: el.documentID, name: el.name }); })[0]
            : { documentID: defaultId, name: defaultName }, getOptionSelected: function (option, value) { return option.documentID === value.documentID; }, disabled: isDisabled, id: "filter-by-users", classes: endAdornmentClasses, options: userList
            .filter(function (el) {
            if (groupFilter.length !== 0) {
                return el.groups.includes(groupFilter);
            }
            return true;
        })
            .map(function (ul) { return ({ documentID: ul.documentID, name: ul.name }); }),
        // defaultValue={{ documentID: defaultId, name: defaultName }}
        getOptionLabel: function (option) { return option.name || ""; }, onChange: function (_, value) { var _a; return setUserFilter(((_a = value) === null || _a === void 0 ? void 0 : _a.documentID) || ""); }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true }), placeholder: defaultId.length > 0 ? defaultName : "Filter by User", variant: "standard" }))); } })));
}
