import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { archiveUserAction, fetchUserAction, resetUserLoadingStateAction } from "./action";
import { ERROR_HIGH, PRIMARY_BLUE } from "../../utils/colorCodes";
export default function ArchiveUserModal(props) {
    var _a, _b;
    var currentUser = props.currentUser, isArchiveUserModalOpen = props.isArchiveUserModalOpen, setArchiveUserModalOpen = props.setArchiveUserModalOpen;
    var dispatch = useDispatch();
    var _c = useSelector(function (state) { return state.userManagement; }), archiveUserSuccess = _c.archiveUserSuccess, archiveUserIsLoading = _c.archiveUserIsLoading;
    React.useEffect(function () {
        if (archiveUserSuccess) {
            setArchiveUserModalOpen(false);
            dispatch(resetUserLoadingStateAction());
            dispatch(fetchUserAction());
        }
    }, [archiveUserSuccess, dispatch, setArchiveUserModalOpen]);
    return (React.createElement(Dialog
    // disableBackdropClick
    , {
        // disableBackdropClick
        open: isArchiveUserModalOpen, onClose: function () {
            setArchiveUserModalOpen(false);
        }, scroll: "body", maxWidth: "xs", fullWidth: true, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray"
            }
        }
    }, React.createElement(DialogTitle, { id: "scroll-dialog-title" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { style: { fontSize: 17, fontWeight: "bold", color: PRIMARY_BLUE } }, "Are you sure?"), React.createElement(Button, { onClick: function () {
            setArchiveUserModalOpen(false);
        }, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null)))), React.createElement(DialogContent, null, React.createElement(Grid, { container: true, justify: "flex-start" }, ((_a = currentUser) === null || _a === void 0 ? void 0 : _a.archive) ? (React.createElement(Typography, null, "This will activate the user.")) : (React.createElement(Typography, null, "This will archive the user. There can be tasks assigned to the user. Please review from the Task Page.")))), React.createElement(DialogActions, { style: { padding: 24 } }, React.createElement(Button, { disabled: archiveUserIsLoading, type: "submit", onClick: function () {
            dispatch(archiveUserAction(currentUser));
        }, color: "primary", fullWidth: true, style: {
            textTransform: "none",
            backgroundColor: ERROR_HIGH,
            color: "white"
            // minWidth: 150
        } }, ((_b = currentUser) === null || _b === void 0 ? void 0 : _b.archive) ? "Activate the user" : "Archive the user"))));
}
