/* eslint-disable  @typescript-eslint/no-explicit-any */
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
export default function ShowTaskDeleteOptionMenu(props) {
    var currentTask = props.currentTask, anchorElTaskDeleteMenu = props.anchorElTaskDeleteMenu, handleCloseTaskDeleteMenu = props.handleCloseTaskDeleteMenu, setToggleArchiveTaskModalOpen = props.setToggleArchiveTaskModalOpen, setDeleteThisTaskOnly = props.setDeleteThisTaskOnly;
    return (React.createElement(Menu, { style: { marginTop: 45, marginLeft: -25 }, id: "task-delete-menu", anchorEl: anchorElTaskDeleteMenu, open: Boolean(anchorElTaskDeleteMenu), onClose: handleCloseTaskDeleteMenu }, currentTask.isChild ? (React.createElement(MenuItem, { style: { fontSize: 15 }, onClick: function () {
            handleCloseTaskDeleteMenu();
            setToggleArchiveTaskModalOpen(true);
            setDeleteThisTaskOnly(true);
        } }, "Delete This Task")) : null, React.createElement(Divider, { style: { marginLeft: 16, marginRight: 16 } }), React.createElement(MenuItem, { style: { fontSize: 15 }, onClick: function () {
            handleCloseTaskDeleteMenu();
            setToggleArchiveTaskModalOpen(true);
            setDeleteThisTaskOnly(false);
        } }, "Delete This Task & The Schedule")));
}
