export function skillMapper(doc) {
    var _a = doc.data(), name = _a.name, archive = _a.archive;
    return {
        id: doc.id,
        name: name,
        archive: archive
    };
}
export function skillCreator(skill) {
    return {
        name: skill.name,
        archive: skill.archive
    };
}
export function skillUpdater(skill) {
    return {
        name: skill.name
        // archive: skill.archive
    };
}
