export function userGroupMapper(doc) {
    var _a = doc.data(), name = _a.name, interval = _a.interval, archive = _a.archive, secret = _a.secret, createdBy = _a.createdBy;
    return {
        id: doc.id,
        name: name,
        interval: interval,
        archive: archive,
        secret: secret,
        createdBy: createdBy
    };
}
export function userGroupCreator(userGroup) {
    return {
        name: userGroup.name,
        interval: userGroup.interval,
        archive: userGroup.archive
    };
}
export function userGroupUpdater(userGroup) {
    return {
        name: userGroup.name,
        interval: userGroup.interval
        // archive: userGroup.archive
    };
}
