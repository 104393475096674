import firebase from "firebase";
import { firestore } from "../firebaseConnector";
import { FIRESTORE_CANCEL_REQUESTS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../firestoreCollections";
export function taskMapper(doc, nextUserFetched) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = doc.data(), address = _l.address, criteria = _l.criteria, suggested = _l.suggested, state = _l.state, sourceERP = _l.sourceERP, taskType = _l.taskType, location = _l.location, mapLink = _l.mapLink, tp = _l.tp, description = _l.description, refId = _l.refId, due = _l.due, start = _l.start, created = _l.created, completed = _l.completed, createdBy = _l.createdBy, supervisor = _l.supervisor, supervisorName = _l.supervisorName, supervisorTp = _l.supervisorTp, assignedTo = _l.assignedTo, priority = _l.priority, travellingRequired = _l.travellingRequired, stateHistory = _l.stateHistory, cancelRequest = _l.cancelRequest, completionImage = _l.completionImage, completionRemarks = _l.completionRemarks, completionRating = _l.completionRating, archive = _l.archive, isChild = _l.isChild, useTaskLocation = _l.useTaskLocation, amount = _l.amount, furtherAction = _l.furtherAction, nextDate = _l.nextDate, nextUser = _l.nextUser, parent = _l.parent;
    var groupCriteria = criteria.filter(function (el) { return el.type === "GroupCriteria"; }).map(function (el) { var _a; return (_a = el.group) === null || _a === void 0 ? void 0 : _a.id; });
    var locationCriteria = criteria.filter(function (el) { return el.type === "LocationCriteria"; }).map(function (el) { return el.location; });
    var skillCriteria = criteria
        .filter(function (el) { return el.type === "SkillCriteria"; })
        .map(function (el) {
        var _a;
        return ({
            skill: (_a = el.skill) === null || _a === void 0 ? void 0 : _a.id,
            level: el.level
        });
    });
    // const stateHistoryList = (stateHistory || []).map((el: any) => ({
    //     changedBy: el.changedBy?.id || undefined,
    //     location: el.location
    //         ? {
    //             lng: el.location?.longitude,
    //             lat: el.location?.latitude
    //         }
    //         : undefined,
    //     state: el.state || undefined,
    //     timestamp: (el.timestamp?.seconds * 1000) || undefined
    // }));
    return {
        amount: amount || null,
        furtherAction: furtherAction || null,
        nextDate: nextDate || null,
        nextUser: nextUserFetched || nextUser || null,
        id: doc.id,
        address: address || "",
        criteria: {
            groupCriteria: groupCriteria.length === 0 ? [""] : groupCriteria,
            locationCriteria: locationCriteria.length === 0 ? [""] : locationCriteria,
            skillCriteria: skillCriteria.length === 0 ? [{ skill: "", level: 1 }] : skillCriteria
        },
        suggested: ((_a = suggested) === null || _a === void 0 ? void 0 : _a.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; })) || [],
        state: state || "",
        stateHistory: stateHistory || null,
        sourceERP: sourceERP || null,
        taskType: taskType || "",
        location: location || "",
        mapLink: mapLink || "",
        tp: (((_b = tp) === null || _b === void 0 ? void 0 : _b.length) !== 0 && tp) || [""],
        description: description || "",
        refId: refId || null,
        due: ((_c = due) === null || _c === void 0 ? void 0 : _c.seconds) * 1000 || 0,
        start: ((_d = start) === null || _d === void 0 ? void 0 : _d.seconds) * 1000 || 0,
        created: ((_e = created) === null || _e === void 0 ? void 0 : _e.seconds) * 1000 || 0,
        completed: ((_f = completed) === null || _f === void 0 ? void 0 : _f.seconds) * 1000 || 0,
        createdBy: ((_g = createdBy) === null || _g === void 0 ? void 0 : _g.id) || "",
        supervisor: ((_h = supervisor) === null || _h === void 0 ? void 0 : _h.id) || "",
        supervisorName: supervisorName || "",
        supervisorTp: supervisorTp || [],
        assignedTo: ((_j = assignedTo) === null || _j === void 0 ? void 0 : _j.id) || "",
        priority: priority || 3,
        travellingRequired: travellingRequired || false,
        cancelRequest: ((_k = cancelRequest) === null || _k === void 0 ? void 0 : _k.id) || "",
        completionImage: completionImage || [],
        completionRemarks: completionRemarks || undefined,
        completionRating: completionRating || undefined,
        archive: archive || false,
        isChild: isChild || false,
        useTaskLocation: useTaskLocation || false,
        parent: parent ? parent.id : undefined
    };
}
// export async function taskCreator(task: Task): Promise<any> {
//   const groupCriteria = task.criteria.groupCriteria
//     .filter((el) => el.length > 0)
//     .map((el) => ({
//       type: "GroupCriteria",
//       group: firestore.doc(`${FIRESTORE_USER_GROUPS_COLLECTION}/${el}`),
//     }));
//   const locationCriteria = task.criteria.locationCriteria
//     .filter((el) => el.length > 0)
//     .map((el) => ({
//       type: "LocationCriteria",
//       location: el,
//     }));
//   const skillCriteria = task.criteria.skillCriteria
//     .filter((el) => el.skill.length > 0)
//     .map((el) => ({
//       type: "SkillCriteria",
//       skill: firestore.doc(`${FIRESTORE_SKILLS_COLLECTION}/${el.skill}`),
//       level: el.level,
//     }));
//   const cr: any[] = [];
//
//   if (task.supervisor) {
//     const supervisorSnap = await firestore.collection(FIRESTORE_USERS_COLLECTION).doc(task.supervisor).get();
//     const supervisor = userMapper(supervisorSnap);
//     task.supervisorName = supervisor.name;
//     task.supervisorTp = supervisor.tp;
//   }
//
//   return {
//     address: task.address.length === 0 ? null : task.address,
//     criteria: cr.concat(groupCriteria).concat(locationCriteria).concat(skillCriteria),
//     suggested: null,
//     state: task.state,
//     stateHistory: [],
//     sourceERP: null,
//     taskType: task.taskType,
//     location: task.location.length === 0 ? null : task.location,
//     mapLink: task.mapLink.length === 0 ? null : task.mapLink,
//     tp: task.tp.filter((el) => el.length !== 0),
//     description: task.description,
//     refId: task.refId,
//     due: firebase.firestore.Timestamp.fromMillis(task.due),
//     start: firebase.firestore.Timestamp.fromMillis(task.start),
//     created: firebase.firestore.Timestamp.fromMillis(task.created),
//     completed: null,
//     createdBy: task.createdBy.length === 0 ? null : firestore.doc(`${FIRESTORE_USERS_COLLECTION}/${task.createdBy}`),
//     supervisor: task.supervisor.length === 0 ? null : firestore.doc(`${FIRESTORE_USERS_COLLECTION}/${task.supervisor}`),
//     supervisorName: task.supervisorName.length === 0 ? null : task.supervisorName,
//     supervisorTp: task.supervisorTp.filter((el) => el.length !== 0),
//     assignedTo: task.assignedTo.length === 0 ? null : firestore.doc(`${FIRESTORE_USERS_COLLECTION}/${task.assignedTo}`),
//     priority: task.priority,
//     travellingRequired: task.travellingRequired,
//     cancelRequest: null,
//     completionImage: null,
//     completionRemarks: null,
//     completionRating: null,
//     archive: false,
//     isChild: false,
//   };
// }
export function taskUpdater(task) {
    // const groupCriteria = task.criteria.groupCriteria.filter(el => el.length > 0).map(el => ({
    //     type: "GroupCriteria",
    //     group: firestore.doc(FIRESTORE_USER_GROUPS_COLLECTION + '/' + el),
    // }))
    // const locationCriteria = task.criteria.locationCriteria.filter(el => el.length > 0).map(el => ({
    //     type: "LocationCriteria",
    //     location: el,
    // }))
    // const skillCriteria = task.criteria.skillCriteria.filter(el => el.skill.length > 0).map(el => ({
    //     type: "SkillCriteria",
    //     skill: firestore.doc(FIRESTORE_SKILLS_COLLECTION + '/' + el.skill),
    //     level: el.level,
    // }))
    // let cr: any[] = []
    // if (task.supervisor) {
    //     const supervisorSnap = await firestore.collection(FIRESTORE_USERS_COLLECTION).doc(task.supervisor).get();
    //     const supervisor = userMapper(supervisorSnap);
    //     task.supervisorName = supervisor.name;
    //     task.supervisorTp = supervisor.tp;
    // }
    return {
        // address: task.address.length === 0 ? null : task.address,
        // criteria: cr.concat(groupCriteria).concat(locationCriteria).concat(skillCriteria),
        // suggested: null,
        state: task.state,
        stateHistory: task.stateHistory,
        // sourceERP: task.sourceERP,
        // taskType: task.taskType,
        // location: task.location.length === 0 ? null : task.location,
        // mapLink: task.mapLink.length === 0 ? null : task.mapLink,
        // tp: task.tp.filter(el => el.length !== 0),
        // description: task.description,
        // refId: task.refId,
        // due: firebase.firestore.Timestamp.fromMillis(task.due),
        // start: firebase.firestore.Timestamp.fromMillis(task.start),
        // created: firebase.firestore.Timestamp.fromMillis(task.created),
        completed: firebase.firestore.Timestamp.fromMillis(task.completed),
        // createdBy: task.createdBy.length === 0 ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + '/' + task.createdBy),
        // supervisor: task.supervisor.length === 0 ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + '/' + task.supervisor),
        // supervisorName: task.supervisorName.length === 0 ? null : task.supervisorName,
        // supervisorTp: task.supervisorTp.filter(el => el.length !== 0),
        assignedTo: task.assignedTo.length === 0 ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + task.assignedTo),
        // priority: task.priority,
        // travellingRequired: task.travellingRequired,
        cancelRequest: task.cancelRequest.length === 0
            ? null
            : firestore.doc(FIRESTORE_CANCEL_REQUESTS_COLLECTION + "/" + task.cancelRequest),
        completionImage: task.completionImage.length === 0 ? null : task.completionImage,
        completionRemarks: task.completionRemarks ? task.completionRemarks : null,
        completionRating: task.completionRating ? task.completionRating : null,
        nextUser: task.nextUser === null ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + task.nextUser),
        nextDate: task.nextDate === null ? null : firebase.firestore.Timestamp.fromMillis(task.nextDate),
        furtherAction: task.furtherAction,
        amount: task.amount
        // archive: task.archive,
        // isChild: false,
    };
}
