var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import "firebase/functions";
import { firestore } from "../../utils/firebaseConnector";
import { userGroupMapper } from "../../utils/mapper/userGroupMapper";
import { createUserGroupFailureAction, createUserGroupSuccessAction, fetchUserGroupsFailureAction, fetchUserGroupsSuccessAction, toggleUserGroupArchiveFailureAction, toggleUserGroupArchiveSuccessAction, updateUserGroupFailureAction, updateUserGroupSuccessAction, userGroupsActionTypes } from "./actions";
import { FIRESTORE_USER_GROUPS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../../utils/firestoreCollections";
// ----------------------------------------------------FETCH_USER_GROUPS-------------------------------------------------
function fetchUserGroupDocumentFromFirestore() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_USER_GROUPS_COLLECTION)
                    .get()
                    .then(function (querySnapshot) {
                    return querySnapshot.docs.map(function (doc) {
                        return userGroupMapper(doc);
                    });
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
export function fetchSingleUserFromFirestore(documentID) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (documentID === undefined || documentID === "")
                return [2 /*return*/, undefined];
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_USERS_COLLECTION)
                    .doc(documentID)
                    .get()
                    .then(function (doc) {
                    var _a;
                    return { groups: (_a = doc.data()) === null || _a === void 0 ? void 0 : _a.groups.map(function (el) { return el.id; }) };
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function fetchSubordinatesRelatedUserGroupIDs(subordinateUserIDs) {
    return __awaiter(this, void 0, void 0, function () {
        var relatedUserGroupIDs;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    relatedUserGroupIDs = [];
                    return [4 /*yield*/, Promise.all(subordinateUserIDs.map(function (user) {
                            return __awaiter(_this, void 0, void 0, function () {
                                var singleUser;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, fetchSingleUserFromFirestore(user)];
                                        case 1:
                                            singleUser = _b.sent();
                                            relatedUserGroupIDs.push.apply(relatedUserGroupIDs, (_a = singleUser) === null || _a === void 0 ? void 0 : _a.groups);
                                            return [2 /*return*/];
                                    }
                                });
                            });
                        }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/, relatedUserGroupIDs];
            }
        });
    });
}
/* function filterUserGroups(
  userGroups: any[],
  relatedUserGroupIDs: string[],
  loggedInUser: any,
  subordinateUserIDs: string[]
) {
  return userGroups.filter((el) => {
    if (
      el.createdBy === loggedInUser.documentID ||
      loggedInUser.groups.includes(el.id) ||
      relatedUserGroupIDs.includes(el.id) ||
      subordinateUserIDs.includes(el.createdBy)
    ) {
      return true;
    }
    return false;
  });
} */
function callFetchUserGroup(action) {
    var subordinateUserIDs, result, userGroups, subordinatorGroupIDs, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, select(function (state) { return state.userManagement.subordinateUserIDs; })];
            case 1:
                subordinateUserIDs = _a.sent();
                return [4 /*yield*/, call(fetchUserGroupDocumentFromFirestore)];
            case 2:
                result = _a.sent();
                userGroups = result.filter(function (el) {
                    var _a;
                    if (!el.secret)
                        return true; // non secret groups
                    return action.loggedInUser.groups.includes(el.id) || action.loggedInUser.documentID === ((_a = el.createdBy) === null || _a === void 0 ? void 0 : _a.id);
                });
                return [4 /*yield*/, fetchSubordinatesRelatedUserGroupIDs(subordinateUserIDs)];
            case 3:
                subordinatorGroupIDs = _a.sent();
                // userGroups = filterUserGroups(userGroups, relatedUserGroupIDs, action.loggedInUser, subordinateUserIDs);
                // }
                return [4 /*yield*/, put(fetchUserGroupsSuccessAction(userGroups, subordinatorGroupIDs))];
            case 4:
                // userGroups = filterUserGroups(userGroups, relatedUserGroupIDs, action.loggedInUser, subordinateUserIDs);
                // }
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                err_1 = _a.sent();
                return [4 /*yield*/, put(fetchUserGroupsFailureAction(err_1.toString()))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
// -----------------------------------------------------CREATE_USER_GROUP------------------------------------------------
function createFirestoreUserGroupDocument(userGroup, createBy) {
    return __awaiter(this, void 0, void 0, function () {
        var creator;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firestore.collection(FIRESTORE_USERS_COLLECTION).doc(createBy)];
                case 1:
                    creator = _a.sent();
                    return [2 /*return*/, firestore
                            .collection(FIRESTORE_USER_GROUPS_COLLECTION)
                            .doc(userGroup.id)
                            .set({
                            name: userGroup.name,
                            interval: userGroup.interval,
                            archive: userGroup.archive,
                            secret: userGroup.secret === undefined ? false : userGroup.secret,
                            createdBy: creator
                        })
                            .then(function () {
                            return "S1000";
                        })
                            .catch(function (e) {
                            throw e;
                        })];
            }
        });
    });
}
function callCreateUserGroup(action) {
    var loggedInUser, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.loginManagement.loggedInUser; })];
            case 1:
                loggedInUser = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 7]);
                return [4 /*yield*/, call(createFirestoreUserGroupDocument, action.userGroup, loggedInUser.documentID)];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(createUserGroupSuccessAction(action.userGroup.name))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_1 = _a.sent();
                return [4 /*yield*/, put(createUserGroupFailureAction(e_1.toString()))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
// -----------------------------------------------------UPDATE_USER_GROUP------------------------------------------------
function updateFirestoreUserGroupDocument(userGroup, createBy) {
    return __awaiter(this, void 0, void 0, function () {
        var creator;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firestore.collection(FIRESTORE_USERS_COLLECTION).doc(createBy)];
                case 1:
                    creator = _a.sent();
                    return [2 /*return*/, firestore
                            .collection(FIRESTORE_USER_GROUPS_COLLECTION)
                            .doc(userGroup.id)
                            .set({
                            name: userGroup.name,
                            interval: userGroup.interval,
                            archive: userGroup.archive,
                            secret: userGroup.secret === undefined ? false : userGroup.secret,
                            createdBy: creator
                        }, { merge: true })
                            .then(function () {
                            return "S1000";
                        })
                            .catch(function (e) {
                            throw e;
                        })];
            }
        });
    });
}
function callUpdateUserGroup(action) {
    var loggedInUser, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.loginManagement.loggedInUser; })];
            case 1:
                loggedInUser = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 7]);
                return [4 /*yield*/, call(updateFirestoreUserGroupDocument, action.userGroup, loggedInUser.documentID)];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(updateUserGroupSuccessAction(action.userGroup.name))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_2 = _a.sent();
                return [4 /*yield*/, put(updateUserGroupFailureAction(e_2.toString()))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
// ----------------------------------------------------TOGGLE_ARCHIVE_USER_GROUP-----------------------------------------
function toggleActiveStatus(userGroup) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_USER_GROUPS_COLLECTION)
                    .doc(userGroup.id)
                    .update({ archive: !userGroup.archive })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callToggleActiveStatus(action) {
    var e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(toggleActiveStatus, action.userGroup)];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(toggleUserGroupArchiveSuccessAction(action.userGroup.name, !action.userGroup.archive))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_3 = _a.sent();
                return [4 /*yield*/, put(toggleUserGroupArchiveFailureAction(e_3.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
//----------------------------------------------------------------------------------------------------------------------
function watchUserGroupsActionSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(userGroupsActionTypes.FETCH_USER_GROUPS, callFetchUserGroup)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(userGroupsActionTypes.CREATE_USER_GROUP, callCreateUserGroup)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(userGroupsActionTypes.UPDATE_USER_GROUP, callUpdateUserGroup)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(userGroupsActionTypes.TOGGLE_ARCHIVE_USER_GROUP, callToggleActiveStatus)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var userGroupSagas = [watchUserGroupsActionSagas];
export default userGroupSagas;
