var TreeNode = /** @class */ (function () {
    function TreeNode(userDocId, supervisorDocId) {
        this._children = [];
        this._user = userDocId;
        this._supervisor = supervisorDocId;
    }
    Object.defineProperty(TreeNode.prototype, "supervisor", {
        get: function () {
            return this._supervisor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeNode.prototype, "children", {
        get: function () {
            return this._children;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeNode.prototype, "user", {
        get: function () {
            return this._user;
        },
        enumerable: true,
        configurable: true
    });
    return TreeNode;
}());
export { TreeNode };
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var isEntered = false;
function placeUserNodeOnHierarchy(userNodeList, userNode) {
    var find = userNodeList.find(function (el) { return el.user === userNode.supervisor; });
    if (find) {
        find.children.push(userNode);
        isEntered = true;
    }
    else {
        userNodeList.forEach(function (el) {
            return placeUserNodeOnHierarchy(el.children, userNode);
        });
    }
    return userNodeList;
}
var userHierarchy = [];
function generateUserHierarchy(userNodeList) {
    var nodes = userNodeList.filter(function (el) { var _a; return ((_a = el.supervisor) === null || _a === void 0 ? void 0 : _a.length) !== 0; });
    if (nodes.length > 0) {
        var tempNode_1 = nodes[0];
        var nodeListWithoutTempNode = userNodeList.filter(function (el) { return el.user !== tempNode_1.user; });
        isEntered = false;
        var receivedResult = placeUserNodeOnHierarchy(nodeListWithoutTempNode, tempNode_1);
        if (!isEntered) {
            console.error("Invalid supervisor reference", tempNode_1);
            tempNode_1._supervisor = "";
            receivedResult.push(tempNode_1);
        }
        generateUserHierarchy(receivedResult);
    }
    else {
        userHierarchy = JSON.parse(JSON.stringify(userNodeList));
    }
}
export function getAllowedUserHierarchy(userList) {
    var userNodeList = userList.map(function (user) { return new TreeNode(user.documentID, user.supervisor); });
    generateUserHierarchy(userNodeList);
    return userHierarchy;
}
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var cascades = [];
function appendChildren(user) {
    cascades.push(user._user);
    if (user._children.length > 0) {
        user._children.forEach(function (el) { return appendChildren(el); });
    }
}
export function findCascadeUserNodes(userNodeList, targetUser) {
    var find = userNodeList.find(function (el) { return el._user === targetUser; });
    if (find) {
        appendChildren(find);
    }
    else {
        userNodeList.forEach(function (el) {
            if (el._children.length > 0) {
                return findCascadeUserNodes(el._children, targetUser);
            }
            return [];
        });
    }
    return cascades;
}
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var subordinates = [];
export function findSubordinateUserNodes(userNodeList, targetUser) {
    var find = userNodeList.find(function (el) { return el._user === targetUser; });
    if (find) {
        find._children.map(function (x) { return subordinates.push(x._user); });
    }
    else {
        userNodeList.forEach(function (el) {
            if (el._children.length > 0) {
                return findSubordinateUserNodes(el._children, targetUser);
            }
            return [];
        });
    }
    return subordinates;
}
