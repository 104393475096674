var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/remote-config";
import "firebase/storage";
var firebaseConfig = {
    apiKey: process.env.API_KEY,
    authDomain: process.env.AUTH_DOMAIN,
    databaseURL: process.env.DATABASE_URL,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.STORAGE_BUCKET,
    messagingSenderId: process.env.MESSAGING_SENDER_ID,
    appId: process.env.APP_ID,
    measurementId: process.env.MEASUREMENT_ID
};
firebase.initializeApp(firebaseConfig);
export var auth = firebase.auth();
export var firestore = firebase.firestore();
export var remoteConfig = firebase.remoteConfig();
export var storageRef = firebase.storage().ref();
// @ts-ignore
remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
    fetchTimeoutMillis: 3600000
};
remoteConfig.defaultConfig = {
    roles: JSON.stringify({
        super_admin: "Super Admin",
        admin: "Admin",
        div_admin: "Div Admin",
        supervisor: "Supervisor",
        user: "User",
        station_user: "Stationed User",
        hr_admin: "HR Admin",
        supervisor_cum_station_user: "Supervisor cum Station User",
        div_admin_cum_station_user: "Div Admin cum Station User"
    }),
    locations: JSON.stringify({
        adhikarigama: {
            longitude: 80.7781,
            name: "Adhikarigama",
            latitude: 7.2192
        },
        agaliya: {
            longitude: 80.2,
            name: "Agaliya",
            latitude: 6.1833
        },
        agrapatana: {
            longitude: 80.7,
            name: "Agrapatana",
            latitude: 6.867000000000001
        },
        ahangama: {
            longitude: 80.3667,
            name: "Ahangama",
            latitude: 5.9833
        },
        ahungalla: {
            longitude: 80.02,
            name: "Ahungalla",
            latitude: 6.32
        },
        akuramboda: {
            longitude: 80.6014,
            name: "Akuramboda",
            latitude: 7.6467
        },
        alakolamada: {
            longitude: 80.6828,
            name: "Alakolamada",
            latitude: 7.4758
        },
        alutnuwara_town: {
            longitude: 80.9833,
            name: "Alutnuwara Town",
            latitude: 7.3167
        },
        alutwewa: {
            longitude: 80.5167,
            name: "Alutwewa",
            latitude: 7.7833
        },
        aluvihare: {
            longitude: 80.6167,
            name: "Aluvihare",
            latitude: 7.5
        },
        alwatta: {
            longitude: 80.66199999999999,
            name: "Alwatta",
            latitude: 7.4432
        },
        ambadeniya: {
            longitude: 80.4667,
            name: "Ambadeniya",
            latitude: 7.1833
        },
        ambagaspitiya: {
            longitude: 80.0667,
            name: "Ambagaspitiya",
            latitude: 7.0833
        },
        ambatalawa: {
            longitude: 80.5,
            name: "Ambatalawa",
            latitude: 6.9667
        },
        ambawela: {
            longitude: 80.817,
            name: "Ambawela",
            latitude: 6.867000000000001
        },
        ambepussa: {
            longitude: 80.2,
            name: "Ambepussa",
            latitude: 7.25
        },
        ambetenna: {
            longitude: 80.8,
            name: "Ambetenna",
            latitude: 7.067
        },
        ambewela: {
            longitude: 80.8022,
            name: "Ambewela",
            latitude: 6.8981
        },
        ampara: {
            longitude: 81.6667,
            name: "Ampara",
            latitude: 7.2833
        },
        ampitiya_udagama: {
            longitude: 80.65,
            name: "Ampitiya Udagama",
            latitude: 7.2667
        },
        amunupura: {
            longitude: 80.6,
            name: "Amunupura",
            latitude: 7.15
        },
        andawela: {
            longitude: 80.6667,
            name: "Andawela",
            latitude: 7.6167
        },
        aparekka: {
            longitude: 80.6,
            name: "Aparekka",
            latitude: 6
        },
        arambepola: {
            longitude: 80.6167,
            name: "Arambepola",
            latitude: 7.4
        },
        aranayaka: {
            longitude: 80.4547,
            name: "Aranayaka",
            latitude: 7.1476
        },
        aruppola: {
            longitude: 80.65,
            name: "Aruppola",
            latitude: 7.3
        },
        atabage: {
            longitude: 80.6167,
            name: "Atabage",
            latitude: 7.1333
        },
        avissawella: {
            longitude: 80.2067,
            name: "Avissawella",
            latitude: 6.9499
        },
        badalagama: {
            longitude: 80.7833,
            name: "Badalagama",
            latitude: 7.1333
        },
        badalgammedda: {
            longitude: 80.6333,
            name: "Badalgammedda",
            latitude: 7.4
        },
        bahirawakanda: {
            longitude: 80.6167,
            name: "Bahirawakanda",
            latitude: 7.3
        },
        balagolla: {
            longitude: 80.7752,
            name: "Balagolla",
            latitude: 7.2218
        },
        bambaradeniya: {
            longitude: 80.5667,
            name: "Bambaradeniya",
            latitude: 7.2
        },
        bambaragaswewa: {
            longitude: 80.5333,
            name: "Bambaragaswewa",
            latitude: 7.7833
        },
        bandarawela: {
            longitude: 80.9859,
            name: "Bandarawela",
            latitude: 6.8245
        },
        barigama: {
            longitude: 80.5833,
            name: "Barigama",
            latitude: 7.3333
        },
        battaramulla: {
            longitude: 79.9192,
            name: "Battaramulla",
            latitude: 6.9017
        },
        batuwatta: {
            longitude: 79.9347,
            name: "Batuwatta",
            latitude: 7.0528
        },
        bebalagama: {
            longitude: 80.517,
            name: "Bebalagama",
            latitude: 7.382999999999999
        },
        beddegama: {
            longitude: 80.1794,
            name: "Beddegama",
            latitude: 6.1688
        },
        beliatta: {
            longitude: 80.7271,
            name: "Beliatta",
            latitude: 6.0434
        },
        beligamuwa: {
            longitude: 80.55,
            name: "Beligamuwa",
            latitude: 7.7167
        },
        beliyakanda: {
            longitude: 80.5667,
            name: "Beliyakanda",
            latitude: 7.8
        },
        beragala: {
            longitude: 80.9149,
            name: "Beragala",
            latitude: 6.763999999999999
        },
        biyagama: {
            longitude: 79.9833,
            name: "Biyagama",
            latitude: 6.9333
        },
        bogahakumbura: {
            longitude: 80.8764,
            name: "Bogahakumbura",
            latitude: 6.8619
        },
        bokkawala: {
            longitude: 80.55,
            name: "Bokkawala",
            latitude: 7.3833
        },
        bolawatta: {
            longitude: 79.85,
            name: "Bolawatta",
            latitude: 7.3
        },
        bothale_ihalagama: {
            longitude: 80.1625,
            name: "Bothale Ihalagama",
            latitude: 7.2472
        },
        botota: {
            longitude: 80.583,
            name: "Botota",
            latitude: 7.367000000000001
        },
        brookside: {
            longitude: 80.85,
            name: "Brookside",
            latitude: 7.0167
        },
        bulukohotenna: {
            longitude: 80.6,
            name: "Bulukohotenna",
            latitude: 7.367000000000001
        },
        colombo: {
            longitude: 79.8578,
            name: "Colombo",
            latitude: 6.932
        },
        dadalla: {
            longitude: 80.2,
            name: "Dadalla",
            latitude: 6.05
        },
        dalugama: {
            longitude: 79.54,
            name: "Dalugama",
            latitude: 6.58
        },
        dalupitiya: {
            longitude: 79.9333,
            name: "Dalupitiya",
            latitude: 7
        },
        dankanda: {
            longitude: 80.7,
            name: "Dankanda",
            latitude: 7.5333
        },
        danowita: {
            longitude: 80.1758,
            name: "Danowita",
            latitude: 7.2028
        },
        dedunupitiya: {
            longitude: 80.4333,
            name: "Dedunupitiya",
            latitude: 7.3333
        },
        dehianga: {
            longitude: 80.55,
            name: "Dehianga",
            latitude: 7.3
        },
        dekinda: {
            longitude: 80.5167,
            name: "Dekinda",
            latitude: 7.0167
        },
        dematagoda: {
            longitude: 79.8778,
            name: "Dematagoda",
            latitude: 6.93
        },
        dembawa: {
            longitude: 80.5667,
            name: "Dembawa",
            latitude: 7.7167
        },
        dharga_town: {
            longitude: 80.0025,
            name: "Dharga Town",
            latitude: 6.4458
        },
        dimbulgoda: {
            longitude: 80.8,
            name: "Dimbulgoda",
            latitude: 6.1667
        },
        dolapihilla: {
            longitude: 80.5833,
            name: "Dolapihilla",
            latitude: 7.3833
        },
        doluwa: {
            longitude: 80.5157,
            name: "Doluwa",
            latitude: 7.287000000000001
        },
        dombemada: {
            longitude: 80.3512,
            name: "Dombemada",
            latitude: 7.3841
        },
        doragomuwa: {
            longitude: 80.65,
            name: "Doragomuwa",
            latitude: 7.367000000000001
        },
        dullewa: {
            longitude: 80.6,
            name: "Dullewa",
            latitude: 7.5
        },
        dunagaha: {
            longitude: 79.9686,
            name: "Dunagaha",
            latitude: 7.2422
        },
        dunkumbura: {
            longitude: 80.4833,
            name: "Dunkumbura",
            latitude: 7.35
        },
        ehelakanda: {
            longitude: 80.6,
            name: "Ehelakanda",
            latitude: 6.2167
        },
        elkaduwa: {
            longitude: 80.6833,
            name: "Elkaduwa",
            latitude: 7.4167
        },
        ella: {
            longitude: 81.0383,
            name: "Ella",
            latitude: 6.8753
        },
        eluvaitivu: {
            longitude: 79.8,
            name: "Eluvaitivu",
            latitude: 9.7
        },
        embekka: {
            longitude: 80.5667,
            name: "Embekka",
            latitude: 7.2167
        },
        embilipitiya: {
            longitude: 80.8489,
            name: "Embilipitiya",
            latitude: 6.3439
        },
        etamulla: {
            longitude: 80.55,
            name: "Etamulla",
            latitude: 7.35
        },
        ethagala: {
            longitude: 80.3697,
            name: "Ethagala",
            latitude: 7.485
        },
        etulgama: {
            longitude: 80.6667,
            name: "Etulgama",
            latitude: 7.25
        },
        galagama: {
            longitude: 80.8247,
            name: "Galagama",
            latitude: 5.9469
        },
        galagammulla: {
            longitude: 80.567,
            name: "Galagammulla",
            latitude: 5.9670000000000005
        },
        galewela: {
            longitude: 80.5697,
            name: "Galewela",
            latitude: 7.7507
        },
        galhinna: {
            longitude: 80.5688,
            name: "Galhinna",
            latitude: 7.4161
        },
        galkadawela: {
            longitude: 80.9333,
            name: "Galkadawela",
            latitude: 7.1
        },
        gammaduwa: {
            longitude: 80.7,
            name: "Gammaduwa",
            latitude: 7.5667
        },
        gampola: {
            longitude: 80.5727,
            name: "Gampola",
            latitude: 7.1482
        },
        ganewatta: {
            longitude: 80.3492,
            name: "Ganewatta",
            latitude: 7.6506
        },
        ganhata: {
            longitude: 80.55,
            name: "Ganhata",
            latitude: 7.2
        },
        getiyawala: {
            longitude: 80.567,
            name: "Getiyawala",
            latitude: 7.382999999999999
        },
        giritale: {
            longitude: 80.917,
            name: "Giritale",
            latitude: 7.983
        },
        godamuna: {
            longitude: 80.7833,
            name: "Godamuna",
            latitude: 7.3333
        },
        gomagoda: {
            longitude: 80.7333,
            name: "Gomagoda",
            latitude: 7.3167
        },
        gonapola: {
            longitude: 80.0146,
            name: "Gonapola",
            latitude: 6.7620000000000005
        },
        haburugala: {
            longitude: 80.0333,
            name: "Haburugala",
            latitude: 6.4
        },
        haliyadda: {
            longitude: 80.5333,
            name: "Haliyadda",
            latitude: 7.2667
        },
        haltota: {
            longitude: 80.0333,
            name: "Haltota",
            latitude: 6.7
        },
        hambantota: {
            longitude: 81.1206,
            name: "Hambantota",
            latitude: 6.1239
        },
        handabowa: {
            longitude: 80.5,
            name: "Handabowa",
            latitude: 7.3167
        },
        handaganawa: {
            longitude: 80.9833,
            name: "Handaganawa",
            latitude: 7.2833
        },
        hangarapitiya: {
            longitude: 80.4667,
            name: "Hangarapitiya",
            latitude: 6.9167
        },
        hapuwala: {
            longitude: 80.7667,
            name: "Hapuwala",
            latitude: 7.1667
        },
        harasbedda: {
            longitude: 80.8667,
            name: "Harasbedda",
            latitude: 7.05
        },
        hataraliyadda: {
            longitude: 80.467,
            name: "Hataraliyadda",
            latitude: 7.332999999999999
        },
        hatton: {
            longitude: 80.5981,
            name: "Hatton",
            latitude: 6.8897
        },
        hedunuwawa: {
            longitude: 80.65,
            name: "Hedunuwawa",
            latitude: 7.05
        },
        henapola: {
            longitude: 80.6,
            name: "Henapola",
            latitude: 7.4333
        },
        hepana: {
            longitude: 80.55,
            name: "Hepana",
            latitude: 7.2333
        },
        hewaheta: {
            longitude: 80.7667,
            name: "Hewaheta",
            latitude: 7.1
        },
        hidallana: {
            longitude: 80.383,
            name: "Hidallana",
            latitude: 6.7170000000000005
        },
        hiddaulla: {
            longitude: 80.55,
            name: "Hiddaulla",
            latitude: 7.233
        },
        hitgoda: {
            longitude: 80.5,
            name: "Hitgoda",
            latitude: 7.3667
        },
        hitigegama: {
            longitude: 80.45,
            name: "Hitigegama",
            latitude: 6.95
        },
        hokandara: {
            longitude: 79.9667,
            name: "Hokandara",
            latitude: 6.8803
        },
        horana: {
            longitude: 80.0592,
            name: "Horana",
            latitude: 6.7144
        },
        huduhumpola: {
            longitude: 80.617,
            name: "Huduhumpola",
            latitude: 7.2829999999999995
        },
        huluganga: {
            longitude: 80.75,
            name: "Huluganga",
            latitude: 7.4
        },
        hunnasgiriya: {
            longitude: 80.85,
            name: "Hunnasgiriya",
            latitude: 7.3
        },
        ibbawala: {
            longitude: 80.4,
            name: "Ibbawala",
            latitude: 6
        },
        idalgashinna: {
            longitude: 80.9,
            name: "Idalgashinna",
            latitude: 6.7829999999999995
        },
        idamagama: {
            longitude: 80.567,
            name: "Idamagama",
            latitude: 7.35
        },
        illagolla: {
            longitude: 80.8167,
            name: "Illagolla",
            latitude: 7.1667
        },
        illawatura: {
            longitude: 80.583,
            name: "Illawatura",
            latitude: 7.167000000000001
        },
        imbuletenna: {
            longitude: 80.4833,
            name: "Imbuletenna",
            latitude: 7.3333
        },
        inguruwatta: {
            longitude: 80.6,
            name: "Inguruwatta",
            latitude: 7.1833
        },
        jahakagama: {
            longitude: 80.5833,
            name: "Jahakagama",
            latitude: 7.7
        },
        jalampitiya: {
            longitude: 80.733,
            name: "Jalampitiya",
            latitude: 6.233
        },
        jamburegoda: {
            longitude: 80.4167,
            name: "Jamburegoda",
            latitude: 6.0167
        },
        julampitiya: {
            longitude: 80.7333,
            name: "Julampitiya",
            latitude: 6.2333
        },
        kadduwa: {
            longitude: 80.5167,
            name: "Kadduwa",
            latitude: 6.0333
        },
        kahapatwala: {
            longitude: 80.4667,
            name: "Kahapatwala",
            latitude: 7.3833
        },
        kahatowita: {
            longitude: 80.1097,
            name: "Kahatowita",
            latitude: 7.095
        },
        kahawatta: {
            longitude: 80.6,
            name: "Kahawatta",
            latitude: 7.1667
        },
        kahawatugoda: {
            longitude: 80.55,
            name: "Kahawatugoda",
            latitude: 7.2833
        },
        kalaganwatta: {
            longitude: 80.9,
            name: "Kalaganwatta",
            latitude: 7.1
        },
        kalagolla: {
            longitude: 80.983,
            name: "Kalagolla",
            latitude: 7.2
        },
        kalawana: {
            longitude: 80.3968,
            name: "Kalawana",
            latitude: 6.5315
        },
        kalaweldeniya: {
            longitude: 80.5,
            name: "Kalaweldeniya",
            latitude: 6.95
        },
        kalmunai: {
            longitude: 81.8234,
            name: "Kalmunai",
            latitude: 7.4045
        },
        kaludella: {
            longitude: 80.7333,
            name: "Kaludella",
            latitude: 7.4
        },
        kalugala: {
            longitude: 80.8833,
            name: "Kalugala",
            latitude: 7.3833
        },
        kanangamuwa: {
            longitude: 80.6333,
            name: "Kanangamuwa",
            latitude: 7.5
        },
        kandalama: {
            longitude: 80.7057,
            name: "Kandalama",
            latitude: 7.895
        },
        kandangama: {
            longitude: 80.5167,
            name: "Kandangama",
            latitude: 7.2667
        },
        kandapola: {
            longitude: 80.8167,
            name: "Kandapola",
            latitude: 6.9833
        },
        kandegama: {
            longitude: 81.0997,
            name: "Kandegama",
            latitude: 7.1811
        },
        kandy: {
            longitude: 80.6375,
            name: "Kandy",
            latitude: 7.2844
        },
        kantale: {
            longitude: 81.0281,
            name: "Kantale",
            latitude: 8.3683
        },
        karagama: {
            longitude: 80.85,
            name: "Karagama",
            latitude: 7.5
        },
        karampon: {
            longitude: 79.8667,
            name: "Karampon",
            latitude: 9.6667
        },
        karaputugala: {
            longitude: 80.6,
            name: "Karaputugala",
            latitude: 6.0833
        },
        kattankudy: {
            longitude: 81.7286,
            name: "Kattankudy",
            latitude: 7.6742
        },
        kawdupelella: {
            longitude: 80.633,
            name: "Kawdupelella",
            latitude: 7.582999999999999
        },
        kegalle: {
            longitude: 80.3582,
            name: "Kegalle",
            latitude: 7.2539
        },
        kehelella: {
            longitude: 79.9833,
            name: "Kehelella",
            latitude: 7.2667
        },
        kengalla: {
            longitude: 80.7167,
            name: "Kengalla",
            latitude: 7.3
        },
        kesbewa: {
            longitude: 79.9386,
            name: "Kesbewa",
            latitude: 6.7953
        },
        ketakumbura: {
            longitude: 80.5833,
            name: "Ketakumbura",
            latitude: 7.2167
        },
        kiran: {
            longitude: 81.5333,
            name: "Kiran",
            latitude: 7.8667
        },
        kirapane: {
            longitude: 80.567,
            name: "Kirapane",
            latitude: 7.167000000000001
        },
        kiribathkumbura: {
            longitude: 80.5833,
            name: "Kiribathkumbura",
            latitude: 7.2667
        },
        kirinda: {
            longitude: 81.3304,
            name: "Kirinda",
            latitude: 6.2149
        },
        kithulgoda: {
            longitude: 80.1833,
            name: "Kithulgoda",
            latitude: 6.5167
        },
        koggala: {
            longitude: 80.3225,
            name: "Koggala",
            latitude: 5.9903
        },
        koholanwala: {
            longitude: 80.6333,
            name: "Koholanwala",
            latitude: 7.6333
        },
        kohuwala: {
            longitude: 79.8753,
            name: "Kohuwala",
            latitude: 6.8753
        },
        kokavil: {
            longitude: 80.4,
            name: "Kokavil",
            latitude: 9.2667
        },
        kokkadichcholai: {
            longitude: 81.7167,
            name: "Kokkadichcholai",
            latitude: 7.6167
        },
        kolonnawa: {
            longitude: 79.895,
            name: "Kolonnawa",
            latitude: 6.9283
        },
        konakalagala: {
            longitude: 80.617,
            name: "Konakalagala",
            latitude: 7.382999999999999
        },
        kottawa: {
            longitude: 79.9647,
            name: "Kottawa",
            latitude: 6.8414
        },
        kuchchaveli: {
            longitude: 81.1,
            name: "Kuchchaveli",
            latitude: 8.8167
        },
        kumarakattuwa: {
            longitude: 79.9,
            name: "Kumarakattuwa",
            latitude: 7.65
        },
        kurana: {
            longitude: 79.8667,
            name: "Kurana",
            latitude: 7.1833
        },
        kurukkalmadam: {
            longitude: 81.7811,
            name: "Kurukkalmadam",
            latitude: 7.5874
        },
        kurunegala: {
            longitude: 80.3631,
            name: "Kurunegala",
            latitude: 7.4904
        },
        labuduwa: {
            longitude: 80.2333,
            name: "Labuduwa",
            latitude: 6.0667
        },
        lansigama: {
            longitude: 79.8333,
            name: "Lansigama",
            latitude: 7.3833
        },
        leloya: {
            longitude: 80.8167,
            name: "Leloya",
            latitude: 7.5833
        },
        lelwala: {
            longitude: 80.3833,
            name: "Lelwala",
            latitude: 6.3333
        },
        lenadora: {
            longitude: 80.6667,
            name: "Lenadora",
            latitude: 7.75
        },
        lenawala: {
            longitude: 80.6,
            name: "Lenawala",
            latitude: 7.7167
        },
        lewella: {
            longitude: 80.65,
            name: "Lewella",
            latitude: 7.3
        },
        liyanagemulla: {
            longitude: 79.8833,
            name: "Liyanagemulla",
            latitude: 7.15
        },
        liyanwela_pahalagama: {
            longitude: 80.833,
            name: "Liyanwela Pahalagama",
            latitude: 7.117000000000001
        },
        loluwagoda: {
            longitude: 80.1333,
            name: "Loluwagoda",
            latitude: 7.3
        },
        lunama: {
            longitude: 80.9667,
            name: "Lunama",
            latitude: 6.1167
        },
        lunawa: {
            longitude: 79.8744,
            name: "Lunawa",
            latitude: 6.7897
        },
        lunuganwehera: {
            longitude: 81.2,
            name: "Lunuganwehera",
            latitude: 6.3333
        },
        maankulam: {
            longitude: 80.4489,
            name: "Maankulam",
            latitude: 9.1267
        },
        mabola: {
            longitude: 79.9,
            name: "Mabola",
            latitude: 7
        },
        mabotuwana: {
            longitude: 80.2833,
            name: "Mabotuwana",
            latitude: 6.1333
        },
        madampagama: {
            longitude: 80.0667,
            name: "Madampagama",
            latitude: 6.2167
        },
        madawala: {
            longitude: 80.6736,
            name: "Madawala",
            latitude: 7.3283
        },
        madiligama: {
            longitude: 80.5,
            name: "Madiligama",
            latitude: 7.2833
        },
        madipola: {
            longitude: 80.5833,
            name: "Madipola",
            latitude: 7.6833
        },
        madulla: {
            longitude: 81.3929,
            name: "Madulla",
            latitude: 7.3108
        },
        mahara: {
            longitude: 79.9383,
            name: "Mahara",
            latitude: 6.9917
        },
        mahawa: {
            longitude: 80.2778,
            name: "Mahawa",
            latitude: 7.8228
        },
        makuldeniya: {
            longitude: 80.7833,
            name: "Makuldeniya",
            latitude: 7.3333
        },
        malagammana: {
            longitude: 80.5833,
            name: "Malagammana",
            latitude: 7.3167
        },
        malgammana: {
            longitude: 80.5667,
            name: "Malgammana",
            latitude: 7.2833
        },
        malwanahinna: {
            longitude: 80.6167,
            name: "Malwanahinna",
            latitude: 7.3833
        },
        malwattai: {
            longitude: 81.7167,
            name: "Malwattai",
            latitude: 7.3167
        },
        mampuri: {
            longitude: 79.7333,
            name: "Mampuri",
            latitude: 8
        },
        manakola: {
            longitude: 80.7667,
            name: "Manakola",
            latitude: 7.0667
        },
        mandaram_nuwara: {
            longitude: 80.7914,
            name: "Mandaram Nuwara",
            latitude: 7.075
        },
        mangoda: {
            longitude: 80.8333,
            name: "Mangoda",
            latitude: 7.3
        },
        mannar: {
            longitude: 79.8833,
            name: "Mannar",
            latitude: 8.9667
        },
        mapalana: {
            longitude: 80.5667,
            name: "Mapalana",
            latitude: 6.05
        },
        maswela: {
            longitude: 80.6333,
            name: "Maswela",
            latitude: 7.0667
        },
        matalapitiya: {
            longitude: 80.5833,
            name: "Matalapitiya",
            latitude: 7.5167
        },
        matale: {
            longitude: 80.6237,
            name: "Matale",
            latitude: 7.4685
        },
        matara: {
            longitude: 80.5354,
            name: "Matara",
            latitude: 5.9493
        },
        mawalgama: {
            longitude: 80.1303,
            name: "Mawalgama",
            latitude: 6.9153
        },
        mawatura: {
            longitude: 80.567,
            name: "Mawatura",
            latitude: 7.1
        },
        mawilmada: {
            longitude: 80.6333,
            name: "Mawilmada",
            latitude: 7.3333
        },
        medakanda: {
            longitude: 80.6667,
            name: "Medakanda",
            latitude: 7.0333
        },
        metihakka: {
            longitude: 80.7833,
            name: "Metihakka",
            latitude: 7.6
        },
        migammana: {
            longitude: 80.667,
            name: "Migammana",
            latitude: 7.332999999999999
        },
        millapitiya: {
            longitude: 80.8667,
            name: "Millapitiya",
            latitude: 7.1
        },
        minigamuwa: {
            longitude: 80.5,
            name: "Minigamuwa",
            latitude: 7.3333
        },
        minipe: {
            longitude: 80.9833,
            name: "Minipe",
            latitude: 7.2167
        },
        mirigama: {
            longitude: 80.1325,
            name: "Mirigama",
            latitude: 7.2414
        },
        molagoda: {
            longitude: 80.3833,
            name: "Molagoda",
            latitude: 7.25
        },
        moragolla: {
            longitude: 80.682,
            name: "Moragolla",
            latitude: 7.2277
        },
        morahenegama: {
            longitude: 80.4667,
            name: "Morahenegama",
            latitude: 6.95
        },
        nabadowa: {
            longitude: 80.35,
            name: "Nabadowa",
            latitude: 6.1167
        },
        namadagala: {
            longitude: 80.8167,
            name: "Namadagala",
            latitude: 7.3
        },
        narahenpita: {
            longitude: 79.8769,
            name: "Narahenpita",
            latitude: 6.8922
        },
        naranpanawa: {
            longitude: 80.7333,
            name: "Naranpanawa",
            latitude: 7.35
        },
        naranwita: {
            longitude: 80.5833,
            name: "Naranwita",
            latitude: 7.1667
        },
        narawala: {
            longitude: 80.233,
            name: "Narawala",
            latitude: 6.1
        },
        nattarampota: {
            longitude: 80.6833,
            name: "Nattarampota",
            latitude: 7.2833
        },
        nawala: {
            longitude: 79.8833,
            name: "Nawala",
            latitude: 6.9
        },
        negombo: {
            longitude: 79.8386,
            name: "Negombo",
            latitude: 7.2111
        },
        niripola: {
            longitude: 80.1,
            name: "Niripola",
            latitude: 6.9
        },
        nittawela: {
            longitude: 80.6333,
            name: "Nittawela",
            latitude: 7.3167
        },
        niyangandara: {
            longitude: 80.65,
            name: "Niyangandara",
            latitude: 7.05
        },
        niyangoda: {
            longitude: 80.55,
            name: "Niyangoda",
            latitude: 7.35
        },
        "norwood,_sri_lanka": {
            longitude: 80.6,
            name: "Norwood, Sri Lanka",
            latitude: 6.8333
        },
        nugaliyadda: {
            longitude: 80.6833,
            name: "Nugaliyadda",
            latitude: 7.2333
        },
        okawala: {
            longitude: 80.717,
            name: "Okawala",
            latitude: 6.1
        },
        omanthai: {
            longitude: 80.5,
            name: "Omanthai",
            latitude: 8.8667
        },
        ovisa: {
            longitude: 80.6,
            name: "Ovisa",
            latitude: 7.382999999999999
        },
        pamankada: {
            longitude: 79.8753,
            name: "Pamankada",
            latitude: 6.8753
        },
        panagoda: {
            longitude: 80.0189,
            name: "Panagoda",
            latitude: 6.8472
        },
        panama: {
            longitude: 81.8019,
            name: "Panama",
            latitude: 6.7449
        },
        pananwala: {
            longitude: 80.0333,
            name: "Pananwala",
            latitude: 6.9833
        },
        panchikawatte: {
            longitude: 79.8753,
            name: "Panchikawatte",
            latitude: 6.9258
        },
        pannala: {
            longitude: 80.0242,
            name: "Pannala",
            latitude: 7.3281
        },
        pannawa: {
            longitude: 80.3167,
            name: "Pannawa",
            latitude: 7.65
        },
        paradeka: {
            longitude: 80.6,
            name: "Paradeka",
            latitude: 7.1167
        },
        pattitalawa: {
            longitude: 80.467,
            name: "Pattitalawa",
            latitude: 7.117000000000001
        },
        pelawatte: {
            longitude: 79.9283,
            name: "Pelawatte",
            latitude: 6.8911
        },
        peliyagoda: {
            longitude: 79.9,
            name: "Peliyagoda",
            latitude: 6.95
        },
        pelwadiya: {
            longitude: 80.4,
            name: "Pelwadiya",
            latitude: 6.6667
        },
        pettah: {
            longitude: 79.8497,
            name: "Pettah",
            latitude: 6.9367
        },
        pilawala: {
            longitude: 80.6833,
            name: "Pilawala",
            latitude: 7.3
        },
        piligalla: {
            longitude: 80.5833,
            name: "Piligalla",
            latitude: 7.2333
        },
        pinwatta: {
            longitude: 79.9125,
            name: "Pinwatta",
            latitude: 6.6903
        },
        poddalgoda: {
            longitude: 80.7833,
            name: "Poddalgoda",
            latitude: 7.3333
        },
        pohaliyadda: {
            longitude: 80.517,
            name: "Pohaliyadda",
            latitude: 7.35
        },
        polgahange: {
            longitude: 80.6,
            name: "Polgahange",
            latitude: 7.2
        },
        polonnaruwa: {
            longitude: 81.0081,
            name: "Polonnaruwa",
            latitude: 7.9329
        },
        pupuressa: {
            longitude: 80.6667,
            name: "Pupuressa",
            latitude: 7.1333
        },
        puttalam: {
            longitude: 79.8258,
            name: "Puttalam",
            latitude: 8.0334
        },
        raithalawela: {
            longitude: 80.6539,
            name: "Raithalawela",
            latitude: 7.4036
        },
        rajagiriya: {
            longitude: 79.895,
            name: "Rajagiriya",
            latitude: 6.9192
        },
        rambukewela: {
            longitude: 80.5667,
            name: "Rambukewela",
            latitude: 7.4
        },
        rangala: {
            longitude: 80.7833,
            name: "Rangala",
            latitude: 7.35
        },
        rangomuwa: {
            longitude: 80.45,
            name: "Rangomuwa",
            latitude: 7.3333
        },
        rathkarawwa: {
            longitude: 80.9067,
            name: "Rathkarawwa",
            latitude: 6.8048
        },
        ratnapura: {
            longitude: 80.3864,
            name: "Ratnapura",
            latitude: 6.6931
        },
        rawanagoda: {
            longitude: 80.6167,
            name: "Rawanagoda",
            latitude: 7
        },
        "rozella,_eastern_province": {
            longitude: 80.55,
            name: "Rozella, Eastern Province",
            latitude: 6.9333
        },
        selagama: {
            longitude: 80.5833,
            name: "Selagama",
            latitude: 7.5833
        },
        singharamulla: {
            longitude: 79.9,
            name: "Singharamulla",
            latitude: 6.95
        },
        sinhapitiya: {
            longitude: 80.55,
            name: "Sinhapitiya",
            latitude: 7.1667
        },
        sirimalwatta: {
            longitude: 80.65,
            name: "Sirimalwatta",
            latitude: 7.3
        },
        siyambalagastenna: {
            longitude: 80.6333,
            name: "Siyambalagastenna",
            latitude: 7.3167
        },
        talagaspe: {
            longitude: 80.2,
            name: "Talagaspe",
            latitude: 6.3
        },
        talahagama: {
            longitude: 80.517,
            name: "Talahagama",
            latitude: 6.132999999999999
        },
        talaimannar: {
            longitude: 79.7167,
            name: "Talaimannar",
            latitude: 9.1
        },
        talakiriyagama: {
            longitude: 80.6167,
            name: "Talakiriyagama",
            latitude: 7.8167
        },
        talawatura: {
            longitude: 80.5833,
            name: "Talawatura",
            latitude: 7.2
        },
        tammitiya: {
            longitude: 80.6333,
            name: "Tammitiya",
            latitude: 7.0667
        },
        tangalle: {
            longitude: 80.7914,
            name: "Tangalle",
            latitude: 6.0259
        },
        telambugahawatta: {
            longitude: 80.6167,
            name: "Telambugahawatta",
            latitude: 7.3667
        },
        tembiligala_pallegama: {
            longitude: 80.55,
            name: "Tembiligala Pallegama",
            latitude: 7.132999999999999
        },
        tenna: {
            longitude: 80.6,
            name: "Tenna",
            latitude: 7.45
        },
        tennekumbura: {
            longitude: 80.66199999999999,
            name: "Tennekumbura",
            latitude: 7.2842
        },
        thambiluvil: {
            longitude: 81.85,
            name: "Thambiluvil",
            latitude: 7.1333
        },
        thampalakamam: {
            longitude: 81.0833,
            name: "Thampalakamam",
            latitude: 8.5167
        },
        tharakundu: {
            longitude: 79.8667,
            name: "Tharakundu",
            latitude: 9
        },
        thihariya: {
            longitude: 80.1167,
            name: "Thihariya",
            latitude: 7.2
        },
        thiriyai: {
            longitude: 81.0074,
            name: "Thiriyai",
            latitude: 8.8704
        },
        tismada: {
            longitude: 80.5,
            name: "Tismada",
            latitude: 7.3
        },
        uda_aparekka: {
            longitude: 80.6167,
            name: "Uda Aparekka",
            latitude: 6
        },
        udamadura: {
            longitude: 80.9167,
            name: "Udamadura",
            latitude: 7.0833
        },
        udappu: {
            longitude: 79.8,
            name: "Udappu",
            latitude: 7.75
        },
        udatenna: {
            longitude: 80.65,
            name: "Udatenna",
            latitude: 7.4167
        },
        udupihilla: {
            longitude: 80.6167,
            name: "Udupihilla",
            latitude: 7.4333
        },
        udurawana: {
            longitude: 80.6667,
            name: "Udurawana",
            latitude: 7.35
        },
        uduwawala: {
            longitude: 80.6167,
            name: "Uduwawala",
            latitude: 7.3333
        },
        uduwela_udagama: {
            longitude: 80.65,
            name: "Uduwela Udagama",
            latitude: 7.25
        },
        ulapane: {
            longitude: 80.55,
            name: "Ulapane",
            latitude: 7.1
        },
        unambuwa: {
            longitude: 80.5667,
            name: "Unambuwa",
            latitude: 7.1833
        },
        unawatuna: {
            longitude: 80.2525,
            name: "Unawatuna",
            latitude: 6.0183
        },
        uswetakeiyawa: {
            longitude: 79.867,
            name: "Uswetakeiyawa",
            latitude: 7.05
        },
        vakarai: {
            longitude: 81.4333,
            name: "Vakarai",
            latitude: 8.1333
        },
        vankalai: {
            longitude: 79.9333,
            name: "Vankalai",
            latitude: 8.8833
        },
        velgalla: {
            longitude: 80.6167,
            name: "Velgalla",
            latitude: 5.95
        },
        vellavely: {
            longitude: 81.7333,
            name: "Vellavely",
            latitude: 7.2167
        },
        vidattaltivu: {
            longitude: 80.05,
            name: "Vidattaltivu",
            latitude: 9.015
        },
        viharahena: {
            longitude: 80.5833,
            name: "Viharahena",
            latitude: 6.3667
        },
        wadduwa: {
            longitude: 79.9352,
            name: "Wadduwa",
            latitude: 6.6674
        },
        walliwela: {
            longitude: 80.6167,
            name: "Walliwela",
            latitude: 7.5667
        },
        wataddora: {
            longitude: 80.617,
            name: "Wataddora",
            latitude: 7.05
        },
        watadeniya: {
            longitude: 80.55,
            name: "Watadeniya",
            latitude: 7.2
        },
        watapuluwa: {
            longitude: 80.65,
            name: "Watapuluwa",
            latitude: 7.3167
        },
        wattappola: {
            longitude: 80.55,
            name: "Wattappola",
            latitude: 7.2333
        },
        watuwala: {
            longitude: 80.6,
            name: "Watuwala",
            latitude: 7.332999999999999
        },
        wegala: {
            longitude: 80.8,
            name: "Wegala",
            latitude: 7.2833
        },
        welangahawatta: {
            longitude: 80.7,
            name: "Welangahawatta",
            latitude: 7.4833
        },
        weligama: {
            longitude: 80.4218,
            name: "Weligama",
            latitude: 5.9738
        },
        welikada: {
            longitude: 79.8833,
            name: "Welikada",
            latitude: 6.9167
        },
        weragantota: {
            longitude: 80.9869,
            name: "Weragantota",
            latitude: 7.3169
        },
        werapitiya: {
            longitude: 80.75,
            name: "Werapitiya",
            latitude: 7.3667
        },
        werawala: {
            longitude: 80.55,
            name: "Werawala",
            latitude: 7.2167
        },
        wetalawa: {
            longitude: 80.65,
            name: "Wetalawa",
            latitude: 7.05
        },
        yagoda: {
            longitude: 79.9689,
            name: "Yagoda",
            latitude: 7.0706
        },
        yahalatenne: {
            longitude: 80.583,
            name: "Yahalatenne",
            latitude: 7.317
        },
        yapagama: {
            longitude: 80.65,
            name: "Yapagama",
            latitude: 7.8333
        },
        yapahuwa: {
            longitude: 80.3167,
            name: "Yapahuwa",
            latitude: 7.8167
        },
        yatala: {
            longitude: 80.4667,
            name: "Yatala",
            latitude: 7.35
        },
        yatihalagala: {
            longitude: 80.6,
            name: "Yatihalagala",
            latitude: 7.3
        },
        yatihalagala_pallegama: {
            longitude: 80.6,
            name: "Yatihalagala Pallegama",
            latitude: 7.3
        },
        yatipiyangala: {
            longitude: 80.5833,
            name: "Yatipiyangala",
            latitude: 7.1167
        },
        yatiwella: {
            longitude: 80.8,
            name: "Yatiwella",
            latitude: 7.0833
        },
        yudaganawa: {
            longitude: 81.233,
            name: "Yudaganawa",
            latitude: 6.767
        }
    }),
    taskTypes: JSON.stringify({
        enquiry: "DPMC-VS-INQUIRY FOLLOW UP",
        mile_delivery: "DPMC-P&A-LAST MILE DELIVERY",
        complaints: "DPMC-FS-CUSTOMER COMPLAINTS",
        warranty_parts: "DPMC-FS-WARRANTY PARTS COLLECTION",
        unscheduled_campaigns: "DPMC-FS-UNSCHEDULED CAMPAIGNS",
        attending_breakdowns: "DPMC-FS-ATTENDING TO BREAKDOWNS",
        dealer_training: "DPMC-FS-DEALER TRAINING",
        local_garage_visits: "DPMC-FS-LOCAL GARAGE VISITS",
        promotional_camps: "DPMC-FS-PROMOTIONAL CAMPAIGNS",
        assistance_premisses: "DPMC-FS-ASSISTANCE TO PREMISES MAINTENANCE",
        assistance_dpgv: "DPMC-FS-ASSISTANCE TO DPGV",
        assistance_riyasakwala: "DPMC-FS-ASSISTANCE TO RIYASAKWALA",
        assistance_riyasakwala_parts: "DPMC-FS-ASSISTANCE TO RIYASAKWALA - PARTS SOURCING",
        assistance_general: "DPMC-FS-ASSISTANCE TO THE GENERAL PROCESSES",
        data_gathering: "DPMC-FS-DATA GATHERING - SURVEY",
        field_service: "DPMC-FS-SPECIAL TASKS ASSIGNED-FIELD SERVICE",
        supplier_eval: "DPIN-SMT-SUPPLIER EVALUATION",
        calling_standard: "DPIN-SMT-CALLING STANDARD QUOTATIONS",
        monthly_invoice: "DPIN-SMT-MONTHLY INVOICING",
        licence: "DPIN-SMT-O365 LICENSE SUMMARIZATION",
        assets_dispose: "DPIN-SMT-DISPOSAL OF IT ASSETS",
        payment_entry: "DPIN-SMT-PAYMENT VOUCHER ENTRY",
        service_req: "DPIN-SMT-SERVICE REQUEST",
        report_creation: "DPIN-SMT-REPORT CREATION",
        calling_quote: "DPIN-SMT-CALLING QUOTATIONS",
        payment_followup: "DPIN-SMT-PAYMENT VOUCHER FOLLOWUP",
        customer_inquiry: "DPMC-P&A-CUSTOMER INQUIRY FOLLOW UP",
        daily_standup: "DPIN-DT-DAILY STANDUP",
        parts_arrangement: "DPMC-PA-PARTS ARRANGEMENT",
        p_a_dealership_request: "DPMC-P&A- DEALERSHIP REQUEST",
        fs_dealership_request: "DPMC-FS- DEALERSHIP REQUEST",
        daliy_service_test: "DPIN-ICT-DAILY SERVICE TEST",
        regional_stock_cash_verifications: "DPMC-IA-REGIONAL STOCK & CASH VERIFICATIONS",
        macp: "DPMC-FA-MACP",
        voucher_authorization: "DPMC-FA-VOUCHER AUTHORIZATION",
        bank_reconciliations: "DPMC-FA-BANK RECONCILIATIONS",
        acpl_tresury_front_office_cashflow_management: "ACPL-TRESURY FRONT OFFICE-CASHFLOW MANAGEMENT",
        acpl_tresury_fron_office_timely_placement_of_investments: "ACPL-TRESURY FRON OFFICE-TIMELY PLACEMENT OF INVESTMENTS",
        acpl_treasury_pm_back_office_preparation_of_deal_tickets_and_investment_letters: "ACPL-TREASURY PM BACK OFFICE-PREPARATION OF DEAL TICKETS AND INVESTMENT LETTERS",
        acpl_treasury_pm_back_office_settlement_of_third_party_payments: "ACPL-TREASURY PM BACK OFFICE-SETTLEMENT OF THIRD-PARTY PAYMENTS",
        acpl_treasury_ut_back_office_preparation_of_deal_tickets_and_investment_letters: "ACPL-TREASURY UT BACK OFFICE-PREPARATION OF DEAL TICKETS AND INVESTMENT LETTERS",
        acpl_treasury_ut_back_office_handling_of_ut_redemptions: "ACPL-TREASURY UT BACK OFFICE-HANDLING OF UT REDEMPTIONS",
        acpl_treasury_ut_back_office_handling_of_ut_subscriptions: "ACPL-TREASURY UT BACK OFFICE-HANDLING OF UT SUBSCRIPTIONS",
        acpl_finance_and_accounting_preparation_of_company_final_accounts: "ACPL-FINANCE AND ACCOUNTING-PREPARATION OF COMPANY FINAL ACCOUNTS",
        acpl_finance_and_accounting_settlement_of_company_payments: "ACPL-FINANCE AND ACCOUNTING-SETTLEMENT OF COMPANY PAYMENTS",
        acpl_finance_and_accounting_preparation_of_company_budget: "ACPL-FINANCE AND ACCOUNTING-PREPARATION OF COMPANY BUDGET",
        acpl_compliance_limit_monitoring: "ACPL-COMPLIANCE-LIMIT MONITORING",
        acpl_compliance_giving_compliance_approval_for_ut_redemptions: "ACPL-COMPLIANCE-GIVING COMPLIANCE APPROVAL FOR UT REDEMPTIONS",
        dpmc_p_a_outlet_inquiry: "DPMC-P&A-OUTLET INQUIRY",
        alcl_rec_arrears_follow_up: "ALCL-REC-ARREARS FOLLOW-UP",
        alcl_rec_lodging_police_entry: "ALCL-REC-LODGING POLICE ENTRY",
        alcl_rec_repossessed: "ALCL-REC-REPOSSESSED",
        alcl_rec_hand_over: "ALCL-REC-HAND OVER",
        alcl_rec_collection: "ALCL-REC-COLLECTION",
        alcl_rec_document_collection: "ALCL-REC-DOCUMENT COLLECTION",
        alcl_rec_appear_in_court: "ALCL-REC-APPEAR IN COURT",
        alcl_rec_special_task: "ALCL-REC-SPECIAL TASK",
        test_task: "DPIN-Test Task",
        dpmc_ia_gcrc: "DPMC-IA-GCRC Divisional tasks",
        dpmc_p_a_debt_collection_courier_payment: "DPMC-P&A-DEBT COLLECTION - COURIER PAYMENT",
        dpmc_p_a_debt_collection_pod_payment: "DPMC-P&A-DEBT COLLECTION - POD PAYMENT",
        dpmc_p_a_debt_collection_retuned_cheque: "DPMC-P&A-DEBT COLLECTION - RETUNED CHEQUE",
        dpmc_p_a_channel_visit_key_dealer: "DPMC-P&A-CHANNEL VISIT – KEY DEALER",
        dpmc_p_a_channel_visit_distributor: "DPMC-P&A-CHANNEL VISIT – DISTRIBUTOR",
        dpmc_p_a_channel_visit_non_key_dealer: "DPMC-P&A-CHANNEL VISIT – NON KEY DEALER",
        dpmc_p_a_channel_visit_service_dealer: "DPMC-P&A-CHANNEL VISIT – SERVICE DEALER",
        dpmc_p_a_channel_visit_non_dpmc_garages: "DPMC-P&A-CHANNEL VISIT – NON DPMC GARAGES",
        dpmc_p_a_dealer_distributor_check_list_kd: "DPMC-P&A-DEALER/ DISTRIBUTOR CHECK LIST – KD",
        dpmc_p_a_dealer_distributor_check_list_distributor: "DPMC-P&A-DEALER/ DISTRIBUTOR CHECK LIST – DISTRIBUTOR",
        dpmc_p_a_dealer_distributor_evaluation: "DPMC-P&A-DEALER/ DISTRIBUTOR EVALUATION",
        dpmc_p_a_letter_distribution: "DPMC-P&A-LETTER DISTRIBUTION",
        dpmc_p_a_dealer_distributor_stock_update: "DPMC-P&A-DEALER/ DISTRIBUTOR STOCK UPDATE",
        dpmc_p_a_handling_customer_complaints: "DPMC-P&A-HANDLING CUSTOMER COMPLAINTS",
        dpmc_p_a_visits_for_new_dealerships: "DPMC-P&A-VISITS FOR NEW DEALERSHIPS",
        dpmc_p_a_visits_for_dealer_distributor_field_promotion_activities: "DPMC-P&A-VISITS FOR DEALER/DISTRIBUTOR FIELD PROMOTION ACTIVITIES",
        dpmc_p_a_dealer_distributor_branding_display_arrangements: "DPMC-P&A-DEALER/DISTRIBUTOR BRANDING /DISPLAY ARRANGEMENTS",
        dpmc_p_a_dealer_distributor_check_list_nkd: "DPMC-P&A-DEALER/ DISTRIBUTOR CHECK LIST – NKD",
        dpmc_ccsd_divisional_tasks: "DPMC – CCSD – Divisional tasks",
        dpmc_csu_divisional_tasks: "DPMC – CSU – Divisional tasks",
        dpmc_imp_checking_possibility_of_imports: "DPMC-IMP-Checking possibility of imports (HS codes/ duty/ restricted items/ credit items/Other authority approvals/ SLSI approvals and etc..)",
        dpmc_imp_obtaining_correct_product_details: "DPMC-IMP-Obtaining correct product details (catalogues, literature, composition etc.)",
        dpmc_imp_checking_the_requirement_of_the_sales_contact: "DPMC-IMP-Checking the requirement of the Sales contact",
        dpmc_imp_obtaining_goods_classification_from_the_clearing_agent: "DPMC-IMP-Obtaining Goods classification from the clearing agent",
        dpmc_imp_obtaining_freight_rates: "DPMC-IMP-Obtaining freight rates",
        dpmc_imp_arranging_estimate_landed_costs: "DPMC-IMP-Arranging estimate landed costs",
        dpmc_imp_preparing_vehicle_orders: "DPMC-IMP-Preparing vehicle orders",
        dpmc_imp_preparing_vehicle_colour_breakups: "DPMC-IMP-Preparing vehicle colour breakups",
        dpmc_imp_completing_bal_system_requirements: "DPMC-IMP-Completing BAL system requirements",
        dpmc_imp_preparing_daily_shipment_status_reports: "DPMC-IMP-Preparing daily shipment status reports",
        dpmc_imp_obtaining_correct_proforma_invoices: "DPMC-IMP-Obtaining Correct Proforma Invoices",
        dpmc_imp_opening_of_lcs: "DPMC-IMP-Opening of LCs",
        dpmc_imp_arranging_ott: "DPMC-IMP-Arranging OTT",
        dpmc_imp_lc_amendments: "DPMC-IMP-LC amendments",
        dpmc_imp_making_marine_insurance_arrangements: "DPMC-IMP-Making marine insurance arrangements",
        dpmc_imp_updating_and_reviewing_marine_open_cover_time_to_time: "DPMC-IMP-Updating and reviewing marine open cover time to time",
        dpmc_imp_making_freight_arrangements: "DPMC-IMP-Making freight arrangements",
        dpmc_imp_making_clearance_arrangements: "DPMC-IMP-Making clearance arrangements",
        "dpmc_imp_obtaining_correct_shipping documents_from_the_suppliers": "DPMC-IMP-Obtaining correct shipping documents (packing lists, commercial invoices, BLs etc.…) from the suppliers",
        dpmc_imp_obtaining_isfta_preferential_documents_from_the_suppliers: "DPMC-IMP-Obtaining ISFTA / preferential documents from the suppliers",
        dpmc_imp_obtaining_manufacturers_certificates_from_the_suppliers: "DPMC-IMP-Obtaining manufacturer’s certificates from the suppliers",
        dpmc_imp_arranging_duty_slpa_and_other_clearance_related_payments: "DPMC-IMP-Arranging duty, SLPA and other clearance related payments",
        dpmc_imp_arranging_cash_deposits_bank_guarantees_in_absence_of_original_isfta_certificates: "DPMC-IMP-Arranging cash deposits/ bank guarantees in absence of original ISFTA certificates",
        dpmc_imp_obtaining_other_authority_approvals: "DPMC-IMP-Obtaining other authority approvals",
        dpmc_imp_visiting_customs_import_control_banks_slsi_mod_and_etc: "DPMC-IMP-Visiting Customs/Import Control/ Banks/ SLSI/ MOD and etc…",
        dpmc_imp_making_transport_arrangements: "DPMC-IMP-Making transport arrangements",
        dpmc_imp_making_unloading_arrangements: "DPMC-IMP-Making unloading arrangements",
        dpmc_imp_entering_packing_lists_to_the_system: "DPMC-IMP-Entering packing lists to the system",
        dpmc_imp_arranging_marine_insurance_claims: "DPMC-IMP-Arranging marine insurance claims",
        dpmc_imp_doing_system_costing: "DPMC-IMP-Doing system costing",
        dpmc_imp_arranging_suppliers_and_bank_payments: "DPMC-IMP-Arranging supplier’s and bank payments",
        dpmc_imp_follow_up_shipment_dispatches: "DPMC-IMP-Follow up shipment dispatches",
        dpmc_imp_follow_up_shipment_arrivals: "DPMC-IMP-Follow up shipment arrivals",
        dpmc_imp_follow_up_shipment_clearance: "DPMC-IMP-Follow up shipment clearance",
        dpmc_imp_follow_up_shipment_examinations: "DPMC-IMP-Follow up shipment examinations",
        dpmc_imp_follow_up_shipment_transport: "DPMC-IMP-Follow up shipment transport",
        dpmc_imp_follow_up_shipment_unloading: "DPMC-IMP-Follow up shipment unloading",
        dpmc_imp_follow_up_returning_of_containers_to_the_yards: "DPMC-IMP-Follow up returning of containers to the yards",
        dpmc_imp_follow_up_lc_status: "DPMC-IMP-Follow up LC status",
        dpmc_imp_follow_up_import_bill_settlements: "DPMC-IMP-Follow up Import bill settlements",
        dpmc_imp_follow_up_custom_entries: "DPMC-IMP-Follow up custom entries",
        dpmc_imp_follow_up_bank_guarantees: "DPMC-IMP-Follow up bank guarantees",
        dpmc_imp_follow_up_refundable_deposits: "DPMC-IMP-Follow up refundable deposits",
        dpmc_imp_follow_up_shipping_guarantee_cancellation: "DPMC-IMP-Follow up shipping guarantee cancellations",
        dpmc_imp_follow_up_retraining_of_documents: "DPMC-IMP-Follow up retaining of documents"
    }),
    taskStates: JSON.stringify({
        queued: "Queued",
        pending: "Pending Dispatched",
        dispatched: "Dispatched",
        travel: "Started Traveling",
        started: "Started",
        completed: "Completed",
        cancelled: "Cancelled"
    }),
    accessControl: JSON.stringify({
        tasks: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            view_individual: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            download_csv: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            add: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            edit: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            edit_status: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            delete: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        cancellation_requests: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            view_task: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            accept_request: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            reject_request: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        users: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            view_individual: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            download_csv: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            add: ["super_admin", "hr_admin"],
            edit: ["super_admin", "admin", "hr_admin"],
            reset_password: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            archive: ["super_admin", "hr_admin"]
        },
        user_groups: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            add: ["super_admin", "admin"],
            edit: ["super_admin", "admin"],
            archive: ["super_admin"]
        },
        alerts: {
            view_tab: ["super_admin", "admin"],
            add: ["super_admin", "admin", "div_admin", "hr_admin", "div_admin_cum_station_user"],
            edit: ["super_admin", "admin", "div_admin", "hr_admin", "div_admin_cum_station_user"],
            archive: ["super_admin", "admin"]
        },
        skills: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            add: ["super_admin", "admin"],
            edit: ["super_admin", "admin"],
            archive: ["super_admin"]
        },
        no_attendance: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            download_csv: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        no_coverage: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            download_csv: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        no_movement: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            download_csv: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        route_history: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            download_csv: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        live_locations: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        tasks_statistics: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        announcements: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            view_individual: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            add: [
                "super_admin",
                "admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ],
            delete: [
                "super_admin",
                "admin",
                "div_admin",
                "supervisor",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        notifications: {
            view_tab: [
                "super_admin",
                "admin",
                "hr_admin",
                "div_admin",
                "supervisor",
                "station_user",
                "user",
                "supervisor_cum_station_user",
                "div_admin_cum_station_user"
            ]
        },
        user_profile: {
            edit: ["super_admin", "admin", "supervisor"]
        }
    }),
    initialConfigs: JSON.stringify({
        coverageThresh: 1.5,
        movementThresh: 1.5,
        coverageTimeout: 5,
        noMovementRadius: 100,
        noMovementTimeout: 30,
        locationTaskAssignRadius: 1500,
        suggestingDistanceRange: 500000
    }),
    furtherActions: JSON.stringify({}),
    taskReminders: JSON.stringify({ task_type: 24 }),
    latestVersion: JSON.stringify("2.0.3+11"),
    mobilePermission: JSON.stringify({}),
    webPermission: JSON.stringify({})
};
export function fetchAndActivateRemoteConfig() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // @ts-ignore
                    return [4 /*yield*/, remoteConfig.fetchAndActivate()];
                case 1:
                    // @ts-ignore
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export default firebase;
