/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import useStyles from "../../styles/styles";
import { PRIMARY_PINK } from "../../../../utils/colorCodes";
export default function AvailableSkills(props) {
    var classes = useStyles();
    var skills = useSelector(function (state) { return state.skillManagement; }).skills;
    var availableSkills = props.availableSkills;
    return (React.createElement(Grid, { item: true, lg: 6 }, availableSkills.map(function (sk, i) {
        var _a;
        var skillLevel;
        switch (sk.level) {
            case 3:
                skillLevel = "High";
                break;
            case 2:
                skillLevel = "Medium";
                break;
            default:
                skillLevel = "Low";
                break;
        }
        return (React.createElement(Box, { display: "flex", flexDirection: "row", key: i, style: { minWidth: 320 } }, React.createElement("div", { style: {
                backgroundColor: PRIMARY_PINK,
                flex: 3,
                marginBottom: 15,
                marginRight: 10,
                padding: 10,
                borderRadius: 4,
                height: 59,
                overflow: "hidden"
            } }, React.createElement(Typography, { className: classes.taskDetailCardTitle }, "Skill"), React.createElement(Typography, { className: classes.taskDetailCardBody }, (_a = skills.filter(function (el) { return el.id === sk.skill; })[0]) === null || _a === void 0 ? void 0 : _a.name)), React.createElement("div", { style: {
                backgroundColor: PRIMARY_PINK,
                flex: 1,
                marginBottom: 15,
                marginRight: 20,
                padding: 10,
                borderRadius: 4,
                height: 59
            } }, React.createElement(Typography, { className: classes.taskDetailCardTitle }, "Level"), React.createElement(Typography, { className: classes.taskDetailCardBody }, skillLevel))));
    })));
}
