import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import * as Yup from "yup";
import { Formik } from "formik";
import { Grid, IconButton, InputAdornment, Link } from "@material-ui/core";
import { useHistory } from "react-router";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import useStyles from "./styles";
import dashboardIcon from "../../assets/images/dpgc.png";
import dpInfoTech from "../../assets/images/dpit.png";
import { changePasswordAction, fetchLoggedInUserAction, resetChangePasswordStateAction } from "./action";
import UserContext from "./UserProvider";
import { LIGHT_BLUE } from "../../utils/colorCodes";
import loadingIcon from "../../assets/images/loadingBox.gif";
import { isLatestPasswordOlderThanOneDay, isPasswordUsedWithin90Days } from "../../utils/passwordValidation";
function ChangePassword() {
    var _a, _b;
    var dispatch = useDispatch();
    var classes = useStyles();
    var history = useHistory();
    var user = useContext(UserContext);
    React.useEffect(function () {
        var _a, _b;
        if (user !== null) {
            // @ts-ignore
            dispatch(fetchLoggedInUserAction((_a = user) === null || _a === void 0 ? void 0 : _a.uid, (_b = user) === null || _b === void 0 ? void 0 : _b.email));
        }
    }, [dispatch, user]);
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    var _c = useSelector(function (state) { return state.loginManagement; }), changePasswordIsSuccess = _c.changePasswordIsSuccess, _d = _c.changePasswordError, changePasswordHasError = _d.hasError, changePasswordDescription = _d.description, changePasswordIsLoading = _c.changePasswordIsLoading;
    var yupSchema = Yup.object().shape({
        currentPassword: Yup.string().required("Current Password is required"),
        newPassword: Yup.string()
            .required("New Password is required")
            .min(8, "Must contain at least 8 characters")
            .max(30, "Maximum length 30 characters")
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/])(?=.{8,})/, "Must contain one uppercase, one lowercase, one number and one special case character"),
        confirmNewPassword: Yup.string()
            .required("Password Confirmation is required")
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    });
    var initialValue = {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    };
    React.useEffect(function () {
        if (changePasswordIsSuccess) {
            dispatch(resetChangePasswordStateAction());
            history.push("/dashboard");
        }
    }, [changePasswordIsSuccess, dispatch, history]);
    var _e = useState(false), showPassword1 = _e[0], setShowPassword1 = _e[1];
    var _f = useState(false), showPassword2 = _f[0], setShowPassword2 = _f[1];
    var _g = useState(false), showPassword3 = _g[0], setShowPassword3 = _g[1];
    var _h = useState(false), passwordUsedWithin90Days = _h[0], setPasswordUsedWithin90Days = _h[1];
    var canChangePassword = isLatestPasswordOlderThanOneDay(((_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.lastPasswords) || []);
    // const canChangePassword = true;
    return (React.createElement(Container, { component: "main", maxWidth: "xs" }, React.createElement("div", { className: classes.paper }, React.createElement("img", { src: dashboardIcon, alt: "Icon Not Found", style: { width: 136 } }), React.createElement(Typography, { variant: "h4", className: classes.title }, "Field Workforce Management Software"), loggedInUser && (React.createElement("div", { style: { marginTop: 20 } }, ((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.isFirebaseUser) ? (React.createElement(Formik, { initialValues: initialValue, validationSchema: yupSchema, onSubmit: function (value) {
            var _a, _b;
            if (isPasswordUsedWithin90Days(((_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.lastPasswords) || [], value.newPassword)) {
                setPasswordUsedWithin90Days(true);
            }
            else {
                dispatch(changePasswordAction((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.email, value.currentPassword, value.newPassword));
            }
        } }, function (_a) {
        var errors = _a.errors, touched = _a.touched, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, handleBlur = _a.handleBlur;
        return (React.createElement("form", { onSubmit: handleSubmit }, React.createElement(Grid, { container: true, justify: "center", style: { width: 300 } }, React.createElement(Grid, { item: true, xs: 12 }, React.createElement(TextField, { InputProps: {
                disableUnderline: true,
                endAdornment: (React.createElement(InputAdornment, { position: "end" }, React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return setShowPassword1(!showPassword1); }, onMouseDown: function () { return setShowPassword1(!showPassword1); } }, showPassword1 ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null))))
            }, variant: "filled", required: true, fullWidth: true, onChange: handleChange, onBlur: handleBlur, name: "currentPassword", label: "Current Password", type: showPassword1 ? "text" : "password", id: "currentPassword" }), errors.currentPassword && touched.currentPassword && (React.createElement("div", { className: classes.error }, errors.currentPassword))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(TextField, { InputProps: {
                disableUnderline: true,
                endAdornment: (React.createElement(InputAdornment, { position: "end" }, React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return setShowPassword2(!showPassword2); }, onMouseDown: function () { return setShowPassword2(!showPassword2); } }, showPassword2 ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null))))
            }, variant: "filled", required: true, fullWidth: true, onChange: handleChange, onBlur: handleBlur, name: "newPassword", label: "New Password", type: showPassword2 ? "text" : "password", id: "newPassword" }), errors.newPassword && touched.newPassword && (React.createElement("div", { className: classes.error }, errors.newPassword))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(TextField, { InputProps: {
                disableUnderline: true,
                endAdornment: (React.createElement(InputAdornment, { position: "end" }, React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return setShowPassword3(!showPassword3); }, onMouseDown: function () { return setShowPassword3(!showPassword3); } }, showPassword3 ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null))))
            }, variant: "filled", required: true, fullWidth: true, onChange: handleChange, onBlur: handleBlur, name: "confirmNewPassword", label: "Confirm New Password", type: showPassword3 ? "text" : "password", id: "confirmNewPassword" }), errors.confirmNewPassword && touched.confirmNewPassword && (React.createElement("div", { className: classes.error }, errors.confirmNewPassword))), changePasswordHasError && (React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, changePasswordDescription === "current_password_verify_error" ? (React.createElement(Typography, { style: { color: "red", fontWeight: 600 } }, "Your current password does not match with our records.")) : (React.createElement(Typography, { style: { color: "red", fontWeight: 600 } }, "There is an error occurred. Please try again later.")))), !canChangePassword && (React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(Typography, { style: {
                color: "red",
                fontWeight: 600,
                fontSize: 14
            } }, "Minimum password age is 1 day. Please try again later."))), passwordUsedWithin90Days && (React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(Typography, { style: {
                color: "red",
                fontWeight: 600,
                fontSize: 14
            } }, "This password used with in 90 days. Please choose a different password."))), React.createElement(Button, { disabled: changePasswordIsLoading || !canChangePassword, component: "button", style: {
                backgroundColor: LIGHT_BLUE,
                color: "white",
                fontSize: 16,
                fontWeight: 600,
                marginTop: 19,
                width: 300,
                height: 50,
                textTransform: "none",
                opacity: canChangePassword ? 1 : 0.3
            }, type: "submit", fullWidth: true, variant: "contained",
            // @ts-ignore
            onClick: handleSubmit }, "Change Password", changePasswordIsLoading && (React.createElement("img", { src: loadingIcon, alt: "Icon Not Found", style: {
                height: 30,
                width: 30,
                position: "absolute",
                right: 10
            } }))))));
    })) : (React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(Typography, { style: { color: "red", fontWeight: 600 } }, "You are not allowed to access this feature."))), React.createElement(Link, { component: "button", onClick: function () {
            dispatch(resetChangePasswordStateAction());
            history.push("/dashboard");
        }, style: {
            textDecoration: "none",
            marginTop: 16,
            color: LIGHT_BLUE,
            fontSize: 16,
            fontWeight: 600
        } }, "Cancel"))), React.createElement("div", { style: { display: "flex", alignItems: "center", marginTop: "36px" } }, React.createElement(Typography, { variant: "h5", className: classes.subTitle }, "POWERED BY"), React.createElement("img", { src: dpInfoTech, alt: "Icon Not Found", style: { width: 64 } })))));
}
export default ChangePassword;
