/* eslint-disable no-console */
import { NotificationManager } from "react-notifications";
export var taskActionTypes;
(function (taskActionTypes) {
    taskActionTypes["FETCH_TASK"] = "field-workforce-management/tasks/fetch_task";
    taskActionTypes["FETCH_TASK_SUCCESS"] = "field-workforce-management/tasks/fetch_task_success";
    taskActionTypes["FETCH_TASK_FAILURE"] = "field-workforce-management/tasks/fetch_task_failure";
    taskActionTypes["FETCH_SINGLE_TASK"] = "field-workforce-management/tasks/fetch_single_task";
    taskActionTypes["FETCH_SINGLE_TASK_SUCCESS"] = "field-workforce-management/tasks/fetch_single_task_success";
    taskActionTypes["FETCH_SINGLE_TASK_FAILURE"] = "field-workforce-management/tasks/fetch_single_task_failure";
    taskActionTypes["RESET_SINGLE_TASK_STATES"] = "field-workforce-management/tasks/reset_single_task_states";
    taskActionTypes["CREATE_TASK_API"] = "field-workforce-management/tasks/create_task_api";
    taskActionTypes["CREATE_TASK_API_SUCCESS"] = "field-workforce-management/tasks/create_task_api_success";
    taskActionTypes["CREATE_TASK_API_FAILURE"] = "field-workforce-management/tasks/create_task_api_failure";
    taskActionTypes["UPDATE_TASK_API"] = "field-workforce-management/tasks/update_task_api";
    taskActionTypes["UPDATE_TASK_API_SUCCESS"] = "field-workforce-management/tasks/update_task_api_success";
    taskActionTypes["UPDATE_TASK_API_FAILURE"] = "field-workforce-management/tasks/update_task_api_failure";
    taskActionTypes["CHANGE_TASK_STATUS_API"] = "field-workforce-management/tasks/change_task_status";
    taskActionTypes["CHANGE_TASK_STATUS_API_SUCCESS"] = "field-workforce-management/tasks/change_task_status_success";
    taskActionTypes["CHANGE_TASK_STATUS_API_FAILURE"] = "field-workforce-management/tasks/change_task_status_failure";
    taskActionTypes["DELETE_TASK_API"] = "field-workforce-management/tasks/delete_task_api";
    taskActionTypes["DELETE_TASK_API_SUCCESS"] = "field-workforce-management/tasks/delete_task_api_success";
    taskActionTypes["DELETE_TASK_API_FAILURE"] = "field-workforce-management/tasks/delete_task_api_failure";
    taskActionTypes["RESET_TASK_LOADING_STATES"] = "field-workforce-management/tasks/reset_task_loading_states";
    taskActionTypes["UPLOAD_TASK_API"] = "field-workforce-management/tasks/upload_task_api";
    taskActionTypes["UPLOAD_TASK_API_SUCCESS"] = "field-workforce-management/tasks/upload_task_api_success";
    taskActionTypes["UPLOAD_TASK_API_FAILURE"] = "field-workforce-management/tasks/upload_task_api_failure";
    taskActionTypes["FETCH_NEAR_BY_USERS"] = "field-workforce-management/tasks/fetch_near_by_users";
    taskActionTypes["FETCH_NEAR_BY_USERS_SUCCESS"] = "field-workforce-management/tasks/fetch_near_by_users_success";
    taskActionTypes["FETCH_NEAR_BY_USERS_FAILURE"] = "field-workforce-management/tasks/fetch_near_by_users_failure";
    taskActionTypes["FETCH_LAST_SEEN_TIME"] = "field-workforce-management/tasks/fetch_last_seen_time";
    taskActionTypes["FETCH_LAST_SEEN_TIME_SUCCESS"] = "field-workforce-management/tasks/fetch_last_seen_time_success";
    taskActionTypes["FETCH_LAST_SEEN_TIME_FAILURE"] = "field-workforce-management/tasks/fetch_last_seen_time_failure";
    taskActionTypes["SET_TASK_FILTER"] = "field-workforce-management/tasks/set_task_filter";
    taskActionTypes["RESET_TASK_FILTER"] = "field-workforce-management/tasks/reset_task_filter";
})(taskActionTypes || (taskActionTypes = {}));
export function resetFilterAction() {
    return {
        type: taskActionTypes.RESET_TASK_FILTER
    };
}
export function setFilterAction(taskFilter) {
    return {
        type: taskActionTypes.SET_TASK_FILTER,
        taskFilter: taskFilter
    };
}
// fetch all task
export function fetchTaskAction(statusFilter, taskTypesFilter, groupFilter, userFilter, searchText, sourceFilter, priorityFilter, startDate, endDate) {
    return {
        type: taskActionTypes.FETCH_TASK,
        statusFilter: statusFilter,
        taskTypesFilter: taskTypesFilter,
        groupFilter: groupFilter,
        userFilter: userFilter,
        searchText: searchText,
        sourceFilter: sourceFilter,
        priorityFilter: priorityFilter,
        startDate: startDate,
        endDate: endDate
    };
}
export function fetchTaskSuccessAction(taskList) {
    return {
        type: taskActionTypes.FETCH_TASK_SUCCESS,
        taskList: taskList
    };
}
export function fetchTaskFailureAction(err) {
    console.error("fetchTaskFailureAction", err);
    NotificationManager.error("Error occurred while loading no movement activity. Please try again later.", "Oops! Error");
    return {
        type: taskActionTypes.FETCH_TASK_FAILURE,
        err: err
    };
}
// fetch single task
export function fetchSingleTaskAction(taskId) {
    return {
        type: taskActionTypes.FETCH_SINGLE_TASK,
        taskId: taskId
    };
}
export function fetchSingleTaskSuccessAction(task, cancellationRequest, supervisor, assignee, taskScheduleData, nextUser) {
    return {
        type: taskActionTypes.FETCH_SINGLE_TASK_SUCCESS,
        task: task,
        cancellationRequest: cancellationRequest,
        supervisor: supervisor,
        assignee: assignee,
        taskScheduleData: taskScheduleData,
        nextUser: nextUser
    };
}
export function fetchSingleTaskFailureAction(err) {
    console.error("fetchSingleTaskFailureAction", err);
    NotificationManager.error("Error occurred while fetching the task. Please try again later.", "Oops! Error");
    return {
        type: taskActionTypes.FETCH_SINGLE_TASK_FAILURE,
        err: err
    };
}
export function resetSingleTaskStates() {
    return {
        type: taskActionTypes.RESET_SINGLE_TASK_STATES
    };
}
// create task
export function createTaskApiAction(task, repeat, weekdays, prior, end, selectOrigin) {
    return {
        type: taskActionTypes.CREATE_TASK_API,
        task: task,
        repeat: repeat,
        weekdays: weekdays,
        prior: prior,
        end: end,
        selectOrigin: selectOrigin
    };
}
export function createTaskApiSuccessAction() {
    NotificationManager.success("You have successfully created the task");
    return {
        type: taskActionTypes.CREATE_TASK_API_SUCCESS
    };
}
export function createTaskApiFailureAction(err) {
    console.error("createTaskFailureAction", err);
    NotificationManager.error("Error occurred while creating task. Please try again later", "Oops! Error");
    return {
        type: taskActionTypes.CREATE_TASK_API_FAILURE,
        err: err
    };
}
// upload tasks
export function uploadTaskApiAction(tasks) {
    return {
        type: taskActionTypes.UPLOAD_TASK_API,
        tasks: tasks
    };
}
export function uploadTaskApiSuccessAction() {
    NotificationManager.success("You have successfully uploaded the task CSV");
    return {
        type: taskActionTypes.UPLOAD_TASK_API_SUCCESS
    };
}
export function uploadTaskApiFailureAction(err) {
    console.error("uploadTaskFailureAction", err);
    NotificationManager.error("Error occurred while uploading tasks. Please try again later", "Oops! Error");
    return {
        type: taskActionTypes.UPLOAD_TASK_API_FAILURE,
        err: err
    };
}
// update task
export function updateTaskApiAction(task, repeat, weekdays, prior, end, selectOrigin) {
    return {
        type: taskActionTypes.UPDATE_TASK_API,
        task: task,
        repeat: repeat,
        weekdays: weekdays,
        prior: prior,
        end: end,
        selectOrigin: selectOrigin
    };
}
export function updateTaskApiSuccessAction() {
    NotificationManager.success("You have successfully updated the task");
    return {
        type: taskActionTypes.UPDATE_TASK_API_SUCCESS
    };
}
export function updateTaskApiFailureAction(err) {
    console.error("updateTaskFailureAction", err);
    NotificationManager.error("Error occurred while updating task. Please try again later", "Oops! Error");
    return {
        type: taskActionTypes.UPDATE_TASK_API_FAILURE,
        err: err
    };
}
// change task state
export function changeTaskStatusApiAction(task, nextState, reason) {
    return {
        type: taskActionTypes.CHANGE_TASK_STATUS_API,
        task: task,
        nextState: nextState,
        reason: reason
    };
}
export function changeTaskStatusApiSuccessAction() {
    NotificationManager.success("You have successfully changed the task status");
    return {
        type: taskActionTypes.CHANGE_TASK_STATUS_API_SUCCESS
    };
}
export function changeTaskStatusApiFailureAction(err) {
    console.error("changeTaskStatusApiFailureAction", err);
    NotificationManager.error("Error occurred while changing task status. Please try again later.", "Oops! Error");
    return {
        type: taskActionTypes.CHANGE_TASK_STATUS_API_FAILURE,
        err: err
    };
}
// delete task
export function deleteTaskApiAction(taskID, option) {
    return {
        type: taskActionTypes.DELETE_TASK_API,
        taskID: taskID,
        option: option
    };
}
export function deleteTaskApiSuccessAction() {
    NotificationManager.success("You have successfully deleted the task");
    return {
        type: taskActionTypes.DELETE_TASK_API_SUCCESS
    };
}
export function deleteTaskApiFailureAction(err) {
    console.error("toggleArchiveTaskFailureAction", err);
    NotificationManager.error("Error occurred while archiving task. Please try again later.", "Oops! Error");
    return {
        type: taskActionTypes.DELETE_TASK_API_FAILURE,
        err: err
    };
}
// near by users
export function fetchNearByUsersAction(mapLink, suggestedLocation, locationCriteria) {
    return {
        type: taskActionTypes.FETCH_NEAR_BY_USERS,
        mapLink: mapLink,
        suggestedLocation: suggestedLocation,
        locationCriteria: locationCriteria
    };
}
export function fetchNearByUsersSuccessAction(nearByUsers) {
    return {
        type: taskActionTypes.FETCH_NEAR_BY_USERS_SUCCESS,
        nearByUsers: nearByUsers
    };
}
export function fetchNearByUsersFailureAction(err) {
    console.error("fetchNearByUsersFailureAction", err);
    NotificationManager.error("Error occurred while fetching near by users. Please try again later.", "Oops! Error");
    return {
        type: taskActionTypes.FETCH_NEAR_BY_USERS_FAILURE,
        err: err
    };
}
// lastSeenTime
export function fetchLastSeenTimeAction(uid) {
    return {
        type: taskActionTypes.FETCH_LAST_SEEN_TIME,
        uid: uid
    };
}
export function fetchLastSeenTimeSuccessAction(lastSeenTime, lastStatus, workType, device) {
    return {
        type: taskActionTypes.FETCH_LAST_SEEN_TIME_SUCCESS,
        lastSeenTime: lastSeenTime,
        lastStatus: lastStatus,
        workType: workType,
        device: device
    };
}
export function fetchLastSeenTimeFailureAction(err) {
    console.error("fetchLastSeenTimeFailureAction", err);
    NotificationManager.error("Error occurred while fetching last seen time. Please try again later.", "Oops! Error");
    return {
        type: taskActionTypes.FETCH_LAST_SEEN_TIME_FAILURE,
        err: err
    };
}
export function resetTaskLoadingStatesAction() {
    return {
        type: taskActionTypes.RESET_TASK_LOADING_STATES
    };
}
