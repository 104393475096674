import * as React from "react";
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { PRIMARY_BLUE } from "../../utils/colorCodes";
import useStyles from "./styles";
import { UsersCsvDownload } from "../../components/CsvDownload/UsersCsvDownload";
export default function CsvDownloadFieldModal(props) {
    var userList = props.userList, roles = props.roles, userGroups = props.userGroups, skills = props.skills, locations = props.locations, isCsvFieldsModalOpen = props.isCsvFieldsModalOpen, setCsvFieldsModalOpen = props.setCsvFieldsModalOpen;
    var classes = useStyles();
    var _a = React.useState(true), id = _a[0], setID = _a[1];
    var _b = React.useState(true), name = _b[0], setName = _b[1];
    var _c = React.useState(true), email = _c[0], setEmail = _c[1];
    var _d = React.useState(true), telephone = _d[0], setTelephone = _d[1];
    var _e = React.useState(true), role = _e[0], setRole = _e[1];
    var _f = React.useState(true), supervisor = _f[0], setSupervisor = _f[1];
    var _g = React.useState(true), groups = _g[0], setGroups = _g[1];
    var _h = React.useState(true), _skills = _h[0], setSkills = _h[1];
    var _j = React.useState(true), _locations = _j[0], setLocations = _j[1];
    var _k = React.useState(true), interval = _k[0], setInterval = _k[1];
    var _l = React.useState(true), archive = _l[0], setArchive = _l[1];
    return (React.createElement(Dialog, { disableBackdropClick: true, open: isCsvFieldsModalOpen, onClose: function () {
            setCsvFieldsModalOpen(false);
        }, scroll: "body", maxWidth: "xs", fullWidth: true, "aria-labelledby": "scroll-dialog-title", "aria-describedby": "scroll-dialog-description", style: { left: "auto" }, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray"
                // width: 444
            }
        } }, React.createElement(DialogTitle, { id: "scroll-dialog-title" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { style: { fontSize: 24, fontWeight: "bold", color: PRIMARY_BLUE } }, "Select Fields"), React.createElement(Button, { onClick: function () {
            setCsvFieldsModalOpen(false);
        }, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null)))), React.createElement(DialogContent, null, React.createElement(Grid, { item: true, xs: 12 }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: id, onChange: function () { return setID(!id); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Id", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: name, onChange: function () { return setName(!name); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Name", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: email, onChange: function () { return setEmail(!email); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Email", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: telephone, onChange: function () { return setTelephone(!telephone); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Telephone number", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: role, onChange: function () { return setRole(!role); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Role", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: supervisor, onChange: function () { return setSupervisor(!supervisor); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Supervisor", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: groups, onChange: function () { return setGroups(!groups); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Groups", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: _skills, onChange: function () { return setSkills(!_skills); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Skills", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: _locations, onChange: function () { return setLocations(!_locations); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Location", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: interval, onChange: function () { return setInterval(!interval); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Interval", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 15 } }, React.createElement("div", { className: classes.weekday, style: { minWidth: 300 } }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: archive, onChange: function () { return setArchive(!archive); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Archive", labelPlacement: "start" })))), React.createElement(DialogActions, { style: { padding: 24 } }, UsersCsvDownload(userList, roles, userGroups, skills, locations, {
        id: id,
        name: name,
        email: email,
        telephone: telephone,
        role: role,
        supervisor: supervisor,
        groups: groups,
        _skills: _skills,
        _locations: _locations,
        interval: interval,
        archive: archive
    }))));
}
