import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
var useStyles = makeStyles({
    root: {
        width: 200,
        display: "flex",
        alignItems: "center"
    }
});
export default function HoverRating(props) {
    var value = props.value, setValue = props.setValue;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.root }, React.createElement(Rating, { name: "hover-feedback", value: value, precision: 1, onChange: function (event, newValue) {
            setValue(newValue);
        } })));
}
