var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
import { Box, FormControl, Grid, IconButton, Link } from "@material-ui/core";
import { FieldArray } from "formik";
import CancelIcon from "@material-ui/icons/Cancel";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { ERROR_HIGH, LIGHT_BLUE, PRIMARY_PINK } from "../../../../utils/colorCodes";
import useStyles from "../../styles/styles";
export default function LocationCriteriaSelector(props) {
    var _a = props.locationCriteria, locationCriteria = _a === void 0 ? [] : _a, setLocationCriteria = props.setLocationCriteria, setFieldValue = props.setFieldValue, setTouched = props.setTouched;
    var locations = useSelector(function (state) { return state.locationManagement; }).locations;
    var classes = useStyles();
    useEffect(function () {
        setLocationCriteria(locationCriteria);
    }, [locationCriteria, setLocationCriteria]);
    function checkLocationSelected(el, location) {
        if (locationCriteria.length > 0) {
            if (el.id != location.id) {
                if (locationCriteria.includes(el.id)) {
                    return false;
                }
            }
        }
        return true;
    }
    function checkLocationCriteria(push) {
        var lastTask = locationCriteria.slice(-1);
        if (lastTask[0] !== "") {
            push("");
        }
    }
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement(FieldArray, { name: "criteria.locationCriteria", render: function (_a) {
            var remove = _a.remove, push = _a.push;
            return (React.createElement("div", null, locationCriteria.length > 0 &&
                locationCriteria.map(function (location, ithElement) {
                    return (React.createElement(Box, { display: "flex", flexDirection: "row", style: {
                            backgroundColor: PRIMARY_PINK,
                            borderRadius: 4,
                            marginBottom: 10
                        }, key: location + "_" + ithElement }, React.createElement(IconButton, { style: {
                            backgroundColor: "transparent"
                        }, disableRipple: true, type: "button", onClick: function () {
                            ithElement === 0 && push("");
                            remove(ithElement);
                        } }, React.createElement(CancelIcon, { style: { color: ERROR_HIGH } })), React.createElement(FormControl, { className: classes.formControl100 }, React.createElement(Autocomplete, { id: "criteria.locationCriteria." + ithElement, options: locations.filter(function (el) { return checkLocationSelected(el, location); }), getOptionLabel: function (option) { return option.name || ""; }, defaultValue: locations.filter(function (el) { return el.id === location; })[0], onChange: function (_, value) { var _a; return setFieldValue("criteria.locationCriteria." + ithElement, ((_a = value) === null || _a === void 0 ? void 0 : _a.id) || ""); }, onBlur: function () {
                            var _a;
                            return setTouched((_a = {}, _a["criteria.locationCriteria." + ithElement] = true, _a));
                        }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true }), label: "Location", variant: "filled" }))); } }))));
                }), React.createElement(Link, { type: "button", component: "button", underline: "none", style: {
                    textTransform: "none",
                    color: LIGHT_BLUE,
                    fontWeight: "bold",
                    fontSize: 17
                }, onClick: function () { return checkLocationCriteria(push); } }, "+ Location")));
        } })));
}
