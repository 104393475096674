import firebase from "firebase";
import { firestore } from "../firebaseConnector";
import { FIRESTORE_SKILLS_COLLECTION, FIRESTORE_USER_GROUPS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../firestoreCollections";
export function userMapper(doc) {
    var _a;
    var _b = doc.data(), uid = _b.uid, name = _b.name, email = _b.email, tp = _b.tp, role = _b.role, supervisor = _b.supervisor, groups = _b.groups, skills = _b.skills, location = _b.location, interval = _b.interval, archive = _b.archive, isFirebaseUser = _b.isFirebaseUser, lastPasswords = _b.lastPasswords, profileImg = _b.profileImg;
    return {
        documentID: doc.id,
        uid: uid,
        name: name,
        email: email || "",
        tp: tp.length === 0 ? [""] : tp,
        role: role || "",
        supervisor: ((_a = supervisor) === null || _a === void 0 ? void 0 : _a.id) || "",
        groups: groups.length === 0 ? [""] : groups.map(function (el) { var _a; return (_a = el) === null || _a === void 0 ? void 0 : _a.id; }),
        skills: skills.length === 0
            ? [
                {
                    skill: "",
                    level: 1
                }
            ]
            : skills.map(function (el) {
                var _a;
                return {
                    skill: (_a = el.skill) === null || _a === void 0 ? void 0 : _a.id,
                    level: el.level
                };
            }),
        location: location || "",
        interval: interval || "",
        archive: archive || false,
        isFirebaseUser: isFirebaseUser || false,
        lastPasswords: (lastPasswords || []).map(function (el) {
            var _a;
            return ({
                timestamp: ((_a = el.timestamp) === null || _a === void 0 ? void 0 : _a.seconds) * 1000 || 0,
                password: el.password
            });
        }),
        initialPassword: "",
        confirmInitialPassword: "",
        profileImg: profileImg
    };
}
export function userCreator(user) {
    return {
        uid: user.uid,
        name: user.name,
        email: user.email,
        tp: user.tp.filter(function (el) { return el.length !== 0; }),
        role: user.role || null,
        supervisor: user.supervisor.length === 0 ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + user.supervisor),
        groups: user.groups
            .filter(function (el) { return el.length !== 0; })
            .map(function (el) { return firestore.doc(FIRESTORE_USER_GROUPS_COLLECTION + "/" + el); }),
        skills: user.skills
            .filter(function (el) { return el.skill.length !== 0; })
            .map(function (el) {
            return {
                skill: firestore.doc(FIRESTORE_SKILLS_COLLECTION + "/" + el.skill),
                level: el.level
            };
        }),
        location: user.location || null,
        interval: user.interval || null,
        archive: user.archive,
        isFirebaseUser: user.isFirebaseUser,
        lastPasswords: user.lastPasswords.map(function (el) {
            return ({
                timestamp: firebase.firestore.Timestamp.fromMillis(el.timestamp),
                password: el.password
            });
        })
    };
}
export function userUpdater(user) {
    return {
        uid: user.uid,
        name: user.name,
        email: user.email,
        tp: user.tp.filter(function (el) { return el.length !== 0; }),
        role: user.role || null,
        supervisor: user.supervisor.length === 0 ? null : firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + user.supervisor),
        groups: user.groups
            .filter(function (el) { return el.length !== 0; })
            .map(function (el) { return firestore.doc(FIRESTORE_USER_GROUPS_COLLECTION + "/" + el); }),
        skills: user.skills
            .filter(function (el) { return el.skill.length !== 0; })
            .map(function (el) {
            return {
                skill: firestore.doc(FIRESTORE_SKILLS_COLLECTION + "/" + el.skill),
                level: el.level
            };
        }),
        location: user.location || null,
        interval: user.interval || null
        // archive: user.archive,
        // isFirebaseUser: user.isFirebaseUser,
        // lastPasswords: user.lastPasswords.map((el:any) => ({
        //   timestamp: firebase.firestore.Timestamp.fromMillis(el.timestamp),
        //   password: el.password
        // })),
    };
}
