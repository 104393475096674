/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { noMovementActivityActionTypes } from "./actions";
var initialState = {
    activities: [],
    fetchNoMovementIsLoading: false,
    fetchNoMovementError: { hasError: false, description: "" }
};
export default function noMovementActivityReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY: {
                draft.fetchNoMovementIsLoading = true;
                break;
            }
            case noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY_CLOUD: {
                draft.fetchNoMovementIsLoading = true;
                break;
            }
            case noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY_SUCCESS: {
                draft.activities = action.activities;
                draft.fetchNoMovementIsLoading = false;
                draft.fetchNoMovementError = { hasError: false, description: "" };
                break;
            }
            case noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY_FAILURE: {
                draft.fetchNoMovementIsLoading = false;
                draft.fetchNoMovementError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            default:
                break;
        }
    });
}
