import { Link as NavigatorLink, useHistory } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AnnouncementRoundedIcon from "@material-ui/icons/AnnouncementRounded";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import React from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { markAsReadAlertAction } from "../Alerts/actions";
import { isUserHasPermission } from "../../utils/isUserHasPermission";
export default function ShowNotificationMenu(anchorElNotifications, handleClose) {
    var _a, _b, _c;
    var classes = useStyles();
    var history = useHistory();
    var dispatch = useDispatch();
    var listenAlertList = useSelector(function (state) { return state.alertManagement; }).listenAlertList;
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    var accessControl = useSelector(function (state) { return state.accessControlManagement; }).accessControl;
    return (React.createElement(Menu, { className: classes.notificationMenuBody, id: "menu-notifications", anchorEl: anchorElNotifications, open: Boolean(anchorElNotifications), onClose: handleClose }, React.createElement("div", { style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: 500
        } }, React.createElement(Typography, { className: classes.notificationMenuTitle }, "Notifications"), isUserHasPermission((_b = (_a = accessControl) === null || _a === void 0 ? void 0 : _a.notifications) === null || _b === void 0 ? void 0 : _b.view_tab, (_c = loggedInUser) === null || _c === void 0 ? void 0 : _c.role) && (React.createElement(Button, { component: NavigatorLink, onClick: function () { return handleClose(); }, to: "/dashboard/notifications", disableRipple: true, disableTouchRipple: true, style: {
            textTransform: "none",
            backgroundColor: "transparent",
            color: "#1d9ad6",
            fontWeight: 600,
            fontSize: 16,
            lineHeight: 1.25,
            marginTop: 5,
            marginLeft: 5,
            minWidth: 30,
            height: 28
        } }, "Show All"))), listenAlertList.length > 0 ? (listenAlertList.map(function (al, id) {
        return (React.createElement("div", { key: id, className: classes.notificationRow }, React.createElement(MenuItem, { id: id + "-notification-body", style: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                whiteSpace: "pre-wrap",
                padding: 0
            }, onClick: function () {
                switch (al.type) {
                    case "no_cov":
                        history.push("/dashboard/no-coverage");
                        break;
                    case "no_mov":
                        history.push("/dashboard/no-movement");
                        break;
                    case "cancellation_req":
                        history.push("/dashboard/cancellation-requests");
                        break;
                    default:
                        break;
                }
                dispatch(markAsReadAlertAction(al.documentID));
                handleClose();
            } }, al.seen ? (React.createElement("div", { id: id + "-notification-icon" }, React.createElement(AnnouncementOutlinedIcon, { color: "primary" }))) : (React.createElement("div", { id: id + "-notification-icon" }, React.createElement(AnnouncementRoundedIcon, { color: "primary" }))), React.createElement(Typography, { id: id + "-notification-title", style: { marginLeft: 10, fontSize: 14 } }, al.description)), React.createElement(Divider, { className: classes.divider })));
    })) : (React.createElement("div", { style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 33
        } }, React.createElement(Typography, { style: { fontSize: 14, color: "#07456B" } }, "There are no notifications to display."))), React.createElement("div", { style: { height: 10 } })));
}
