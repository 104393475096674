import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as React from "react";
import { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteTaskApiAction, resetTaskLoadingStatesAction } from "../redux/actions";
import { ERROR_HIGH, PRIMARY_BLUE } from "../../../utils/colorCodes";
import loadingIcon from "../../../assets/images/loadingBox.gif";
export default function DeleteTaskModal(props) {
    var currentTask = props.currentTask, deleteThisTaskOnly = props.deleteThisTaskOnly, isToggleArchiveTaskModalOpen = props.isToggleArchiveTaskModalOpen, setToggleArchiveTaskModalOpen = props.setToggleArchiveTaskModalOpen;
    var dispatch = useDispatch();
    var history = useHistory();
    var _a = useSelector(function (state) { return state.taskManagement; }), deleteTaskApiSuccess = _a.deleteTaskApiSuccess, deleteTaskApiIsLoading = _a.deleteTaskApiIsLoading;
    useEffect(function () {
        if (deleteTaskApiSuccess) {
            setToggleArchiveTaskModalOpen(false);
            dispatch(resetTaskLoadingStatesAction());
            history.push("/dashboard/tasks");
        }
    }, [deleteTaskApiSuccess, dispatch, history, setToggleArchiveTaskModalOpen]);
    return (React.createElement(Dialog
    // disableBackdropClick
    , {
        // disableBackdropClick
        open: isToggleArchiveTaskModalOpen, onClose: function () {
            setToggleArchiveTaskModalOpen(false);
        }, scroll: "body", maxWidth: "xs", fullWidth: true, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray"
            }
        }
    }, React.createElement(DialogTitle, { id: "scroll-dialog-title" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { style: { fontSize: 17, fontWeight: "bold", color: PRIMARY_BLUE } }, "Are you sure?"), React.createElement(Button, { onClick: function () { return setToggleArchiveTaskModalOpen(false); }, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null)))), React.createElement(DialogContent, null, React.createElement(Grid, { container: true, justify: "flex-start" }, React.createElement(Typography, null, deleteThisTaskOnly ? "Delete this task permanently." : "Delete this task & future tasks permanently."))), React.createElement(DialogActions, { style: { padding: 24 } }, React.createElement(Button, { disabled: deleteTaskApiIsLoading, type: "submit", onClick: function () {
            if (deleteThisTaskOnly) {
                dispatch(deleteTaskApiAction(currentTask.id, "this"));
            }
            else {
                dispatch(deleteTaskApiAction(currentTask.id, "all"));
            }
        }, color: "primary", fullWidth: true, style: {
            textTransform: "none",
            backgroundColor: ERROR_HIGH,
            color: "white",
            position: "relative"
        } }, "Delete the task", deleteTaskApiIsLoading && (React.createElement("img", { src: loadingIcon, alt: "Not Found", style: { height: 20, width: 20, position: "absolute", right: 10 } }))))));
}
