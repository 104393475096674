import { NotificationManager } from "react-notifications";
export var noMovementActivityActionTypes;
(function (noMovementActivityActionTypes) {
    noMovementActivityActionTypes["FETCH_NO_MOVEMENT_ACTIVITY"] = "field-workforce-management/no_movement/fetch_no_movement_activity";
    noMovementActivityActionTypes["FETCH_NO_MOVEMENT_ACTIVITY_SUCCESS"] = "field-workforce-management/no_movement/fetch_no_movement_activity_success";
    noMovementActivityActionTypes["FETCH_NO_MOVEMENT_ACTIVITY_FAILURE"] = "field-workforce-management/no_movement/fetch_no_movement_activity_failure";
    noMovementActivityActionTypes["FETCH_NO_MOVEMENT_ACTIVITY_CLOUD"] = "field-workforce-management/no_movement/fetch_no_movement_activity_cloud";
})(noMovementActivityActionTypes || (noMovementActivityActionTypes = {}));
export function fetchNoMovementActivityAction(startDate, endDate, selectedWeekDays, excludeDates) {
    return {
        type: noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY,
        startDate: startDate,
        endDate: endDate,
        selectedWeekDays: selectedWeekDays,
        excludeDates: excludeDates
    };
}
export function fetchNoMovementActivityCloudAction(startDate, endDate) {
    return {
        type: noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY_CLOUD,
        startDate: startDate,
        endDate: endDate
    };
}
export function fetchNoMovementActivitySuccessAction(activities) {
    return {
        type: noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY_SUCCESS,
        activities: activities
    };
}
export function fetchNoMovementActivityFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchNoMovementActivityFailureAction", err);
    NotificationManager.error("Error occurred while loading no movement activity. Please try again later.", "Oops! Error");
    return {
        type: noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY_FAILURE,
        err: err
    };
}
