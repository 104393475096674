import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY_BLUE, PRIMARY_PINK } from "../../../utils/colorCodes";

const useStyles = makeStyles(() => ({
  icon: {
    fill: "white"
  },
  table: {
    minWidth: 640
  },
  dateCollector: {
    marginTop: 10,
    width: 400
  },
  selectFormControl: {
    display: "flex",
    overflow: "hidden",
    width: 166
  },
  selectFormControlZ: {
    overflow: "hidden",
    width: 166
  },
  formStatusControlUserList: {
    width: 200,
    height: 40,
    marginRight: 15,
    marginTop: 10,
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    paddingTop: 5,
    paddingLeft: 10
  },
  formStatusControl: {
    width: 200,
    height: 40,
    marginRight: 15,
    marginTop: 10,
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    paddingTop: 5,
    paddingLeft: 10
  },
  formControl100: {
    width: "100%"
  },
  groupControl: {
    // margin: theme.spacing(1),
    width: "90%"
  },
  datetimeContainer: {
    width: "100%",
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    paddingTop: 8,
    paddingLeft: 10,
    paddingRight: 5
  },
  datetimeTaskComplete: {
    width: "100%",
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    paddingRight: 5,
    marginTop: 10
  },
  datetimeTextField: {
    width: "100%"
  },
  weekday: {
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    height: 56
  },
  repeatWeekday: {
    height: 36,
    alignItems: "center",
    display: "flex"
  },
  error: {
    color: "red",
    paddingLeft: 10
  },
  errorText: {
    color: "red",
    fontSize: 10,
    marginTop: 0,
    marginLeft: 10,
    marginBottom: 10
  },
  imageFileName: {
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  addMoreBtn: {
    height: "35px",
    marginTop: "-45px",
    "border-radius": "0",
    "border-left": "1px solid #00000047"
  },
  overDue: {
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    marginRight: 15,
    marginLeft: 0,
    marginTop: 10,
    height: 40,
    width: 200
  },
  changeStateModalText: {
    color: PRIMARY_BLUE,
    fontSize: 14,
    fontWeight: 400,
    textAlign: "left"
  },
  tableCell: {
    color: PRIMARY_BLUE,
    fontSize: 14,
    fontWeight: 500
  },
  tableCellEnd: {
    color: PRIMARY_BLUE,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.25
  },
  tableHeaderCell: {
    color: PRIMARY_BLUE,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.429
  },
  pageTitle: {
    color: PRIMARY_BLUE,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 0.833,
    textAlign: "left",
    marginTop: 14
  },
  pageSubTitle: {
    color: PRIMARY_BLUE,
    fontSize: 10,
    fontWeight: 400,
    textAlign: "left",
    marginTop: 10
  },
  taskDetailCardTitle: {
    fontSize: 12,
    color: PRIMARY_BLUE,
    opacity: 0.6,
    textAlign: "left"
  },
  taskDetailCardBody: {
    fontSize: 14,
    color: PRIMARY_BLUE,
    textAlign: "left",
    overflowX: "scroll"
  },
  viewTaskHeadTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: PRIMARY_BLUE,
    // opacity: 0.6,
    textAlign: "left"
  },
  viewTaskHeadBody: {
    fontSize: 14,
    // fontWeight: 400,
    color: PRIMARY_BLUE,
    // opacity: 0.6,
    textAlign: "left"
  },
  viewTaskHead: {
    flex: 1,
    minWidth: 300,
    marginBottom: 15,
    marginRight: 20
  },
  viewTaskBody: {
    backgroundColor: PRIMARY_PINK,
    flex: 1,
    minWidth: 300,
    marginBottom: 15,
    marginRight: 20,
    padding: 10,
    borderRadius: 4,
    minHeight: 59
  },
  timelineCard: {
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    width: "170px",
    textAlign: "left"
  },
  timeline: {
    maxHeight: "150vh",
    overflowY: "scroll"
  },
  timeLineContent: {
    width: "100%"
  },
  timeLineItem: {
    width: "100%",
    minHeight: "initial !important"
  },
  autoCompleteDropDownPaper: {
    width: 500,
    height: "auto",
    position: "fixed",
    left: -10
  },
  customTextField: {
    "& input::placeholder": {
      opacity: 1,
      color: "rgb(7, 69, 107)"
    }
  }
}));

export default useStyles;
