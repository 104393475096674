/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { NotificationManager } from "react-notifications";
export var userGroupsActionTypes;
(function (userGroupsActionTypes) {
    userGroupsActionTypes["FETCH_USER_GROUPS"] = "field-workforce-management/userGroups/fetch_user_group";
    userGroupsActionTypes["FETCH_USER_GROUPS_SUCCESS"] = "field-workforce-management/userGroups/fetch_user_group_success";
    userGroupsActionTypes["FETCH_USER_GROUPS_FAILURE"] = "field-workforce-management/userGroups/fetch_user_group_failure";
    userGroupsActionTypes["CREATE_USER_GROUP"] = "field-workforce-management/userGroups/add_new_user_group";
    userGroupsActionTypes["CREATE_USER_GROUP_SUCCESS"] = "field-workforce-management/userGroups/add_new_user_group_success";
    userGroupsActionTypes["CREATE_USER_GROUP_FAILURE"] = "field-workforce-management/userGroups/add_new_user_group_failure";
    userGroupsActionTypes["UPDATE_USER_GROUP"] = "field-workforce-management/userGroups/update_user_group";
    userGroupsActionTypes["UPDATE_USER_GROUP_SUCCESS"] = "field-workforce-management/userGroups/update_user_group_success";
    userGroupsActionTypes["UPDATE_USER_GROUP_FAILURE"] = "field-workforce-management/userGroups/update_user_group_failure";
    userGroupsActionTypes["TOGGLE_ARCHIVE_USER_GROUP"] = "field-workforce-management/userGroups/toggle_archive_user_group";
    userGroupsActionTypes["TOGGLE_ARCHIVE_USER_GROUP_SUCCESS"] = "field-workforce-management/userGroups/toggle_archive_user_group_success";
    userGroupsActionTypes["TOGGLE_ARCHIVE_USER_GROUP_FAILURE"] = "field-workforce-management/userGroups/toggle_archive_user_group_failure";
    userGroupsActionTypes["RESET_SUCCESS_STATE"] = "field-workforce-management/userGroups/reset_success_state";
})(userGroupsActionTypes || (userGroupsActionTypes = {}));
export function fetchUserGroupsAction(loggedInUser) {
    return {
        loggedInUser: loggedInUser,
        type: userGroupsActionTypes.FETCH_USER_GROUPS
    };
}
export function fetchUserGroupsSuccessAction(userGroups, subordinatorGroupIDs) {
    return {
        type: userGroupsActionTypes.FETCH_USER_GROUPS_SUCCESS,
        userGroups: userGroups,
        subordinatorGroupIDs: subordinatorGroupIDs
    };
}
export function fetchUserGroupsFailureAction(err) {
    console.error("fetchUserGroupsFailureAction", err);
    NotificationManager.error("Error occurred while fetching user groups. Please try again later.", "Oops! Error");
    return {
        type: userGroupsActionTypes.FETCH_USER_GROUPS_FAILURE,
        err: err
    };
}
export function createUserGroupAction(userGroup) {
    return {
        type: userGroupsActionTypes.CREATE_USER_GROUP,
        userGroup: userGroup
    };
}
export function createUserGroupSuccessAction(name) {
    NotificationManager.success("You have successfully created " + name);
    return {
        type: userGroupsActionTypes.CREATE_USER_GROUP_SUCCESS
    };
}
export function createUserGroupFailureAction(err) {
    console.error("createUserGroupFailureAction", err);
    NotificationManager.error("Error occurred while adding new user group. Please try again later.", "Oops! Error");
    return {
        type: userGroupsActionTypes.CREATE_USER_GROUP_FAILURE,
        err: err
    };
}
export function updateUserGroupAction(userGroup) {
    return {
        type: userGroupsActionTypes.UPDATE_USER_GROUP,
        userGroup: userGroup
    };
}
export function updateUserGroupSuccessAction(name) {
    NotificationManager.success("You have successfully created " + name);
    return {
        type: userGroupsActionTypes.UPDATE_USER_GROUP_SUCCESS
    };
}
export function updateUserGroupFailureAction(err) {
    console.error("updateUserGroupFailureAction", err);
    NotificationManager.error("Error occurred while updating user group. Please try again later.", "Oops! Error");
    return {
        type: userGroupsActionTypes.UPDATE_USER_GROUP_FAILURE,
        err: err
    };
}
export function toggleUserGroupArchiveAction(userGroup) {
    return {
        type: userGroupsActionTypes.TOGGLE_ARCHIVE_USER_GROUP,
        userGroup: userGroup
    };
}
export function toggleUserGroupArchiveSuccessAction(name, isArchived) {
    if (isArchived) {
        NotificationManager.success("You have successfully archived " + name);
    }
    else {
        NotificationManager.success("You have successfully activated " + name);
    }
    return {
        type: userGroupsActionTypes.TOGGLE_ARCHIVE_USER_GROUP_SUCCESS
    };
}
export function toggleUserGroupArchiveFailureAction(err) {
    console.error("toggleUserGroupArchiveFailureAction", err);
    NotificationManager.error("Error occurred while archiving the user group. Please try again later.", "Oops! Error");
    return {
        type: userGroupsActionTypes.TOGGLE_ARCHIVE_USER_GROUP_FAILURE,
        err: err
    };
}
export function resetUserGroupStatesAction() {
    return {
        type: userGroupsActionTypes.RESET_SUCCESS_STATE
    };
}
