/* eslint-disable no-continue, @typescript-eslint/no-unused-vars */
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import papaparse from "papaparse";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import useStyles from "../styles/styles";
import { LIGHT_BLUE, PRIMARY_BLUE } from "../../../utils/colorCodes";
import uploadTaskMapping from "../Components/uploadTaskMapping";
import { CustomTooltipWhite } from "../../../components/CustomeTooltip";
import { uploadTaskApiAction } from "../redux/actions";
function isValid(task) {
    var _a, _b, _c, _d, _e, _f;
    return (!JSON.stringify(task || {}).includes("ERROR") &&
        (((_b = (_a = task) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.length) > 0 || ((_d = (_c = task) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.length) > 0 || ((_f = (_e = task) === null || _e === void 0 ? void 0 : _e.mapLink) === null || _f === void 0 ? void 0 : _f.length) > 0) &&
        task.start < task.due);
}
/* this function will only call for invalid tasks. keep remember, first need to trigger isValid function */
function getErrorMsg(task) {
    var _a, _b, _c, _d, _e, _f;
    var errMsgs = [];
    if (task) {
        if (task.type === "ERROR") {
            errMsgs.push("Task type error");
        }
        if (task.address === "ERROR") {
            errMsgs.push("Address error");
        }
        if (task.location === "ERROR") {
            errMsgs.push("Location error");
        }
        if (task.mapLink === "ERROR") {
            errMsgs.push("Location link error");
        }
        if (JSON.stringify(task.contacts).includes("ERROR")) {
            errMsgs.push("Telephone number error");
        }
        if (task.description === "ERROR") {
            errMsgs.push("Short description error");
        }
        if (task.refID === "ERROR") {
            errMsgs.push("Ref ID error");
        }
        if (task.start === "ERROR") {
            errMsgs.push("Start date & time error");
        }
        if (task.due === "ERROR") {
            errMsgs.push("Due date & time error");
        }
        if (task.supervisor === "ERROR") {
            errMsgs.push("Supervisor error");
        }
        if (task.user === "ERROR") {
            errMsgs.push("Assignee error");
        }
        if (task.priority === "ERROR") {
            errMsgs.push("Priority error");
        }
        if (task.travellingRequired === "ERROR") {
            errMsgs.push("Travel required error");
        }
        if (JSON.stringify(task.allocation).includes("ERROR")) {
            errMsgs.push("Group/Skill/Location criteria error");
        }
        if (((_b = (_a = task) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.length) === 0 && ((_d = (_c = task) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.length) === 0 && ((_f = (_e = task) === null || _e === void 0 ? void 0 : _e.mapLink) === null || _f === void 0 ? void 0 : _f.length) === 0) {
            errMsgs.push("Address or Location or Location link is required");
        }
        if (typeof task.start === "number" && typeof task.due === "number") {
            if (task.start >= task.due) {
                errMsgs.push("Due date & time should greater than start date & time");
            }
        }
    }
    return errMsgs;
}
export default function UploadTaskModal(props) {
    var classes = useStyles();
    var dispatch = useDispatch();
    var isUploadTaskModalOpen = props.isUploadTaskModalOpen, setUploadTaskModalOpen = props.setUploadTaskModalOpen;
    var _a = React.useState([]), rawData = _a[0], setRawData = _a[1];
    var _b = React.useState([]), taskToUpload = _b[0], setTaskToUpload = _b[1];
    var _c = React.useState("Browse or Drag an CSV file"), uploadedFileName = _c[0], setUploadedFileName = _c[1];
    var taskTypes = useSelector(function (state) { return state.taskTypeManagement; }).taskTypes;
    var locations = useSelector(function (state) { return state.locationManagement; }).locations;
    var userGroups = useSelector(function (state) { return state.userGroupManagement; }).userGroups;
    var userList = useSelector(function (state) { return state.userManagement; }).userList;
    var skills = useSelector(function (state) { return state.skillManagement; }).skills;
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    function closeModal() {
        setUploadTaskModalOpen(false);
        setTimeout(function () {
            setUploadedFileName("Browse or Drag an CSV file");
            setRawData([]);
            setTaskToUpload([]);
        }, 500);
    }
    function clearAll() {
        setUploadedFileName("Browse or Drag an CSV file");
        setRawData([]);
        setTaskToUpload([]);
    }
    function onFileChangeHandler(e) {
        // @ts-ignore
        var file = e.target.files[0];
        setUploadedFileName(file.name);
        papaparse.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                var _a;
                var data = results.data;
                setRawData(data);
                uploadTaskMapping(data, setTaskToUpload, ((_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.uid) || "", taskTypes, locations, userList, userGroups, skills);
            }
        });
    }
    function onFileSubmitHandler() {
        // eslint-disable-next-line array-callback-return
        var readyToSubmit = taskToUpload.filter(function (t) { return isValid(t); });
        if (readyToSubmit.length > 0) {
            dispatch(uploadTaskApiAction(readyToSubmit));
        }
        closeModal();
    }
    var uploadFileView = (React.createElement("div", { style: {
            width: "100%",
            backgroundColor: "#D6EFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 30,
            paddingBottom: 30
        } }, React.createElement("label", { htmlFor: "files", className: "btn" }), React.createElement("input", { id: "files", style: {
            width: 396,
            height: 124,
            backgroundColor: "transparent",
            position: "absolute",
            cursor: "pointer",
            opacity: 0
        }, type: "file", accept: ".csv", onChange: function (e) { return onFileChangeHandler(e); }, multiple: false }), React.createElement(CloudUploadOutlinedIcon, { style: { height: 40, width: 40, color: PRIMARY_BLUE } }), React.createElement(Typography, { style: {
            fontSize: 16,
            color: PRIMARY_BLUE,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            overflowWrap: "anywhere"
        } }, uploadedFileName.length > 40 ? uploadedFileName.substring(0, 40) + "..." : uploadedFileName)));
    var liveResultView = (React.createElement("div", null, React.createElement("div", { style: { maxHeight: "380px", overflowY: "scroll", padding: "0 10px 0 0" } }, rawData.map(function (el, index) {
        return (React.createElement("div", { key: index, style: { display: "flex", flexDirection: "row", justifyContent: "space-between" } }, React.createElement("div", { style: { display: "flex", flexDirection: "row" } }, React.createElement("p", { style: { margin: "0 0 10px 0" } }, index + 1), React.createElement("p", { style: {
                margin: "0 0 10px 0",
                width: 330,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            } }, ". " + JSON.stringify(el))), isValid(taskToUpload[index]) ? (React.createElement(CheckCircleIcon, { style: { height: 18, width: 18, color: "#1ECE35" } })) : (React.createElement(CustomTooltipWhite, { disableFocusListener: true, arrow: true, placement: "left", title: React.createElement("ul", { style: { padding: "5px 10px 5px 20px", margin: 0 } }, getErrorMsg(taskToUpload[index]).map(function (err, id) { return (React.createElement("li", { key: id }, err)); })) }, React.createElement(ErrorIcon, { style: { height: 18, width: 18, color: "darkorange" } })))));
    })), React.createElement("p", { style: { fontSize: 16, color: PRIMARY_BLUE, paddingRight: 20, paddingTop: 10 } }, "Upload this csv? ", React.createElement("br", null), "(Invalid tasks will not be uploaded)")));
    return (React.createElement(Dialog, { disableBackdropClick: true, open: isUploadTaskModalOpen, onClose: function () { return closeModal(); }, scroll: "body", maxWidth: "xs", fullWidth: true, "aria-labelledby": "scroll-dialog-title", "aria-describedby": "scroll-dialog-description", style: { left: "auto", top: "auto" }, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray",
                width: 444,
                maxHeight: "90vh"
            }
        } }, React.createElement(DialogTitle, { id: "scroll-dialog-title" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { className: classes.pageTitle }, "Upload File"), React.createElement(Button, { onClick: function () { return closeModal(); }, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null)))), React.createElement(DialogContent, null, rawData.length > 0 ? liveResultView : uploadFileView), React.createElement(DialogActions, { style: { padding: 24 } }, React.createElement("div", { style: { display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" } }, rawData.length > 0 ? (React.createElement(Button, { component: "button", onClick: function () { return clearAll(); }, color: "primary", style: {
            textTransform: "none",
            color: "rgb(29, 154, 214)"
        } }, "Clear All")) : (React.createElement("div", null)), React.createElement(Button, { component: "button", onClick: function () { return onFileSubmitHandler(); }, color: "primary", style: {
            textTransform: "none",
            backgroundColor: LIGHT_BLUE,
            color: "white",
            minWidth: 150
        } }, "Upload")))));
}
