var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { call, put, select, takeEvery } from "redux-saga/effects";
import axios from "axios";
import moment from "moment";
import { firestore, auth } from "../../utils/firebaseConnector";
import { getFilteredDaysArray } from "../../utils/dateOperations";
import analyseNoMovement, { mapNoMovement } from "./analyseNoMovement";
import { fetchNoMovementActivityFailureAction, fetchNoMovementActivitySuccessAction, noMovementActivityActionTypes } from "./actions";
import { FIRESTORE_HISTORIES_SUB_COLLECTION } from "../../utils/firestoreCollections";
import { API_CLOUD } from "../../utils/apiEndPoints";
// eslint-disable-next-line @typescript-eslint/no-var-requires
var _ = require("lodash");
// ------------------------------------------------------saga-1----------------------------------------------------------
export function fetchNoMovementActivityFromApi(daysArray) {
    return __awaiter(this, void 0, Promise, function () {
        var promiseArray;
        return __generator(this, function (_a) {
            promiseArray = _.chunk(daysArray, 10).map(function (subArray) {
                return firestore
                    .collectionGroup(FIRESTORE_HISTORIES_SUB_COLLECTION)
                    .where("date", "in", subArray)
                    .get()
                    .then(function (querySnapshot) {
                    return querySnapshot.docs.map(function (doc) {
                        var _a;
                        var parentId = (_a = doc.ref.parent.parent) === null || _a === void 0 ? void 0 : _a.id;
                        var _b = doc.data(), date = _b.date, history = _b.history;
                        return {
                            parentId: parentId,
                            date: new Date(date),
                            history: history.filter(function (x) { return x.status === "MovingStarted" || x.status === "MovingStopped"; })
                        };
                    });
                })
                    .catch(function (e) {
                    throw e;
                });
            });
            return [2 /*return*/, Promise.all(promiseArray)
                    .then(function (values) {
                    return values.map(function (ob) { return ob; });
                })
                    .then(function (r) { return r.flatMap(function (e) { return e; }); })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callFetchNoMovementActivity(action) {
    var filteredDaysArray, userList, result, records, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                filteredDaysArray = getFilteredDaysArray(action.startDate, action.endDate, action.excludeDates, action.selectedWeekDays);
                return [4 /*yield*/, select(function (state) { return state.userManagement.userList; })];
            case 1:
                userList = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 7]);
                return [4 /*yield*/, call(fetchNoMovementActivityFromApi, filteredDaysArray)];
            case 3:
                result = _a.sent();
                records = analyseNoMovement(result, userList);
                return [4 /*yield*/, put(fetchNoMovementActivitySuccessAction(records))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_1 = _a.sent();
                return [4 /*yield*/, put(fetchNoMovementActivityFailureAction(e_1.toString()))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function fetchNoMovementActivityFromCloud(startDate, endDate) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var token, data;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, ((_b = (_a = auth) === null || _a === void 0 ? void 0 : _a.currentUser) === null || _b === void 0 ? void 0 : _b.getIdToken())];
                case 1:
                    token = _c.sent();
                    return [4 /*yield*/, axios.get(API_CLOUD + "/no-movement", {
                            params: {
                                start: moment(startDate).format("YYYY-MM-DD"),
                                end: moment(endDate).add(1, "days").format("YYYY-MM-DD")
                            },
                            headers: { Authorization: "Bearer " + token }
                        })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
function callFetchNoMovementActivityFromCloud(action) {
    var result, records, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(fetchNoMovementActivityFromCloud, action.startDate, action.endDate)];
            case 1:
                result = _a.sent();
                records = mapNoMovement(result);
                return [4 /*yield*/, put(fetchNoMovementActivitySuccessAction(records))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_2 = _a.sent();
                return [4 /*yield*/, put(fetchNoMovementActivityFailureAction(e_2.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function watchNoMovementActionSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY, callFetchNoMovementActivity)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(noMovementActivityActionTypes.FETCH_NO_MOVEMENT_ACTIVITY_CLOUD, callFetchNoMovementActivityFromCloud)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var noMovementActivitySagas = [watchNoMovementActionSagas];
