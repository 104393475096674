import { NotificationManager } from "react-notifications";
export var homePageActionTypes;
(function (homePageActionTypes) {
    homePageActionTypes["MARK_ATTENDANCE_CHECK_IN"] = "field-workforce-management/home/mark_attendance_check_in";
    homePageActionTypes["MARK_ATTENDANCE_CHECK_IN_SUCCESS"] = "field-workforce-management/home/mark_attendance_check_in_success";
    homePageActionTypes["MARK_ATTENDANCE_CHECK_IN_FAILURE"] = "field-workforce-management/home/mark_attendance_check_in_failure";
    homePageActionTypes["MARK_ATTENDANCE_CHECK_OUT"] = "field-workforce-management/home/mark_attendance_check_out";
    homePageActionTypes["MARK_ATTENDANCE_CHECK_OUT_SUCCESS"] = "field-workforce-management/home/mark_attendance_check_out_success";
    homePageActionTypes["MARK_ATTENDANCE_CHECK_OUT_FAILURE"] = "field-workforce-management/home/mark_attendance_check_out_failure";
    homePageActionTypes["GET_ATTENDANCE_STATUS"] = "field-workforce-management/home/get_attendance_status";
    homePageActionTypes["GET_ATTENDANCE_STATUS_SUCCESS"] = "field-workforce-management/home/get_attendance_status_success";
    homePageActionTypes["GET_ATTENDANCE_STATUS_FAILURE"] = "field-workforce-management/home/get_attendance_status_failure";
})(homePageActionTypes || (homePageActionTypes = {}));
export function markAttendanceCheckInAction(loggedInUserID, workType) {
    return {
        type: homePageActionTypes.MARK_ATTENDANCE_CHECK_IN,
        loggedInUserID: loggedInUserID,
        workType: workType
    };
}
export function markAttendanceCheckInSuccessAction() {
    return {
        type: homePageActionTypes.MARK_ATTENDANCE_CHECK_IN_SUCCESS
    };
}
export function markAttendanceCheckInFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("markAttendanceCheckInFailureAction", err);
    NotificationManager.error("Error occurred while marking attendance. Please try again later.", "Oops! Error");
    return {
        type: homePageActionTypes.MARK_ATTENDANCE_CHECK_IN_FAILURE,
        err: err
    };
}
export function markAttendanceCheckOutAction(loggedInUserID, workType) {
    return {
        type: homePageActionTypes.MARK_ATTENDANCE_CHECK_OUT,
        loggedInUserID: loggedInUserID,
        workType: workType
    };
}
export function markAttendanceCheckOutSuccessAction() {
    return {
        type: homePageActionTypes.MARK_ATTENDANCE_CHECK_OUT_SUCCESS
    };
}
export function markAttendanceCheckOutFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("markAttendanceCheckOutFailureAction", err);
    NotificationManager.error("Error occurred while marking checkout. Please try again later.", "Oops! Error");
    return {
        type: homePageActionTypes.MARK_ATTENDANCE_CHECK_OUT_FAILURE,
        err: err
    };
}
export function getAttendanceStatusAction(loggedInUserID) {
    return {
        type: homePageActionTypes.GET_ATTENDANCE_STATUS,
        loggedInUserID: loggedInUserID
    };
}
export function getAttendanceStatusSuccessAction(workType) {
    return {
        type: homePageActionTypes.GET_ATTENDANCE_STATUS_SUCCESS,
        workType: workType
    };
}
export function getAttendanceStatusFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("getAttendanceStatusFailureAction", err);
    // NotificationManager.error(`Error occurred while getting attendance state. Please try again later.`, `Oops! Error`);
    return {
        type: homePageActionTypes.GET_ATTENDANCE_STATUS_FAILURE,
        err: err
    };
}
