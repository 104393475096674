import { makeStyles } from "@material-ui/core/styles";
import { LIGHT_BLUE, PRIMARY_BLUE, PRIMARY_PINK } from "../../utils/colorCodes";

const drawerWidth = 277;

const useStyles = makeStyles((theme) => ({
  notificationIcon: {
    color: PRIMARY_BLUE
  },
  personIcon: {
    fontSize: "35px"
  },
  leftPanelSubMenu: {
    paddingLeft: 33,
    textAlign: "left",
    paddingTop: 30
  },
  leftPanelSubMenuTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: PRIMARY_BLUE,
    opacity: 0.6,
    marginBottom: 5
  },
  avatar: {
    fontSize: "25px",
    fontWeight: "bold",
    alignItems: "center",
    backgroundColor: PRIMARY_PINK
  },
  root: {
    display: "flex",
    backgroundColor: "white"
    // height: '100vh',
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  appBar: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: "white",
    boxShadow: "none"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: "white",
    boxShadow: "none"
  },
  title: {
    color: PRIMARY_BLUE,
    textAlign: "end",
    fontSize: 14
  },
  listMenuTitle: {
    color: PRIMARY_BLUE,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.429,
    textAlign: "left",
    marginTop: 30
  },
  listMenuSubTitle: {
    color: "#969696B1",
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.429,
    marginRight: 16,
    marginLeft: 30
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: PRIMARY_PINK,
    paddingBottom: 100
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto"
  },
  accessDeniedDialogTitle: {
    fontSize: 17,
    fontWeight: "bold",
    color: PRIMARY_BLUE
  },
  accessDeniedDialogLoadingIcon: {
    height: 50,
    width: 50
  },
  accessDeniedDialogBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    paddingTop: "45vh"
  },
  header: {
    paddingLeft: 33,
    textAlign: "left",
    marginTop: 30,
    marginBottom: 0
  },
  headerLogo: {
    display: "flex",
    alignItems: "center",
    marginTop: "-30px"
  },
  list: {
    padding: 0
  },
  icon: {
    width: 170,
    marginLeft: 13
  },
  dp: {
    width: 50
  },
  divider: {
    whiteSpace: "normal"
  },
  notificationMenu: {
    paddingLeft: 0,
    paddingRight: 0,
    whiteSpace: "normal",
    fontSize: 15,
    overflow: "auto",
    maxHeight: "100%"
  },
  notificationRow: {
    paddingLeft: 25,
    paddingRight: 25,
    width: 500
  },
  notificationMenuTitle: {
    fontSize: 24,
    fontWeight: "bold",
    paddingLeft: 25,
    paddingTop: 10,
    paddingBottom: 10,
    color: PRIMARY_BLUE
  },
  attendanceMenuTitle: {
    fontSize: 24,
    fontWeight: "bold",
    paddingLeft: 8,
    paddingTop: 10,
    paddingBottom: 10,
    color: PRIMARY_BLUE
  },
  notificationMenuBody: {
    marginTop: 45
  },
  typo: {
    color: PRIMARY_BLUE,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.25,
    textAlign: "left"
  },
  toggle: {
    color: PRIMARY_BLUE,
    paddingRight: 10
  },
  attendanceType: {
    alignSelf: "center",
    borderColor: LIGHT_BLUE,
    borderWidth: 2,
    boxShadow: "none",
    textTransform: "none",
    height: 40,
    width: 200,
    color: LIGHT_BLUE,
    fontSize: 16,
    fontWeight: 600,
    margin: 5
  },
  attendanceTypeChecked: {
    alignSelf: "center",
    boxShadow: "none",
    textTransform: "none",
    height: 40,
    width: 200,
    color: PRIMARY_PINK,
    fontSize: 16,
    fontWeight: 600,
    margin: 5
  }
}));

export default useStyles;
