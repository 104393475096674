var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put } from "redux-saga/effects";
import { getDistance } from "geolib";
import { fetchTableHistoryFailureAction, fetchTableHistorySuccessAction } from "../actions";
import { getDaysArray } from "../../../utils/dateOperations";
import { firestore } from "../../../utils/firebaseConnector";
import { FIRESTORE_HISTORIES_SUB_COLLECTION, FIRESTORE_LOCATIONS_SUB_COLLECTION } from "../../../utils/firestoreCollections";
// eslint-disable-next-line @typescript-eslint/no-var-requires
var _ = require("lodash");
var lastCoordinates = { latitude: undefined, longitude: undefined };
function getCalculatedDistance(newCoordinates) {
    var distance;
    if (lastCoordinates.latitude !== undefined &&
        lastCoordinates.longitude !== undefined &&
        newCoordinates.latitude !== undefined &&
        newCoordinates.longitude !== undefined) {
        distance = (getDistance(lastCoordinates, newCoordinates, 1) / 1000).toFixed(1).toString();
    }
    lastCoordinates = JSON.parse(JSON.stringify(newCoordinates));
    return distance;
}
function fetchFromLocations(daysArray) {
    return __awaiter(this, void 0, void 0, function () {
        var promiseArray;
        return __generator(this, function (_a) {
            promiseArray = _.chunk(daysArray, 10).map(function (subArray) {
                return firestore
                    .collectionGroup(FIRESTORE_LOCATIONS_SUB_COLLECTION)
                    .where("date", "in", subArray)
                    .get()
                    .then(function (querySnapshot) {
                    return querySnapshot.docs.map(function (doc) {
                        var _a;
                        var parentId = (_a = doc.ref.parent.parent) === null || _a === void 0 ? void 0 : _a.id;
                        var _b = doc.data(), date = _b.date, history = _b.history;
                        return {
                            parentId: parentId,
                            date: new Date(date),
                            history: history.sort(function (a, b) { return a.timestamp.seconds - b.timestamp.seconds; })
                        };
                    });
                })
                    .catch(function (e) {
                    throw e;
                });
            });
            return [2 /*return*/, Promise.all(promiseArray)
                    .then(function (values) {
                    return values.map(function (ob) { return ob; });
                })
                    .then(function (r) { return r.flatMap(function (e) { return e; }); })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function fetchFromHistories(daysArray) {
    return __awaiter(this, void 0, void 0, function () {
        var promiseArray;
        return __generator(this, function (_a) {
            promiseArray = _.chunk(daysArray, 10).map(function (subArray) {
                return firestore
                    .collectionGroup(FIRESTORE_HISTORIES_SUB_COLLECTION)
                    .where("date", "in", subArray)
                    .get()
                    .then(function (querySnapshot) {
                    return querySnapshot.docs.map(function (doc) {
                        var _a;
                        var parentId = (_a = doc.ref.parent.parent) === null || _a === void 0 ? void 0 : _a.id;
                        var _b = doc.data(), date = _b.date, history = _b.history;
                        return {
                            parentId: parentId,
                            date: new Date(date),
                            history: history.sort(function (a, b) { return a.timestamp.seconds - b.timestamp.seconds; })
                        };
                    });
                })
                    .catch(function (e) {
                    throw e;
                });
            });
            return [2 /*return*/, Promise.all(promiseArray)
                    .then(function (values) {
                    return values.map(function (ob) { return ob; });
                })
                    .then(function (r) { return r.flatMap(function (e) { return e; }); })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
export default function callFetchTableHistory(action) {
    var filteredDaysArray, resultOfHistories, mappedResultOfHistories_1, resultOfLocations, mappedResultOfLocations_1, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                filteredDaysArray = getDaysArray(action.startDate, action.endDate);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(fetchFromHistories, filteredDaysArray)];
            case 2:
                resultOfHistories = _a.sent();
                mappedResultOfHistories_1 = [];
                resultOfHistories.forEach(function (el) {
                    el.history.forEach(function (ei) {
                        var _a, _b, _c, _d, _e, _f, _g;
                        mappedResultOfHistories_1.push({
                            userDocumentID: el.parentId,
                            timeStamp: new Date(ei.timestamp.seconds * 1000),
                            location: {
                                lng: ((_a = ei.location) === null || _a === void 0 ? void 0 : _a.longitude) || 0,
                                lat: ((_b = ei.location) === null || _b === void 0 ? void 0 : _b.latitude) || 0
                            },
                            distance: undefined,
                            workType: (_c = ei.data) === null || _c === void 0 ? void 0 : _c.workType,
                            locationType: ei.status,
                            photo: (_d = ei.data) === null || _d === void 0 ? void 0 : _d.photo,
                            taskId: (_f = (_e = ei.data) === null || _e === void 0 ? void 0 : _e.task) === null || _f === void 0 ? void 0 : _f.id,
                            taskState: (_g = ei.data) === null || _g === void 0 ? void 0 : _g.state
                        });
                    });
                });
                return [4 /*yield*/, call(fetchFromLocations, filteredDaysArray)];
            case 3:
                resultOfLocations = _a.sent();
                mappedResultOfLocations_1 = [];
                resultOfLocations.forEach(function (el) {
                    lastCoordinates = { latitude: undefined, longitude: undefined };
                    el.history.forEach(function (ei) {
                        var _a, _b, _c, _d;
                        mappedResultOfLocations_1.push({
                            userDocumentID: el.parentId,
                            timeStamp: new Date(ei.timestamp.seconds * 1000),
                            location: {
                                lng: ((_a = ei.location) === null || _a === void 0 ? void 0 : _a.longitude) || 0,
                                lat: ((_b = ei.location) === null || _b === void 0 ? void 0 : _b.latitude) || 0
                            },
                            distance: getCalculatedDistance({
                                latitude: (_c = ei.location) === null || _c === void 0 ? void 0 : _c.latitude,
                                longitude: (_d = ei.location) === null || _d === void 0 ? void 0 : _d.longitude
                            })
                        });
                    });
                });
                return [4 /*yield*/, put(fetchTableHistorySuccessAction(mappedResultOfHistories_1, mappedResultOfLocations_1))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_1 = _a.sent();
                return [4 /*yield*/, put(fetchTableHistoryFailureAction(e_1.toString()))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
