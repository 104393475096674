var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, take, takeEvery, takeLatest } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { firestore } from "../../utils/firebaseConnector";
import { announcementsActionTypes, archiveAnnouncementFailureAction, archiveAnnouncementSuccessAction, createAnnouncementFailureAction, createAnnouncementSuccessAction, fetchAnnouncementFailureAction, fetchAnnouncementSuccessAction, fetchSingleAnnouncementFailureAction, fetchSingleAnnouncementSuccessAction } from "./actions";
import { announcementCreator, announcementMapper } from "../../utils/mapper/announcementMapper";
import { FIRESTORE_ANNOUNCEMENTS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../../utils/firestoreCollections";
// ------------------------------------------------------saga-1----------------------------------------------------------
function callFetchAnnouncements(action) {
    var startDate, endDate, ref, channel, data, result, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                startDate = action.startDate;
                endDate = action.endDate;
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 0);
                ref = firestore
                    .collection(FIRESTORE_ANNOUNCEMENTS_COLLECTION)
                    .where("timestamp", ">=", startDate)
                    .where("timestamp", "<=", endDate)
                    .where("archive", "==", false)
                    .orderBy("timestamp", "desc");
                channel = eventChannel(function (emit) { return ref.onSnapshot(emit); });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                _a.label = 2;
            case 2:
                if (!true)
                    return [3 /*break*/, 5];
                return [4 /*yield*/, take(channel)];
            case 3:
                data = _a.sent();
                result = data.docs.map(function (doc) {
                    return announcementMapper(doc);
                });
                return [4 /*yield*/, put(fetchAnnouncementSuccessAction(result))];
            case 4:
                _a.sent();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                err_1 = _a.sent();
                return [4 /*yield*/, put(fetchAnnouncementFailureAction(err_1.toString()))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-2----------------------------------------------------------
function createAnnouncementDocument(announcement) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_ANNOUNCEMENTS_COLLECTION)
                    .doc()
                    .set(announcementCreator(announcement))
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callCreateAnnouncement(action) {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(createAnnouncementDocument, action.announcement)];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(createAnnouncementSuccessAction())];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_1 = _a.sent();
                return [4 /*yield*/, put(createAnnouncementFailureAction(e_1.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
function archiveAnnouncementDocument(announcementID) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_ANNOUNCEMENTS_COLLECTION)
                    .doc(announcementID)
                    .set({
                    archive: true
                }, { merge: true })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callArchiveAnnouncement(action) {
    var e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(archiveAnnouncementDocument, action.announcement.documentID)];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(archiveAnnouncementSuccessAction())];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_2 = _a.sent();
                return [4 /*yield*/, put(archiveAnnouncementFailureAction(e_2.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
function callFetchSingleAnnouncementsDocument(announcementID) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_ANNOUNCEMENTS_COLLECTION)
                    .doc(announcementID)
                    .get()
                    .then(function (doc) {
                    var _a;
                    if (doc.exists && ((_a = doc.data()) === null || _a === void 0 ? void 0 : _a.archive) === false) {
                        return announcementMapper(doc);
                    }
                    // eslint-disable-next-line no-throw-literal
                    throw "announcement_does_not_exist:" + announcementID;
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function singleAnnouncementMarkAsRead(announcement, loggedInUserID) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_ANNOUNCEMENTS_COLLECTION)
                    .doc(announcement.documentID)
                    .set({
                    isRead: announcement.isRead
                        .concat(loggedInUserID)
                        .map(function (el) { return firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + el); })
                }, { merge: true })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callFetchSingleAnnouncement(action) {
    var result, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(callFetchSingleAnnouncementsDocument, action.announcementID)];
            case 1:
                result = _a.sent();
                if (!(result.isRead && !result.isRead.includes(action.loggedInUserID)))
                    return [3 /*break*/, 3];
                return [4 /*yield*/, call(singleAnnouncementMarkAsRead, result, action.loggedInUserID)];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [4 /*yield*/, put(fetchSingleAnnouncementSuccessAction(result))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_3 = _a.sent();
                return [4 /*yield*/, put(fetchSingleAnnouncementFailureAction(e_3.toString()))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getAnnouncementManagementSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(announcementsActionTypes.FETCH_ANNOUNCEMENTS, callFetchAnnouncements)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(announcementsActionTypes.FETCH_SINGLE_ANNOUNCEMENT, callFetchSingleAnnouncement)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(announcementsActionTypes.CREATE_ANNOUNCEMENT, callCreateAnnouncement)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(announcementsActionTypes.ARCHIVE_ANNOUNCEMENT, callArchiveAnnouncement)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var announcementsSagas = [getAnnouncementManagementSagas];
export default announcementsSagas;
