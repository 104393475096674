var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
import { FieldArray } from "formik";
import { Box, FormControl, Grid, IconButton, Link } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import * as React from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import useStyles from "../../styles/styles";
import { ERROR_HIGH, LIGHT_BLUE, PRIMARY_PINK } from "../../../../utils/colorCodes";
export default function GroupCriteriaSelector(props) {
    var _a = props.groupCriteria, groupCriteria = _a === void 0 ? [] : _a, errors = props.errors, touched = props.touched, setFieldValue = props.setFieldValue, setTouched = props.setTouched;
    var classes = useStyles();
    var userGroups = useSelector(function (state) { return state.userGroupManagement; }).userGroups;
    function checkGroupSelected(el, grp) {
        if (groupCriteria.length > 0) {
            if (el.id !== grp) {
                if (groupCriteria.includes(el.id)) {
                    return false;
                }
            }
        }
        return true;
    }
    function checkGroupCriteria(push) {
        var lastTask = groupCriteria.slice(-1);
        if (lastTask[0] !== "") {
            push("");
        }
    }
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement(FieldArray, { name: "criteria.groupCriteria", render: function (_a) {
            var remove = _a.remove, push = _a.push;
            return (React.createElement("div", null, groupCriteria.length > 0 &&
                groupCriteria
                    // .filter((el: UserGroup)=>checkGroupSelected(el))
                    .map(function (grp, ithElement) {
                    var _a;
                    return (React.createElement("div", { className: "row", key: ithElement }, React.createElement("div", { className: "col" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: {
                            backgroundColor: PRIMARY_PINK,
                            borderRadius: 4
                        } }, React.createElement(IconButton, { style: {
                            backgroundColor: "transparent"
                        }, disableRipple: true, type: "button", onClick: function () {
                            ithElement === 0 && push("");
                            remove(ithElement);
                        } }, React.createElement(CancelIcon, { style: { color: ERROR_HIGH } })), React.createElement(FormControl, { variant: "filled", className: classes.groupControl }, React.createElement(Autocomplete, { id: "criteria.locationCriteria." + ithElement, value: grp ? userGroups.filter(function (el) { return el.id === grp; })[0] : null, options: userGroups
                            .filter(function (el) { return !el.archive; })
                            .filter(function (el) {
                            return el.name !== "ALL USER" &&
                                el.name !== "ALCL" &&
                                el.name !== "DPMC" &&
                                el.name !== "DPL" &&
                                el.name !== "ACPL" &&
                                el.name !== "AIBL" &&
                                el.name !== "DPIN";
                        })
                            .filter(function (el) { return checkGroupSelected(el, grp); }), getOptionLabel: function (option) { return option.name || ""; }, onChange: function (_, value) { var _a; return setFieldValue("criteria.groupCriteria." + ithElement, ((_a = value) === null || _a === void 0 ? void 0 : _a.id) || ""); }, onBlur: function () {
                            var _a;
                            return setTouched((_a = {}, _a["criteria.groupCriteria." + ithElement] = true, _a));
                        }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true }), label: "Group", variant: "filled" }))); } }))), ((_a = errors.criteria) === null || _a === void 0 ? void 0 : _a.groupCriteria[ithElement]) && touched.criteria.groupCriteria ? (React.createElement("div", { className: classes.error }, errors.criteria.groupCriteria[ithElement])) : (React.createElement("div", { style: { marginBottom: 10 } })))));
                }), React.createElement(Link, { type: "button", component: "button", underline: "none", style: {
                    textTransform: "none",
                    color: LIGHT_BLUE,
                    fontWeight: "bold",
                    fontSize: 17
                }, onClick: function () { return checkGroupCriteria(push); } }, "+ Group")));
        } })));
}
