import * as React from "react";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";
import { LIGHT_BLUE } from "../../utils/colorCodes";
function getCsvBody(userList, roles, userGroups, skills, locations, fields) {
    return userList.map(function (user) {
        var _a, _b, _c, _d, _e, _f, _g;
        return [
            fields.id && user.uid,
            fields.name && user.name,
            fields.email && user.email,
            fields.telephone && ((_a = user) === null || _a === void 0 ? void 0 : _a.tp.join(", ")),
            fields.role && ((_b = roles.filter(function (el) { var _a; return el.id === ((_a = user) === null || _a === void 0 ? void 0 : _a.role); })[0]) === null || _b === void 0 ? void 0 : _b.name),
            fields.supervisor && ((_c = userList.filter(function (el) { var _a; return el.documentID === ((_a = user) === null || _a === void 0 ? void 0 : _a.supervisor); })[0]) === null || _c === void 0 ? void 0 : _c.name),
            fields.groups && ((_d = user) === null || _d === void 0 ? void 0 : _d.groups.map(function (el) { var _a; return (_a = userGroups.filter(function (g) { return g.id === el; })[0]) === null || _a === void 0 ? void 0 : _a.name; }).join(", ")),
            fields._skills && ((_e = user) === null || _e === void 0 ? void 0 : _e.skills.filter(function (el) { return el.skill !== ""; }).map(function (el) {
                var _a;
                return ((_a = skills.filter(function (s) { return s.id === el.skill; })[0]) === null || _a === void 0 ? void 0 : _a.name) + " (level: " + el.level + ")";
            }).join(", ")),
            fields._locations && ((_f = locations.filter(function (el) { var _a; return el.id === ((_a = user) === null || _a === void 0 ? void 0 : _a.location); })[0]) === null || _f === void 0 ? void 0 : _f.name),
            fields.interval && user.interval.toString() + " mins",
            fields.archive && (((_g = user) === null || _g === void 0 ? void 0 : _g.archive) ? "Archived" : "Active")
        ].filter(function (x) {
            return x !== false;
        });
    });
}
export function UsersCsvDownload(userList, roles, userGroups, skills, locations, fields) {
    var csvBody = getCsvBody(userList, roles, userGroups, skills, locations, fields);
    var csvData = [
        [
            fields.id && "Id",
            fields.name && "Name",
            fields.email && "Email",
            fields.telephone && "Telephone number",
            fields.role && "Role",
            fields.supervisor && "Supervisor",
            fields.groups && "Groups",
            fields._skills && "Skills",
            fields._locations && "Location",
            fields.interval && "Interval",
            fields.archive && "Archive"
        ].filter(function (x) {
            return x !== false;
        })
    ].concat(csvBody);
    var csvTitle = "Users.csv";
    var csvLink = useRef(null);
    return (React.createElement(React.Fragment, null, React.createElement(CSVLink, { data: csvData, filename: csvTitle, target: "_blank", ref: csvLink }), React.createElement(Button, { style: {
            borderColor: LIGHT_BLUE,
            borderWidth: 2,
            boxShadow: "none",
            textTransform: "none",
            height: 40,
            width: 160,
            color: LIGHT_BLUE,
            fontSize: 16,
            fontWeight: 600,
            marginTop: 10
        }, fullWidth: true,
        // @ts-ignore
        onClick: function () { return csvLink.current.link.click(); }, variant: "outlined" }, "Download CSV")));
}
