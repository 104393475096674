import * as React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import useStyles from "../../styles/styles";
// @ts-ignore
export default function FilterByOverdue(props) {
    var checkedOverDue = props.checkedOverDue, setOverDue = props.setOverDue;
    var classes = useStyles();
    return (React.createElement(FormControlLabel, { id: "overdue", className: classes.overDue, control: React.createElement(Checkbox, { checked: checkedOverDue, onChange: function (event) { return setOverDue(event.target.checked); }, name: "checkedOverDue", color: "default" }), label: "Overdue Task", labelPlacement: "start" }));
}
