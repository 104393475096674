var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { taskActionTypes } from "./actions";
var initialState = {
    fetchSingleTaskIsLoading: false,
    singleTask: undefined,
    cancellationRequest: undefined,
    supervisor: undefined,
    assignee: undefined,
    taskScheduleData: undefined,
    fetchSingleTaskError: { hasError: false, description: "" },
    fetchTaskIsLoading: true,
    taskList: [],
    fetchTaskError: { hasError: false, description: "" },
    createTaskApiIsLoading: false,
    createTaskApiSuccess: false,
    createTaskApiError: { hasError: false, description: "" },
    uploadTaskApiIsLoading: false,
    uploadTaskApiSuccess: false,
    uploadTaskApiError: { hasError: false, description: "" },
    updateTaskApiIsLoading: false,
    updateTaskApiSuccess: false,
    updateTaskApiError: { hasError: false, description: "" },
    changeTaskStatusIsLoading: false,
    changeTaskStatusSuccess: false,
    changeTaskStatusError: { hasError: false, description: "" },
    deleteTaskApiIsLoading: false,
    deleteTaskApiSuccess: false,
    deleteTaskApiError: { hasError: false, description: "" },
    nearByUsersIsLoading: false,
    nearByUsers: [],
    nearByUsersError: { description: "", hasError: false },
    lastSeenTimeIsLoading: false,
    lastSeenTime: undefined,
    lastStatus: undefined,
    workType: undefined,
    device: undefined,
    lastSeenTimeError: { description: "", hasError: false },
    taskFilter: {},
    nextUser: undefined
};
export default function taskReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case taskActionTypes.SET_TASK_FILTER: {
                draft.taskFilter = __assign(__assign({}, draft.taskFilter), action.taskFilter);
                break;
            }
            case taskActionTypes.RESET_TASK_FILTER: {
                draft.taskFilter = {};
                break;
            }
            case taskActionTypes.FETCH_TASK: {
                draft.fetchTaskIsLoading = true;
                break;
            }
            case taskActionTypes.FETCH_TASK_SUCCESS: {
                draft.taskList = action.taskList;
                draft.fetchTaskIsLoading = false;
                draft.fetchTaskError = { hasError: false, description: "" };
                break;
            }
            case taskActionTypes.FETCH_TASK_FAILURE: {
                draft.fetchTaskIsLoading = false;
                draft.fetchTaskError = { hasError: true, description: action.err };
                break;
            }
            case taskActionTypes.FETCH_SINGLE_TASK: {
                draft.singleTask = undefined;
                draft.cancellationRequest = undefined;
                draft.supervisor = undefined;
                draft.assignee = undefined;
                draft.taskScheduleData = undefined;
                draft.fetchSingleTaskIsLoading = true;
                break;
            }
            case taskActionTypes.FETCH_SINGLE_TASK_SUCCESS: {
                draft.singleTask = action.task;
                draft.cancellationRequest = action.cancellationRequest;
                draft.supervisor = action.supervisor;
                draft.assignee = action.assignee;
                draft.taskScheduleData = action.taskScheduleData;
                draft.fetchSingleTaskIsLoading = false;
                draft.fetchSingleTaskError = { hasError: false, description: "" };
                draft.nextUser = action.nextUser;
                break;
            }
            case taskActionTypes.FETCH_SINGLE_TASK_FAILURE: {
                draft.singleTask = undefined;
                draft.cancellationRequest = undefined;
                draft.supervisor = undefined;
                draft.assignee = undefined;
                draft.taskScheduleData = undefined;
                draft.fetchSingleTaskIsLoading = false;
                draft.fetchSingleTaskError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskActionTypes.CREATE_TASK_API: {
                draft.createTaskApiIsLoading = true;
                break;
            }
            case taskActionTypes.CREATE_TASK_API_SUCCESS: {
                draft.createTaskApiSuccess = true;
                draft.createTaskApiIsLoading = false;
                draft.createTaskApiError = { hasError: false, description: "" };
                break;
            }
            case taskActionTypes.CREATE_TASK_API_FAILURE: {
                draft.createTaskApiIsLoading = false;
                draft.createTaskApiError = { hasError: true, description: action.err };
                break;
            }
            case taskActionTypes.UPLOAD_TASK_API: {
                draft.uploadTaskApiIsLoading = true;
                break;
            }
            case taskActionTypes.UPLOAD_TASK_API_SUCCESS: {
                draft.uploadTaskApiSuccess = true;
                draft.uploadTaskApiIsLoading = false;
                draft.uploadTaskApiError = { hasError: false, description: "" };
                break;
            }
            case taskActionTypes.UPLOAD_TASK_API_FAILURE: {
                draft.uploadTaskApiIsLoading = false;
                draft.uploadTaskApiError = { hasError: true, description: action.err };
                break;
            }
            case taskActionTypes.UPDATE_TASK_API: {
                draft.updateTaskApiIsLoading = true;
                break;
            }
            case taskActionTypes.UPDATE_TASK_API_SUCCESS: {
                draft.updateTaskApiSuccess = true;
                draft.updateTaskApiIsLoading = false;
                draft.updateTaskApiError = { hasError: false, description: "" };
                break;
            }
            case taskActionTypes.UPDATE_TASK_API_FAILURE: {
                draft.updateTaskApiIsLoading = false;
                draft.updateTaskApiError = { hasError: true, description: action.err };
                break;
            }
            case taskActionTypes.CHANGE_TASK_STATUS_API: {
                draft.changeTaskStatusIsLoading = true;
                break;
            }
            case taskActionTypes.CHANGE_TASK_STATUS_API_SUCCESS: {
                draft.changeTaskStatusSuccess = true;
                draft.changeTaskStatusIsLoading = false;
                draft.changeTaskStatusError = { hasError: false, description: "" };
                break;
            }
            case taskActionTypes.CHANGE_TASK_STATUS_API_FAILURE: {
                draft.changeTaskStatusIsLoading = false;
                draft.changeTaskStatusError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case taskActionTypes.DELETE_TASK_API: {
                draft.deleteTaskApiIsLoading = true;
                break;
            }
            case taskActionTypes.DELETE_TASK_API_SUCCESS: {
                draft.deleteTaskApiSuccess = true;
                draft.deleteTaskApiIsLoading = false;
                draft.deleteTaskApiError = { hasError: false, description: "" };
                break;
            }
            case taskActionTypes.DELETE_TASK_API_FAILURE: {
                draft.deleteTaskApiIsLoading = false;
                draft.deleteTaskApiError = { hasError: true, description: action.err };
                break;
            }
            case taskActionTypes.FETCH_NEAR_BY_USERS: {
                draft.nearByUsersIsLoading = true;
                break;
            }
            case taskActionTypes.FETCH_NEAR_BY_USERS_SUCCESS: {
                draft.nearByUsersIsLoading = false;
                draft.nearByUsers = action.nearByUsers;
                draft.nearByUsersError = { hasError: false, description: "" };
                break;
            }
            case taskActionTypes.FETCH_NEAR_BY_USERS_FAILURE: {
                draft.nearByUsersIsLoading = false;
                draft.nearByUsersError = { hasError: true, description: action.err };
                break;
            }
            case taskActionTypes.FETCH_LAST_SEEN_TIME: {
                draft.lastSeenTimeIsLoading = true;
                break;
            }
            case taskActionTypes.FETCH_LAST_SEEN_TIME_SUCCESS: {
                draft.lastSeenTimeIsLoading = false;
                draft.lastSeenTime = action.lastSeenTime;
                draft.lastStatus = action.lastStatus;
                draft.workType = action.workType;
                draft.device = action.device;
                draft.lastSeenTimeError = { hasError: false, description: "" };
                break;
            }
            case taskActionTypes.FETCH_LAST_SEEN_TIME_FAILURE: {
                draft.lastSeenTimeIsLoading = false;
                draft.lastSeenTimeError = { hasError: true, description: action.err };
                break;
            }
            case taskActionTypes.RESET_TASK_LOADING_STATES: {
                draft.fetchTaskIsLoading = false;
                draft.createTaskApiSuccess = false;
                draft.updateTaskApiSuccess = false;
                draft.changeTaskStatusSuccess = false;
                draft.deleteTaskApiSuccess = false;
                draft.uploadTaskApiSuccess = false;
                draft.nearByUsers = [];
                draft.lastSeenTime = undefined;
                draft.lastStatus = undefined;
                draft.workType = undefined;
                break;
            }
            case taskActionTypes.RESET_SINGLE_TASK_STATES: {
                draft.singleTask = undefined;
                draft.cancellationRequest = undefined;
                draft.supervisor = undefined;
                draft.assignee = undefined;
                draft.taskScheduleData = undefined;
                break;
            }
            default:
                break;
        }
    });
}
