import { storageRef } from "../../../utils/firebaseConnector";
export default function firebaseUpload(taskID, imageAsFile, setImageAsUrl) {
    var uploadTask = storageRef.child("tasks/" + taskID + "/" + imageAsFile.name).put(imageAsFile);
    uploadTask.on("state_changed", function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
    }, function (error) {
        console.error("image upload error", error);
    }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            setImageAsUrl(downloadURL);
        });
    });
}
