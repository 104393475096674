import * as React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import useStyles from "../../styles/styles";
// @ts-ignore
export default function FilterByCascadeTask(props) {
    var checkedCascadeTasks = props.checkedCascadeTasks, setCascadeTasks = props.setCascadeTasks;
    var classes = useStyles();
    return (React.createElement(FormControlLabel, { id: "cascadeTasks", className: classes.overDue, control: React.createElement(Checkbox, { checked: checkedCascadeTasks, onChange: function (event) { return setCascadeTasks(event.target.checked); }, name: "cascadeTasks", color: "default" }), label: "Cascade Tasks", labelPlacement: "start" }));
}
