var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put } from "redux-saga/effects";
import { createSmartAlertApiSuccessAction, createSmartAlertApiFailureAction, fetchAlertAction } from "../actions";
import { getCurrentUserAuthorization } from "./index";
function createSmartAlertApi(alert, authToken) {
    return __awaiter(this, void 0, Promise, function () {
        var taskToSubmit, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    taskToSubmit = {
                        name: alert.name,
                        description: alert.description,
                        emailBody: alert.emailBody,
                        startAt: alert.startAt,
                        repeat: alert.preset,
                        dueAt: alert.dueAt,
                        overdue: alert.overdue,
                        overdueDays: alert.overdueDays,
                        taskTypes: alert.taskTypes,
                        allTaskTypes: alert.allTaskTypes,
                        groups: alert.groups,
                        allGroups: alert.allGroups,
                        priorities: alert.priorities,
                        allPriorities: alert.allPriorities,
                        recipientUsers: alert.recipientUsers,
                        recipientGroups: alert.recipientGroups,
                        allAssignees: alert.allAssignees,
                        allSupervisors: alert.allSupervisors,
                        allGrandSupervisors: alert.allGrandSupervisors,
                        ccUsers: alert.ccUsers,
                        ccDivAdmins: alert.ccDivAdmins,
                        ccTaskAdmins: alert.ccTaskAdmins,
                        ccAllAdmins: alert.ccAllAdmins,
                        ccSuperAdmins: alert.ccSuperAdmins,
                        sendIfEmpty: alert.sendIfEmpty,
                        fields: alert.fields,
                        allStates: alert.allStates,
                        states: alert.states
                    };
                    return [4 /*yield*/, fetch(process.env.API_BASE_URL + "/api/smart-alerts", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + authToken
                            },
                            body: JSON.stringify(taskToSubmit)
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
export default function callCreateSmartAlertApiSaga(action) {
    var authToken, result, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(getCurrentUserAuthorization)];
            case 1:
                authToken = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(createSmartAlertApi, action.alert, authToken)];
            case 3:
                result = _a.sent();
                if (!(result.status === 201))
                    return [3 /*break*/, 6];
                return [4 /*yield*/, put(createSmartAlertApiSuccessAction())];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(fetchAlertAction())];
            case 5:
                _a.sent();
                return [3 /*break*/, 7];
            case 6: throw JSON.stringify(result);
            case 7: return [3 /*break*/, 10];
            case 8:
                e_1 = _a.sent();
                console.error("error", e_1.toString());
                return [4 /*yield*/, put(createSmartAlertApiFailureAction(e_1.toString()))];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
