/* eslint-disable no-console */
import { NotificationManager } from "react-notifications";
export var alertActionTypes;
(function (alertActionTypes) {
    alertActionTypes["FETCH_ALERTS"] = "field-workforce-management/alerts/fetch_alerts";
    alertActionTypes["FETCH_ALERTS_SUCCESS"] = "field-workforce-management/alerts/fetch_alerts_success";
    alertActionTypes["FETCH_ALERTS_FAILURE"] = "field-workforce-management/alerts/fetch_alerts_failure";
    alertActionTypes["CREATE_SMART_ALERT_API"] = "field-workforce-management/tasks/create_smart_alert_api";
    alertActionTypes["CREATE_SMART_ALERT_API_SUCCESS"] = "field-workforce-management/tasks/create_smart_alert_api_success";
    alertActionTypes["CREATE_SMART_ALERT_API_FAILURE"] = "field-workforce-management/tasks/create_smart_alert_api_failure";
    alertActionTypes["UPDATE_SMART_ALERT_API"] = "field-workforce-management/tasks/update_smart_alert_api";
    alertActionTypes["UPDATE_SMART_ALERT_API_SUCCESS"] = "field-workforce-management/tasks/update_smart_alert_api_success";
    alertActionTypes["UPDATE_SMART_ALERT_API_FAILURE"] = "field-workforce-management/tasks/update_smart_alert_api_failure";
    alertActionTypes["DELETE_SMART_ALERT_API"] = "field-workforce-management/tasks/delete_smart_alert_api";
    alertActionTypes["DELETE_SMART_ALERT_API_SUCCESS"] = "field-workforce-management/tasks/delete_smart_alert_api_success";
    alertActionTypes["DELETE_SMART_ALERT_API_FAILURE"] = "field-workforce-management/tasks/delete_smart_alert_api_failure";
    alertActionTypes["CHANAGE_ENABLED_SMART_ALERT"] = "field-workforce-management/alerts/change_enabled_smart_alerts";
    alertActionTypes["CHANAGE_ENABLED_SMART_ALERT_SUCCESS"] = "field-workforce-management/alerts/change_enabled_smart_alerts_success";
    alertActionTypes["CHANAGE_ENABLED_SMART_ALERT_FAILURE"] = "field-workforce-management/alerts/change_enabled_smart_alerts_failure";
})(alertActionTypes || (alertActionTypes = {}));
// fetch all task
export function fetchAlertAction() {
    return {
        type: alertActionTypes.FETCH_ALERTS
    };
}
export function fetchAlertSuccessAction(alertList) {
    return {
        type: alertActionTypes.FETCH_ALERTS_SUCCESS,
        alertList: alertList
    };
}
export function fetchAlertFailureAction(err) {
    console.error("fetchAlertFailureAction", err);
    NotificationManager.error("Error occurred while fetching alerts. Please try again later.", "Oops! Error");
    return {
        type: alertActionTypes.FETCH_ALERTS_FAILURE,
        err: err
    };
}
// create task
export function createSmartAlertApiAction(alert) {
    return {
        type: alertActionTypes.CREATE_SMART_ALERT_API,
        alert: alert
    };
}
export function createSmartAlertApiSuccessAction() {
    NotificationManager.success("You have successfully created the smart alert");
    return {
        type: alertActionTypes.CREATE_SMART_ALERT_API_SUCCESS
    };
}
export function createSmartAlertApiFailureAction(err) {
    console.error("createSmartAlertFailureAction", err);
    NotificationManager.error("Error occurred while creating smart alert. Please try again later", "Oops! Error");
    return {
        type: alertActionTypes.CREATE_SMART_ALERT_API_FAILURE,
        err: err
    };
}
// update task
export function updateSmartAlertApiAction(alert) {
    return {
        type: alertActionTypes.UPDATE_SMART_ALERT_API,
        alert: alert
    };
}
export function updateSmartAlertApiSuccessAction() {
    NotificationManager.success("You have successfully updated the smart alert");
    return {
        type: alertActionTypes.UPDATE_SMART_ALERT_API_SUCCESS
    };
}
export function updateSmartAlertApiFailureAction(err) {
    console.error("updateSmartAlertFailureAction", err);
    NotificationManager.error("Error occurred while updating smart alert. Please try again later", "Oops! Error");
    return {
        type: alertActionTypes.UPDATE_SMART_ALERT_API_FAILURE,
        err: err
    };
}
// delete smart alert
export function deleteSmartAlertApiAction(alert) {
    return {
        type: alertActionTypes.DELETE_SMART_ALERT_API,
        alert: alert
    };
}
export function deleteSmartAlertApiSuccessAction() {
    NotificationManager.success("You have successfully deleted the smart alert");
    return {
        type: alertActionTypes.DELETE_SMART_ALERT_API_SUCCESS
    };
}
export function deleteSmartAlertApiFailureAction(err) {
    console.error("toggleArchiveSmartAlertFailureAction", err);
    NotificationManager.error("Error occurred while archiving smart alert. Please try again later.", "Oops! Error");
    return {
        type: alertActionTypes.DELETE_SMART_ALERT_API_FAILURE,
        err: err
    };
}
// change smart alert enabled boolean
export function changeEnabledSmartAlertAction(alert, enabled) {
    return {
        type: alertActionTypes.CHANAGE_ENABLED_SMART_ALERT,
        alert: alert,
        enabled: enabled
    };
}
export function changeEnabledSmartAlertSuccessAction() {
    NotificationManager.success("You have successfully enabled the smart alert");
    return {
        type: alertActionTypes.CHANAGE_ENABLED_SMART_ALERT_SUCCESS
    };
}
export function changeEnabledSmartAlertFailureAction(err) {
    console.error("changeEnabledSmartAlertFailureAction", err);
    NotificationManager.error("Error occurred while changing enable boolean. Please try again later.", "Oops! Error");
    return {
        type: alertActionTypes.CHANAGE_ENABLED_SMART_ALERT_FAILURE,
        err: err
    };
}
