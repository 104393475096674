export default function calculatePercentage(currentValue, previousValue) {
    var result = (100 * (currentValue - previousValue)) / previousValue;
    // console.log(currentValue, previousValue, result);
    if (isNaN(result)) {
        return 0;
    }
    if (result === Infinity) {
        return 100;
    }
    return result.toFixed(2);
}
