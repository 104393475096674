// @ts-ignore
import sha256 from "crypto-js/sha256";
export function isLatestPasswordOlderThanOneDay(lastPasswords) {
    if (lastPasswords.length > 0) {
        var latestPassword = lastPasswords
            .slice()
            .sort(function (a, b) { return a.timestamp - b.timestamp; })
            .reverse();
        return latestPassword[0].timestamp + 86400000 < new Date().getTime();
    }
    return true;
}
export function isCurrentPasswordOlderThan45Days(lastPasswords, isFirebaseUser) {
    if (lastPasswords.length > 0 && isFirebaseUser) {
        var latestPassword = lastPasswords
            .slice()
            .sort(function (a, b) { return a.timestamp - b.timestamp; })
            .reverse();
        return latestPassword[0].timestamp + 3888000000 < new Date().getTime();
    }
    return false;
}
export function isPasswordUsedWithin90Days(lastPasswords, newPassword) {
    var _a;
    var shaOfNewPassword = sha256(newPassword).toString();
    if (lastPasswords.length > 0) {
        var xx = (_a = lastPasswords.filter(function (el) { return el.password === shaOfNewPassword; })[0]) === null || _a === void 0 ? void 0 : _a.timestamp;
        if (xx) {
            return parseInt(xx) + 7776000000 > new Date().getTime();
        }
        return false;
    }
    return false;
}
