import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { Table } from "@material-ui/core";
import * as React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { useStyles } from "./styles/TableStyles";
import { StyledTableCell, StyledTableRow } from "./StyledTableComponent";
import TablePaginationFooter from "./TablePaginationFooter";
export function CustomTable(props) {
    var classes = useStyles();
    var headers = props.header;
    var rows = props.rows;
    var _a = React.useState(0), page = _a[0], setPage = _a[1];
    var _b = React.useState(10), rowsPerPage = _b[0], setRowsPerPage = _b[1];
    React.useEffect(function () {
        setPage(0);
    }, [rows]);
    var emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (React.createElement(React.Fragment, null, React.createElement(TableContainer, { component: Paper, style: { marginTop: 30 }, elevation: 0 }, React.createElement(Table, { className: classes.table, size: "small", "aria-label": "customized table" }, React.createElement(TableHead, null, React.createElement(TableRow, null, headers.map(function (header, index) {
        return (
        // @ts-ignore
        React.createElement(StyledTableCell, { className: classes.tableHeaderCell, align: header.alignment, key: index }, header.headerId));
    }))), React.createElement(TableBody, null, props.fetchTaskIsLoading ? (React.createElement(TableRow, { style: { height: 410 } }, React.createElement(StyledTableCell, { colSpan: 12, align: "center", style: { color: "gray" } }, React.createElement("div", { style: {
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100%",
            height: "100%"
        } }, React.createElement(PuffLoader, { size: 50, color: "#07456b", loading: true }))))) : (React.createElement(React.Fragment, null, rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(function (row, index) {
        return (React.createElement(StyledTableRow, { key: index }, row.map(function (cell, j) {
            return (
            // @ts-ignore
            React.createElement(StyledTableCell, { key: j, className: cell.className, align: cell.alignment }, cell.cell));
        })));
    }), emptyRows > 0 &&
        (rows.length === 0 ? (React.createElement(TableRow, { style: { height: 410 } }, React.createElement(StyledTableCell, { colSpan: 12, align: "center", style: { color: "gray" } }, "Table is empty"))) : (React.createElement(TableRow, { style: { height: 40 * (emptyRows % 10) } }, React.createElement(StyledTableCell, { colSpan: 12 }))))))))), React.createElement(TablePaginationFooter, { totalCount: rows.length, rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage, page: page, setPage: setPage })));
}
