import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Grid, IconButton, InputAdornment } from "@material-ui/core";
import { useHistory } from "react-router";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import useStyles from "./styles";
import dashboardIcon from "../../assets/images/dpgc.png";
import dpInfoTech from "../../assets/images/dpit.png";
import { setMaxLoginAttemptAction, signInWithCustomTokenAction, signInWithEmailAndPasswordAction } from "./action";
import UserContext from "./UserProvider";
import loadingIcon from "../../assets/images/loadingBox.gif";
import { LIGHT_BLUE, PRIMARY_BLUE } from "../../utils/colorCodes";
export var KEY_MAX_ATTEMPTS = "max_attempts_count_exceeded_time";
function Login() {
    var dispatch = useDispatch();
    var classes = useStyles();
    var history = useHistory();
    var user = useContext(UserContext);
    var _a = useSelector(function (state) { return state.loginManagement; }), loginAttempts = _a.loginAttempts, isSignIn = _a.isSignIn, signInWithEmailAndPasswordIsLoading = _a.signInWithEmailAndPasswordIsLoading, signInWithCustomTokenIsLoading = _a.signInWithCustomTokenIsLoading, signInWithEmailAndPasswordHasError = _a.signInWithEmailAndPasswordError.hasError, signInWithCustomTokenHasError = _a.signInWithCustomTokenError.hasError;
    useEffect(function () {
        if (isSignIn && !signInWithCustomTokenHasError && !signInWithCustomTokenHasError && user !== null) {
            history.push("/dashboard");
        }
    }, [history, isSignIn, signInWithCustomTokenHasError, user]);
    var yupSchema = Yup.object().shape({
        userId: Yup.string().required("User ID or Email is required"),
        password: Yup.string().required("Password is required")
    });
    var yupIsEmailSchema = Yup.string().email();
    var initialValue = {
        userId: "",
        password: ""
    };
    var _b = useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var isMaxAttemptsCountExceeded = loginAttempts >= 3;
    useEffect(function () {
        if (isMaxAttemptsCountExceeded) {
            try {
                localStorage.setItem(KEY_MAX_ATTEMPTS, String(new Date().getTime()));
            }
            catch (e) {
                //
            }
        }
    }, [isMaxAttemptsCountExceeded]);
    useEffect(function () {
        try {
            var timestamp = localStorage.getItem(KEY_MAX_ATTEMPTS);
            if (timestamp) {
                var number = parseInt(String(timestamp), 10);
                if (new Date().getTime() < number + 600000) {
                    // 10 min
                    dispatch(setMaxLoginAttemptAction());
                }
                else {
                    localStorage.removeItem(KEY_MAX_ATTEMPTS);
                }
            }
            // eslint-disable-next-line no-empty
        }
        catch (e) { }
    }, [dispatch]);
    return (React.createElement(Container, { component: "main", maxWidth: "xs" }, React.createElement("div", { className: classes.paper }, React.createElement("img", { id: "login-page-logo", src: dashboardIcon, alt: "Not Found", className: classes.logoStyle }), React.createElement(Typography, { id: "login-page-title", variant: "h4", className: classes.title }, "Field Workforce Management Software"), React.createElement("div", { className: classes.formBody }, React.createElement(Formik, { initialValues: initialValue, validationSchema: yupSchema, onSubmit: function (value) {
            yupIsEmailSchema
                .validate(value.userId)
                .then(function () { return dispatch(signInWithEmailAndPasswordAction(value.userId, value.password)); })
                .catch(function () { return dispatch(signInWithCustomTokenAction(value.userId, value.password)); });
        } }, function (_a) {
        var errors = _a.errors, touched = _a.touched, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, handleBlur = _a.handleBlur;
        return (React.createElement("form", { onSubmit: handleSubmit }, React.createElement(Grid, { container: true, justify: "center", style: { width: 300 } }, React.createElement(Grid, { item: true, xs: 12 }, React.createElement(TextField, { InputProps: { disableUnderline: true }, variant: "filled", required: true, fullWidth: true, onChange: handleChange, onBlur: handleBlur, name: "userId", label: "User ID or Email Address", id: "userId" }), errors.userId && touched.userId && (React.createElement("div", { id: "login-page-username-error", className: classes.error }, errors.userId))), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(TextField, { variant: "filled", required: true, fullWidth: true, onChange: handleChange, onBlur: handleBlur, name: "password", label: "Password", type: showPassword ? "text" : "password", id: "password", autoComplete: "current-password", InputProps: {
                disableUnderline: true,
                endAdornment: (React.createElement(InputAdornment, { position: "end" }, React.createElement(IconButton, { id: "login-page-visibility-icon", "aria-label": "toggle password visibility", onClick: function () { return setShowPassword(!showPassword); }, onMouseDown: function () { return setShowPassword(!showPassword); } }, showPassword ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null))))
            } }), errors.password && touched.password && (React.createElement("div", { id: "login-page-password-error", className: classes.error }, errors.password))), isMaxAttemptsCountExceeded && (React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(Typography, { id: "login-page-request-error", className: classes.requestError }, "Login is disabled for 10 minutes."))), (signInWithEmailAndPasswordHasError || signInWithCustomTokenHasError) &&
            !isMaxAttemptsCountExceeded && (React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(Typography, { id: "login-page-request-error", className: classes.requestError }, "Sorry, Your domain ID/email or password is incorrect. Please try again."))), React.createElement(Button, { disabled: signInWithCustomTokenIsLoading ||
                signInWithEmailAndPasswordIsLoading ||
                isMaxAttemptsCountExceeded, component: "button", style: {
                backgroundColor: LIGHT_BLUE,
                color: "white",
                fontSize: 16,
                fontWeight: 600,
                marginTop: 19,
                width: 300,
                height: 50,
                textTransform: "none",
                position: "relative",
                opacity: isMaxAttemptsCountExceeded ? 0.6 : 1
            }, type: "submit", fullWidth: true, variant: "contained", id: "login-page-btn",
            // @ts-ignore
            onClick: handleSubmit }, "Login", (signInWithCustomTokenIsLoading || signInWithEmailAndPasswordIsLoading) && (React.createElement("img", { id: "login-page-loading-icon", src: loadingIcon, alt: "Not Found", className: classes.loadingIcon }))))));
    })), React.createElement("div", { className: classes.footer }, React.createElement(Typography, { id: "login-page-footer-text", variant: "h5", className: classes.subTitle }, "POWERED BY"), React.createElement("img", { id: "login-page-footer-img", src: dpInfoTech, alt: "Not Found", className: classes.footerLogo })), React.createElement(Link, { to: { pathname: "/privacy", state: { from: "/login" } }, style: { fontSize: 15, color: PRIMARY_BLUE } }, "Privacy Policy"))));
}
export default Login;
