/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
import { FieldArray } from "formik";
import { Box, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Select } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import * as React from "react";
import { useSelector } from "react-redux";
import { ERROR_HIGH, LIGHT_BLUE, PRIMARY_PINK } from "../../../../utils/colorCodes";
import useStyles from "../../styles/styles";
export default function SkillCriteriaSelector(props) {
    var _a = props.skillCriteria, skillCriteria = _a === void 0 ? [] : _a, onChange = props.onChange, onBlur = props.onBlur, errors = props.errors, touched = props.touched;
    var classes = useStyles();
    var skills = useSelector(function (state) { return state.skillManagement; }).skills;
    function checkSkillSelected(el, skl) {
        if (skillCriteria.length > 0) {
            if (el.id != skl.skill) {
                for (var i = 0; i < skillCriteria.length; i++) {
                    if (skillCriteria[i].skill === el.id) {
                        return false;
                    }
                }
                return true;
            }
        }
        return true;
    }
    function checkSkillCriteria(push) {
        var lastTask = skillCriteria.slice(-1);
        if (lastTask[0].skill !== "") {
            push({ skill: "", level: 1 });
        }
    }
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement(FieldArray, { name: "criteria.skillCriteria", render: function (_a) {
            var remove = _a.remove, push = _a.push;
            return (React.createElement("div", null, skillCriteria.length > 0 &&
                skillCriteria.map(function (skl, ithElement) {
                    var _a;
                    return (React.createElement("div", { className: "col", key: ithElement }, React.createElement(Box, { display: "flex", flexDirection: "row" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: {
                            borderRadius: 4,
                            backgroundColor: PRIMARY_PINK,
                            flex: 1,
                            maxWidth: 238
                        } }, React.createElement(IconButton, { style: {
                            backgroundColor: "transparent"
                        }, disableRipple: true, type: "button", onClick: function () {
                            ithElement === 0 && push({ skill: "", level: 1 });
                            remove(ithElement);
                        } }, React.createElement(CancelIcon, { style: { color: ERROR_HIGH } })), React.createElement(FormControl, { variant: "filled", className: classes.groupControl }, React.createElement(InputLabel, { id: "filter-by-skills-label" }, "Skill"), React.createElement(Select, { disableUnderline: true, labelId: "filter-by-skills-label", id: "skills", value: skl.skill, name: "criteria.skillCriteria." + ithElement + ".skill", defaultValue: skl.skill, onChange: onChange, onBlur: onBlur, style: {
                            backgroundColor: PRIMARY_PINK,
                            textAlign: "left",
                            borderRadius: 4
                        } }, React.createElement(MenuItem, { value: "" }, React.createElement("em", null, "None")), skills
                        .filter(function (el) { return !el.archive; })
                        .filter(function (el) { return checkSkillSelected(el, skl); })
                        .map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id }, el.name)); })))), React.createElement(FormControl, { variant: "filled" }, React.createElement(InputLabel, { id: "create-user-skills-label" }, "Level"), React.createElement(Select, { disableUnderline: true, labelId: "create-user-skills-input", id: "skills", value: skl.level, name: "criteria.skillCriteria." + ithElement + ".level", defaultValue: skl.level, onChange: onChange, onBlur: onBlur, style: {
                            marginLeft: 10,
                            width: 105,
                            backgroundColor: PRIMARY_PINK,
                            textAlign: "left",
                            borderRadius: 4
                        } }, [
                        { name: "Low", id: 1 },
                        { name: "Medium", id: 2 },
                        { name: "High", id: 3 }
                    ].map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id }, el.name)); })))), ((_a = errors.criteria) === null || _a === void 0 ? void 0 : _a.skillCriteria[ithElement]) && touched.criteria.skillCriteria ? (React.createElement("div", { className: classes.error }, errors.criteria.skillCriteria[ithElement])) : (React.createElement("div", { style: { marginBottom: 10 } }))));
                }), React.createElement(Link, { type: "button", component: "button", underline: "none", style: {
                    textTransform: "none",
                    color: LIGHT_BLUE,
                    fontWeight: "bold",
                    fontSize: 17
                }, onClick: function () { return checkSkillCriteria(push); } }, "+ Skill")));
        } })));
}
