import { NotificationManager } from "react-notifications";
export var cancellationRequestActionTypes;
(function (cancellationRequestActionTypes) {
    cancellationRequestActionTypes["FETCH_CANCELLATION_REQUEST"] = "field-workforce-management/cancellation_requests/fetch_cancellation_request";
    cancellationRequestActionTypes["FETCH_CANCELLATION_REQUEST_SUCCESS"] = "field-workforce-management/cancellation_requests/fetch_cancellation_request_success";
    cancellationRequestActionTypes["FETCH_CANCELLATION_REQUEST_FAILURE"] = "field-workforce-management/cancellation_requests/fetch_cancellation_request_failure";
    cancellationRequestActionTypes["REJECT_REQUEST"] = "field-workforce-management/cancellation_requests/reject_request";
    cancellationRequestActionTypes["REJECT_REQUEST_SUCCESS"] = "field-workforce-management/cancellation_requests/reject_request_success";
    cancellationRequestActionTypes["REJECT_REQUEST_FAILURE"] = "field-workforce-management/cancellation_requests/reject_request_failure";
    cancellationRequestActionTypes["ACCEPT_REQUEST"] = "field-workforce-management/cancellation_requests/accept_request";
    cancellationRequestActionTypes["ACCEPT_REQUEST_SUCCESS"] = "field-workforce-management/cancellation_requests/accept_request_success_success";
    cancellationRequestActionTypes["ACCEPT_REQUEST_FAILURE"] = "field-workforce-management/cancellation_requests/accept_request_failure_failure";
})(cancellationRequestActionTypes || (cancellationRequestActionTypes = {}));
export function fetchCancellationRequestAction(uid) {
    return {
        type: cancellationRequestActionTypes.FETCH_CANCELLATION_REQUEST,
        uid: uid
    };
}
export function fetchCancellationRequestSuccessAction(tasks) {
    return {
        type: cancellationRequestActionTypes.FETCH_CANCELLATION_REQUEST_SUCCESS,
        tasks: tasks
    };
}
export function fetchCancellationRequestFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchCancellationRequestFailureAction", err);
    NotificationManager.error("Error occurred while loading cancellation requests. Please try again later.", "Oops! Error");
    return {
        type: cancellationRequestActionTypes.FETCH_CANCELLATION_REQUEST_FAILURE,
        err: err
    };
}
export function rejectCancellationRequestAction(cancellationRequest) {
    return {
        type: cancellationRequestActionTypes.REJECT_REQUEST,
        cancellationRequest: cancellationRequest
    };
}
export function rejectCancellationRequestSuccessAction() {
    NotificationManager.success("You have successfully rejected the cancellation request.");
    return {
        type: cancellationRequestActionTypes.REJECT_REQUEST_SUCCESS
    };
}
export function rejectCancellationRequestFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("rejectCancellationRequestFailureAction", err);
    NotificationManager.error("Error occurred while rejecting request. Please try again later.", "Oops! Error");
    return {
        type: cancellationRequestActionTypes.REJECT_REQUEST_FAILURE,
        err: err
    };
}
export function acceptCancellationRequestAction(cancellationRequest) {
    return {
        type: cancellationRequestActionTypes.ACCEPT_REQUEST,
        cancellationRequest: cancellationRequest
    };
}
export function acceptCancellationRequestSuccessAction() {
    // NotificationManager.success(`You have successfully accepted cancellation request.`);
    return {
        type: cancellationRequestActionTypes.ACCEPT_REQUEST_SUCCESS
    };
}
export function acceptCancellationRequestFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("acceptCancellationRequestFailureAction", err);
    NotificationManager.error("Error occurred while accepting request. Please try again later.", "Oops! Error");
    return {
        type: cancellationRequestActionTypes.ACCEPT_REQUEST_FAILURE,
        err: err
    };
}
