var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { FormControl, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { BarLoader } from "react-spinners";
import useStyles from "../../styles/styles";
import { fetchLastSeenTimeAction } from "../../redux/actions";
import getReadableStatus from "../../../../utils/getReadableStatus";
export default function UserSelector(props) {
    var id = props.id, label = props.label, defaultValue = props.defaultValue, setFieldValue = props.setFieldValue, setTouched = props.setTouched, errors = props.errors, touched = props.touched, showNearByUsers = props.showNearByUsers;
    var dispatch = useDispatch();
    var classes = useStyles();
    var userList = useSelector(function (state) { return state.userManagement; }).userList;
    var nearByUsers = useSelector(function (state) { return state.taskManagement; }).nearByUsers;
    var _a = useSelector(function (state) { return state.taskManagement; }), lastSeenTime = _a.lastSeenTime, lastStatus = _a.lastStatus, workType = _a.workType, lastSeenTimeIsLoading = _a.lastSeenTimeIsLoading, device = _a.device;
    var _b = React.useState(""), assignee = _b[0], setAssignee = _b[1];
    useEffect(function () {
        if (id === "assignedTo" && assignee.length > 0) {
            dispatch(fetchLastSeenTimeAction(assignee));
        }
    }, [assignee, dispatch, id]);
    var customUserList;
    if (showNearByUsers && id === "assignedTo") {
        customUserList = userList
            .filter(function (el) { return !el.archive; })
            .filter(function (el) { return nearByUsers.map(function (x) { return x.id; }).includes(el.documentID); });
    }
    else {
        customUserList = userList.filter(function (el) { return !el.archive; });
    }
    function getDistance(documentID) {
        var _a;
        var distance = (_a = nearByUsers.filter(function (el) { return el.id === documentID; })[0]) === null || _a === void 0 ? void 0 : _a.distance;
        return distance ? "(" + (distance / 1000).toFixed(1) + " km)" : "";
    }
    function getLastSeenTime() {
        if (lastSeenTime) {
            var currentDateTime = new Date();
            var timeDiff = currentDateTime.getTime() - lastSeenTime.getTime();
            if (timeDiff < 60000) {
                return "Just Now";
            }
            if (timeDiff >= 60000 && timeDiff < 3600000) {
                var number_1 = Math.floor(timeDiff / 60000);
                return number_1 + " " + (number_1 === 1 ? "minute ago" : "minutes ago");
            }
            if (timeDiff >= 3600000 && timeDiff < 86400000) {
                var number_2 = Math.floor(timeDiff / 3600000);
                return number_2 + " " + (number_2 === 1 ? "hour ago" : "hours ago");
            }
            if (timeDiff >= 86400000 && timeDiff < 2628000000) {
                var number_3 = Math.floor(timeDiff / 86400000);
                return number_3 + " " + (number_3 === 1 ? "day ago" : "days ago");
            }
            var number = Math.floor(timeDiff / 2628000000);
            return number + " " + (number === 1 ? "month ago" : "months ago");
        }
        return "Never";
    }
    function getColorCode() {
        if (!lastSeenTimeIsLoading && lastSeenTime) {
            var currentDateTime = new Date();
            var timeDiff = currentDateTime.getTime() - lastSeenTime.getTime();
            if (timeDiff < 86400000) {
                return "#1ECE35";
            }
            return "#FF3A2F";
        }
        return "darkorange";
    }
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement(FormControl, { className: classes.formControl100 }, React.createElement(Autocomplete, { id: id, options: customUserList, getOptionLabel: function (option) { return (option.name + " " + getDistance(option.documentID)).trim() || ""; }, defaultValue: userList.filter(function (el) { return el.documentID === defaultValue; })[0], onChange: function (_, value) {
            var _a, _b;
            setAssignee(((_a = value) === null || _a === void 0 ? void 0 : _a.documentID) || "");
            setFieldValue(id, ((_b = value) === null || _b === void 0 ? void 0 : _b.documentID) || "");
        }, onBlur: function () {
            var _a;
            return setTouched((_a = {}, _a[id] = true, _a));
        }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true }), label: label, variant: "filled" }))); } })), assignee.length > 0 && id === "assignedTo" && (React.createElement(React.Fragment, null, React.createElement("div", { style: { display: "flex", flexDirection: "row", alignItems: "center" } }, React.createElement(Typography, { style: {
            color: getColorCode(),
            paddingLeft: 13,
            paddingRight: 5,
            fontSize: 14
        } }, "Last seen:"), lastSeenTimeIsLoading ? (React.createElement(BarLoader, { height: 4, width: 50, color: getColorCode(), loading: true })) : (React.createElement(Typography, { style: {
            color: getColorCode(),
            fontSize: 14
        } }, getLastSeenTime()))), !lastSeenTimeIsLoading && device && (React.createElement("div", { style: { display: "flex", flexDirection: "row", alignItems: "center" } }, React.createElement(Typography, { style: {
            color: getColorCode(),
            paddingLeft: 13,
            paddingRight: 5,
            fontSize: 14
        } }, "Device:"), React.createElement(Typography, { style: {
            color: getColorCode(),
            fontSize: 14
        } }, getReadableStatus(device || "")))), !lastSeenTimeIsLoading && lastStatus && (React.createElement("div", { style: { display: "flex", flexDirection: "row", alignItems: "center" } }, React.createElement(Typography, { style: {
            color: getColorCode(),
            paddingLeft: 13,
            paddingRight: 5,
            fontSize: 14
        } }, "Last status:"), React.createElement(Typography, { style: {
            color: getColorCode(),
            fontSize: 14
        } }, getReadableStatus(lastStatus || "")))), !lastSeenTimeIsLoading && workType && (React.createElement("div", { style: { display: "flex", flexDirection: "row", alignItems: "center" } }, React.createElement(Typography, { style: {
            color: getColorCode(),
            paddingLeft: 13,
            paddingRight: 5,
            fontSize: 14
        } }, "Work type:"), React.createElement(Typography, { style: {
            color: getColorCode(),
            fontSize: 14
        } }, workType))))), errors[id] && touched[id] && React.createElement("div", { className: classes.error }, errors[id])));
}
