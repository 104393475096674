import { FormControl, MenuItem, Select } from "@material-ui/core";
import * as React from "react";
import { PRIMARY_BLUE } from "../utils/colorCodes";
import useStyles from "../views/Users/styles";
// @ts-ignore
export default function FilterByArchiveStatus(props) {
    var statusFilter = props.statusFilter, setStatusFilter = props.setStatusFilter;
    var classes = useStyles();
    return (React.createElement(FormControl, { className: classes.formStatusControl }, React.createElement(Select, { displayEmpty: true, disableUnderline: true, labelId: "filter-by-status-label", id: "filter-by-status", value: statusFilter, onChange: function (event) { return setStatusFilter(event.target.value); }, style: {
            textAlign: "left",
            color: PRIMARY_BLUE
        } }, React.createElement(MenuItem, { value: "", style: { color: PRIMARY_BLUE } }, "Filter by Status"), React.createElement(MenuItem, { value: "ACTIVE", style: { color: PRIMARY_BLUE } }, "Active"), React.createElement(MenuItem, { value: "ARCHIVED", style: { color: PRIMARY_BLUE } }, "Archived"))));
}
