import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { PRIMARY_BLUE } from "../../../../utils/colorCodes";
export default function SubTitle(props) {
    var subTitle = props.subTitle;
    return (React.createElement(Grid, { item: true, xs: 12 }, React.createElement(Typography, { style: {
            fontWeight: 500,
            fontSize: 14,
            color: PRIMARY_BLUE,
            marginLeft: 10,
            marginTop: 20
        } }, subTitle)));
}
