/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from "react-redux";
import Menu from "@material-ui/core/Menu";
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { markAttendanceCheckInAction, markAttendanceCheckOutAction } from "./actions";
import { workTypeEnum } from "./reducer";
export default function AttendanceTypeMenu(props) {
    var anchorElUserMenu = props.anchorElUserMenu, handleClose = props.handleClose, attendanceType = props.attendanceType;
    var dispatch = useDispatch();
    var classes = useStyles();
    var loggedInUserID = useSelector(function (state) { var _a; return (_a = state.loginManagement.loggedInUser) === null || _a === void 0 ? void 0 : _a.documentID; });
    if (!loggedInUserID) {
        // undefined case handle
        return React.createElement("div", null);
    }
    return (React.createElement(Menu, { style: { marginTop: 45, marginLeft: -115 }, id: "menu-user", anchorEl: anchorElUserMenu, open: Boolean(anchorElUserMenu), onClose: handleClose }, React.createElement("div", null, React.createElement("div", { style: {
            display: "flex",
            flexDirection: "column",
            width: 250,
            paddingLeft: 8,
            paddingRight: 8
        } }, React.createElement(Typography, { className: classes.attendanceMenuTitle }, attendanceType ? "I am leaving work" : "I am working from"), React.createElement(Button, { className: classes.attendanceType, style: { backgroundColor: "transparent" }, fullWidth: true, onClick: function () {
            setTimeout(function () {
                handleClose();
            }, 500);
            return attendanceType
                ? dispatch(markAttendanceCheckOutAction(loggedInUserID, workTypeEnum.HOME))
                : dispatch(markAttendanceCheckInAction(loggedInUserID, workTypeEnum.HOME));
        }, variant: "outlined" }, "Home"), React.createElement(Button, { className: classes.attendanceType, style: { backgroundColor: "transparent" }, fullWidth: true, onClick: function () {
            setTimeout(function () {
                handleClose();
            }, 500);
            return attendanceType
                ? dispatch(markAttendanceCheckOutAction(loggedInUserID, workTypeEnum.OFFICE))
                : dispatch(markAttendanceCheckInAction(loggedInUserID, workTypeEnum.OFFICE));
        }, variant: "outlined" }, "Office"), React.createElement(Button, { className: classes.attendanceType, style: { backgroundColor: "transparent" }, fullWidth: true, onClick: function () {
            setTimeout(function () {
                handleClose();
            }, 500);
            return attendanceType
                ? dispatch(markAttendanceCheckOutAction(loggedInUserID, workTypeEnum.FIELD))
                : dispatch(markAttendanceCheckInAction(loggedInUserID, workTypeEnum.FIELD));
        }, variant: "outlined" }, "Field")))));
}
