/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { noAttendanceActivityActionTypes } from "./actions";
var initialState = {
    activities: [],
    fetchNoAttendanceActivityIsLoading: false,
    fetchNoAttendanceActivityError: { hasError: false, description: "" }
};
export default function noAttendanceActivityReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case noAttendanceActivityActionTypes.FETCH_NO_ATTENDANCE_ACTIVITY: {
                draft.fetchNoAttendanceActivityIsLoading = true;
                break;
            }
            case noAttendanceActivityActionTypes.FETCH_NO_ATTENDANCE_ACTIVITY_SUCCESS: {
                draft.activities = action.activities;
                draft.fetchNoAttendanceActivityIsLoading = false;
                draft.fetchNoAttendanceActivityError = {
                    hasError: false,
                    description: ""
                };
                break;
            }
            case noAttendanceActivityActionTypes.FETCH_NO_ATTENDANCE_ACTIVITY_FAILURE: {
                draft.fetchNoAttendanceActivityIsLoading = false;
                draft.fetchNoAttendanceActivityError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            default:
                break;
        }
    });
}
