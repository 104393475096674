import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY_BLUE } from "../../utils/colorCodes";

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: "20vh",
    marginBottom: "20vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    marginTop: 19,
    color: PRIMARY_BLUE,
    fontSize: 14,
    fontWeight: 600,
    width: 180,
    lineHeight: 1.429
  },
  subTitle: {
    color: "#969696B1",
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.429,
    marginRight: 16
  },
  error: {
    color: "red",
    paddingLeft: 10,
    textAlign: "left",
    fontSize: 14
  },
  logoStyle: {
    width: 136
  },
  formBody: {
    marginTop: 20
  },
  loadingIcon: {
    height: 30,
    width: 30,
    position: "absolute",
    right: 10
  },
  footer: {
    display: "flex",
    alignItems: "center",
    marginTop: "36px"
  },
  footerLogo: {
    width: 64
  },
  requestError: {
    color: "red",
    fontWeight: 600
  }
}));

export default useStyles;
