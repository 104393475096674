import { TablePagination } from "@material-ui/core";
import * as React from "react";
// @ts-ignore
export default function TablePaginationFooter(props) {
    var totalCount = props.totalCount, rowsPerPage = props.rowsPerPage, setRowsPerPage = props.setRowsPerPage, page = props.page, setPage = props.setPage;
    return (React.createElement(TablePagination, { rowsPerPageOptions: [10, 50, 100], component: "div", count: totalCount, rowsPerPage: rowsPerPage, page: page, onChangePage: function (event, newPage) {
            setPage(newPage);
        }, onChangeRowsPerPage: function (e) {
            setRowsPerPage(e.target.value);
            setPage(0);
        } }));
}
