import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY_BLUE, PRIMARY_PINK } from "../../utils/colorCodes";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 640
  },
  formStatusControl: {
    width: 200,
    height: 40,
    marginRight: 15,
    marginTop: 10,
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    paddingTop: 5,
    paddingLeft: 10
  },
  formControl100: {
    width: "100%"
  },
  groupControl: {
    // margin: theme.spacing(1),
    width: "90%"
  },
  error: {
    color: "red",
    paddingLeft: 10
  },
  addMoreBtn: {
    height: "35px",
    marginTop: "-45px",
    "border-radius": "0",
    "border-left": "1px solid #00000047"
  },
  userDetailCardTitle: {
    fontSize: 15,
    color: PRIMARY_BLUE,
    fontWeight: "bold"
  },
  userDetailCardBody: {
    fontSize: 15,
    color: PRIMARY_BLUE
  },
  userDetailCard: {
    textAlign: "left",
    flex: 1,
    minWidth: 300,
    marginBottom: 40
  },
  tableCell: {
    color: PRIMARY_BLUE,
    fontSize: 14,
    fontWeight: 500
  },
  tableCellEnd: {
    color: PRIMARY_BLUE,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.25
  },
  tableHeaderCell: {
    color: PRIMARY_BLUE,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.429
  },
  pageTitle: {
    color: PRIMARY_BLUE,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 0.833,
    textAlign: "left",
    marginTop: 14
  },
  pageSubTitle: {
    color: PRIMARY_BLUE,
    fontSize: 10,
    fontWeight: 400,
    textAlign: "left",
    marginTop: 10
  },
  weekday: {
    backgroundColor: PRIMARY_PINK,
    borderRadius: 4,
    height: 56
  }
}));

export default useStyles;
