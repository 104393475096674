import { getFormattedDateTime, secondsToTime, secondsToTimeAbs } from "../../utils/dateOperations";
import { getGoogleLocationLink } from "../../utils/getGoogleLocationLink";
export default function analyseNoMovement(result, userList) {
    var records = [];
    result
        .filter(function (el) { return el.date.toLocaleString().slice(0, 9) === new Date().toLocaleString().slice(0, 9); })
        .forEach(function (el) {
        var _a, _b, _c, _d, _e;
        var element = (el.history || []).sort(function (a, b) { return b.timestamp.seconds - a.timestamp.seconds; })[0];
        if (((_a = element) === null || _a === void 0 ? void 0 : _a.status) === "MovingStopped") {
            records.push({
                userDocumentID: el.parentId,
                userGroups: (_b = userList.filter(function (ul) { return ul.documentID === el.parentId; })[0]) === null || _b === void 0 ? void 0 : _b.groups,
                time: "-",
                lastLocationTimeStamp: getFormattedDateTime(((_c = element.timestamp) === null || _c === void 0 ? void 0 : _c.seconds) * 1000 || 0),
                lastUpdateLocation: getGoogleLocationLink(((_d = element.location) === null || _d === void 0 ? void 0 : _d.latitude) || 0, ((_e = element.location) === null || _e === void 0 ? void 0 : _e.longitude) || 0)
            });
        }
    });
    result.forEach(function (el) {
        var movingStoppedFound;
        el.history
            .sort(function (a, b) { return a.timestamp.seconds - b.timestamp.seconds; })
            .forEach(function (smh) {
            var _a, _b, _c;
            if (smh.status === "MovingStopped") {
                smh.parentId = el.parentId;
                movingStoppedFound = smh;
            }
            if (movingStoppedFound !== undefined && smh.status === "MovingStarted") {
                records.push({
                    userDocumentID: el.parentId,
                    userGroups: (_a = userList.filter(function (ul) { return ul.documentID === el.parentId; })[0]) === null || _a === void 0 ? void 0 : _a.groups,
                    time: secondsToTime(smh.timestamp, movingStoppedFound.timestamp),
                    lastLocationTimeStamp: getFormattedDateTime(movingStoppedFound.timestamp.seconds * 1000),
                    lastUpdateLocation: getGoogleLocationLink(((_b = movingStoppedFound.location) === null || _b === void 0 ? void 0 : _b.latitude) || 0, ((_c = movingStoppedFound.location) === null || _c === void 0 ? void 0 : _c.longitude) || 0)
                });
                movingStoppedFound = undefined;
            }
        });
    });
    return records;
}
export function mapNoMovement(results) {
    return results.map(function (el) {
        return ({
            userDocumentID: el.userDocumentId,
            userGroups: el.userGroup,
            lastLocationTimeStamp: getFormattedDateTime(el.lastCoverageTime),
            // time: secondsToTimeAbs(el.time) || "-",
            time: el.time === "-" ? el.time : secondsToTimeAbs(el.time),
            lastUpdateLocation: getGoogleLocationLink(el.lastCoverageLocation[0] || 0, el.lastCoverageLocation[1] || 0)
        });
    });
}
