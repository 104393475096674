import * as React from "react";
import { Divider, FormControl, MenuItem, Select } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector } from "react-redux";
import { PRIMARY_BLUE } from "../../../../utils/colorCodes";
import useStyles from "../../styles/styles";
// @ts-ignore
export default function FilterByTaskStatus(props) {
    var statusFilter = props.statusFilter, setStatusFilter = props.setStatusFilter;
    var classes = useStyles();
    var taskStates = useSelector(function (state) { return state.taskStateManagement; }).taskStates;
    var _a = React.useState(false), isAllTypeSelected = _a[0], setAllTypeSelected = _a[1];
    return (React.createElement(FormControl, { className: classes.formStatusControl }, React.createElement(Select, { displayEmpty: true, disableUnderline: true, labelId: "filter-by-status-label", id: "filter-by-status", multiple: true, value: statusFilter,
        // onClose={() => dispatch(fetchTaskAction(statusFilter, taskTypeFilter, groupFilter, userFilter, searchText, sourceFilter, priorityFilter, startDate, endDate))}
        onChange: function (el) {
            var params = el.target.value;
            if (params.includes("all")) {
                if (params.length > statusFilter.length) {
                    setStatusFilter(["all"].concat(taskStates.map(function (ts) { return ts.id; })));
                    setAllTypeSelected(true);
                }
                else {
                    var index = params.indexOf("all");
                    params.splice(index, 1);
                    setStatusFilter(params);
                    setAllTypeSelected(false);
                }
            }
            else if (taskStates.length === params.length) {
                if (isAllTypeSelected) {
                    setStatusFilter([]);
                    setAllTypeSelected(false);
                }
                else {
                    setStatusFilter(["all"].concat(taskStates.map(function (ts) { return ts.id; })));
                    setAllTypeSelected(true);
                }
            }
            else {
                setStatusFilter(params);
            }
        }, input: React.createElement(Input, null), renderValue: function () { return "Filter by Status"; }, MenuProps: {
            PaperProps: {
                style: {
                    width: 220,
                    marginTop: -40
                }
            },
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            getContentAnchorEl: null
        }, style: {
            textAlign: "left",
            color: PRIMARY_BLUE
        } }, React.createElement(MenuItem, { value: "all", style: { color: PRIMARY_BLUE } }, React.createElement(Checkbox, { checked: statusFilter.includes("all") }), React.createElement(ListItemText, { primary: "Select All" })), React.createElement(Divider, { style: { marginLeft: 16, marginRight: 16, height: 2 } }), taskStates.map(function (el) {
        return (React.createElement(MenuItem, { key: el.id,
            /* disabled={statusFilter.includes("all")} */
            value: el.id, style: { color: PRIMARY_BLUE } }, React.createElement(Checkbox, { checked: statusFilter.includes(el.id) }), React.createElement(ListItemText, { primary: el.name })));
    }))));
}
