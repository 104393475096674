import * as React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import useStyles from "../views/Tasks/styles/styles";
import { PRIMARY_BLUE, PRIMARY_PINK, WHITE } from "../utils/colorCodes";
// @ts-ignore
export default function FilterBySource(props) {
    var sourceFilter = props.sourceFilter, setSourceFilter = props.setSourceFilter, hidden = props.hidden;
    var classes = useStyles();
    return (React.createElement(FormControl, { className: classes.formStatusControl, style: { backgroundColor: hidden ? WHITE : PRIMARY_PINK } }, React.createElement(Select, { disabled: hidden, displayEmpty: true, disableUnderline: true, labelId: "filter-by-source-label", id: "filter-by-source", value: sourceFilter, onChange: function (event) { return setSourceFilter(event.target.value); }, style: {
            textAlign: "left",
            color: PRIMARY_BLUE
        } }, React.createElement(MenuItem, { value: "", style: { color: PRIMARY_BLUE } }, "Filter by Source"), React.createElement(MenuItem, { value: "ERP", style: { color: PRIMARY_BLUE } }, "ERP"), React.createElement(MenuItem, { value: "NON_ERP", style: { color: PRIMARY_BLUE } }, "non ERP"))));
}
