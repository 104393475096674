function getTime(timeString) {
    var timeArray = timeString.split(/-| |:/).filter(function (el) { return el.length > 0; });
    try {
        var year = Number(timeArray[2].length === 2 ? "20" + timeArray[2] : "" + timeArray[2]);
        var month = Number(timeArray[1]);
        var day = Number(timeArray[0]);
        var hour = Number(timeArray[3]);
        var minute = Number(timeArray[4]);
        var dateTime = new Date(year + "-" + month + "-" + day + " " + hour + ":" + minute);
        if (dateTime.toString() === "Invalid Date") {
            return undefined;
        }
        return dateTime.getTime();
    }
    catch (e) {
        return undefined;
    }
}
function getPriority(priority) {
    var _a;
    switch ((_a = priority) === null || _a === void 0 ? void 0 : _a.toLowerCase()) {
        case "high":
            return 1;
        case "medium":
            return 2;
        case "low":
            return 3;
        default:
            return undefined;
    }
}
function getTravellingRequired(travellingRequired) {
    var _a;
    switch ((_a = travellingRequired) === null || _a === void 0 ? void 0 : _a.toLowerCase()) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            return undefined;
    }
}
function getSkillLevel(skillLevel) {
    var _a;
    switch ((_a = skillLevel) === null || _a === void 0 ? void 0 : _a.toLowerCase()) {
        case "low":
            return 1;
        case "medium":
            return 2;
        case "high":
            return 3;
        default:
            return undefined;
    }
}
var phoneRegExp = /^0[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;
var mapLinkRegEx = /(https|http):\/\/(www\.|)google\.[a-z]+\/maps/g;
export default function uploadTaskMapping(data, setTaskToUpload, loggedInUserId, taskTypes, locations, userList, userGroups, skills) {
    setTaskToUpload(data.map(function (el) {
        var _a, _b, _c, _d;
        try {
            var iMapLink = el["Location Link"].trim();
            var iLocation_1 = el.Location.trim();
            var iDescription = el["Short Description"].trim();
            var iSupervisor_1 = el.Supervisor.trim();
            var iAssignee_1 = el.Assignee.trim();
            var iTravellingRequired = getTravellingRequired(el["Travel Required"].trim());
            return {
                type: ((_a = taskTypes.filter(function (x) { return x.name === el["Task Type"].trim(); })[0]) === null || _a === void 0 ? void 0 : _a.id) || "ERROR",
                address: el.Address.trim(),
                location: iLocation_1.length > 0 ? ((_b = locations.filter(function (x) { return x.name === iLocation_1; })[0]) === null || _b === void 0 ? void 0 : _b.id) || "ERROR" : "",
                mapLink: iMapLink.length > 0 ? (iMapLink.match(mapLinkRegEx) ? iMapLink : "ERROR") : "",
                contacts: el["Telephone Number"]
                    .split(", ")
                    .map(function (x) {
                    var s = x.trim();
                    return s.length === 9 ? "0" + s : s;
                })
                    .filter(function (x) { return x.length > 0; })
                    .map(function (x) {
                    if (x.match(phoneRegExp)) {
                        return x;
                    }
                    return "ERROR";
                }),
                description: iDescription.length > 0 ? iDescription : "ERROR",
                refID: el["Ref ID"].trim(),
                start: getTime(el["Start Date & Time"].trim()) || "ERROR",
                due: getTime(el["Due Date & Time"].trim()) || "ERROR",
                supervisor: iSupervisor_1.length > 0
                    ? ((_c = userList.filter(function (x) { return x.name.trim() === iSupervisor_1; })[0]) === null || _c === void 0 ? void 0 : _c.documentID) || "ERROR"
                    : "",
                user: iAssignee_1.length > 0 ? ((_d = userList.filter(function (x) { return x.name.trim() === iAssignee_1; })[0]) === null || _d === void 0 ? void 0 : _d.documentID) || "ERROR" : "",
                priority: getPriority(el.Priority.trim()) || "ERROR",
                travellingRequired: iTravellingRequired !== undefined ? iTravellingRequired : "ERROR",
                allocation: {
                    groups: el["Group Criteria"]
                        .split(", ")
                        .map(function (x) { return x.trim(); })
                        .filter(function (x) { return x.length > 0; })
                        .map(function (x) { var _a; return ((_a = userGroups.filter(function (ug) { return ug.name === x.trim(); })[0]) === null || _a === void 0 ? void 0 : _a.id) || "ERROR"; }),
                    skills: el["Skill Criteria"]
                        .split(", ")
                        .map(function (x) { return x.trim(); })
                        .filter(function (x) { return x.length > 0; })
                        .map(function (x) {
                        var _a;
                        var eachSkill = x.trim();
                        var eachSkillAsArray = eachSkill.split(":");
                        return {
                            skill: ((_a = skills.filter(function (sk) { return sk.name === eachSkillAsArray[0].trim(); })[0]) === null || _a === void 0 ? void 0 : _a.id) || "ERROR",
                            level: getSkillLevel(eachSkillAsArray[1])
                        };
                    }),
                    location: el["Location Criteria"]
                        .split(", ")
                        .map(function (x) { return x.trim(); })
                        .filter(function (x) { return x.length > 0; })
                        .map(function (x) { var _a; return ((_a = locations.filter(function (l) { return l.name === x.trim(); })[0]) === null || _a === void 0 ? void 0 : _a.id) || "ERROR"; })
                },
                createdBy: loggedInUserId
            };
        }
        catch (e) {
            return {
                type: "ERROR",
                address: "ERROR",
                location: "ERROR",
                mapLink: "ERROR",
                contacts: "ERROR",
                description: "ERROR",
                refID: "ERROR",
                start: "ERROR",
                due: "ERROR",
                supervisor: "ERROR",
                user: "ERROR",
                priority: "ERROR",
                travellingRequired: "ERROR",
                allocation: {
                    groups: "ERROR",
                    skills: "ERROR",
                    location: "ERROR"
                },
                createdBy: loggedInUserId
            };
        }
    }));
}
