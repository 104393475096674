import { NotificationManager } from "react-notifications";
export var noCoverageActivityActionTypes;
(function (noCoverageActivityActionTypes) {
    noCoverageActivityActionTypes["FETCH_NO_COVERAGE_ACTIVITY"] = "field-workforce-management/no_coverage/fetch_no_coverage_activity";
    noCoverageActivityActionTypes["FETCH_NO_COVERAGE_ACTIVITY_SUCCESS"] = "field-workforce-management/no_coverage/fetch_no_coverage_activity_success";
    noCoverageActivityActionTypes["FETCH_NO_COVERAGE_ACTIVITY_FAILURE"] = "field-workforce-management/no_coverage/fetch_no_coverage_activity_failure";
    noCoverageActivityActionTypes["FETCH_NO_COVERAGE_ACTIVITY_CLOUD"] = "field-workforce-management/no_coverage/fetch_no_coverage_activity_cloud";
    noCoverageActivityActionTypes["FETCH_NO_COVERAGE_ACTIVITY_SUCCESS_CLOUD"] = "field-workforce-management/no_coverage/fetch_no_coverage_activity_success_cloud";
    noCoverageActivityActionTypes["FETCH_NO_COVERAGE_ACTIVITY_FAILURE_CLOUD"] = "field-workforce-management/no_coverage/fetch_no_coverage_activity_failure_cloud";
})(noCoverageActivityActionTypes || (noCoverageActivityActionTypes = {}));
export function fetchNoCoverageActivityAction(startDate, endDate, selectedWeekDays, excludeDates) {
    return {
        type: noCoverageActivityActionTypes.FETCH_NO_COVERAGE_ACTIVITY,
        startDate: startDate,
        endDate: endDate,
        selectedWeekDays: selectedWeekDays,
        excludeDates: excludeDates
    };
}
export function fetchNoCoverageActivityCloudAction(startDate, endDate) {
    return {
        type: noCoverageActivityActionTypes.FETCH_NO_COVERAGE_ACTIVITY_CLOUD,
        startDate: startDate,
        endDate: endDate
    };
}
export function fetchNoCoverageActivitySuccessAction(activities) {
    return {
        type: noCoverageActivityActionTypes.FETCH_NO_COVERAGE_ACTIVITY_SUCCESS,
        activities: activities
    };
}
export function fetchNoCoverageActivityFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchNoCoverageActivityFailureAction", err);
    NotificationManager.error("Error occurred while loading No Coverage Activity. Please try again later.", "Oops! Error");
    return {
        type: noCoverageActivityActionTypes.FETCH_NO_COVERAGE_ACTIVITY_FAILURE,
        err: err
    };
}
