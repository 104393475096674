/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { NotificationManager } from "react-notifications";
export var skillsActionTypes;
(function (skillsActionTypes) {
    skillsActionTypes["FETCH_SKILLS"] = "field-workforce-management/skills/fetch_skills";
    skillsActionTypes["FETCH_SKILLS_SUCCESS"] = "field-workforce-management/skills/fetch_skills_success";
    skillsActionTypes["FETCH_SKILLS_FAILURE"] = "field-workforce-management/skills/fetch_skills_failure";
    skillsActionTypes["CREATE_SKILL"] = "field-workforce-management/skills/add_new_skill";
    skillsActionTypes["CREATE_SKILL_SUCCESS"] = "field-workforce-management/skills/add_new_skill_success";
    skillsActionTypes["CREATE_SKILL_FAILURE"] = "field-workforce-management/skills/add_new_skill_failure";
    skillsActionTypes["UPDATE_SKILL"] = "field-workforce-management/skills/update_skill";
    skillsActionTypes["UPDATE_SKILL_SUCCESS"] = "field-workforce-management/skills/update_skill_success";
    skillsActionTypes["UPDATE_SKILL_FAILURE"] = "field-workforce-management/skills/update_skill_failure";
    skillsActionTypes["TOGGLE_ARCHIVE_SKILL"] = "field-workforce-management/skills/toggle_skill";
    skillsActionTypes["TOGGLE_ARCHIVE_SKILL_SUCCESS"] = "field-workforce-management/skills/toggle_skill_success";
    skillsActionTypes["TOGGLE_ARCHIVE_SKILL_FAILURE"] = "field-workforce-management/skills/toggle_skill_failure";
    skillsActionTypes["RESET_SUCCESS_STATE"] = "field-workforce-management/skills/reset_success_state";
})(skillsActionTypes || (skillsActionTypes = {}));
export function fetchSkillsAction() {
    return {
        type: skillsActionTypes.FETCH_SKILLS
    };
}
export function fetchSkillsSuccessAction(skills) {
    return {
        type: skillsActionTypes.FETCH_SKILLS_SUCCESS,
        skills: skills
    };
}
export function fetchSkillsFailureAction(err) {
    console.error("fetchSkillsFailureAction", err);
    NotificationManager.error("Error occurred while fetching skills. Please try again later.", "Oops! Error");
    return {
        type: skillsActionTypes.FETCH_SKILLS_FAILURE,
        err: err
    };
}
export function createSkillAction(skill) {
    return {
        type: skillsActionTypes.CREATE_SKILL,
        skill: skill
    };
}
export function createSkillSuccessAction(name) {
    NotificationManager.success("You have successfully created " + name);
    return {
        type: skillsActionTypes.CREATE_SKILL_SUCCESS
    };
}
export function createSkillFailureAction(err) {
    console.error("createSkillFailureAction", err);
    NotificationManager.error("Error occurred while adding new skill. Please try again later.", "Oops! Error");
    return {
        type: skillsActionTypes.CREATE_SKILL_FAILURE,
        err: err
    };
}
export function updateSkillAction(skill) {
    return {
        type: skillsActionTypes.UPDATE_SKILL,
        skill: skill
    };
}
export function updateSkillSuccessAction(name) {
    NotificationManager.success("You have successfully updated " + name);
    return {
        type: skillsActionTypes.UPDATE_SKILL_SUCCESS
    };
}
export function updateSkillFailureAction(err) {
    console.error("updateSkillFailureAction", err);
    NotificationManager.error("Error occurred while updating skill. Please try again later.", "Oops! Error");
    return {
        type: skillsActionTypes.UPDATE_SKILL_FAILURE,
        err: err
    };
}
export function toggleSkillArchiveAction(skill) {
    return {
        type: skillsActionTypes.TOGGLE_ARCHIVE_SKILL,
        skill: skill
    };
}
export function toggleSkillArchiveSuccessAction(name, isArchived) {
    if (isArchived) {
        NotificationManager.success("You have successfully archived " + name);
    }
    else {
        NotificationManager.success("You have successfully activated " + name);
    }
    return {
        type: skillsActionTypes.TOGGLE_ARCHIVE_SKILL_SUCCESS
    };
}
export function toggleSkillArchiveFailureAction(err) {
    console.error("toggleSkillArchiveFailureAction", err);
    NotificationManager.error("Error occurred while archiving the skill. Please try again later.", "Oops! Error");
    return {
        type: skillsActionTypes.TOGGLE_ARCHIVE_SKILL_FAILURE,
        err: err
    };
}
export function resetSkillStatesAction() {
    return {
        type: skillsActionTypes.RESET_SUCCESS_STATE
    };
}
