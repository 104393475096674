export default function sortedUserFilter(userList, statusFilter, groupFilter, roleFilter, searchText, skillFilter) {
    var filteredUserList = userList.filter(function (el) {
        var _a;
        var isValid = true;
        if (statusFilter.length !== 0) {
            if (statusFilter === "ARCHIVED") {
                isValid = isValid && el.archive;
            }
            else {
                isValid = isValid && !el.archive;
            }
        }
        if (groupFilter.length !== 0) {
            isValid = isValid && ((_a = el.groups) === null || _a === void 0 ? void 0 : _a.includes(groupFilter));
        }
        if (roleFilter.length !== 0) {
            isValid = isValid && el.role === roleFilter;
        }
        if (searchText.length !== 0) {
            isValid = isValid && Object.values(el).toString().toLowerCase().includes(searchText.toLowerCase());
        }
        if (skillFilter.length !== 0) {
            isValid = isValid && el.skills.map(function (s) { return s.skill; }).includes(skillFilter);
        }
        return isValid;
    });
    return filteredUserList
        .filter(function (user) { return !user.archive; })
        .concat(filteredUserList.filter(function (user) { return user.archive; }));
}
