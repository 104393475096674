/* eslint-disable react/jsx-no-duplicate-props */
import { FormControl, Menu, MenuItem } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import useStyles from "../styles/styles";
import { convertUTCDateToLocalDate } from "../../../utils/dateOperations";
import RepeatOptionWeekDaySelector from "./RepeatOptionWeekDaySelector";
import repeatMenu from "../utils/repeatMenuOption";
export default function RepeatOptionMenu(props) {
    var repeatOption = props.repeatOption, setRepeatOption = props.setRepeatOption, selectedWeekDays = props.selectedWeekDays, setSelectedWeekDays = props.setSelectedWeekDays, prior = props.prior, setPrior = props.setPrior, endDate = props.endDate, setEndDate = props.setEndDate;
    var classes = useStyles();
    var _a = React.useState(null), anchorElRepeatOptions = _a[0], setAnchorElRepeatOptions = _a[1];
    var handleCloseRepeatOption = function () {
        setAnchorElRepeatOptions(null);
    };
    var _b = React.useState(!endDate), doesNotEnd = _b[0], setDoesNotEnd = _b[1];
    var _c = React.useState(false), priorAvailable = _c[0], setPriorAvailable = _c[1];
    useEffect(function () {
        if (repeatOption === "weekly" ||
            repeatOption === "monthly-1" ||
            repeatOption === "monthly-2" ||
            repeatOption === "annually") {
            setPrior(1);
            setPriorAvailable(true);
        }
        else {
            setPrior(-1);
            setPriorAvailable(false);
        }
    }, [repeatOption, setPrior]);
    var _d = React.useState(""), priorError = _d[0], setPriorError = _d[1];
    useEffect(function () {
        if (repeatOption === "weekly" && (prior > 7 || prior < 1)) {
            setPriorError("Enter value between 1 and 7");
        }
        else if (repeatOption === "monthly-1" && (prior > 30 || prior < 1)) {
            setPriorError("Enter value between 1 and 30");
        }
        else if (repeatOption === "monthly-2" && (prior > 30 || prior < 1)) {
            setPriorError("Enter value between 1 and 30");
        }
        else if (repeatOption === "annually" && (prior > 365 || prior < 1)) {
            setPriorError("Enter value between 1 and 365");
        }
        else {
            setPriorError("");
        }
    }, [prior, repeatOption]);
    var _e = React.useState(false), visibleFromThePreviousDueDate = _e[0], setVisibleFromThePreviousDueDate = _e[1];
    useEffect(function () {
        if (visibleFromThePreviousDueDate) {
            setPrior(-1);
        }
        else {
            setPrior(1);
        }
    }, [setPrior, visibleFromThePreviousDueDate]);
    useEffect(function () {
        if (repeatOption && repeatOption !== "custom") {
            setSelectedWeekDays({
                checkedMonday: false,
                checkedTuesday: false,
                checkedWednesday: false,
                checkedThursday: false,
                checkedFriday: false,
                checkedSaturday: false,
                checkedSunday: false
            });
        }
    }, [repeatOption, setSelectedWeekDays]);
    var di = new Date();
    var predictedDate = new Date(di.setDate(di.getDate() + 1)).getTime();
    return (React.createElement(React.Fragment, null, React.createElement("div", { className: classes.formControl100 }, React.createElement("div", { className: "MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth" }, React.createElement("label", { className: "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-filled MuiFormLabel-filled", id: "repeat-label" }, "Repeat"), React.createElement("div", { onClick: function (event) { return setAnchorElRepeatOptions(event.currentTarget); }, style: {
            height: 56,
            padding: "27px 12px 10px",
            cursor: "pointer"
        }, className: "MuiInputBase-root MuiFilledInput-root MuiInputBase-fullWidth MuiInputBase-formControl" }, React.createElement("label", { style: { cursor: "pointer" } }, repeatMenu[repeatOption]), React.createElement("svg", { className: "MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled", focusable: "false", viewBox: "0 0 24 24", "aria-hidden": "true" }, React.createElement("path", { d: "M7 10l5 5 5-5z" }))))), React.createElement(Menu, { style: {}, id: "menu-repeat", anchorEl: anchorElRepeatOptions, open: Boolean(anchorElRepeatOptions), onClose: handleCloseRepeatOption, PaperProps: {
            style: {
                minWidth: 353
            }
        } }, React.createElement("div", { style: {
            display: "flex",
            flexDirection: "row",
            padding: 0
        } }, React.createElement(RepeatOptionWeekDaySelector, { repeatOption: repeatOption, selectedWeekDays: selectedWeekDays, setSelectedWeekDays: setSelectedWeekDays, handleCloseRepeatOption: handleCloseRepeatOption }), React.createElement("div", { style: {
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid lightgray",
            paddingLeft: "10px",
            width: "100%",
            paddingRight: "10px"
        } }, React.createElement(MenuItem, { onClick: function () {
            handleCloseRepeatOption();
            setRepeatOption("none");
        }, value: "none" }, React.createElement("em", null, repeatMenu.none)), React.createElement(MenuItem, { onClick: function () {
            handleCloseRepeatOption();
            setRepeatOption("daily");
        }, value: "daily" }, repeatMenu.daily), React.createElement(MenuItem, { onClick: function () {
            handleCloseRepeatOption();
            setRepeatOption("mon-fri");
        }, value: "mon-fri" }, repeatMenu["mon-fri"]), React.createElement(MenuItem, { onClick: function () {
            handleCloseRepeatOption();
            setRepeatOption("mon-sat");
        }, value: "mon-sat" }, repeatMenu["mon-sat"]), React.createElement(MenuItem, { onClick: function () {
            handleCloseRepeatOption();
            setRepeatOption("weekly");
        }, value: "weekly" }, repeatMenu.weekly), React.createElement(MenuItem, { onClick: function () {
            // handleCloseRepeatOption()
            setRepeatOption("custom");
        }, value: "custom" }, React.createElement("svg", { className: "MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled", focusable: "false", viewBox: "0 0 24 24", "aria-hidden": "true", style: { transform: "rotate(90deg)", left: -5 } }, React.createElement("path", { d: "M7 10l5 5 5-5z" })), repeatMenu.custom), React.createElement(MenuItem, { onClick: function () {
            handleCloseRepeatOption();
            setRepeatOption("monthly-1");
        }, value: "monthly-1" }, repeatMenu["monthly-1"]), React.createElement(MenuItem, { onClick: function () {
            handleCloseRepeatOption();
            setRepeatOption("monthly-2");
        }, value: "monthly-2" }, repeatMenu["monthly-2"]), React.createElement(MenuItem, { onClick: function () {
            handleCloseRepeatOption();
            setRepeatOption("annually");
        }, value: "annually" }, repeatMenu.annually)))), repeatOption !== "none" && (React.createElement(React.Fragment, null, priorAvailable && (React.createElement(React.Fragment, null, React.createElement(FormControl, { variant: "filled", style: { flex: 1, width: "100%", marginTop: 15 } }, React.createElement(TextField, { disabled: visibleFromThePreviousDueDate, required: true, InputProps: { disableUnderline: true }, inputProps: { min: "1", max: "60", step: "1" }, type: "number", fullWidth: true, value: prior, onChange: function (e) { return setPrior(e.target.value); }, id: "task-duration", name: "task-duration", label: "Task Visible Before (day/s)", variant: "filled" })), React.createElement("div", { className: classes.error }, priorError), React.createElement(FormControlLabel, { style: {
            marginLeft: 10,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: visibleFromThePreviousDueDate, onChange: function (event) { return setVisibleFromThePreviousDueDate(event.target.checked); }, id: "visibleFromThePreviousDueDate", name: "visibleFromThePreviousDueDate", color: "default" }), label: "New task is visible from the previous due date", labelPlacement: "end" }))), React.createElement("div", { className: classes.datetimeContainer, style: { marginTop: 15 } }, React.createElement(TextField, { disabled: doesNotEnd, InputProps: { disableUnderline: true }, inputProps: {
            min: new Date().toISOString().slice(0, 16)
        }, fullWidth: true, defaultValue: convertUTCDateToLocalDate(endDate || predictedDate), onChange: function (el) {
            setEndDate(new Date(el.target.value).getTime());
        }, id: "endDate", name: "endDate", label: "Repeat End Date & Time", type: "datetime-local", className: classes.datetimeTextField, InputLabelProps: {
            shrink: true
        } })), React.createElement(FormControlLabel, { style: {
            marginLeft: 10,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: doesNotEnd, onChange: function (event) { return setDoesNotEnd(event.target.checked); }, id: "doesNotEnd", name: "doesNotEnd", color: "default" }), label: "Repeat never end", labelPlacement: "end" })))));
}
