import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import * as React from "react";
import useStyles from "../../styles/styles";
export default function InfoCheckBoxColored(props) {
    var title = props.title, checked = props.checked;
    var classes = useStyles();
    return (React.createElement(Grid, { item: true, lg: 6 }, React.createElement("div", { className: classes.viewTaskBody }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0
        }, control: React.createElement(Checkbox, { checked: checked, id: "checkBoxInfo", name: "checkBoxInfo", color: "default" }), label: title, labelPlacement: "start" }))));
}
