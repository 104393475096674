import { TASK_STATE_CANCELLED, TASK_STATE_COMPLETED, TASK_STATE_DISPATCHED, TASK_STATE_PENDING, TASK_STATE_QUEUED, TASK_STATE_STARTED, TASK_STATE_TRAVEL } from "./remoteConfig/taskStates/taskStateEnum";
export function allowedTaskStateForAdmin(currentTaskState, taskState) {
    var allowedTaskStates = [];
    switch (currentTaskState) {
        case TASK_STATE_QUEUED:
            allowedTaskStates.push(TASK_STATE_QUEUED, TASK_STATE_PENDING, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_PENDING:
            allowedTaskStates.push(TASK_STATE_PENDING, TASK_STATE_QUEUED, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_DISPATCHED:
            allowedTaskStates.push(TASK_STATE_DISPATCHED);
            break;
        case TASK_STATE_TRAVEL:
            allowedTaskStates.push(TASK_STATE_TRAVEL);
            break;
        case TASK_STATE_STARTED:
            allowedTaskStates.push(TASK_STATE_STARTED);
            break;
        case TASK_STATE_COMPLETED:
            allowedTaskStates.push(TASK_STATE_COMPLETED);
            break;
        case TASK_STATE_CANCELLED:
            allowedTaskStates.push(TASK_STATE_CANCELLED);
            break;
        default:
            break;
    }
    return taskState.filter(function (el) { return allowedTaskStates.includes(el.id); });
}
export function allowedTaskStateForWorker(currentTaskState, taskState) {
    var allowedTaskStates = [];
    switch (currentTaskState) {
        case TASK_STATE_QUEUED:
            allowedTaskStates.push(TASK_STATE_QUEUED);
            break;
        case TASK_STATE_PENDING:
            allowedTaskStates.push(TASK_STATE_PENDING, TASK_STATE_DISPATCHED);
            break;
        case TASK_STATE_DISPATCHED:
            allowedTaskStates.push(TASK_STATE_DISPATCHED, TASK_STATE_STARTED, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_TRAVEL:
            allowedTaskStates.push(TASK_STATE_TRAVEL, TASK_STATE_COMPLETED, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_STARTED:
            allowedTaskStates.push(TASK_STATE_STARTED, TASK_STATE_COMPLETED, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_COMPLETED:
            allowedTaskStates.push(TASK_STATE_COMPLETED);
            break;
        case TASK_STATE_CANCELLED:
            allowedTaskStates.push(TASK_STATE_CANCELLED);
            break;
        default:
            break;
    }
    return taskState.filter(function (el) { return allowedTaskStates.includes(el.id); });
}
export function allowedTaskStateForSelfAssigneWorker(currentTaskState, taskState) {
    var allowedTaskStates = [];
    switch (currentTaskState) {
        case TASK_STATE_QUEUED:
            allowedTaskStates.push(TASK_STATE_QUEUED, TASK_STATE_PENDING, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_PENDING:
            allowedTaskStates.push(TASK_STATE_QUEUED, TASK_STATE_PENDING, TASK_STATE_DISPATCHED, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_DISPATCHED:
            allowedTaskStates.push(TASK_STATE_DISPATCHED, TASK_STATE_STARTED, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_TRAVEL:
            allowedTaskStates.push(TASK_STATE_TRAVEL, TASK_STATE_COMPLETED, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_STARTED:
            allowedTaskStates.push(TASK_STATE_STARTED, TASK_STATE_COMPLETED, TASK_STATE_CANCELLED);
            break;
        case TASK_STATE_COMPLETED:
            allowedTaskStates.push(TASK_STATE_COMPLETED);
            break;
        case TASK_STATE_CANCELLED:
            allowedTaskStates.push(TASK_STATE_CANCELLED);
            break;
        default:
            break;
    }
    return taskState.filter(function (el) { return allowedTaskStates.includes(el.id); });
}
export function allowedTaskStateForFreezedUser(currentTaskState, taskState) {
    var allowedTaskStates = [];
    switch (currentTaskState) {
        case TASK_STATE_QUEUED:
            allowedTaskStates.push(TASK_STATE_QUEUED);
            break;
        case TASK_STATE_PENDING:
            allowedTaskStates.push(TASK_STATE_PENDING);
            break;
        case TASK_STATE_DISPATCHED:
            allowedTaskStates.push(TASK_STATE_DISPATCHED);
            break;
        case TASK_STATE_TRAVEL:
            allowedTaskStates.push(TASK_STATE_TRAVEL);
            break;
        case TASK_STATE_STARTED:
            allowedTaskStates.push(TASK_STATE_STARTED);
            break;
        case TASK_STATE_COMPLETED:
            allowedTaskStates.push(TASK_STATE_COMPLETED);
            break;
        case TASK_STATE_CANCELLED:
            allowedTaskStates.push(TASK_STATE_CANCELLED);
            break;
        default:
            break;
    }
    return taskState.filter(function (el) { return allowedTaskStates.includes(el.id); });
}
