var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put } from "redux-saga/effects";
import { firestore } from "../../../../utils/firebaseConnector";
import { FIRESTORE_CANCEL_REQUESTS_COLLECTION, FIRESTORE_TASK_SCHEDULES_COLLECTION, FIRESTORE_TASKS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../../../../utils/firestoreCollections";
import { taskMapper } from "../../../../utils/mapper/taskMapper";
import { cancelRequestMapper } from "../../../../utils/mapper/cancellationRequestsMapper";
import { fetchSingleTaskFailureAction, fetchSingleTaskSuccessAction } from "../actions";
import { userMapper } from "../../../../utils/mapper/userMapper";
export function fetchSingleTaskFromFirestore(taskId) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            if (taskId === undefined || taskId === "")
                return [2 /*return*/, undefined];
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_TASKS_COLLECTION)
                    .doc(taskId)
                    .get()
                    .then(function (doc) {
                    var _a;
                    if (doc.exists && ((_a = doc.data()) === null || _a === void 0 ? void 0 : _a.archive) === false) {
                        return taskMapper(doc, null);
                    }
                    // eslint-disable-next-line no-console
                    console.error(doc.data());
                    throw new Error("task_does_not_exist:" + taskId);
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
export function fetchSingleCancellationRequestFromFirestore(cancelRequestId) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            if (cancelRequestId === undefined || cancelRequestId === "")
                return [2 /*return*/, undefined];
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_CANCEL_REQUESTS_COLLECTION)
                    .doc(cancelRequestId)
                    .get()
                    .then(function (doc) {
                    // @ts-ignore
                    if (doc.exists && doc.data().state === 0) {
                        return cancelRequestMapper(doc);
                    }
                    return undefined;
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
export function fetchSingleUserFromFirestore(documentID) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (documentID === undefined || documentID === "")
                return [2 /*return*/, undefined];
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_USERS_COLLECTION)
                    .doc(documentID)
                    .get()
                    .then(function (doc) {
                    if (doc.exists /* && doc.data()?.archive === false */) {
                        return userMapper(doc);
                    }
                    console.error(doc.data());
                    throw new Error("user_does_not_exist: " + documentID);
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
export function fetchFirestoreTaskSchedulesData(taskID) {
    return __awaiter(this, void 0, Promise, function () {
        var taskDocRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firestore.collection(FIRESTORE_TASKS_COLLECTION).doc(taskID)];
                case 1:
                    taskDocRef = _a.sent();
                    return [2 /*return*/, firestore
                            .collection(FIRESTORE_TASK_SCHEDULES_COLLECTION)
                            .where("task", "==", taskDocRef)
                            .get()
                            .then(function (querySnapshot) {
                            var _a;
                            var docs = querySnapshot.docs;
                            if (docs.length !== 0) {
                                var data = docs[0].data();
                                return {
                                    repeat: data.preset,
                                    weekdays: data.weekdays,
                                    prior: data.prior,
                                    end: ((_a = data.end) === null || _a === void 0 ? void 0 : _a.seconds) * 1000 || null
                                };
                            }
                            return undefined;
                        })
                            .catch(function (e) {
                            throw e;
                        })];
            }
        });
    });
}
export default function callFetchSingleTaskSaga(action) {
    var result, cancellationRequest, supervisor, assignee, nextUser, taskScheduleData, e_1;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _f.trys.push([0, 8, , 10]);
                return [4 /*yield*/, call(fetchSingleTaskFromFirestore, action.taskId)];
            case 1:
                result = _f.sent();
                return [4 /*yield*/, call(fetchSingleCancellationRequestFromFirestore, (_a = result) === null || _a === void 0 ? void 0 : _a.cancelRequest)];
            case 2:
                cancellationRequest = _f.sent();
                return [4 /*yield*/, call(fetchSingleUserFromFirestore, (_b = result) === null || _b === void 0 ? void 0 : _b.supervisor)];
            case 3:
                supervisor = _f.sent();
                return [4 /*yield*/, call(fetchSingleUserFromFirestore, (_c = result) === null || _c === void 0 ? void 0 : _c.assignedTo)];
            case 4:
                assignee = _f.sent();
                return [4 /*yield*/, call(fetchSingleUserFromFirestore, (_e = (_d = result) === null || _d === void 0 ? void 0 : _d.nextUser) === null || _e === void 0 ? void 0 : _e.id)];
            case 5:
                nextUser = _f.sent();
                return [4 /*yield*/, call(fetchFirestoreTaskSchedulesData, action.taskId)];
            case 6:
                taskScheduleData = _f.sent();
                return [4 /*yield*/, put(fetchSingleTaskSuccessAction(result, cancellationRequest, supervisor, assignee, taskScheduleData, nextUser))];
            case 7:
                _f.sent();
                return [3 /*break*/, 10];
            case 8:
                e_1 = _f.sent();
                return [4 /*yield*/, put(fetchSingleTaskFailureAction(e_1.toString()))];
            case 9:
                _f.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
