import * as React from "react";
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { PRIMARY_BLUE } from "../../../../utils/colorCodes";
import useStyles from "./styles";
import TasksCsvDownload from "../TasksCsvDownload";
export default function CsvDownloadFieldModal(props) {
    var taskList = props.taskList, startDate = props.startDate, endDate = props.endDate, isCsvFieldsModalOpen = props.isCsvFieldsModalOpen, setCsvFieldsModalOpen = props.setCsvFieldsModalOpen;
    var classes = useStyles();
    var _a = React.useState(true), id = _a[0], setID = _a[1];
    var _b = React.useState(true), source = _b[0], setSource = _b[1];
    var _c = React.useState(true), createdOn = _c[0], setCreatedOn = _c[1];
    var _d = React.useState(true), createdBy = _d[0], setCreatedBy = _d[1];
    var _e = React.useState(true), type = _e[0], setType = _e[1];
    var _f = React.useState(true), refId = _f[0], setRefId = _f[1];
    var _g = React.useState(true), desc = _g[0], setDesc = _g[1];
    var _h = React.useState(true), address = _h[0], setAddress = _h[1];
    var _j = React.useState(true), telephone = _j[0], setTelephone = _j[1];
    var _k = React.useState(true), location = _k[0], setLocation = _k[1];
    var _l = React.useState(true), locationLink = _l[0], setLocationLink = _l[1];
    var _m = React.useState(true), assignee = _m[0], setAssignee = _m[1];
    var _o = React.useState(true), supervisor = _o[0], setSupervisor = _o[1];
    var _p = React.useState(true), _startDate = _p[0], setStartDate = _p[1];
    var _q = React.useState(true), dueDate = _q[0], setDueDate = _q[1];
    var _r = React.useState(true), priority = _r[0], setPriority = _r[1];
    var _s = React.useState(true), travelReq = _s[0], setTravelReq = _s[1];
    var _t = React.useState(true), taskState = _t[0], setTaskState = _t[1];
    var _u = React.useState(true), cancellationRemark = _u[0], setCancellationRemark = _u[1];
    var _v = React.useState(true), cancellationReq = _v[0], setCancellationReq = _v[1];
    var _w = React.useState(true), rating = _w[0], setRating = _w[1];
    var _x = React.useState(true), remarks = _x[0], setRemarks = _x[1];
    var _y = React.useState(true), nextUser = _y[0], setNextUser = _y[1];
    var _z = React.useState(true), nextDate = _z[0], setNextDate = _z[1];
    var _0 = React.useState(true), amount = _0[0], setAmount = _0[1];
    var _1 = React.useState(true), furtherAction = _1[0], setFurtherAction = _1[1];
    return (React.createElement(Dialog, { disableBackdropClick: true, open: isCsvFieldsModalOpen, onClose: function () {
            setCsvFieldsModalOpen(false);
        }, scroll: "body", maxWidth: "xs", fullWidth: true, "aria-labelledby": "scroll-dialog-title", "aria-describedby": "scroll-dialog-description", style: { left: "auto" }, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray"
                // width: 444
            }
        } }, React.createElement(DialogTitle, { id: "scroll-dialog-title" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { style: { fontSize: 24, fontWeight: "bold", color: PRIMARY_BLUE } }, "Select Fields"), React.createElement(Button, { onClick: function () {
            setCsvFieldsModalOpen(false);
        }, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null)))), React.createElement(DialogContent, null, React.createElement(Grid, { container: true, spacing: 3 }, React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: id, onChange: function () { return setID(!id); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Id", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: source, onChange: function () { return setSource(!source); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Task Source", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: createdOn, onChange: function () { return setCreatedOn(!createdOn); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Task Created On", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: createdBy, onChange: function () { return setCreatedBy(!createdBy); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Task Created by", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: type, onChange: function () { return setType(!type); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Task Type", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: refId, onChange: function () { return setRefId(!refId); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Ref Id", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: desc, onChange: function () { return setDesc(!desc); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Short Description", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: address, onChange: function () { return setAddress(!address); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Address", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: telephone, onChange: function () { return setTelephone(!telephone); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Telephone Number", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: location, onChange: function () { return setLocation(!location); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Location", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: locationLink, onChange: function () { return setLocationLink(!locationLink); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Location Link", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: assignee, onChange: function () { return setAssignee(!assignee); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Assignee", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: supervisor, onChange: function () { return setSupervisor(!supervisor); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Supervisor", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: _startDate, onChange: function () { return setStartDate(!_startDate); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Start Date & Time", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: dueDate, onChange: function () { return setDueDate(!dueDate); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Due Date & Time", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: priority, onChange: function () { return setPriority(!priority); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Priority", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: travelReq, onChange: function () { return setTravelReq(!travelReq); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Travel Required", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: taskState, onChange: function () { return setTaskState(!taskState); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Task State", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: cancellationRemark, onChange: function () { return setCancellationRemark(!cancellationRemark); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Cancellation remarks", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: cancellationReq, onChange: function () { return setCancellationReq(!cancellationReq); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Cancellation Request Date & Time", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: rating, onChange: function () { return setRating(!rating); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Rating", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: remarks, onChange: function () { return setRemarks(!remarks); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Remarks", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: nextUser, onChange: function () { return setNextUser(!nextUser); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Next User", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: nextDate, onChange: function () { return setNextDate(!nextDate); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Next Date", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: amount, onChange: function () { return setAmount(!amount); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Amount", labelPlacement: "start" }))), React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: furtherAction, onChange: function () { return setFurtherAction(!furtherAction); }, id: "isFirebaseUser", name: "isFirebaseUser", color: "default" }), label: "Further Action", labelPlacement: "start" }))))), React.createElement(DialogActions, { style: { padding: 24 } }, TasksCsvDownload(taskList, startDate, endDate, {
        id: id,
        source: source,
        createdOn: createdOn,
        createdBy: createdBy,
        type: type,
        refId: refId,
        desc: desc,
        address: address,
        telephone: telephone,
        location: location,
        locationLink: locationLink,
        assignee: assignee,
        supervisor: supervisor,
        _startDate: _startDate,
        dueDate: dueDate,
        priority: priority,
        travelReq: travelReq,
        taskState: taskState,
        cancellationRemark: cancellationRemark,
        cancellationReq: cancellationReq,
        rating: rating,
        remarks: remarks,
        nextUser: nextUser,
        nextDate: nextDate,
        amount: amount,
        furtherAction: furtherAction
    }))));
}
