var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { firestore } from "../../utils/firebaseConnector";
import { cancelRequestMapper } from "../../utils/mapper/cancellationRequestsMapper";
import { FIRESTORE_CANCEL_REQUESTS_COLLECTION, FIRESTORE_TASKS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../../utils/firestoreCollections";
import { acceptCancellationRequestFailureAction, acceptCancellationRequestSuccessAction, cancellationRequestActionTypes, fetchCancellationRequestFailureAction, fetchCancellationRequestSuccessAction, rejectCancellationRequestFailureAction, rejectCancellationRequestSuccessAction } from "./action";
import { TASK_STATE_CANCELLED } from "../../utils/remoteConfig/taskStates/taskStateEnum";
// ------------------------------------------------------FETCH_CANCELLATION_REQUEST--------------------------------------
function cancelRequestFilter(result, uid) {
    return __awaiter(this, void 0, void 0, function () {
        var filteredRequests;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    filteredRequests = [];
                    return [4 /*yield*/, Promise.all(result.map(function (request) {
                            return __awaiter(_this, void 0, void 0, function () {
                                var taskDetailsRow, taskDetails, assigneeDetails, assigneeDetailsRow;
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                return __generator(this, function (_l) {
                                    switch (_l.label) {
                                        case 0: return [4 /*yield*/, firestore.collection(FIRESTORE_TASKS_COLLECTION).doc(request.task).get()];
                                        case 1:
                                            taskDetailsRow = _l.sent();
                                            taskDetails = taskDetailsRow.data();
                                            if (((_b = (_a = taskDetails) === null || _a === void 0 ? void 0 : _a.createdBy) === null || _b === void 0 ? void 0 : _b.id) === uid) {
                                                filteredRequests.push(request);
                                                return [2 /*return*/];
                                            }
                                            if (((_d = (_c = taskDetails) === null || _c === void 0 ? void 0 : _c.supervisor) === null || _d === void 0 ? void 0 : _d.id) === uid) {
                                                filteredRequests.push(request);
                                                return [2 /*return*/];
                                            }
                                            if (!((_f = (_e = taskDetails) === null || _e === void 0 ? void 0 : _e.assignedTo) === null || _f === void 0 ? void 0 : _f.id))
                                                return [3 /*break*/, 3];
                                            return [4 /*yield*/, firestore
                                                    .collection(FIRESTORE_USERS_COLLECTION)
                                                    .doc((_h = (_g = taskDetails) === null || _g === void 0 ? void 0 : _g.assignedTo) === null || _h === void 0 ? void 0 : _h.id)
                                                    .get()];
                                        case 2:
                                            assigneeDetailsRow = _l.sent();
                                            assigneeDetails = assigneeDetailsRow.data();
                                            if (((_k = (_j = assigneeDetails) === null || _j === void 0 ? void 0 : _j.supervisor) === null || _k === void 0 ? void 0 : _k.id) === uid) {
                                                filteredRequests.push(request);
                                            }
                                            _l.label = 3;
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            });
                        }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/, filteredRequests];
            }
        });
    });
}
function callFetchCancellationRequest(action) {
    var ref, channel, data, result, filteredResult, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                ref = firestore
                    .collection(FIRESTORE_CANCEL_REQUESTS_COLLECTION)
                    .where("state", "==", 0)
                    .orderBy("requestedAt", "desc");
                channel = eventChannel(function (emit) { return ref.onSnapshot(emit); });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 7, , 9]);
                _a.label = 2;
            case 2:
                if (!true)
                    return [3 /*break*/, 6];
                return [4 /*yield*/, take(channel)];
            case 3:
                data = _a.sent();
                result = data.docs.map(function (doc) {
                    return cancelRequestMapper(doc);
                });
                return [4 /*yield*/, call(cancelRequestFilter, result, action.uid)];
            case 4:
                filteredResult = _a.sent();
                return [4 /*yield*/, put(fetchCancellationRequestSuccessAction(filteredResult))];
            case 5:
                _a.sent();
                return [3 /*break*/, 2];
            case 6: return [3 /*break*/, 9];
            case 7:
                err_1 = _a.sent();
                return [4 /*yield*/, put(fetchCancellationRequestFailureAction(err_1.toString()))];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
// ------------------------------------------------------saga-2----------------------------------------------------------
function rejectRequestCancellationDocument(cancellationRequest) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_CANCEL_REQUESTS_COLLECTION)
                    .doc(cancellationRequest.id)
                    .set({
                    state: 1,
                    handledBy: firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + cancellationRequest.handledBy)
                }, { merge: true })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
function acceptRequestCancellationDocument(cancellationRequest) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_CANCEL_REQUESTS_COLLECTION)
                    .doc(cancellationRequest.id)
                    .set({
                    state: 2,
                    handledBy: firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + cancellationRequest.handledBy)
                }, { merge: true })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
export function completeTaskWithoutCancelRequest(cancellationRequestID, handledBy) {
    return firestore
        .collection(FIRESTORE_CANCEL_REQUESTS_COLLECTION)
        .doc(cancellationRequestID)
        .set({
        state: 3,
        handledBy: firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + handledBy)
    }, { merge: true })
        .then(function () {
        return "S1000";
    })
        .catch(function (e) {
        throw e;
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
function removeCancelRequestReferenceFromTask(taskId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_TASKS_COLLECTION)
                    .doc(taskId)
                    .set({
                    cancelRequest: null
                }, { merge: true })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
// ------------------------------------------------------saga-3----------------------------------------------------------
function changeTaskState(taskId, state) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_TASKS_COLLECTION)
                    .doc(taskId)
                    .set({
                    state: state
                }, { merge: true })
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function callRejectRequest(action) {
    var loggedInUser, cancellationRequest, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.loginManagement.loggedInUser; })];
            case 1:
                loggedInUser = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 8]);
                cancellationRequest = JSON.parse(JSON.stringify(action.cancellationRequest));
                cancellationRequest.handledBy = loggedInUser.documentID;
                return [4 /*yield*/, call(rejectRequestCancellationDocument, cancellationRequest)];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(removeCancelRequestReferenceFromTask, cancellationRequest.task)];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(rejectCancellationRequestSuccessAction())];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                e_1 = _a.sent();
                return [4 /*yield*/, put(rejectCancellationRequestFailureAction(e_1.toString()))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function callAcceptRequest(action) {
    var loggedInUser, cancellationRequest, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.loginManagement.loggedInUser; })];
            case 1:
                loggedInUser = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 7, , 9]);
                cancellationRequest = JSON.parse(JSON.stringify(action.cancellationRequest));
                cancellationRequest.handledBy = loggedInUser.documentID;
                return [4 /*yield*/, call(acceptRequestCancellationDocument, cancellationRequest)];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(removeCancelRequestReferenceFromTask, cancellationRequest.task)];
            case 4:
                _a.sent();
                return [4 /*yield*/, call(changeTaskState, cancellationRequest.task, TASK_STATE_CANCELLED)];
            case 5:
                _a.sent();
                return [4 /*yield*/, put(acceptCancellationRequestSuccessAction())];
            case 6:
                _a.sent();
                return [3 /*break*/, 9];
            case 7:
                e_2 = _a.sent();
                return [4 /*yield*/, put(acceptCancellationRequestFailureAction(e_2.toString()))];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function getTaskManagementSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(cancellationRequestActionTypes.FETCH_CANCELLATION_REQUEST, callFetchCancellationRequest)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(cancellationRequestActionTypes.REJECT_REQUEST, callRejectRequest)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(cancellationRequestActionTypes.ACCEPT_REQUEST, callAcceptRequest)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var cancellationRequestSagas = [getTaskManagementSagas];
