import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link as LinkTo } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useSelector } from "react-redux";
function NotFound() {
    var fetchLoggedInUserIsLoading = useSelector(function (state) { return state.loginManagement; }).fetchLoggedInUserIsLoading;
    return (React.createElement(Grid, { container: true, spacing: 0, alignItems: "center", justify: "center", style: {} }, React.createElement(Grid, { item: true, xs: 8 }, (!fetchLoggedInUserIsLoading || !window.location.href.includes("/dashboard")) && (React.createElement("div", { style: { flexDirection: "column" } }, React.createElement(Typography, { style: { fontSize: 100, fontWeight: "bold" } }, "404"), React.createElement(Typography, { style: { fontSize: 24, fontWeight: "bold" } }, "Oops! This Page Could Not Be Found"), React.createElement(Typography, { style: { fontSize: 17 } }, "Sorry but the page you are looking for does not exist, have been removed. Name changed or is temporarily unavailable"), React.createElement(Link, { component: LinkTo, to: "/dashboard", style: { fontSize: 15 } }, "Go To Dashboard"))))));
}
export default NotFound;
