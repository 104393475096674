/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import useStyles from "../../styles/styles";
export default function InputTaskDetails(props) {
    var _a = props.isLarge, isLarge = _a === void 0 ? false : _a, _b = props.isDisable, isDisable = _b === void 0 ? false : _b, _c = props.required, required = _c === void 0 ? false : _c, _d = props.multiline, multiline = _d === void 0 ? false : _d, defaultValue = props.defaultValue, onChange = props.onChange, onBlur = props.onBlur, id = props.id, label = props.label, errors = props.errors, touched = props.touched;
    var classes = useStyles();
    return (React.createElement(Grid, { item: true, xs: isLarge ? 12 : 6 }, React.createElement("div", { className: classes.formControl100 }, React.createElement(TextField, { disabled: isDisable, required: required, multiline: multiline, InputProps: { disableUnderline: true }, fullWidth: true, defaultValue: defaultValue, onChange: onChange, onBlur: onBlur, id: id, label: label, variant: "filled" })), errors[id] && touched[id] && React.createElement("div", { className: classes.error }, errors[id])));
}
