import isOverDue from "../../../utils/isOverDue";
import { ROLE_ADMIN, ROLE_DIV_ADMIN, ROLE_STATION_USER, ROLE_SUPER_ADMIN, ROLE_SUPERVISOR, ROLE_USER } from "../../../utils/remoteConfig/roles/rolesEnum";
export default function getFilteredTask(taskList, checkedCascadeTasks, cascadeUserIDs, loggedInUser, subordinateUserIDs, checkedOverDue, statusFilter, groupFilter, taskTypesFilter, userFilter, searchText, sourceFilter, priorityFilter, creatorFilter) {
    var _a;
    var loggedInUserWithSubordinates = [(_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.documentID].concat(subordinateUserIDs);
    function checkDocumentID(creatorArray, element) {
        var i;
        for (i = 0; i < creatorArray.length; i++) {
            if (creatorArray[i].documentID === element.createdBy) {
                // console.log("same",creatorArray[i].documentID," -  ",element.createdBy)
                return true;
            }
        }
        return false;
    }
    return taskList.filter(function (el) {
        var _a, _b;
        var isValid = true;
        if (statusFilter.length !== 0) {
            isValid = isValid && statusFilter.includes(el.state);
        }
        else {
            isValid = false;
        }
        if (groupFilter.length !== 0) {
            isValid = isValid && ((_a = el.criteria.groupCriteria) === null || _a === void 0 ? void 0 : _a.includes(groupFilter));
        }
        if (taskTypesFilter.length >= 0) {
            isValid = isValid && taskTypesFilter.includes(el.taskType);
            // isValid = isValid && el.taskType.includes(taskTypeFilter);
        }
        if (creatorFilter.length > 0) {
            isValid = isValid && checkDocumentID(creatorFilter, el);
        }
        if (userFilter.length !== 0) {
            isValid = isValid && el.assignedTo === userFilter;
        }
        if (searchText.length !== 0) {
            isValid = isValid && Object.values(el).toString().toLowerCase().includes(searchText.toLowerCase());
        }
        if (sourceFilter.length !== 0) {
            if (sourceFilter === "ERP") {
                isValid = isValid && el.sourceERP !== null;
            }
            else {
                isValid = isValid && el.sourceERP === null;
            }
        }
        if (priorityFilter !== 0) {
            isValid = isValid && el.priority === priorityFilter;
        }
        if (checkedCascadeTasks) {
            isValid = isValid && (cascadeUserIDs.includes(el.assignedTo) || cascadeUserIDs.includes(el.supervisor));
        }
        if (checkedOverDue) {
            isValid = isValid && isOverDue(el);
        }
        switch ((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.role) {
            case ROLE_USER:
            case ROLE_STATION_USER:
                // only show assigned task and suggested task
                isValid =
                    isValid && (loggedInUser.documentID === el.assignedTo || el.suggested.indexOf(loggedInUser.documentID) >= 0);
                break;
            case ROLE_SUPER_ADMIN:
            case ROLE_DIV_ADMIN:
            case ROLE_ADMIN:
                // show all task
                // isValid = isValid && true; // < - always true
                break;
            case ROLE_SUPERVISOR:
                isValid =
                    isValid &&
                        (loggedInUserWithSubordinates.includes(el.assignedTo) ||
                            loggedInUserWithSubordinates.includes(el.createdBy) ||
                            loggedInUserWithSubordinates.includes(el.supervisor));
                break;
            default:
                break;
        }
        return isValid;
    });
}
