var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { call, put, take, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { firestore } from "../../utils/firebaseConnector";
import { getLocalDate } from "../../utils/dateOperations";
import { TASK_STATE_STARTED, TASK_STATE_TRAVEL } from "../../utils/remoteConfig/taskStates/taskStateEnum";
import { fetchUpdatedLiveHistoriesFailureAction, fetchUpdatedLiveHistoriesSuccessAction, fetchUpdatedLiveLocationsFailureAction, fetchUpdatedLiveLocationsSuccessAction, liveLocationActionTypes } from "./actions";
import { FIRESTORE_HISTORIES_SUB_COLLECTION, FIRESTORE_LOCATIONS_SUB_COLLECTION } from "../../utils/firestoreCollections";
import "firebase/firestore";
// ------------------------------------------------------saga-1----------------------------------------------------------
function fetchFromLocations(date) {
    var ref, channel, Data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                ref = firestore.collectionGroup(FIRESTORE_LOCATIONS_SUB_COLLECTION).where("date", "==", date);
                channel = eventChannel(function (emit) { return ref.onSnapshot(emit); });
                _a.label = 1;
            case 1:
                if (!true)
                    return [3 /*break*/, 6];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, take(channel)];
            case 3:
                Data = _a.sent();
                // console.log("Data - ",Data);
                return [2 /*return*/, Data.docs.map(function (doc) {
                        var _a, _b, _c;
                        var data = doc.data();
                        var documentID = ((_a = doc.ref.parent.parent) === null || _a === void 0 ? void 0 : _a.id) || "";
                        if (data.history.length > 0) {
                            var history = data.history.sort(function (a, b) { return b.timestamp.seconds - a.timestamp.seconds; });
                            var latestElement = history[0];
                            return {
                                userDocumentID: documentID,
                                timeStamp: latestElement.timestamp.seconds * 1000,
                                status: undefined,
                                loggedInDevice: undefined,
                                attendanceOption: undefined,
                                location: {
                                    lng: ((_b = latestElement.location) === null || _b === void 0 ? void 0 : _b.longitude) || 0,
                                    lat: ((_c = latestElement.location) === null || _c === void 0 ? void 0 : _c.latitude) || 0
                                }
                            };
                        }
                        return null;
                    })];
            case 4:
                error_1 = _a.sent();
                console.error("Error Occured1:", error_1);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 1];
            case 6: return [2 /*return*/];
        }
    });
}
function callFetchUpdatedLiveLocations(action) {
    var date, resultOfLocations, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                date = getLocalDate(new Date());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                _a.label = 2;
            case 2:
                if (!true)
                    return [3 /*break*/, 5];
                return [4 /*yield*/, call(fetchFromLocations, date)];
            case 3:
                resultOfLocations = _a.sent();
                return [4 /*yield*/, put(fetchUpdatedLiveLocationsSuccessAction(resultOfLocations))];
            case 4:
                _a.sent();
                return [3 /*break*/, 2];
            case 5: return [3 /*break*/, 8];
            case 6:
                err_1 = _a.sent();
                return [4 /*yield*/, put(fetchUpdatedLiveLocationsFailureAction(err_1.toString()))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
// -----------------------------------------
function fetchFromUpdatedHistories(date) {
    var ref, channel, Data, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                ref = firestore.collectionGroup(FIRESTORE_HISTORIES_SUB_COLLECTION).where("date", "==", date);
                channel = eventChannel(function (emit) { return ref.onSnapshot(emit); });
                _a.label = 1;
            case 1:
                if (!true)
                    return [3 /*break*/, 6];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, take(channel)];
            case 3:
                Data = _a.sent();
                // console.log("Data - ",Data);
                return [2 /*return*/, Data.docs.map(function (doc) {
                        var _a;
                        return {
                            data: doc.data(),
                            documentID: (_a = doc.ref.parent.parent) === null || _a === void 0 ? void 0 : _a.id
                        };
                    })];
            case 4:
                error_2 = _a.sent();
                console.error("Error Occured2:", error_2);
                return [3 /*break*/, 5];
            case 5: return [3 /*break*/, 1];
            case 6: return [2 /*return*/];
        }
    });
}
function getStatusText(latestElement) {
    var _a, _b, _c, _d, _e;
    if (latestElement.status === "TaskUpdated") {
        switch ((_a = latestElement.data) === null || _a === void 0 ? void 0 : _a.state) {
            case TASK_STATE_STARTED:
                return "Task Ongoing (ID: " + ((_c = (_b = latestElement.data) === null || _b === void 0 ? void 0 : _b.task) === null || _c === void 0 ? void 0 : _c.id) + ")";
            case TASK_STATE_TRAVEL:
                return "Traveling (ID: " + ((_e = (_d = latestElement.data) === null || _d === void 0 ? void 0 : _d.task) === null || _e === void 0 ? void 0 : _e.id) + ")";
            default:
                return "CheckIn";
        }
    }
    else {
        return latestElement.status;
    }
}
function callFetchLiveHistories(action) {
    var date, _loop_1, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                date = getLocalDate(new Date());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 7]);
                _loop_1 = function () {
                    var resultOfHistories, iResult;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, call(fetchFromUpdatedHistories, date)];
                            case 1:
                                resultOfHistories = _a.sent();
                                iResult = [];
                                resultOfHistories.forEach(function (el) {
                                    var _a, _b, _c, _d, _e, _f;
                                    var history = el.data.history;
                                    if (history.length > 0) {
                                        var filteredHistory = history.reverse();
                                        var loggedInDevice = "";
                                        var attendanceOption = "";
                                        var filteredHistoryCheckIn = filteredHistory.filter(function (e) { return e.status === "CheckIn"; });
                                        if (filteredHistoryCheckIn.length > 0) {
                                            var latestElement_1 = filteredHistoryCheckIn[0];
                                            loggedInDevice = (_b = (_a = latestElement_1) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.device;
                                            attendanceOption = (_d = (_c = latestElement_1) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.workType;
                                        }
                                        var latestElement = void 0;
                                        var i = void 0;
                                        var skipOneCoverageOff = false;
                                        var skipOneMovingStopped = false;
                                        for (i = 0; i < filteredHistory.length; i += 1) {
                                            latestElement = filteredHistory[i];
                                            if (filteredHistory[i].status === "CoverageOn") {
                                                // get previous
                                                skipOneCoverageOff = true;
                                            }
                                            else if (skipOneCoverageOff && filteredHistory[i].status === "CoverageOff") {
                                                // get previous
                                            }
                                            else if (filteredHistory[i].status === "MovingStarted") {
                                                // get previous
                                                skipOneMovingStopped = true;
                                            }
                                            else if (skipOneMovingStopped && filteredHistory[i].status === "MovingStopped") {
                                                // get previous
                                            }
                                            else {
                                                break;
                                            }
                                        }
                                        // TODO: debugging
                                        // console.log(el.documentID, history, latestElement);
                                        iResult.push({
                                            userDocumentID: el.documentID,
                                            timeStamp: latestElement.timestamp.seconds * 1000,
                                            status: getStatusText(latestElement),
                                            loggedInDevice: loggedInDevice,
                                            attendanceOption: attendanceOption,
                                            location: latestElement.location
                                                ? {
                                                    lng: ((_e = latestElement.location) === null || _e === void 0 ? void 0 : _e.longitude) || 0,
                                                    lat: ((_f = latestElement.location) === null || _f === void 0 ? void 0 : _f.latitude) || 0
                                                }
                                                : undefined
                                        });
                                    }
                                });
                                return [4 /*yield*/, put(fetchUpdatedLiveHistoriesSuccessAction(iResult))];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                };
                _a.label = 2;
            case 2:
                if (!true)
                    return [3 /*break*/, 4];
                return [5 /*yield**/, _loop_1()];
            case 3:
                _a.sent();
                return [3 /*break*/, 2];
            case 4: return [3 /*break*/, 7];
            case 5:
                err_2 = _a.sent();
                return [4 /*yield*/, put(fetchUpdatedLiveHistoriesFailureAction(err_2.toString()))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function watchUpdatedLiveLocationActionSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(liveLocationActionTypes.FETCH_UPDATED_LIVE_LOCATIONS, callFetchUpdatedLiveLocations)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(liveLocationActionTypes.FETCH_UPDATED_LIVE_HISTORIES, callFetchLiveHistories)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var updatedLiveLocationSagas = [watchUpdatedLiveLocationActionSagas];
export default updatedLiveLocationSagas;
