import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY_BLUE } from "../../utils/colorCodes";

export const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    color: PRIMARY_BLUE,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.429
  },
  table: {
    minWidth: 640
  },
  tableCell: {
    color: PRIMARY_BLUE,
    fontSize: 14,
    fontWeight: 500
  }
}));
