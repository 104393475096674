/* eslint-disable  @typescript-eslint/no-explicit-any */
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import useStyles from "../../styles/styles";
export default function CheckboxSelector(props) {
    var id = props.id, label = props.label, checked = props.checked, onChange = props.onChange, onBlur = props.onBlur, errors = props.errors, touched = props.touched;
    var classes = useStyles();
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement("div", { className: classes.weekday }, React.createElement(FormControlLabel, { style: {
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 0,
            paddingTop: 8
        }, control: React.createElement(Checkbox, { checked: checked, onChange: onChange, onBlur: onBlur, id: id, color: "default" }), label: label, labelPlacement: "start" })), errors[id] && touched[id] && React.createElement("div", { className: classes.error }, errors[id])));
}
