import { useDispatch, useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import React from "react";
import { signOutAction } from "../Login/action";
export default function ShowUserOptionMenu(anchorElUserMenu, handleClose) {
    var _a, _b;
    var dispatch = useDispatch();
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    return (React.createElement(Menu, { style: { marginTop: 45, marginLeft: -50 }, id: "menu-user", anchorEl: anchorElUserMenu, open: Boolean(anchorElUserMenu), onClose: handleClose }, React.createElement(MenuItem, { id: "my-profile", style: { fontSize: 15 }, onClick: handleClose, component: Link, to: "/dashboard/user-profile" }, "My Profile"), ((_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.isFirebaseUser) && (React.createElement(MenuItem, { id: "change-password", style: { fontSize: 15 }, onClick: handleClose, component: Link, to: "/change-password" }, "Change Password")), ((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.isFirebaseUser) && React.createElement(Divider, { style: { marginLeft: 16, marginRight: 16 } }), React.createElement(MenuItem, { id: "logout", style: { fontSize: 15, width: 160 }, onClick: function () {
            handleClose();
            dispatch(signOutAction());
        } }, "Log Out")));
}
