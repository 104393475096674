export default function getReadableTaskStatus(status) {
    switch (status) {
        case "queued":
            return "Queued";
        case "pending":
            return "Pending";
        case "dispatched":
            return "Dispatched";
        case "travel":
            return "Travel";
        case "started":
            return "Started";
        case "completed":
            return "Completed";
        case "canceled":
            return "Canceled";
        default:
            return status;
    }
}
