var dateFormat = require("dateformat");
export function getDaysArray(start, end) {
    var st = new Date(start);
    var ed = new Date(end).setHours(23, 59, 59, 999);
    var arr = [];
    while (st <= new Date(ed)) {
        arr.push(getLocalDate(st));
        st.setDate(st.getDate() + 1);
    }
    return arr.reverse();
}
export function convertUTCDateToLocalDate(milliseconds) {
    var date = new Date(milliseconds);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 16);
}
export function getLocalDate(date) {
    return dateFormat(date, "yyyy-mm-dd");
}
export function secondsToTimeAbs(sec) {
    var dateObj = new Date(Math.abs(sec) * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();
    var h = hours.toString().padStart(2, "0");
    var m = minutes.toString().padStart(2, "0");
    var s = seconds.toString().padStart(2, "0");
    if (h === "00") {
        if (m === "00") {
            return s + " sec";
        }
        return m + " min: " + s + " sec";
    }
    return h + " h: " + m + " min: " + s + " sec";
}
export function secondsToTime(start, end) {
    var dateObj = new Date(Math.abs(start - end) * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();
    var h = hours.toString().padStart(2, "0");
    var m = minutes.toString().padStart(2, "0");
    var s = seconds.toString().padStart(2, "0");
    if (h === "00") {
        if (m === "00") {
            return s + " sec";
        }
        return m + " min: " + s + " sec";
    }
    return h + " h: " + m + " min: " + s + " sec";
}
export function getFilteredDaysArray(startDate, endDate, excludeDates, checkedDays) {
    var daysArray = getDaysArray(startDate, endDate);
    return daysArray.filter(function (date) {
        var isValid = true;
        if (excludeDates.includes(date)) {
            isValid = false;
        }
        if (new Date(date).getDay() === 0 && !checkedDays.checkedSunday) {
            isValid = false;
        }
        if (new Date(date).getDay() === 1 && !checkedDays.checkedMonday) {
            isValid = false;
        }
        if (new Date(date).getDay() === 2 && !checkedDays.checkedTuesday) {
            isValid = false;
        }
        if (new Date(date).getDay() === 3 && !checkedDays.checkedWednesday) {
            isValid = false;
        }
        if (new Date(date).getDay() === 4 && !checkedDays.checkedThursday) {
            isValid = false;
        }
        if (new Date(date).getDay() === 5 && !checkedDays.checkedFriday) {
            isValid = false;
        }
        if (new Date(date).getDay() === 6 && !checkedDays.checkedSaturday) {
            isValid = false;
        }
        return isValid;
    });
}
export function getFormattedDate(input) {
    try {
        var date = new Date(input);
        return dateFormat(date, "dd/mm/yyyy");
    }
    catch (e) {
        return "-";
    }
}
export function getFormattedDateTime(input) {
    try {
        var date = new Date(input);
        return dateFormat(date, "dd/mm/yyyy hh:MM TT");
    }
    catch (e) {
        return "-";
    }
}
export function getPreviousPeriod(startDate, endDate) {
    var previousStartDate = new Date(startDate.getTime() * 2 - endDate.getTime() - 1000);
    var previousEndDate = new Date(startDate.getTime() - 1000);
    return { previousStartDate: previousStartDate, previousEndDate: previousEndDate };
}
