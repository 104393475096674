var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { FormControl, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import { useSelector } from "react-redux";
import useStyles from "../../styles/styles";
export default function LocationSelector(props) {
    var id = props.id, label = props.label, defaultValue = props.defaultValue, setFieldValue = props.setFieldValue, setSuggestedLocation = props.setSuggestedLocation, setTouched = props.setTouched;
    var classes = useStyles();
    var locations = useSelector(function (state) { return state.locationManagement; }).locations;
    return (React.createElement(Grid, { item: true, xs: 6 }, React.createElement(FormControl, { className: classes.formControl100 }, React.createElement(Autocomplete, { id: id, options: locations, getOptionLabel: function (option) { return option.name || ""; }, defaultValue: locations.filter(function (el) { return el.id === defaultValue; })[0], onChange: function (_, value) {
            var _a, _b;
            setFieldValue(id, (_a = value) === null || _a === void 0 ? void 0 : _a.id);
            setSuggestedLocation((_b = value) === null || _b === void 0 ? void 0 : _b.id);
        }, onBlur: function () {
            var _a;
            return setTouched((_a = {}, _a[id] = true, _a));
        }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true }), label: label, variant: "filled" }))); } }))));
}
