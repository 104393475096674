import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-notifications/lib/notifications.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { NotificationContainer } from "react-notifications";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import configureStore from "./store";
import history from "./utils/history";
// @ts-ignore
import App from "./App";
var store = configureStore();
var THEME = createMuiTheme({
    typography: {
        fontFamily: "\"Rubik",
        fontSize: 14
    },
    props: {
        MuiButton: {
            disableElevation: true
        }
    },
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "transparent"
                }
            }
        },
        MuiInputBase: {
            input: {
                "&:-webkit-autofill": {
                    transitionDelay: "9999s",
                    transitionProperty: "background-color, color"
                }
            }
        }
    }
});
ReactDOM.render(React.createElement(MuiThemeProvider, { theme: THEME }, React.createElement(Provider, { store: store }, React.createElement(ConnectedRouter, { history: history }, React.createElement(NotificationContainer, null), React.createElement(App, null)))), document.getElementById("root"));
