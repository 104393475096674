import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as React from "react";
import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { changeUserPasswordAction, resetUserLoadingStateAction } from "./action";
import useStyles from "./styles";
import { LIGHT_BLUE, PRIMARY_BLUE } from "../../utils/colorCodes";
import loadingIcon from "../../assets/images/loadingBox.gif";
import { isPasswordUsedWithin90Days } from "../../utils/passwordValidation";
export default function ResetPasswordModal(props) {
    var dispatch = useDispatch();
    var classes = useStyles();
    var currentUser = props.currentUser, isResetPasswordModalOpen = props.isResetPasswordModalOpen, setResetPasswordModalOpen = props.setResetPasswordModalOpen;
    var _a = useSelector(function (state) { return state.userManagement; }), resetPasswordSuccess = _a.resetPasswordSuccess, resetPasswordIsLoading = _a.resetPasswordIsLoading;
    var yupSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required("Initial password required")
            .min(8, "Must contain at least 8 characters")
            .max(30, "Maximum length 30 characters")
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/])(?=.{8,})/, "Must contain one uppercase, one lowercase, one number and one special case character"),
        confirmNewPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    });
    var initialValue = {
        newPassword: "",
        confirmNewPassword: ""
    };
    React.useEffect(function () {
        if (resetPasswordSuccess) {
            setResetPasswordModalOpen(false);
            dispatch(resetUserLoadingStateAction());
        }
    }, [dispatch, resetPasswordSuccess, setResetPasswordModalOpen]);
    var _b = useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var _c = useState(false), showConfirmPassword = _c[0], setShowConfirmPassword = _c[1];
    var _d = useState(false), passwordUsedWithin90Days = _d[0], setPasswordUsedWithin90Days = _d[1];
    return (React.createElement(Dialog, { disableBackdropClick: true, open: isResetPasswordModalOpen, onClose: function () {
            setResetPasswordModalOpen(false);
        }, scroll: "body", maxWidth: "xs", fullWidth: true, "aria-labelledby": "scroll-dialog-title", "aria-describedby": "scroll-dialog-description", style: { left: "auto", top: "auto" }, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray",
                width: 444
            }
        } }, React.createElement(DialogTitle, { id: "scroll-dialog-title" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { style: { fontSize: 24, fontWeight: "bold", color: PRIMARY_BLUE } }, "Reset Password"), React.createElement(Button, { onClick: function () {
            setResetPasswordModalOpen(false);
        }, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null)))), React.createElement(Formik, { initialValues: initialValue, validationSchema: yupSchema, onSubmit: function (value) {
            var _a, _b, _c;
            var email = (_a = currentUser) === null || _a === void 0 ? void 0 : _a.email;
            var identifier = email.length === 0 ? ((_b = currentUser) === null || _b === void 0 ? void 0 : _b.uid) + "@email.com" : email;
            if (isPasswordUsedWithin90Days((_c = currentUser) === null || _c === void 0 ? void 0 : _c.lastPasswords, value.newPassword)) {
                setPasswordUsedWithin90Days(true);
            }
            else {
                dispatch(changeUserPasswordAction(identifier, value.newPassword));
            }
        } }, function (_a) {
        var errors = _a.errors, touched = _a.touched, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, handleBlur = _a.handleBlur;
        return (React.createElement("form", { onSubmit: handleSubmit }, React.createElement(DialogContent, null, React.createElement(Grid, { container: true, justify: "center", spacing: 2 }, React.createElement(Grid, { item: true, xs: 12 }, React.createElement("div", { className: classes.formControl100 }, React.createElement(TextField, { required: true, InputProps: {
                disableUnderline: true,
                endAdornment: (React.createElement(InputAdornment, { position: "end" }, React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return setShowPassword(!showPassword); }, onMouseDown: function () { return setShowPassword(!showPassword); } }, showPassword ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null))))
            }, fullWidth: true, defaultValue: initialValue.newPassword, onChange: handleChange, onBlur: handleBlur, id: "newPassword", type: showPassword ? "text" : "password", name: "newPassword", label: "New Password", variant: "filled" })), errors.newPassword && touched.newPassword && (React.createElement("div", { className: classes.error }, errors.newPassword)), passwordUsedWithin90Days && (React.createElement("div", { className: classes.error }, "This password used with in 90 days. Please choose a different password."))), React.createElement(Grid, { item: true, xs: 12 }, React.createElement("div", { className: classes.formControl100 }, React.createElement(TextField, { required: true, InputProps: {
                disableUnderline: true,
                endAdornment: (React.createElement(InputAdornment, { position: "end" }, React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return setShowConfirmPassword(!showConfirmPassword); }, onMouseDown: function () { return setShowConfirmPassword(!showConfirmPassword); } }, showConfirmPassword ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null))))
            }, fullWidth: true, defaultValue: initialValue.confirmNewPassword, onChange: handleChange, onBlur: handleBlur, id: "confirmNewPassword", type: showConfirmPassword ? "text" : "password", name: "confirmNewPassword", label: "Confirm New Password", variant: "filled" })), errors.confirmNewPassword && touched.confirmNewPassword && (React.createElement("div", { className: classes.error }, errors.confirmNewPassword))))), React.createElement(DialogActions, { style: { padding: 24 } }, React.createElement(Button, { disabled: resetPasswordIsLoading, component: "button",
            // @ts-ignore
            onClick: handleSubmit, color: "primary", style: {
                textTransform: "none",
                backgroundColor: LIGHT_BLUE,
                color: "white",
                minWidth: 150
            } }, "Save", resetPasswordIsLoading && (React.createElement("img", { src: loadingIcon, alt: "Icon Not Found", style: {
                height: 20,
                width: 20,
                position: "absolute",
                right: 10
            } }))))));
    })));
}
