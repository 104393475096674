var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++)
        s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as React from "react";
import { useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { Link as NavigatorLink } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { LIGHT_BLUE, PRIMARY_BLUE } from "../../../utils/colorCodes";
import useStyles from "../styles/styles";
import { changeTaskStatusApiAction, resetTaskLoadingStatesAction } from "../redux/actions";
import loadingIcon from "../../../assets/images/loadingBox.gif";
import { ROLE_STATION_USER } from "../../../utils/remoteConfig/roles/rolesEnum";
import { TASK_STATE_CANCELLED, TASK_STATE_COMPLETED, TASK_STATE_DISPATCHED, TASK_STATE_STARTED, TASK_STATE_TRAVEL } from "../../../utils/remoteConfig/taskStates/taskStateEnum";
import firebaseUpload from "../utils/firebaseUpload";
import HoverRating from "../Components/RateCompletedTask";
export default function ChangeTaskStateModal(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var dispatch = useDispatch();
    var classes = useStyles();
    var isViewButtonAvailable = props.isViewButtonAvailable, currentTask = props.currentTask, nextTaskState = props.nextTaskState, isChangeTaskStateModalOpen = props.isChangeTaskStateModalOpen, setChangeTaskStateModalOpen = props.setChangeTaskStateModalOpen;
    var furtherActions = useSelector(function (state) { return state.furtherActionManagement; }).furtherActions;
    var currentTaskFurtherAction = furtherActions.filter(function (action) { return action.id === currentTask.taskType; })[0];
    var _o = useSelector(function (state) { return state.taskManagement; }), changeTaskStatusSuccess = _o.changeTaskStatusSuccess, changeTaskStatusIsLoading = _o.changeTaskStatusIsLoading;
    var taskStates = useSelector(function (state) { return state.taskStateManagement; }).taskStates;
    var locations = useSelector(function (state) { return state.locationManagement; }).locations;
    var userList = useSelector(function (state) { return state.userManagement; }).userList;
    var skills = useSelector(function (state) { return state.skillManagement; }).skills;
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    var availableSkills = (_a = currentTask.criteria.skillCriteria) === null || _a === void 0 ? void 0 : _a.filter(function (el) { return el.skill.length !== 0; });
    var titleForRoleStationUser;
    var descriptionForRoleStationUser;
    var btnTextForRoleStationUser;
    switch (nextTaskState) {
        case TASK_STATE_DISPATCHED:
            titleForRoleStationUser = "Are you sure?";
            descriptionForRoleStationUser =
                "You are about to assign this task to you. Please confirm you are ready to take it. You will need to contact your supervisor to undo this action.";
            btnTextForRoleStationUser = "Assign to me and dispatch";
            break;
        case TASK_STATE_STARTED:
            titleForRoleStationUser = "Are you sure?";
            descriptionForRoleStationUser = "You are about to start this task.";
            btnTextForRoleStationUser = "Mark as Started";
            break;
        case TASK_STATE_TRAVEL:
            titleForRoleStationUser = "Are you sure?";
            descriptionForRoleStationUser = "You are about to traveling start for this task.";
            btnTextForRoleStationUser = "Mark as Start Traveling";
            break;
        case TASK_STATE_CANCELLED:
            titleForRoleStationUser = "Reason for cancellation";
            descriptionForRoleStationUser =
                "You will be sending a cancellation request to your supervisor. Please note that you will still be responsible for the task until it's cancelled. We will notify you if it is cancelled.";
            btnTextForRoleStationUser = "Send Cancellation Request";
            break;
        case TASK_STATE_COMPLETED:
            titleForRoleStationUser = "Are you sure?";
            descriptionForRoleStationUser = "You are about to complete this task";
            btnTextForRoleStationUser = "Mark as Completed";
            break;
        default:
            titleForRoleStationUser = "Are you sure?";
            descriptionForRoleStationUser = "";
            btnTextForRoleStationUser = "Done";
    }
    var _p = React.useState(""), reason = _p[0], setReason = _p[1];
    var _q = React.useState(""), remarks = _q[0], setRemarks = _q[1];
    var _r = React.useState(""), comment = _r[0], setComment = _r[1];
    var _s = React.useState(0), rating = _s[0], setRating = _s[1];
    var _t = React.useState([]), imagePreview = _t[0], setImagePreview = _t[1];
    var _u = React.useState(""), nextUser = _u[0], setNextUser = _u[1];
    var _v = React.useState(-1), nextDate = _v[0], setNextDate = _v[1];
    var _w = React.useState(""), furtherActionValue = _w[0], setFurtherActionValue = _w[1];
    var _x = React.useState(-1), amount = _x[0], setAmount = _x[1];
    var _y = React.useState(""), nextDateError = _y[0], setNextDateError = _y[1];
    var cancellationRequestIsReady = ((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.role) === ROLE_STATION_USER && nextTaskState === TASK_STATE_CANCELLED && reason.length === 0;
    var _z = React.useState(""), imageAsFile = _z[0], setImageAsFile = _z[1];
    var _0 = React.useState([]), imageAsUrl = _0[0], setImageAsUrl = _0[1];
    var handleImageAsFile = function (e) {
        e.preventDefault();
        var image = e.target.files[0];
        setImageAsFile(image);
        var tempArray = imagePreview;
        tempArray.push([URL.createObjectURL(image), image.name, (image.size / 1000 / 1000).toFixed(2)]);
        setImagePreview(tempArray);
    };
    var setDownloadUrl = function (downloadURL) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        setImageAsUrl(function (imageAsUrl) { return __spreadArrays(imageAsUrl, [downloadURL]); });
    };
    var removeImagePreview = function (index, name) {
        // remove corresponding image from preview
        var removedPreviewArray = imagePreview;
        removedPreviewArray.splice(index, 1);
        setImagePreview(__spreadArrays(removedPreviewArray));
        // remove corresponding image from imageAsUrl array
        var urlToRemove = imageAsUrl.filter(function (url) { return url.includes(name); });
        var urlArrayIndex = imageAsUrl.indexOf(urlToRemove[0]);
        var removedUrlArray = imageAsUrl;
        removedUrlArray.splice(urlArrayIndex, 1);
        setImageAsUrl(__spreadArrays(removedUrlArray));
    };
    useEffect(function () {
        if (changeTaskStatusSuccess) {
            setChangeTaskStateModalOpen(false);
            setImagePreview([]);
            setImageAsUrl([]);
            setRating(0);
            setAmount(-1);
            setNextDate(-1);
            setNextUser("");
            setFurtherActionValue("");
            setNextDateError("");
            dispatch(resetTaskLoadingStatesAction());
        }
    }, [changeTaskStatusSuccess, dispatch, setChangeTaskStateModalOpen]);
    useEffect(function () {
        if (imageAsFile !== "") {
            firebaseUpload(currentTask.id, imageAsFile, setDownloadUrl);
        }
    }, [currentTask.id, imageAsFile]);
    return (React.createElement(Dialog, { disableBackdropClick: true, open: isChangeTaskStateModalOpen, onClose: function () {
            setChangeTaskStateModalOpen(false);
            setImagePreview([]);
            setImageAsUrl([]);
            setRating(0);
            setAmount(-1);
            setNextDate(-1);
            setNextUser("");
            setFurtherActionValue("");
            setNextDateError("");
        }, scroll: "body", maxWidth: "xs", fullWidth: true, BackdropProps: {
            style: {
                backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
        }, PaperProps: {
            style: {
                boxShadow: "0px 0px 20px gray"
            }
        } }, React.createElement(DialogTitle, { id: "scroll-dialog-title" }, React.createElement(Box, { display: "flex", flexDirection: "row", style: { justifyContent: "space-between" } }, React.createElement(Typography, { style: { fontSize: 17, fontWeight: "bold", color: PRIMARY_BLUE } }, ((_c = loggedInUser) === null || _c === void 0 ? void 0 : _c.role) === ROLE_STATION_USER ? titleForRoleStationUser : "Confirm the task state change?"), React.createElement(Button, { onClick: function () {
            setChangeTaskStateModalOpen(false);
            setImagePreview([]);
            setImageAsUrl([]);
            setRating(0);
            setAmount(-1);
            setNextDate(-1);
            setNextUser("");
            setFurtherActionValue("");
            setNextDateError("");
        }, style: {
            backgroundColor: "transparent",
            padding: 0,
            minWidth: 36,
            marginRight: -11
        } }, React.createElement(CloseIcon, null)))), React.createElement(DialogContent, null, React.createElement(Grid, { container: true, justify: "flex-start", style: { flexDirection: "column" } }, nextTaskState === TASK_STATE_CANCELLED || nextTaskState === TASK_STATE_COMPLETED ? (React.createElement(React.Fragment, null, nextTaskState === TASK_STATE_CANCELLED && (React.createElement(TextField, { required: true, multiline: true, InputProps: { disableUnderline: true }, fullWidth: true, defaultValue: reason, onChange: function (e) { return setReason(e.target.value); }, id: "reason", label: "Reason", variant: "filled" })), nextTaskState === TASK_STATE_COMPLETED && (React.createElement(React.Fragment, null, React.createElement("span", null, "Rate"), React.createElement(HoverRating, { setValue: setRating, value: rating }), React.createElement(TextField, { multiline: true, InputProps: { disableUnderline: true }, fullWidth: true, onChange: function (e) { return setComment(e.target.value); }, id: "remarks", label: "Remarks", variant: "filled", style: {
            marginBottom: 10,
            marginTop: 10
        } }), React.createElement("div", { style: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            borderRadius: 3
        } }, React.createElement("label", { htmlFor: "files", className: "btn" }), React.createElement(Button, { component: "button", color: "primary", style: {
            textTransform: "none",
            backgroundColor: LIGHT_BLUE,
            color: "white",
            width: 120,
            height: 30,
            position: "relative",
            top: "0%",
            left: "0%"
        } }, "Upload Images"), React.createElement("input", { id: "files", style: {
            backgroundColor: "transparent",
            position: "absolute",
            opacity: 0,
            width: 120,
            height: 30
        }, type: "file", accept: "image/*", onChange: handleImageAsFile, multiple: false }), React.createElement(Grid, { container: true, direction: "row", alignItems: "flex-start" }, imagePreview.length > 0 &&
        imagePreview.map(function (image, index) {
            return (React.createElement(Grid, { item: true, key: index, style: { width: 115, marginRight: 10, marginTop: 10 } }, React.createElement("img", { style: { height: 100, width: 115 }, src: image[0], alt: "Uploaded Preview" }), React.createElement("p", { className: classes.imageFileName }, image[1]), React.createElement(Grid, { container: true, direction: "row", justify: "space-between", alignItems: "flex-start" }, React.createElement(Grid, { item: true }, React.createElement("p", { style: { marginTop: 8 } }, image[2], " MB")), React.createElement(Grid, { item: true }, React.createElement(IconButton, { onClick: function () { return removeImagePreview(index, image[1]); } }, React.createElement(DeleteIcon, null))))));
        }))), React.createElement(React.Fragment, null, React.createElement(Autocomplete, { id: "next-user", options: userList.filter(function (el) { return !el.archive; }), getOptionLabel: function (option) { return option.name || ""; }, onChange: function (_, value) { var _a; return setNextUser(((_a = value) === null || _a === void 0 ? void 0 : _a.documentID) || ""); }, renderInput: function (params) {
            return (React.createElement(TextField, __assign({}, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true, style: { marginTop: 10 } }), InputLabelProps: {
                    style: { marginTop: 10 }
                }, label: "Select Next User", variant: "filled" })));
        } }), React.createElement("div", { className: classes.datetimeTaskComplete }, React.createElement(TextField, { InputProps: { disableUnderline: true }, fullWidth: true, error: nextDateError !== "", onChange: function (el) {
            if (new Date().getTime() > new Date(el.target.value).getTime()) {
                setNextDateError("Cannot select past days");
            }
            else {
                setNextDateError("");
                setNextDate(new Date(el.target.value).getTime());
            }
        }, id: "next-date", label: "Select Next Date", type: "datetime-local", variant: "filled", className: classes.datetimeTextField, InputLabelProps: {
            shrink: true
        } })), nextDateError !== "" && React.createElement("p", { className: classes.errorText }, nextDateError), React.createElement(TextField, { InputProps: { disableUnderline: true, inputProps: { min: 0 } }, fullWidth: true, onChange: function (e) { return setAmount(parseInt(e.target.value, 10)); }, id: "amount", label: "Amount", type: "number", variant: "filled", style: {
            marginTop: 10
        } }), React.createElement(Autocomplete, { id: "further-actions", options: ((_d = currentTaskFurtherAction) === null || _d === void 0 ? void 0 : _d.actions) || [], getOptionLabel: function (option) { return option.value || ""; }, onChange: function (_, value) { var _a; return setFurtherActionValue(((_a = value) === null || _a === void 0 ? void 0 : _a.key) || ""); }, renderInput: function (params) {
            return (React.createElement(TextField, __assign({}, params, { InputProps: __assign(__assign({}, params.InputProps), { disableUnderline: true, style: { marginTop: 10 } }), InputLabelProps: {
                    style: { marginTop: 10 }
                }, label: "Further Action", variant: "filled" })));
        } })))), React.createElement(Typography, { className: classes.changeStateModalText, style: { marginTop: 30 } }, descriptionForRoleStationUser))) : (React.createElement(React.Fragment, null, React.createElement(Typography, { className: classes.changeStateModalText }, "Task will be " + ((_e = taskStates.filter(function (ts) { return ts.id === nextTaskState; })[0]) === null || _e === void 0 ? void 0 : _e.name)), ((_f = currentTask) === null || _f === void 0 ? void 0 : _f.assignedTo.length) !== 0 && (React.createElement(Typography, { className: classes.changeStateModalText }, "Assignee: " + ((_g = userList.filter(function (el) { var _a; return el.documentID === ((_a = currentTask) === null || _a === void 0 ? void 0 : _a.assignedTo); })[0]) === null || _g === void 0 ? void 0 : _g.name))), ((_h = currentTask) === null || _h === void 0 ? void 0 : _h.location.length) !== 0 && (React.createElement(Typography, { className: classes.changeStateModalText }, "Location: " + ((_j = locations.filter(function (ts) { var _a; return ts.id === ((_a = currentTask) === null || _a === void 0 ? void 0 : _a.location); })[0]) === null || _j === void 0 ? void 0 : _j.name))), availableSkills.length !== 0 && (React.createElement(Typography, { className: classes.changeStateModalText }, "Skills: " + ((_k = availableSkills) === null || _k === void 0 ? void 0 : _k.map(function (el) {
        var _a;
        return ((_a = skills.filter(function (s) { return s.id === el.skill; })[0]) === null || _a === void 0 ? void 0 : _a.name) + " (level: " + el.level + ")";
    }).join(", ")))), React.createElement(TextField, { multiline: true, InputProps: { disableUnderline: true }, fullWidth: true, onChange: function (e) { return setComment(e.target.value); }, id: "commentss", label: "Add Comment", variant: "filled", style: {
            marginBottom: 10,
            marginTop: 10
        } }), React.createElement("div", { style: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            borderRadius: 3
        } }, React.createElement("label", { htmlFor: "files", className: "btn" }), React.createElement(Button, { component: "button", color: "primary", style: {
            textTransform: "none",
            backgroundColor: LIGHT_BLUE,
            color: "white",
            width: 120,
            height: 30,
            position: "relative",
            top: "0%",
            left: "0%"
        } }, "Upload Image"), React.createElement("input", { id: "files", style: {
            backgroundColor: "transparent",
            position: "absolute",
            opacity: 0,
            width: 120,
            height: 30
        }, type: "file", accept: "image/*", onChange: handleImageAsFile, multiple: false }), React.createElement(Grid, { container: true, direction: "row", alignItems: "flex-start" }, imagePreview.length > 0 &&
        imagePreview.map(function (image, index) {
            return (React.createElement(Grid, { item: true, key: index, style: { width: 115, marginRight: 10, marginTop: 10 } }, React.createElement("img", { style: { height: 100, width: 115 }, src: image[0], alt: "Uploaded Preview" }), React.createElement("p", { className: classes.imageFileName }, image[1]), React.createElement(Grid, { container: true, direction: "row", justify: "space-between", alignItems: "flex-start" }, React.createElement(Grid, { item: true }, React.createElement("p", { style: { marginTop: 8 } }, image[2], " MB")), React.createElement(Grid, { item: true }, React.createElement(IconButton, { onClick: function () { return removeImagePreview(index, image[1]); } }, React.createElement(DeleteIcon, null))))));
        }))))))), React.createElement(DialogActions, { style: { padding: 24, paddingBottom: isViewButtonAvailable ? 15 : 24 } }, React.createElement("div", { style: {
            flexDirection: "column",
            width: "100%",
            justifyContent: "center"
        } }, React.createElement(Button, { disabled: changeTaskStatusIsLoading || cancellationRequestIsReady || imageAsUrl.length !== imagePreview.length, type: "submit", onClick: function () {
            var newTask = __assign({}, currentTask);
            newTask.completionImage = imageAsUrl;
            newTask.completionRating = rating;
            newTask.completionRemarks = comment;
            newTask.nextUser = nextUser === "" ? null : nextUser;
            newTask.nextDate = nextDate === -1 ? null : nextDate;
            newTask.furtherAction = furtherActionValue === "" ? null : furtherActionValue;
            newTask.amount = amount === -1 ? null : amount;
            dispatch(changeTaskStatusApiAction(newTask, nextTaskState, reason));
        }, color: "primary", fullWidth: true, style: {
            fontSize: 16,
            fontWeight: 500,
            textTransform: "none",
            backgroundColor: LIGHT_BLUE,
            color: "white",
            position: "relative"
        } }, ((_l = loggedInUser) === null || _l === void 0 ? void 0 : _l.role) === ROLE_STATION_USER
        ? btnTextForRoleStationUser
        : "Mark as " + ((_m = taskStates.filter(function (ts) { return ts.id === nextTaskState; })[0]) === null || _m === void 0 ? void 0 : _m.name), (changeTaskStatusIsLoading || imageAsUrl.length !== imagePreview.length) && (React.createElement("img", { src: loadingIcon, alt: "Not Found", style: {
            height: 30,
            width: 30,
            position: "absolute",
            right: 10
        } }))), isViewButtonAvailable && (React.createElement(Box, { display: "flex", justifyContent: "center" }, React.createElement(Link, { component: NavigatorLink, to: "/dashboard/tasks/" + currentTask.id, style: {
            fontSize: 16,
            color: LIGHT_BLUE,
            fontWeight: 500,
            marginTop: 5
        } }, "View Task")))))));
}
