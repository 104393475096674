import * as React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useSelector } from "react-redux";
import useStyles from "../views/Tasks/styles/styles";
import { PRIMARY_BLUE } from "../utils/colorCodes";
// @ts-ignore
export default function FilterByRoles(props) {
    var roleFilter = props.roleFilter, setRoleFilter = props.setRoleFilter;
    var classes = useStyles();
    var roles = useSelector(function (state) { return state.roleManagement; }).roles;
    return (React.createElement(FormControl, { className: classes.formStatusControl }, React.createElement(Select, { displayEmpty: true, disableUnderline: true, labelId: "filter-by-role-label", id: "filter-by-role", value: roleFilter, onChange: function (event) { return setRoleFilter(event.target.value); }, style: {
            textAlign: "left",
            color: PRIMARY_BLUE
        } }, React.createElement(MenuItem, { value: "", style: { color: PRIMARY_BLUE } }, "Filter by Role"), roles.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); }))));
}
