import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
export var CustomTooltip = withStyles(function (theme) {
    return ({
        tooltipPlacementTop: {
            margin: "15px 0"
        },
        tooltip: {
            backgroundColor: "#F7F7F7",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 250,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9"
        },
        arrow: {
            color: "#F7F7F7",
            fontSize: 14,
            width: 20,
            "&::before": {
                border: "1px solid #dadde9",
                backgroundColor: "#F7F7F7",
                boxSizing: "border-box"
            }
        }
    });
})(Tooltip);
export var CustomTooltipWhite = withStyles(function (theme) {
    return ({
        tooltipPlacementTop: {
            margin: "15px 0"
        },
        tooltip: {
            backgroundColor: "#ffece1",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 250,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid darkorange"
        },
        arrow: {
            color: "darkorange",
            fontSize: 14,
            width: 20,
            "&::before": {
                border: "1px solid #dadde9",
                backgroundColor: "darkorange",
                boxSizing: "border-box"
            }
        }
    });
})(Tooltip);
export var CustomTooltipRight = withStyles(function (theme) {
    return ({
        tooltipPlacementTop: {
            margin: "15px 0"
        },
        tooltip: {
            backgroundColor: "#F7F7F7",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 250,
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9"
        },
        arrow: {
            color: "#F7F7F7",
            fontSize: 14,
            width: 20,
            "&::before": {
                border: "1px solid #dadde9",
                backgroundColor: "#F7F7F7",
                boxSizing: "border-box"
            }
        }
    });
})(Tooltip);
