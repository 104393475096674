import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AppBar from "@material-ui/core/AppBar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useStyles from "./styles";
import ShowNotificationMenu from "./ShowNotificationMenu";
import ShowUserOptionMenu from "./ShowUserOptionMenu";
import AttendanceTypeMenu from "./AttendanceTypeMenu";
import { workTypeEnum } from "./reducer";
import { getAttendanceStatusAction } from "./actions";
import { ROLE_STATION_USER, ROLE_DIV_ADMIN_CUM_STATION_USER, ROLE_SUPERVISOR_CUM_STATION_USER } from "../../utils/remoteConfig/roles/rolesEnum";
export default function AppHeaderBar() {
    var _a, _b, _c, _d, _e, _f;
    var dispatch = useDispatch();
    var classes = useStyles();
    var _g = React.useState(null), anchorElAttendanceTypeMenu = _g[0], setAnchorElAttendanceTypeMenu = _g[1];
    var _h = React.useState(null), anchorElUserMenu = _h[0], setAnchorElUserMenu = _h[1];
    var _j = React.useState(null), anchorElNotifications = _j[0], setAnchorElNotifications = _j[1];
    var handleClose = function () {
        setAnchorElAttendanceTypeMenu(null);
        setAnchorElUserMenu(null);
        setAnchorElNotifications(null);
    };
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    var _k = useSelector(function (state) { return state.alertManagement; }), fetchAlertIsLoading = _k.fetchAlertIsLoading, listenAlertList = _k.listenAlertList, listenAlertIsLoading = _k.listenAlertIsLoading;
    var _l = useSelector(function (state) { return state.homePageManagement; }), workType = _l.workType, getAttendanceStateIsLoading = _l.getAttendanceStateIsLoading;
    var _m = React.useState(undefined), attendanceType = _m[0], setAttendanceType = _m[1];
    React.useEffect(function () {
        var _a;
        if ((_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.documentID) {
            dispatch(getAttendanceStatusAction(loggedInUser.documentID));
        }
    }, [dispatch, (_a = loggedInUser) === null || _a === void 0 ? void 0 : _a.documentID]);
    React.useEffect(function () {
        if (!getAttendanceStateIsLoading) {
            setAttendanceType(workType);
        }
    }, [getAttendanceStateIsLoading, workType]);
    return (React.createElement(AppBar, { id: "app-bar", position: "absolute", className: clsx(classes.appBar, classes.appBarShift), style: { backgroundColor: "white" } }, React.createElement(Toolbar, { className: classes.toolbar }, React.createElement("div", { style: { flexGrow: 1 } }), (((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.role) === ROLE_STATION_USER ||
        ((_c = loggedInUser) === null || _c === void 0 ? void 0 : _c.role) === ROLE_DIV_ADMIN_CUM_STATION_USER ||
        ((_d = loggedInUser) === null || _d === void 0 ? void 0 : _d.role) === ROLE_SUPERVISOR_CUM_STATION_USER) && (React.createElement(React.Fragment, null, React.createElement(FormControlLabel, { className: classes.toggle, value: "start", control: React.createElement(Switch, { checked: !!attendanceType,
            // onChange={event => setUserWorking(event.target.checked)}
            onClick: function (event) { return setAnchorElAttendanceTypeMenu(event.currentTarget); }, color: "primary" }), label: attendanceType
            ? attendanceType === workTypeEnum.UNKNOWN
                ? "I am working"
                : "I am working from " + attendanceType
            : "I am not working", labelPlacement: "start" }), React.createElement(AttendanceTypeMenu, { anchorElUserMenu: anchorElAttendanceTypeMenu, handleClose: handleClose, attendanceType: attendanceType }))), React.createElement(Button, { id: "app-bar-icon-btn", onClick: function (event) { return setAnchorElUserMenu(event.currentTarget); }, style: {
            backgroundColor: "transparent"
        }, disableRipple: true }, ((_e = loggedInUser) === null || _e === void 0 ? void 0 : _e.profileImg) ? (React.createElement(Avatar, { className: classes.avatar }, React.createElement("img", { src: loggedInUser.profileImg, height: "40px", width: "40px", style: { objectFit: "cover", borderRadius: "50%", flexDirection: "column" } }))) : (React.createElement(Avatar, { className: classes.avatar }, (_f = loggedInUser) === null || _f === void 0 ? void 0 : _f.name[0]))), ShowUserOptionMenu(anchorElUserMenu, handleClose), React.createElement(IconButton, { id: "app-bar-notification-icon", className: classes.notificationIcon, onClick: function (event) { return setAnchorElNotifications(event.currentTarget); } }, React.createElement(Badge, { id: "app-bar-notification-icon-count", badgeContent: !listenAlertIsLoading ? listenAlertList.filter(function (el) { return !el.seen; }).length : null, color: "secondary" }, React.createElement(NotificationsIcon, null))), ShowNotificationMenu(anchorElNotifications, handleClose))));
}
