/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { NotificationManager } from "react-notifications";
export var taskStatisticsActionTypes;
(function (taskStatisticsActionTypes) {
    taskStatisticsActionTypes["SET_STATISTICS_FILTERS"] = "field-workforce-management/task_statistics/set_statistics_filters";
    taskStatisticsActionTypes["CLEAR_STATISTICS_FILTERS"] = "field-workforce-management/task_statistics/clear_statistics_filters";
    taskStatisticsActionTypes["FETCH_TASK_STATISTICS"] = "field-workforce-management/task_statistics/fetch_task_statistics";
    taskStatisticsActionTypes["FETCH_TASK_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_task_statistics_success";
    taskStatisticsActionTypes["FETCH_TASK_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_task_statistics_failure";
    taskStatisticsActionTypes["FETCH_TASK_STATISTICS_CLOUD"] = "field-workforce-management/task_statistics/fetch_task_statistics_cloud";
    taskStatisticsActionTypes["FETCH_TASK_STATISTICS_SUCCESS_CLOUD"] = "field-workforce-management/task_statistics/fetch_task_statistics_success_cloud";
    taskStatisticsActionTypes["FETCH_TASK_STATISTICS_FAILURE_CLOUD"] = "field-workforce-management/task_statistics/fetch_task_statistics_failure_cloud";
    taskStatisticsActionTypes["FETCH_TASK_COMPLETION_STATISTICS"] = "field-workforce-management/task_statistics/fetch_task_completion_statistics";
    taskStatisticsActionTypes["FETCH_TASK_COMPLETION_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_task_completion_statistics_success";
    taskStatisticsActionTypes["FETCH_TASK_COMPLETION_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_task_completion_statistics_failure";
    taskStatisticsActionTypes["FETCH_TASK_OVERDUE_STATISTICS"] = "field-workforce-management/task_statistics/fetch_task_overdue_statistics";
    taskStatisticsActionTypes["FETCH_TASK_OVERDUE_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_task_overdue_statistics_success";
    taskStatisticsActionTypes["FETCH_TASK_OVERDUE_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_task_overdue_statistics_failure";
    taskStatisticsActionTypes["FETCH_TASK_STATUS_BREAKDOWN_STATISTICS"] = "field-workforce-management/task_statistics/fetch_task_status_breakdown_statistics";
    taskStatisticsActionTypes["FETCH_TASK_STATUS_BREAKDOWN_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_task_status_breakdown_statistics_success";
    taskStatisticsActionTypes["FETCH_TASK_STATUS_BREAKDOWN_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_task_status_breakdown_statistics_failure";
    taskStatisticsActionTypes["FETCH_UNUSUAL_ACTIVITIES_STATISTICS"] = "field-workforce-management/task_statistics/fetch_unusual_activities_statistics";
    taskStatisticsActionTypes["FETCH_UNUSUAL_ACTIVITIES_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_unusual_activities_statistics_success";
    taskStatisticsActionTypes["FETCH_UNUSUAL_ACTIVITIES_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_unusual_activities_statistics_failure";
    taskStatisticsActionTypes["FETCH_CANCEL_REQUESTS_STATISTICS"] = "field-workforce-management/task_statistics/fetch_cancel_requests_statistics";
    taskStatisticsActionTypes["FETCH_CANCEL_REQUESTS_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_cancel_requests_statistics_success";
    taskStatisticsActionTypes["FETCH_CANCEL_REQUESTS_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_cancel_requests_statistics_failure";
    taskStatisticsActionTypes["FETCH_NO_ATTENDANCE_STATISTICS"] = "field-workforce-management/task_statistics/fetch_no_attendance_statistics";
    taskStatisticsActionTypes["FETCH_NO_ATTENDANCE_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_no_attendance_statistics_success";
    taskStatisticsActionTypes["FETCH_NO_ATTENDANCE_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_no_attendance_failure";
    taskStatisticsActionTypes["FETCH_NO_COVERAGE_STATISTICS"] = "field-workforce-management/task_statistics/fetch_no_coverage_statistics";
    taskStatisticsActionTypes["FETCH_NO_COVERAGE_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_no_coverage_statistics_success";
    taskStatisticsActionTypes["FETCH_NO_COVERAGE_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_no_coverage_failure";
    taskStatisticsActionTypes["FETCH_NO_MOVEMENT_STATISTICS"] = "field-workforce-management/task_statistics/fetch_no_movement_statistics";
    taskStatisticsActionTypes["FETCH_NO_MOVEMENT_STATISTICS_SUCCESS"] = "field-workforce-management/task_statistics/fetch_no_movement_statistics_success";
    taskStatisticsActionTypes["FETCH_NO_MOVEMENT_STATISTICS_FAILURE"] = "field-workforce-management/task_statistics/fetch_no_movement_statistics_failure";
    taskStatisticsActionTypes["RESET_STATISTICS"] = "field-workforce-management/task_statistics/reset_statistics";
    taskStatisticsActionTypes["FETCH_UNUSUAL_STATISTICS_SUCCESS_CLOUD"] = "field-workforce-management/task_statistics/fetch_unusual_statistics_success_cloud";
    taskStatisticsActionTypes["FETCH_UNUSUAL_STATISTICS_FAILURE_CLOUD"] = "field-workforce-management/task_statistics/fetch_unusual_statistics_failure_cloud";
})(taskStatisticsActionTypes || (taskStatisticsActionTypes = {}));
export function fetchTaskStatisticsAction(startDate, endDate, groupFilter, userFilter, loggedInUserRole, taskTypeFilter, priorityFilter, sourceFilter) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATISTICS,
        startDate: startDate,
        endDate: endDate,
        groupFilter: groupFilter,
        userFilter: userFilter,
        loggedInUserRole: loggedInUserRole,
        taskTypeFilter: taskTypeFilter,
        priorityFilter: priorityFilter,
        sourceFilter: sourceFilter
    };
}
export function setStatisticsFilters(startDate, endDate, groupFilter, userFilter, loggedInUserRole, taskTypeFilter, priorityFilter, sourceFilter, daysFilter, creatorFilter) {
    return {
        type: taskStatisticsActionTypes.SET_STATISTICS_FILTERS,
        startDate: startDate,
        endDate: endDate,
        groupFilter: groupFilter,
        userFilter: userFilter,
        loggedInUserRole: loggedInUserRole,
        taskTypeFilter: taskTypeFilter,
        priorityFilter: priorityFilter,
        sourceFilter: sourceFilter,
        daysFilter: daysFilter,
        creatorFilter: creatorFilter
    };
}
export function clearStatisticsFilters() {
    return {
        type: taskStatisticsActionTypes.CLEAR_STATISTICS_FILTERS
    };
}
export function fetchTaskStatisticsSuccessAction(tasks) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATISTICS_SUCCESS,
        tasks: tasks
    };
}
export function fetchTaskStatisticsFailureAction(err) {
    console.error("fetchTaskStatisticsFailureAction", err);
    NotificationManager.error("Error occurred while loading task statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATISTICS_FAILURE,
        err: err
    };
}
export function fetchTaskStatisticsCloudAction(start, end, user, group, creators, _type, priority, source) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATISTICS_CLOUD,
        start: start,
        end: end,
        user: user,
        group: group,
        creators: creators,
        _type: _type,
        priority: priority,
        source: source
    };
}
export function fetchTaskStatisticsCloudFailureAction(err) {
    console.error("fetchTaskStatisticsFailureAction", err);
    NotificationManager.error("Error occurred while loading task statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATISTICS_FAILURE_CLOUD,
        err: err
    };
}
export function fetchUnusualStatisticsCloudFailureAction(err) {
    console.error("fetchUnusualStatisticsCloudFailureAction", err);
    NotificationManager.error("Error occurred while fetching unusual activities statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_UNUSUAL_STATISTICS_FAILURE_CLOUD,
        err: err
    };
}
export function fetchTaskStatisticsCloudSuccessAction(tasks, cancellationRequest
/*  noAttendance: number,
noAttendancePercentage: number,
noCoverage: number,
noCoveragePercentage: number,
noMovement: number,
noMovementPercentage: number */
) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATISTICS_SUCCESS_CLOUD,
        tasks: tasks,
        cancellationRequest: cancellationRequest
        /* noAttendance,
        noAttendancePercentage,
        noCoverage,
        noCoveragePercentage,
        noMovement,
        noMovementPercentage */
    };
}
export function fetchUnusualStatisticsCloudSuccessAction(noAttendance, noAttendancePercentage, noCoverage, noCoveragePercentage, noMovement, noMovementPercentage) {
    return {
        type: taskStatisticsActionTypes.FETCH_UNUSUAL_STATISTICS_SUCCESS_CLOUD,
        noAttendance: noAttendance,
        noAttendancePercentage: noAttendancePercentage,
        noCoverage: noCoverage,
        noCoveragePercentage: noCoveragePercentage,
        noMovement: noMovement,
        noMovementPercentage: noMovementPercentage
    };
}
export function fetchTaskCompletionAction(start, end, user, group, creators, _type, priority, source) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_COMPLETION_STATISTICS,
        start: start,
        end: end,
        user: user,
        group: group,
        creators: creators,
        _type: _type,
        priority: priority,
        source: source
    };
}
export function fetchTaskCompletionSuccessAction(taskCompletion) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_COMPLETION_STATISTICS_SUCCESS,
        taskCompletion: taskCompletion
    };
}
export function fetchTaskCompletionFailureAction(err) {
    console.error("fetchTaskCompletionFailureAction", err);
    NotificationManager.error("Error occurred while loading task completion statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_COMPLETION_STATISTICS_FAILURE,
        err: err
    };
}
export function fetchTaskOverdueAction(start, end, user, group, creators, _type, priority, source) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_OVERDUE_STATISTICS,
        start: start,
        end: end,
        user: user,
        group: group,
        creators: creators,
        _type: _type,
        priority: priority,
        source: source
    };
}
export function fetchTaskOverdueSuccessAction(taskOverdue) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_OVERDUE_STATISTICS_SUCCESS,
        taskOverdue: taskOverdue
    };
}
export function fetchTaskOverdueFailureAction(err) {
    console.error("fetchTaskOverdueFailureAction", err);
    NotificationManager.error("Error occurred while loading task overdue statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_OVERDUE_STATISTICS_FAILURE,
        err: err
    };
}
export function fetchTaskStatusBreakdownAction(start, end, user, group, creators, _type, priority, source) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATUS_BREAKDOWN_STATISTICS,
        start: start,
        end: end,
        user: user,
        group: group,
        creators: creators,
        _type: _type,
        priority: priority,
        source: source
    };
}
export function fetchTaskStatusBreakdownSuccessAction(taskStatusBreakdown) {
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATUS_BREAKDOWN_STATISTICS_SUCCESS,
        taskStatusBreakdown: taskStatusBreakdown
    };
}
export function fetchTaskStatusBreakdownStatisticsFailureAction(err) {
    console.error("fetchTaskStatusBreakdownFailureAction", err);
    NotificationManager.error("Error occurred while loading task status breakdown statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_TASK_STATUS_BREAKDOWN_STATISTICS_FAILURE,
        err: err
    };
}
export function fetchUnusualActivitiesAction(start, end, user, group) {
    return {
        type: taskStatisticsActionTypes.FETCH_UNUSUAL_ACTIVITIES_STATISTICS,
        start: start,
        end: end,
        user: user,
        group: group
    };
}
export function fetchUnusualActivitySuccessAction(unusualActivity) {
    return {
        type: taskStatisticsActionTypes.FETCH_UNUSUAL_ACTIVITIES_STATISTICS_SUCCESS,
        unusualActivity: unusualActivity
    };
}
export function fetchUnusualActivityStatisticsFailureAction(err) {
    console.error("fetchUnusualActivitiesFailureAction", err);
    NotificationManager.error("Error occurred while loading unusual activities statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_UNUSUAL_ACTIVITIES_STATISTICS_FAILURE,
        err: err
    };
}
export function fetchCancelRequestsStatisticsAction(startDate, endDate, groupFilter, userFilter, loggedInUserRole, taskTypeFilter, priorityFilter, sourceFilter) {
    return {
        type: taskStatisticsActionTypes.FETCH_CANCEL_REQUESTS_STATISTICS,
        startDate: startDate,
        endDate: endDate,
        groupFilter: groupFilter,
        userFilter: userFilter,
        loggedInUserRole: loggedInUserRole,
        taskTypeFilter: taskTypeFilter,
        priorityFilter: priorityFilter,
        sourceFilter: sourceFilter
    };
}
export function fetchCancelRequestsStatisticsSuccessAction(cancellationRequest) {
    return {
        type: taskStatisticsActionTypes.FETCH_CANCEL_REQUESTS_STATISTICS_SUCCESS,
        cancellationRequest: cancellationRequest
    };
}
export function fetchCancelRequestsStatisticsFailureAction(err) {
    console.error("fetchCancelRequestsStatisticsFailureAction", err);
    NotificationManager.error("Error occurred cancel requests loading task statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_CANCEL_REQUESTS_STATISTICS_FAILURE,
        err: err
    };
}
export function fetchNoAttendanceStatisticsAction(startDate, endDate, groupFilter, userFilter, loggedInUserRole) {
    return {
        type: taskStatisticsActionTypes.FETCH_NO_ATTENDANCE_STATISTICS,
        startDate: startDate,
        endDate: endDate,
        groupFilter: groupFilter,
        userFilter: userFilter,
        loggedInUserRole: loggedInUserRole
    };
}
export function fetchNoAttendanceStatisticsSuccessAction(noAttendance, noAttendancePercentage) {
    return {
        type: taskStatisticsActionTypes.FETCH_NO_ATTENDANCE_STATISTICS_SUCCESS,
        noAttendance: noAttendance,
        noAttendancePercentage: noAttendancePercentage
    };
}
export function fetchNoAttendanceStatisticsFailureAction(err) {
    console.error("fetchNoAttendanceStatisticsFailureAction", err);
    NotificationManager.error("Error occurred while loading no attendance statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_NO_ATTENDANCE_STATISTICS_FAILURE,
        err: err
    };
}
export function fetchNoCoverageStatisticsAction(startDate, endDate, groupFilter, userFilter, loggedInUserRole) {
    return {
        type: taskStatisticsActionTypes.FETCH_NO_COVERAGE_STATISTICS,
        startDate: startDate,
        endDate: endDate,
        groupFilter: groupFilter,
        userFilter: userFilter,
        loggedInUserRole: loggedInUserRole
    };
}
export function fetchNoCoverageStatisticsSuccessAction(noCoverage, noCoveragePercentage) {
    return {
        type: taskStatisticsActionTypes.FETCH_NO_COVERAGE_STATISTICS_SUCCESS,
        noCoverage: noCoverage,
        noCoveragePercentage: noCoveragePercentage
    };
}
export function fetchNoCoverageStatisticsFailureAction(err) {
    console.error("fetchNoCoverageStatisticsFailureAction", err);
    NotificationManager.error("Error occurred while loading no coverage statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_NO_COVERAGE_STATISTICS_FAILURE,
        err: err
    };
}
export function fetchNoMovementStatisticsAction(startDate, endDate, groupFilter, userFilter, loggedInUserRole) {
    return {
        type: taskStatisticsActionTypes.FETCH_NO_MOVEMENT_STATISTICS,
        startDate: startDate,
        endDate: endDate,
        groupFilter: groupFilter,
        userFilter: userFilter,
        loggedInUserRole: loggedInUserRole
    };
}
export function fetchNoMovementStatisticsSuccessAction(noMovement, noMovementPercentage) {
    return {
        type: taskStatisticsActionTypes.FETCH_NO_MOVEMENT_STATISTICS_SUCCESS,
        noMovement: noMovement,
        noMovementPercentage: noMovementPercentage
    };
}
export function fetchNoMovementStatisticsFailureAction(err) {
    console.error("fetchNoMovementStatisticsFailureAction", err);
    NotificationManager.error("Error occurred while loading task statistics. Please try again later.", "Oops! Error");
    return {
        type: taskStatisticsActionTypes.FETCH_NO_MOVEMENT_STATISTICS_FAILURE,
        err: err
    };
}
export function resetStatisticsAction() {
    return {
        type: taskStatisticsActionTypes.RESET_STATISTICS
    };
}
