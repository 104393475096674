var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { call, put, select } from "redux-saga/effects";
import { getFilteredDaysArray, getPreviousPeriod } from "../../utils/dateOperations";
import { fetchNoCoverageActivityFromApi } from "../NoCoverage";
import { fetchNoCoverageStatisticsFailureAction, fetchNoCoverageStatisticsSuccessAction } from "./actions";
import analyseNoCoverage from "../NoCoverage/analyseNoCoverage";
import { ROLE_ADMIN, ROLE_DIV_ADMIN, ROLE_SUPER_ADMIN } from "../../utils/remoteConfig/roles/rolesEnum";
import calculatePercentage from "../../utils/percentage";
// ------------------------------------------------------saga-4----------------------------------------------------------
function applyFiltersForNoCoverageStatistics(records, action, children) {
    return records
        .filter(function (el) {
        return [ROLE_SUPER_ADMIN, ROLE_DIV_ADMIN, ROLE_ADMIN].includes(action.loggedInUserRole || "") ||
            children.includes(el.userDocumentID);
    })
        .filter(function (el) {
        var _a;
        var isValid = true;
        if (action.groupFilter.length !== 0) {
            isValid = isValid && ((_a = el.userGroups) === null || _a === void 0 ? void 0 : _a.includes(action.groupFilter));
        }
        if (action.userFilter.length !== 0) {
            isValid = isValid && el.userDocumentID === action.userFilter;
        }
        return isValid;
    });
}
export default function callFetchNoCoverageStatistics(action) {
    var startDate, endDate, _a, previousStartDate, previousEndDate, userList, children, filteredDaysArray, filteredDaysArrayOfPreviousPeriod, resultNoCoverage, resultNoCoverageOfPreviousPeriod, records, recordsOfPreviousPeriod, filteredNoCoverageResult, filteredNoCoverageResultOfPreviousPeriod, v1, v2, e_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                startDate = action.startDate;
                endDate = action.endDate;
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 0);
                _a = getPreviousPeriod(startDate, endDate), previousStartDate = _a.previousStartDate, previousEndDate = _a.previousEndDate;
                return [4 /*yield*/, select(function (state) { return state.userManagement.userList; })];
            case 1:
                userList = _b.sent();
                return [4 /*yield*/, select(function (state) { return state.userManagement.children; })];
            case 2:
                children = _b.sent();
                _b.label = 3;
            case 3:
                _b.trys.push([3, 7, , 9]);
                filteredDaysArray = getFilteredDaysArray(action.startDate, action.endDate, [], {
                    checkedMonday: true,
                    checkedTuesday: true,
                    checkedWednesday: true,
                    checkedThursday: true,
                    checkedFriday: true,
                    checkedSaturday: false,
                    checkedSunday: false
                });
                filteredDaysArrayOfPreviousPeriod = getFilteredDaysArray(previousStartDate, previousEndDate, [], {
                    checkedMonday: true,
                    checkedTuesday: true,
                    checkedWednesday: true,
                    checkedThursday: true,
                    checkedFriday: true,
                    checkedSaturday: false,
                    checkedSunday: false
                });
                return [4 /*yield*/, call(fetchNoCoverageActivityFromApi, filteredDaysArray)];
            case 4:
                resultNoCoverage = _b.sent();
                return [4 /*yield*/, call(fetchNoCoverageActivityFromApi, filteredDaysArrayOfPreviousPeriod)];
            case 5:
                resultNoCoverageOfPreviousPeriod = _b.sent();
                records = analyseNoCoverage(resultNoCoverage, userList);
                recordsOfPreviousPeriod = analyseNoCoverage(resultNoCoverageOfPreviousPeriod, userList);
                filteredNoCoverageResult = applyFiltersForNoCoverageStatistics(records, action, children);
                filteredNoCoverageResultOfPreviousPeriod = applyFiltersForNoCoverageStatistics(recordsOfPreviousPeriod, action, children);
                v1 = filteredNoCoverageResult.length;
                v2 = filteredNoCoverageResultOfPreviousPeriod.length;
                return [4 /*yield*/, put(fetchNoCoverageStatisticsSuccessAction(v1, calculatePercentage(v1, v2)))];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7:
                e_1 = _b.sent();
                return [4 /*yield*/, put(fetchNoCoverageStatisticsFailureAction(e_1.toString()))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
