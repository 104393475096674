export var loginActionTypes;
(function (loginActionTypes) {
    loginActionTypes["CHANGE_PASSWORD"] = "field-workforce-management/login/change_password";
    loginActionTypes["CHANGE_PASSWORD_SUCCESS"] = "field-workforce-management/login/change_password_success";
    loginActionTypes["CHANGE_PASSWORD_FAILURE"] = "field-workforce-management/login/change_password_failure";
    loginActionTypes["RESET_CHANGE_PASSWORD_STATE"] = "field-workforce-management/login/reset_change_password_state";
    loginActionTypes["SIGN_IN_WITH_EMAIL_AND_PASSWORD"] = "field-workforce-management/login/sign_in_with_email_and_password";
    loginActionTypes["SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS"] = "field-workforce-management/login/sign_in_with_email_and_password_success";
    loginActionTypes["SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE"] = "field-workforce-management/login/sign_in_with_email_and_password_failure";
    loginActionTypes["SIGN_IN_WITH_CUSTOM_TOKEN"] = "field-workforce-management/login/sign_in_with_custom_token";
    loginActionTypes["SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS"] = "field-workforce-management/login/sign_in_with_custom_token_success";
    loginActionTypes["SIGN_IN_WITH_CUSTOM_TOKEN_FAILURE"] = "field-workforce-management/login/sign_in_with_custom_token_failure";
    loginActionTypes["SIGN_OUT"] = "field-workforce-management/login/sign_out";
    loginActionTypes["SET_SIGN_IN"] = "field-workforce-management/login/set_sign_in";
    loginActionTypes["FETCH_LOGGED_IN_USER"] = "field-workforce-management/login/fetch_logged_in_user";
    loginActionTypes["FETCH_LOGGED_IN_USER_SUCCESS"] = "field-workforce-management/login/fetch_logged_in_user_success";
    loginActionTypes["FETCH_LOGGED_IN_USER_FAILURE"] = "field-workforce-management/login/fetch_logged_in_user_failure";
    loginActionTypes["SET_MAX_LOGIN_ATTEMPTS"] = "field-workforce-management/login/set_max_login_attempts";
})(loginActionTypes || (loginActionTypes = {}));
export function signInWithEmailAndPasswordAction(email, password) {
    return {
        type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD,
        email: email,
        password: password
    };
}
export function signInWithEmailAndPasswordSuccessAction(status) {
    return {
        type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
        status: status
    };
}
export function signInWithEmailAndPasswordFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("signInWithEmailAndPasswordFailureAction", err);
    return {
        type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE,
        err: err
    };
}
export function changePasswordAction(email, oldPassword, newPassword) {
    return {
        type: loginActionTypes.CHANGE_PASSWORD,
        email: email,
        oldPassword: oldPassword,
        newPassword: newPassword
    };
}
export function changePasswordSuccessAction(status) {
    return {
        type: loginActionTypes.CHANGE_PASSWORD_SUCCESS,
        status: status
    };
}
export function changePasswordFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("changePasswordFailureAction", err);
    return {
        type: loginActionTypes.CHANGE_PASSWORD_FAILURE,
        err: err
    };
}
export function resetChangePasswordStateAction() {
    return {
        type: loginActionTypes.RESET_CHANGE_PASSWORD_STATE
    };
}
export function signInWithCustomTokenAction(email, password) {
    return {
        type: loginActionTypes.SIGN_IN_WITH_CUSTOM_TOKEN,
        email: email,
        password: password
    };
}
export function signInWithCustomTokenSuccessAction(status) {
    return {
        type: loginActionTypes.SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS,
        status: status
    };
}
export function signInWithCustomTokenFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("signInWithCustomTokenFailureAction", err);
    return {
        type: loginActionTypes.SIGN_IN_WITH_CUSTOM_TOKEN_FAILURE,
        err: err
    };
}
export function fetchLoggedInUserAction(userUID, email) {
    return {
        type: loginActionTypes.FETCH_LOGGED_IN_USER,
        userUID: userUID,
        email: email
    };
}
export function fetchLoggedInUserSuccessAction(user) {
    return {
        type: loginActionTypes.FETCH_LOGGED_IN_USER_SUCCESS,
        user: user
    };
}
export function fetchLoggedInUserFailureAction(err) {
    // eslint-disable-next-line no-console
    console.error("fetchLoggedInUserFailureAction", err);
    // NotificationManager.error(`Error occurred while fetching logged in user. Please try again later.`, `Oops! Error`);
    return {
        type: loginActionTypes.FETCH_LOGGED_IN_USER_FAILURE,
        err: err
    };
}
export function signOutAction() {
    return {
        type: loginActionTypes.SIGN_OUT
    };
}
export function setSignInAction() {
    return {
        type: loginActionTypes.SET_SIGN_IN
    };
}
export function setMaxLoginAttemptAction() {
    return {
        type: loginActionTypes.SET_MAX_LOGIN_ATTEMPTS
    };
}
