import { FormControl, MenuItem, Select } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { PRIMARY_BLUE } from "../utils/colorCodes";
import useStyles from "../views/Users/styles";
// @ts-ignore
export default function FilterBySkills(props) {
    var skillFilter = props.skillFilter, setSkillFilter = props.setSkillFilter;
    var skills = useSelector(function (state) { return state.skillManagement; }).skills;
    var classes = useStyles();
    return (React.createElement(FormControl, { className: classes.formStatusControl }, React.createElement(Select, { displayEmpty: true, disableUnderline: true, labelId: "filter-by-skill-label", id: "filter-by-skill", value: skillFilter, onChange: function (event) { return setSkillFilter(event.target.value); }, style: {
            textAlign: "left",
            color: PRIMARY_BLUE
        } }, React.createElement(MenuItem, { value: "", style: { color: PRIMARY_BLUE } }, "Filter by Skill"), skills.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); }))));
}
