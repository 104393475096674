var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Select, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
// import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Formik } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import EditIcon from "@material-ui/icons/Edit";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ImageUploader from "react-images-upload";
import useStyles from "./styles";
// @ts-ignore
import ProfilePicture from "../../assets/images/profile_pic.png";
import { LIGHT_BLUE, PRIMARY_PINK } from "../../utils/colorCodes";
import { updateUserProfileAction } from "./action";
import loadingIcon from "../../assets/images/loadingBox.gif";
import { isUserHasPermission } from "../../utils/isUserHasPermission";
function UserProfile() {
    var classes = useStyles();
    var dispatch = useDispatch();
    var accessControl = useSelector(function (state) { return state.accessControlManagement; }).accessControl;
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    var locations = useSelector(function (state) { return state.locationManagement; }).locations;
    var updateUserProfileIsLoading = useSelector(function (state) { return state.userManagement; }).updateUserProfileIsLoading;
    // const dispatch = useDispatch();
    var phoneRegExp = /^0[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;
    // const uidRegExp = /^[a-z0-9]+$/;
    var emailRegExp = /^([0-9a-z]([-._]*[0-9a-z])*@([0-9a-z]*[0-9a-z]\.)+[a-z0-9]{2,})$/;
    var yupSchema = Yup.object().shape({
        name: Yup.string().required("User name required"),
        email: Yup.string()
            // .required("Email required")
            .email("Invalid email")
            .when("isFirebaseUser", {
            is: true,
            then: Yup.string().matches(emailRegExp, "Email should be lowercase alphanumeric.")
        }),
        tp: Yup.array().of(Yup.string().matches(phoneRegExp, "Invalid contact"))
    }, [["groups", "interval"]]);
    var initialValues = __assign({}, loggedInUser);
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var _b = React.useState(null), picture = _b[0], setPicture = _b[1];
    var onDrop = function (image) {
        try {
            // console.log(image[0].size)
            if (image.length > 0) {
                setPicture(image);
                setOpen(false);
            }
        }
        catch (error) {
            console.error("Error- Image size exceeds 5mb.");
        }
    };
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null, React.createElement(Dialog, { onClose: handleClose, "aria-labelledby": "simple-dialog-title", open: open }, React.createElement(DialogTitle, { id: "simple-dialog-title" }, "Upload Profile Picture"), React.createElement(ImageUploader, { withIcon: true, buttonText: "Choose images", onChange: onDrop, imgExtension: [".jpg", ".png", ".jpeg"], maxFileSize: 5242880, fileSizeError: "File  size exceeds 5mb" })), React.createElement(Grid, { container: true, justify: "flex-start", style: { flexDirection: "column" } }, React.createElement(Typography, { className: classes.pageTitle }, "My Profile")), React.createElement(Grid, { container: true, justify: "center", style: { width: 300, marginTop: 30 } }, React.createElement("div", { style: { flex: 10 } }, React.createElement(EditIcon, { onClick: handleClickOpen, style: { marginTop: 130, marginLeft: 130, position: "absolute" } }), React.createElement("img", { src: picture ? window.URL.createObjectURL(picture[0]) : initialValues.profileImg || ProfilePicture, height: 150, width: 150, style: { objectFit: "cover", borderRadius: "50%", flexDirection: "column" } }))), React.createElement(Formik
    // @ts-ignore
    , {
        // @ts-ignore
        initialValues: initialValues, validationSchema: yupSchema, onSubmit: function (user) {
            // @ts-ignore
            dispatch(updateUserProfileAction(user, picture ? picture[0] || null : null));
        }
    }, function (_a) {
        var values = _a.values, errors = _a.errors, touched = _a.touched, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, handleBlur = _a.handleBlur;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return (React.createElement("form", { onSubmit: handleSubmit }, React.createElement(Grid, { container: true, justify: "center", style: { width: 300 } }, React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement("div", { className: classes.formControl100 }, React.createElement(TextField, { disabled: !isUserHasPermission((_c = (_b = accessControl) === null || _b === void 0 ? void 0 : _b.user_profile) === null || _c === void 0 ? void 0 : _c.edit, (_d = loggedInUser) === null || _d === void 0 ? void 0 : _d.role), required: true, InputProps: { disableUnderline: true }, fullWidth: true, defaultValue: values.name, onChange: handleChange, onBlur: handleBlur, id: "name", name: "name", label: "Name", variant: "filled" })), errors.name && touched.name && React.createElement("div", { className: classes.error }, errors.name)), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(FieldArray, { name: "tp", render: function (_a) {
                var remove = _a.remove, push = _a.push;
                var _b, _c, _d;
                return (React.createElement("div", null, values.tp.length > 0 &&
                    values.tp.map(function (number, ithElement) {
                        var _a, _b, _c;
                        return (React.createElement("div", { className: "row", key: ithElement }, React.createElement("div", { className: "col" }, React.createElement(Grid, { container: true, justify: "flex-end" }, React.createElement(TextField, { disabled: !isUserHasPermission((_b = (_a = accessControl) === null || _a === void 0 ? void 0 : _a.user_profile) === null || _b === void 0 ? void 0 : _b.edit, (_c = loggedInUser) === null || _c === void 0 ? void 0 : _c.role), InputProps: { disableUnderline: true }, fullWidth: true, defaultValue: number, onChange: handleChange, onBlur: handleBlur, name: "tp." + ithElement, label: "Telephone Number", variant: "filled" }), ithElement > 0 && (React.createElement(IconButton, { style: {
                                backgroundColor: "transparent"
                            }, disableRipple: true, type: "button", className: classes.addMoreBtn, onClick: function () { return remove(ithElement); } }, React.createElement(CloseIcon, null)))), errors.tp && errors.tp[ithElement] && touched.tp ? (React.createElement("div", { className: classes.error }, errors.tp[ithElement])) : (React.createElement("div", { style: { marginBottom: 10 } })))));
                    }), React.createElement(Link, { disabled: !isUserHasPermission((_c = (_b = accessControl) === null || _b === void 0 ? void 0 : _b.user_profile) === null || _c === void 0 ? void 0 : _c.edit, (_d = loggedInUser) === null || _d === void 0 ? void 0 : _d.role), type: "button", component: "button", underline: "none", style: {
                        textTransform: "none",
                        color: LIGHT_BLUE,
                        fontWeight: "bold",
                        fontSize: 17
                    }, onClick: function () { return push(""); } }, "+ Telephone Number")));
            } })), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(FormControl, { variant: "filled", className: classes.formControl100 }, React.createElement(InputLabel, { id: "filter-by-location-label" }, "Location"), React.createElement(Select, { disabled: !isUserHasPermission((_f = (_e = accessControl) === null || _e === void 0 ? void 0 : _e.user_profile) === null || _f === void 0 ? void 0 : _f.edit, (_g = loggedInUser) === null || _g === void 0 ? void 0 : _g.role), disableUnderline: true, labelId: "filter-by-location-label", id: "location", name: "location", defaultValue: values.location, onChange: handleChange, onBlur: handleBlur, style: {
                backgroundColor: PRIMARY_PINK,
                textAlign: "left",
                borderRadius: 4
            } }, React.createElement(MenuItem, { value: "" }, React.createElement("em", null, "None")), locations.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id }, el.name)); }))), errors.location && touched.location && React.createElement("div", { className: classes.error }, errors.location)), React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(TextField, { InputProps: { disableUnderline: true }, variant: "filled", fullWidth: true, defaultValue: values.email, name: "email", label: "Email", id: "email", disabled: true }))), React.createElement(Grid, { container: true, justify: "center", style: { width: 140 } }, React.createElement(Grid, { item: true, xs: 12, style: { marginTop: 16 } }, React.createElement(Button, { disabled: !isUserHasPermission((_j = (_h = accessControl) === null || _h === void 0 ? void 0 : _h.user_profile) === null || _j === void 0 ? void 0 : _j.edit, (_k = loggedInUser) === null || _k === void 0 ? void 0 : _k.role), component: "button", style: {
                backgroundColor: LIGHT_BLUE,
                color: "white",
                // fontSize: 16,
                fontWeight: 600,
                marginTop: 19,
                width: 140,
                height: 41,
                // borderRadius: "41px",
                textTransform: "none"
                // position: "relative"
            }, fullWidth: true, variant: "contained", id: "login-page-btn",
            // @ts-ignore
            onClick: handleSubmit }, "Save", updateUserProfileIsLoading && (React.createElement("img", { src: loadingIcon, alt: "Not Found", style: {
                height: 20,
                width: 20,
                position: "absolute",
                right: 10
            } })))))));
    })));
}
export default UserProfile;
