/* eslint-disable no-param-reassign */
import produce from "immer";
import { loginActionTypes } from "./action";
var initialState = {
    loginAttempts: 0,
    fetchLoggedInUserIsLoading: true,
    loggedInUser: undefined,
    fetchLoggedInUserError: { hasError: false, description: "" },
    isSignIn: false,
    signInWithEmailAndPasswordStatus: "",
    signInWithEmailAndPasswordIsLoading: false,
    signInWithEmailAndPasswordError: { hasError: false, description: "" },
    changePasswordIsSuccess: false,
    changePasswordIsLoading: false,
    changePasswordError: { hasError: false, description: "" },
    signInWithCustomTokenIsLoading: false,
    signInWithCustomTokenStatus: "",
    signInWithCustomTokenError: { hasError: false, description: "" }
};
export default function loginReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD: {
                draft.signInWithEmailAndPasswordIsLoading = true;
                draft.signInWithCustomTokenError = { hasError: false, description: "" };
                draft.signInWithEmailAndPasswordError = {
                    hasError: false,
                    description: ""
                };
                break;
            }
            case loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS: {
                draft.isSignIn = true;
                draft.signInWithEmailAndPasswordStatus = action.status;
                draft.signInWithEmailAndPasswordIsLoading = false;
                break;
            }
            case loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE: {
                draft.loginAttempts += 1;
                draft.isSignIn = false;
                draft.signInWithEmailAndPasswordIsLoading = false;
                draft.signInWithEmailAndPasswordError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case loginActionTypes.SIGN_IN_WITH_CUSTOM_TOKEN: {
                draft.signInWithCustomTokenIsLoading = true;
                draft.signInWithEmailAndPasswordError = {
                    hasError: false,
                    description: ""
                };
                draft.signInWithCustomTokenError = { hasError: false, description: "" };
                break;
            }
            case loginActionTypes.SIGN_IN_WITH_CUSTOM_TOKEN_SUCCESS: {
                draft.isSignIn = true;
                draft.signInWithCustomTokenStatus = action.status;
                draft.signInWithCustomTokenIsLoading = false;
                break;
            }
            case loginActionTypes.SIGN_IN_WITH_CUSTOM_TOKEN_FAILURE: {
                draft.loginAttempts += 1;
                draft.isSignIn = false;
                draft.signInWithCustomTokenIsLoading = false;
                draft.signInWithCustomTokenError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case loginActionTypes.FETCH_LOGGED_IN_USER: {
                draft.fetchLoggedInUserIsLoading = true;
                break;
            }
            case loginActionTypes.FETCH_LOGGED_IN_USER_SUCCESS: {
                draft.loggedInUser = action.user;
                draft.fetchLoggedInUserIsLoading = false;
                draft.fetchLoggedInUserError = { hasError: false, description: "" };
                break;
            }
            case loginActionTypes.FETCH_LOGGED_IN_USER_FAILURE: {
                draft.loggedInUser = undefined;
                draft.fetchLoggedInUserIsLoading = false;
                draft.fetchLoggedInUserError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case loginActionTypes.CHANGE_PASSWORD: {
                draft.changePasswordIsLoading = true;
                draft.changePasswordError = { hasError: false, description: "" };
                break;
            }
            case loginActionTypes.CHANGE_PASSWORD_SUCCESS: {
                draft.changePasswordIsSuccess = true;
                draft.changePasswordIsLoading = false;
                draft.changePasswordError = { hasError: false, description: "" };
                break;
            }
            case loginActionTypes.CHANGE_PASSWORD_FAILURE: {
                draft.changePasswordIsLoading = false;
                draft.changePasswordError = { hasError: true, description: action.err };
                break;
            }
            case loginActionTypes.RESET_CHANGE_PASSWORD_STATE: {
                draft.changePasswordIsSuccess = false;
                draft.changePasswordIsLoading = false;
                draft.changePasswordError = { hasError: false, description: "" };
                break;
            }
            case loginActionTypes.SIGN_OUT: {
                draft.isSignIn = false;
                draft.signInWithEmailAndPasswordStatus = "";
                break;
            }
            case loginActionTypes.SET_SIGN_IN: {
                draft.isSignIn = true;
                break;
            }
            case loginActionTypes.SET_MAX_LOGIN_ATTEMPTS: {
                draft.loginAttempts = 3;
                break;
            }
            default:
                break;
        }
    });
}
