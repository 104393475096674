import { Box, Button, FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Rating from "@material-ui/lab/Rating";
import moment from "moment";
import { allowedTaskStateForAdmin, allowedTaskStateForFreezedUser, allowedTaskStateForSelfAssigneWorker, allowedTaskStateForWorker } from "../../../../utils/taskStateHandler";
import { ERROR_HIGH, LIGHT_BLUE, PRIMARY_BLUE } from "../../../../utils/colorCodes";
import { isUserHasPermission } from "../../../../utils/isUserHasPermission";
import { TASK_STATE_COMPLETED, TASK_STATE_STARTED, TASK_STATE_TRAVEL } from "../../../../utils/remoteConfig/taskStates/taskStateEnum";
import useStyles from "../../styles/styles";
import ChangeTaskStateModal from "../../Pages/ChangeTaskStateModal";
import OpenImageModal from "../../../RouteHistory/OpenImageModal";
import CreateUpdateTaskModal from "../../Pages/CreateUpdateTaskModal";
import DeleteTaskModal from "../../Pages/DeleteTaskModal";
import AcceptOrRejectRequestModal from "../../../CancellationRequests/AcceptOrRejectRequestModal";
import ShowTaskDeleteOptionMenu from "./ShowTaskDeleteOptionMenu";
import { ROLE_ADMIN, ROLE_DIV_ADMIN, ROLE_DIV_ADMIN_CUM_STATION_USER, ROLE_STATION_USER, ROLE_SUPER_ADMIN, ROLE_SUPERVISOR, ROLE_SUPERVISOR_CUM_STATION_USER, ROLE_USER } from "../../../../utils/remoteConfig/roles/rolesEnum";
import { getFormattedDateTime } from "../../../../utils/dateOperations";
import getReadableTaskStatus from "../../../../utils/getReadableTaskStatus";
export default function RightInfoPanel(_a) {
    var currentTask = _a.currentTask, nextUser = _a.nextUser;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
    var classes = useStyles();
    var accessControl = useSelector(function (state) { return state.accessControlManagement; }).accessControl;
    var workType = useSelector(function (state) { return state.homePageManagement; }).workType;
    var _5 = useSelector(function (state) { return state.userManagement; }), userList = _5.userList, children = _5.children;
    var loggedInUser = useSelector(function (state) { return state.loginManagement; }).loggedInUser;
    var cancellationRequest = useSelector(function (state) { return state.taskManagement; }).cancellationRequest;
    var taskStates = useSelector(function (state) { return state.taskStateManagement; }).taskStates;
    var _6 = React.useState(null), anchorElTaskDeleteMenu = _6[0], setAnchorElTaskDeleteMenu = _6[1];
    var handleCloseTaskDeleteMenu = function () {
        setAnchorElTaskDeleteMenu(null);
    };
    var _7 = React.useState(true), deleteThisTaskOnly = _7[0], setDeleteThisTaskOnly = _7[1];
    var _8 = React.useState(""), nextTaskState = _8[0], setNextTaskState = _8[1];
    var _9 = React.useState(false), isChangeTaskStateModalOpen = _9[0], setChangeTaskStateModalOpen = _9[1];
    var _10 = React.useState(false), isCreateUpdateTaskModalOpen = _10[0], setCreateUpdateTaskModalOpen = _10[1];
    var _11 = React.useState(false), isToggleArchiveTaskModalOpen = _11[0], setToggleArchiveTaskModalOpen = _11[1];
    var _12 = React.useState(false), isAcceptOrRejectRequestModalOpen = _12[0], setAcceptOrRejectRequestModalOpen = _12[1];
    var _13 = React.useState(""), isModalOpen = _13[0], setModalOpen = _13[1];
    var isDeleteDisable = ((_b = loggedInUser) === null || _b === void 0 ? void 0 : _b.role) === ROLE_STATION_USER
        ? true
        : [TASK_STATE_TRAVEL, TASK_STATE_STARTED, TASK_STATE_COMPLETED].includes(currentTask.state);
    var isEditDisable = ((_c = loggedInUser) === null || _c === void 0 ? void 0 : _c.role) === ROLE_STATION_USER
        ? true
        : [TASK_STATE_TRAVEL, TASK_STATE_STARTED, TASK_STATE_COMPLETED].includes(currentTask.state);
    var optionList;
    var isChangeTaskStateDisable;
    switch ((_d = loggedInUser) === null || _d === void 0 ? void 0 : _d.role) {
        case ROLE_USER:
            if (currentTask.assignedTo === ((_e = loggedInUser) === null || _e === void 0 ? void 0 : _e.documentID)) {
                var data = allowedTaskStateForWorker(currentTask.state, taskStates);
                isChangeTaskStateDisable = data.length === 1;
                optionList = data.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); });
            }
            else {
                var data = allowedTaskStateForFreezedUser(currentTask.state, taskStates);
                isChangeTaskStateDisable = data.length === 1;
                optionList = data.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); });
            }
            break;
        case ROLE_STATION_USER:
            if (currentTask.assignedTo === ((_f = loggedInUser) === null || _f === void 0 ? void 0 : _f.documentID)) {
                var data = allowedTaskStateForWorker(currentTask.state, taskStates);
                isChangeTaskStateDisable = data.length === 1;
                optionList = data.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); });
            }
            else {
                var data = allowedTaskStateForFreezedUser(currentTask.state, taskStates);
                isChangeTaskStateDisable = data.length === 1;
                optionList = data.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); });
            }
            break;
        case ROLE_SUPER_ADMIN:
        case ROLE_ADMIN:
        case ROLE_DIV_ADMIN:
        case ROLE_SUPERVISOR:
            if (currentTask.assignedTo === ((_g = loggedInUser) === null || _g === void 0 ? void 0 : _g.documentID)) {
                var data = allowedTaskStateForSelfAssigneWorker(currentTask.state, taskStates);
                isChangeTaskStateDisable = data.length === 1;
                optionList = data.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); });
            }
            else {
                var data = allowedTaskStateForAdmin(currentTask.state, taskStates);
                isChangeTaskStateDisable = data.length === 1;
                optionList = data.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); });
            }
            break;
        case ROLE_SUPERVISOR_CUM_STATION_USER:
        case ROLE_DIV_ADMIN_CUM_STATION_USER:
        default:
            if (workType !== undefined && currentTask.assignedTo === ((_h = loggedInUser) === null || _h === void 0 ? void 0 : _h.documentID)) {
                var data = allowedTaskStateForSelfAssigneWorker(currentTask.state, taskStates);
                isChangeTaskStateDisable = data.length === 1;
                optionList = data.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); });
            }
            else {
                var data = allowedTaskStateForAdmin(currentTask.state, taskStates);
                isChangeTaskStateDisable = data.length === 1;
                optionList = data.map(function (el) { return (React.createElement(MenuItem, { key: el.id, value: el.id, style: { color: PRIMARY_BLUE } }, el.name)); });
            }
    }
    return (React.createElement(React.Fragment, null, React.createElement(ChangeTaskStateModal, { isViewButtonAvailable: false, currentTask: currentTask, nextTaskState: nextTaskState, isChangeTaskStateModalOpen: isChangeTaskStateModalOpen, setChangeTaskStateModalOpen: setChangeTaskStateModalOpen }), React.createElement(OpenImageModal, { isModalOpen: isModalOpen, setModalOpen: setModalOpen }), React.createElement(CreateUpdateTaskModal, { currentTask: currentTask, dialogType: "edit", isCreateUpdateTaskModalOpen: isCreateUpdateTaskModalOpen, setCreateUpdateTaskModalOpen: setCreateUpdateTaskModalOpen }), React.createElement(DeleteTaskModal, { currentTask: currentTask, deleteThisTaskOnly: deleteThisTaskOnly, isToggleArchiveTaskModalOpen: isToggleArchiveTaskModalOpen, setToggleArchiveTaskModalOpen: setToggleArchiveTaskModalOpen }), cancellationRequest && (React.createElement(AcceptOrRejectRequestModal, { currentCancellationRequest: cancellationRequest, currentTask: currentTask, dialogType: "reject", isAcceptOrRejectRequestModalOpen: isAcceptOrRejectRequestModalOpen, setAcceptOrRejectRequestModalOpen: setAcceptOrRejectRequestModalOpen })), (isUserHasPermission((_k = (_j = accessControl) === null || _j === void 0 ? void 0 : _j.tasks) === null || _k === void 0 ? void 0 : _k.delete, (_l = loggedInUser) === null || _l === void 0 ? void 0 : _l.role) ||
        isUserHasPermission((_o = (_m = accessControl) === null || _m === void 0 ? void 0 : _m.tasks) === null || _o === void 0 ? void 0 : _o.edit, (_p = loggedInUser) === null || _p === void 0 ? void 0 : _p.role)) && (React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "flex-end", style: { marginBottom: 15 } }, isUserHasPermission((_r = (_q = accessControl) === null || _q === void 0 ? void 0 : _q.tasks) === null || _r === void 0 ? void 0 : _r.delete, (_s = loggedInUser) === null || _s === void 0 ? void 0 : _s.role) && (React.createElement(Button, { disabled: isDeleteDisable, onClick: function (event) {
            if (currentTask.isChild) {
                setAnchorElTaskDeleteMenu(event.currentTarget);
            }
            else {
                setAnchorElTaskDeleteMenu(event.currentTarget);
                // setToggleArchiveTaskModalOpen(true);
            }
        }, style: {
            color: isDeleteDisable ? "lightgray" : ERROR_HIGH,
            borderColor: isDeleteDisable ? "lightgray" : ERROR_HIGH,
            textTransform: "none",
            fontSize: 16,
            fontWeight: 600,
            width: 120,
            height: 40
        }, variant: "outlined" }, "Delete")), React.createElement(ShowTaskDeleteOptionMenu, { currentTask: currentTask, anchorElTaskDeleteMenu: anchorElTaskDeleteMenu, handleCloseTaskDeleteMenu: handleCloseTaskDeleteMenu, setToggleArchiveTaskModalOpen: setToggleArchiveTaskModalOpen, setDeleteThisTaskOnly: setDeleteThisTaskOnly }), isUserHasPermission((_u = (_t = accessControl) === null || _t === void 0 ? void 0 : _t.tasks) === null || _u === void 0 ? void 0 : _u.edit, (_v = loggedInUser) === null || _v === void 0 ? void 0 : _v.role) && !currentTask.isChild && (React.createElement(Button, { disabled: isEditDisable, onClick: function () { return setCreateUpdateTaskModalOpen(true); }, style: {
            color: isEditDisable ? "lightgray" : LIGHT_BLUE,
            borderColor: isEditDisable ? "lightgray" : LIGHT_BLUE,
            textTransform: "none",
            fontSize: 16,
            fontWeight: 600,
            width: 120,
            height: 40,
            marginLeft: 10
        }, variant: "outlined" }, "Edit")))), isUserHasPermission((_x = (_w = accessControl) === null || _w === void 0 ? void 0 : _w.tasks) === null || _x === void 0 ? void 0 : _x.edit_status, (_y = loggedInUser) === null || _y === void 0 ? void 0 : _y.role) && (React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "flex-end" }, React.createElement(FormControl, { style: {
            backgroundColor: isChangeTaskStateDisable ? "lightgray" : LIGHT_BLUE,
            width: 220,
            height: 40,
            borderRadius: 4,
            paddingTop: 5,
            paddingLeft: 10
        } }, React.createElement(Select, { disabled: isChangeTaskStateDisable, inputProps: {
            classes: {
                icon: classes.icon
            }
        }, displayEmpty: true, disableUnderline: true, labelId: "filter-by-status-label", id: "filter-by-status", value: currentTask.state, onChange: function (e) {
            setNextTaskState(e.target.value);
            setChangeTaskStateModalOpen(true);
        }, style: {
            textAlign: "left",
            color: "white"
        } }, optionList)))), cancellationRequest && (React.createElement("div", null, React.createElement(Typography, { style: {
            textAlign: "right",
            marginTop: 20,
            fontWeight: 600,
            fontSize: 16,
            color: LIGHT_BLUE
        } }, "CANCELLATION REQUESTED"), React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "flex-end" }, React.createElement(Typography, { style: {
            textAlign: "right",
            fontWeight: 600,
            fontSize: 16,
            color: LIGHT_BLUE
        } }, "Reason: " + ((_z = cancellationRequest) === null || _z === void 0 ? void 0 : _z.reason))), React.createElement(Box, { display: "flex", flexDirection: "row", justifyContent: "flex-end" }, isUserHasPermission((_1 = (_0 = accessControl) === null || _0 === void 0 ? void 0 : _0.cancellation_requests) === null || _1 === void 0 ? void 0 : _1.reject_request, (_2 = loggedInUser) === null || _2 === void 0 ? void 0 : _2.role) && (React.createElement(Button, { onClick: function () { return setAcceptOrRejectRequestModalOpen(true); }, style: {
            color: LIGHT_BLUE,
            borderColor: LIGHT_BLUE,
            textTransform: "none",
            fontSize: 16,
            fontWeight: 600,
            height: 40,
            marginTop: 20
        }, variant: "outlined" }, "Reject Request"))))), React.createElement(Timeline, { className: classes.timeline }, (_4 = (_3 = currentTask) === null || _3 === void 0 ? void 0 : _3.stateHistory) === null || _4 === void 0 ? void 0 : _4.slice(0).reverse().map(function (element, indexNum) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return (React.createElement(TimelineItem, { key: indexNum, classes: { root: classes.timeLineItem } }, React.createElement(TimelineOppositeContent, { style: { maxWidth: 100 } }, React.createElement(Typography, { color: "textSecondary", style: {
                fontWeight: 400,
                fontSize: 14
            } }, getFormattedDateTime(element.timestamp.seconds * 1000))), React.createElement(TimelineSeparator, null, React.createElement(TimelineDot, null), React.createElement(TimelineConnector, null)), React.createElement(TimelineContent, { className: classes.timeLineContent }, React.createElement(Card, { className: classes.timelineCard }, React.createElement(CardContent, null, React.createElement(Typography, { style: {
                fontWeight: 600,
                fontSize: 14
            } }, "State: ", ((_a = element) === null || _a === void 0 ? void 0 : _a.state) ? getReadableTaskStatus(element.state) : " - ", " "), React.createElement(Typography, { style: {
                fontWeight: 400,
                fontSize: 14
            } }, "By: ", (_c = (_b = userList.filter(function (eli) { return eli.documentID === element.changedBy.id; })[0]) === null || _b === void 0 ? void 0 : _b.name, (_c !== null && _c !== void 0 ? _c : " - "))), React.createElement(Typography, { style: {
                fontWeight: 400,
                fontSize: 14
            } }, ((_d = element) === null || _d === void 0 ? void 0 : _d.comment) ? element.state === "completed"
            ? "Remark: " + element.comment
            : "Comment: " + element.comment
            : null), element.state === "completed" && (React.createElement(Box, null, React.createElement(Typography, { style: {
                fontWeight: 400,
                fontSize: 14
            } }, "Rating:"), React.createElement(Rating, { value: currentTask.completionRating ? currentTask.completionRating : 0, readOnly: true, precision: 1 }))), ((_f = (_e = element) === null || _e === void 0 ? void 0 : _e.photo) === null || _f === void 0 ? void 0 : _f.length) > 0 && (React.createElement(Box, { display: "flex", flexDirection: "column", style: { marginTop: 15, marginBottom: "10px" } }, (_g = element) === null || _g === void 0 ? void 0 : _g.photo.map(function (el, index) {
            return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
            React.createElement("img", { src: el, key: index, onClick: function () { return setModalOpen(el); }, alt: "Not Found", style: {
                    width: "100%",
                    height: "130px",
                    border: "1px solid gray",
                    objectFit: "cover",
                    marginTop: index !== 0 ? 10 : 0
                } }));
        }))), element.state === "completed" && (React.createElement(Box, null, React.createElement(Typography, { style: {
                fontWeight: 400,
                fontSize: 14,
                marginBottom: 10
            } }, nextUser ? "Next User: " + ((_h = nextUser) === null || _h === void 0 ? void 0 : _h.name) : null), React.createElement(Typography, { style: {
                fontWeight: 400,
                fontSize: 14,
                marginBottom: 10
            } }, currentTask.nextDate
            ? "Next Date: " + moment(currentTask.nextDate.toDate()).format("DD/MM/YYYY")
            : null), React.createElement(Typography, { style: {
                fontWeight: 400,
                fontSize: 14,
                marginBottom: 10
            } }, currentTask.amount ? "Amount: " + currentTask.amount : null), currentTask.furtherAction && (React.createElement(React.Fragment, null, React.createElement(Typography, { style: {
                fontWeight: 400,
                fontSize: 14
            } }, currentTask.furtherAction ? "Further Action:" : null), (_k = (_j = currentTask) === null || _j === void 0 ? void 0 : _j.furtherAction) === null || _k === void 0 ? void 0 :
            _k.split("|").map(function (el) {
                return (React.createElement(Typography, { style: {
                        fontWeight: 400,
                        fontSize: 14
                    } }, el));
            }))))))))));
    }))));
}
