import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import useStyles from "../../styles/styles";
export default function AvailableLocations(props) {
    var classes = useStyles();
    var locations = useSelector(function (state) { return state.locationManagement; }).locations;
    var availableLocations = props.availableLocations;
    return (React.createElement(Grid, { item: true, lg: 6 }, availableLocations.map(function (lc, i) {
        var _a;
        return (React.createElement("div", { className: classes.viewTaskBody, key: i }, React.createElement(Typography, { className: classes.taskDetailCardTitle }, "Location"), React.createElement(Typography, { className: classes.taskDetailCardBody }, (_a = locations.filter(function (el) { return el.id === lc; })[0]) === null || _a === void 0 ? void 0 : _a.name)));
    })));
}
