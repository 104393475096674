var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable no-param-reassign, no-underscore-dangle */
import { NotificationManager } from "react-notifications";
import produce from "immer";
import { call, put, takeEvery } from "redux-saga/effects";
import { remoteConfig } from "../../firebaseConnector";
var furtherActionsActionTypes;
(function (furtherActionsActionTypes) {
    furtherActionsActionTypes["FETCH_FURTHER_ACTIONS"] = "field-workforce-management/furtherActions/fetch_furtherActions";
    furtherActionsActionTypes["FETCH_FURTHER_ACTIONS_SUCCESS"] = "field-workforce-management/furtherActions/fetch_furtherActions_success";
    furtherActionsActionTypes["FETCH_FURTHER_ACTIONS_FAILURE"] = "field-workforce-management/furtherActions/fetch_furtherActions_failure";
})(furtherActionsActionTypes || (furtherActionsActionTypes = {}));
function fetchFurtherActionsAction() {
    return {
        type: furtherActionsActionTypes.FETCH_FURTHER_ACTIONS
    };
}
function fetchFurtherActionsSuccessAction(furtherActions) {
    return {
        type: furtherActionsActionTypes.FETCH_FURTHER_ACTIONS_SUCCESS,
        furtherActions: furtherActions
    };
}
function fetchFurtherActionsFailureAction(err) {
    console.error("fetchFurtherActionsFailureAction", err);
    NotificationManager.error("Error occurred while loading Further Actions. Please try again later.", "Oops! Error");
    return {
        type: furtherActionsActionTypes.FETCH_FURTHER_ACTIONS_FAILURE,
        err: err
    };
}
var initialState = {
    furtherActions: [],
    fetchFurtherActionsIsLoading: false,
    fetchFurtherActionsFailure: { description: "", hasError: false }
};
export default function furtherActionsReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case furtherActionsActionTypes.FETCH_FURTHER_ACTIONS: {
                draft.fetchFurtherActionsIsLoading = true;
                break;
            }
            case furtherActionsActionTypes.FETCH_FURTHER_ACTIONS_SUCCESS: {
                draft.furtherActions = action.furtherActions;
                draft.fetchFurtherActionsIsLoading = false;
                draft.fetchFurtherActionsFailure = { hasError: false, description: "" };
                break;
            }
            case furtherActionsActionTypes.FETCH_FURTHER_ACTIONS_FAILURE: {
                draft.fetchFurtherActionsIsLoading = false;
                draft.fetchFurtherActionsFailure = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            default:
                break;
        }
    });
}
// ------------------------------------------------------saga-1----------------------------------------------------------
function fetchFurtherActionsFromFirestore() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // @ts-ignore
            return [2 /*return*/, JSON.parse(remoteConfig.getValue("furtherActions")._value)];
        });
    });
}
function callFetchFurtherActions() {
    var result_1, furtherActionsList, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(fetchFurtherActionsFromFirestore)];
            case 1:
                result_1 = _a.sent();
                furtherActionsList = Object.keys(result_1).map(function (el) {
                    return {
                        id: el,
                        nextUser: result_1[el].next_user,
                        nextDate: result_1[el].next_date,
                        furtherAction: result_1[el].further_action,
                        amount: result_1[el].amount,
                        actions: Object.keys(result_1[el].actions).map(function (element) {
                            return {
                                key: element,
                                value: result_1[el].actions[element]
                            };
                        })
                    };
                });
                return [4 /*yield*/, put(fetchFurtherActionsSuccessAction(furtherActionsList))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_1 = _a.sent();
                return [4 /*yield*/, put(fetchFurtherActionsFailureAction(e_1.toString()))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
//-----------------------------------------------------------------------------------------------------------------------
function watchFurtherActionsActionSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(furtherActionsActionTypes.FETCH_FURTHER_ACTIONS, callFetchFurtherActions)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var furtherActionsSagas = [watchFurtherActionsActionSagas];
export { furtherActionsSagas, fetchFurtherActionsAction };
