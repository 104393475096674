/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { NotificationManager } from "react-notifications";
export var userActionTypes;
(function (userActionTypes) {
    userActionTypes["FETCH_USERS"] = "field-workforce-management/users/fetch_users";
    userActionTypes["FETCH_USERS_SUCCESS"] = "field-workforce-management/users/fetch_users_success";
    userActionTypes["FETCH_USERS_FAILURE"] = "field-workforce-management/users/fetch_users_failure";
    userActionTypes["CREATE_USER"] = "field-workforce-management/users/create_user";
    userActionTypes["CREATE_USER_SUCCESS"] = "field-workforce-management/users/create_user_success";
    userActionTypes["CREATE_USER_FAILURE"] = "field-workforce-management/users/create_user_failure";
    userActionTypes["UPDATE_USER"] = "field-workforce-management/users/update_user";
    userActionTypes["UPDATE_USER_SUCCESS"] = "field-workforce-management/users/update_user_success";
    userActionTypes["UPDATE_USER_FAILURE"] = "field-workforce-management/users/update_user_failure";
    userActionTypes["ARCHIVE_USER"] = "field-workforce-management/users/archive_user";
    userActionTypes["ARCHIVE_USER_SUCCESS"] = "field-workforce-management/users/archive_user_success";
    userActionTypes["ARCHIVE_USER_FAILURE"] = "field-workforce-management/users/archive_user_failure";
    userActionTypes["CHANGE_USER_PASSWORD"] = "field-workforce-management/users/change_user_password";
    userActionTypes["CHANGE_USER_PASSWORD_SUCCESS"] = "field-workforce-management/users/change_user_password_success";
    userActionTypes["CHANGE_USER_PASSWORD_FAILURE"] = "field-workforce-management/users/change_user_password_failure";
    userActionTypes["RESET_USER_LOADING_STATE"] = "field-workforce-management/users/reset_user_loading_state";
    userActionTypes["FIND_SUBORDINATE_AND_CASCADE_USERS"] = "field-workforce-management/users/find_subordinate_user";
    userActionTypes["FIND_SUBORDINATE_USER_SUCCESS"] = "field-workforce-management/users/find_subordinate_user_success";
    userActionTypes["UPDATE_USER_PROFILE"] = "field-workforce-management/users/update_user_profile";
    userActionTypes["UPDATE_USER_PROFILE_SUCCESS"] = "field-workforce-management/users/update_user_profile_success";
    userActionTypes["UPDATE_USER_PROFILE_FAILURE"] = "field-workforce-management/users/update_user_profile_failure";
    userActionTypes["UPDATE_USER_LOGIN"] = "field-workforce-management/users/update_user_login";
    userActionTypes["UPDATE_USER_LOGIN_SUCCESS"] = "field-workforce-management/users/update_user_login_success";
    userActionTypes["UPDATE_USER_LOGIN_FAILURE"] = "field-workforce-management/users/update_user_login_failure";
    userActionTypes["SET_USER_FILTER"] = "field-workforce-management/users/set_user_filter";
    userActionTypes["RESET_USER_FILTER"] = "field-workforce-management/users/reset_user_filter";
})(userActionTypes || (userActionTypes = {}));
export function resetUserFilterAction() {
    return {
        type: userActionTypes.RESET_USER_FILTER
    };
}
export function setUserFilterAction(userFilter) {
    return {
        type: userActionTypes.SET_USER_FILTER,
        userFilter: userFilter
    };
}
export function fetchUserAction() {
    return {
        type: userActionTypes.FETCH_USERS
    };
}
export function updateUserProfileAction(user, picture) {
    return {
        type: userActionTypes.UPDATE_USER_PROFILE,
        user: user,
        picture: picture
    };
}
export function fetchUserSuccessAction(userList, userHierarchy) {
    return {
        type: userActionTypes.FETCH_USERS_SUCCESS,
        userList: userList,
        userHierarchy: userHierarchy
    };
}
export function fetchUserFailureAction(err) {
    console.error("fetchUserFailureAction", err);
    NotificationManager.error("Error occurred while loading users. Please try again later.", "Oops! Error");
    return {
        type: userActionTypes.FETCH_USERS_FAILURE,
        err: err
    };
}
export function createUserAction(user) {
    return {
        type: userActionTypes.CREATE_USER,
        user: user
    };
}
export function createUserSuccessAction(name) {
    NotificationManager.success("You have successfully created " + name);
    return {
        type: userActionTypes.CREATE_USER_SUCCESS
    };
}
export function createUserFailureAction(err) {
    console.error("createUserFailureAction", err);
    if (err === "email_exist") {
        NotificationManager.error("Email already exists. Please choose a different Email", "Oops! Error");
    }
    else if (err === "uid_exist") {
        NotificationManager.error("User Id already exists. Please choose a different User Id", "Oops! Error");
    }
    else {
        NotificationManager.error("Error occurred while creating user. Please try again later.", "Oops! Error");
    }
    return {
        type: userActionTypes.CREATE_USER_FAILURE,
        err: err
    };
}
export function updateUserAction(user) {
    return {
        type: userActionTypes.UPDATE_USER,
        user: user
    };
}
export function updateUserSuccessAction(name) {
    NotificationManager.success("You have successfully updated " + name);
    return {
        type: userActionTypes.UPDATE_USER_SUCCESS
    };
}
export function resetUserLoadingStateAction() {
    return {
        type: userActionTypes.RESET_USER_LOADING_STATE
    };
}
export function updateUserFailureAction(err) {
    console.error("updateUserFailureAction", err);
    if (err === "email_exist") {
        NotificationManager.error("Email already exists. Please choose a different Email", "Oops! Error");
    }
    else {
        NotificationManager.error("Error occurred while updating user. Please try again later.", "Oops! Error");
    }
    return {
        type: userActionTypes.UPDATE_USER_FAILURE,
        err: err
    };
}
export function archiveUserAction(user) {
    return {
        type: userActionTypes.ARCHIVE_USER,
        user: user
    };
}
export function archiveUserSuccessAction(name, isArchived) {
    if (isArchived) {
        NotificationManager.success("You have successfully archived " + name);
    }
    else {
        NotificationManager.success("You have successfully activated " + name);
    }
    return {
        type: userActionTypes.ARCHIVE_USER_SUCCESS
    };
}
export function archiveUserFailureAction(err) {
    console.error("archiveUserFailureAction", err);
    NotificationManager.error("Error occurred while archiving user. Please try again later.", "Oops! Error");
    return {
        type: userActionTypes.ARCHIVE_USER_FAILURE,
        err: err
    };
}
export function changeUserPasswordAction(email, newPassword) {
    return {
        type: userActionTypes.CHANGE_USER_PASSWORD,
        email: email,
        newPassword: newPassword
    };
}
export function changeUserPasswordSuccessAction() {
    NotificationManager.success("You have successfully reset the password.");
    return {
        type: userActionTypes.CHANGE_USER_PASSWORD_SUCCESS
    };
}
export function changeUserPasswordFailureAction(err) {
    console.error("changeUserPasswordFailureAction", err);
    NotificationManager.error("Error occurred while reset password. Please try again later.", "Oops! Error");
    return {
        type: userActionTypes.CHANGE_USER_PASSWORD_FAILURE,
        err: err
    };
}
export function findSubordinateAndCascadeUserAction(loggedInUserUid, userHierarchy) {
    return {
        type: userActionTypes.FIND_SUBORDINATE_AND_CASCADE_USERS,
        loggedInUserDocumentID: loggedInUserUid,
        userHierarchy: userHierarchy
    };
}
export function findSubordinateAndCascadeUserSuccessAction(loggedInUserDocumentID, subordinateUserIDs, cascadeUserIDs) {
    return {
        type: userActionTypes.FIND_SUBORDINATE_USER_SUCCESS,
        subordinateUserIDs: subordinateUserIDs,
        cascadeUserIDs: cascadeUserIDs,
        loggedInUserDocumentID: loggedInUserDocumentID
    };
}
export function updateUserProfileSuccessAction() {
    NotificationManager.success("You have updated your profile successfully.");
    return {
        type: userActionTypes.UPDATE_USER_PROFILE_SUCCESS
    };
}
export function updateUserProfileFailureAction(err) {
    console.error("updateUserProfileFailureAction", err);
    NotificationManager.error("Error occurred while updating your profile. Please try again later.", "Oops! Error");
    return {
        type: userActionTypes.UPDATE_USER_PROFILE_FAILURE,
        err: err
    };
}
export function fetchUpdateUserLoginAction(user) {
    return {
        type: userActionTypes.UPDATE_USER_LOGIN,
        user: user
    };
}
export function fetchUpdateUserLoginSuccessAction() {
    // NotificationManager.success(`You have updated your profile successfully.`);
    return {
        type: userActionTypes.UPDATE_USER_LOGIN_SUCCESS
    };
}
export function fetchUpdateUserLoginFailureAction(err) {
    // console.error("updateUserProfileFailureAction", err);
    // NotificationManager.error(`Error occurred while updating your profile. Please try again later.`, `Oops! Error`);
    return {
        type: userActionTypes.UPDATE_USER_LOGIN_FAILURE,
        err: err
    };
}
