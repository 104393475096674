/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { cancellationRequestActionTypes } from "./action";
var initialState = {
    cancellationRequestList: [],
    rejectSuccess: false,
    acceptSuccess: false,
    fetchCancellationRequestIsLoading: false,
    rejectRequestIsLoading: false,
    acceptRequestIsLoading: false,
    fetchCancellationRequestError: { hasError: false, description: "" },
    rejectRequestError: { hasError: false, description: "" },
    acceptRequestError: { hasError: false, description: "" }
};
export default function cancellationRequestReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case cancellationRequestActionTypes.FETCH_CANCELLATION_REQUEST: {
                draft.fetchCancellationRequestIsLoading = true;
                draft.rejectSuccess = false;
                draft.acceptSuccess = false;
                break;
            }
            case cancellationRequestActionTypes.FETCH_CANCELLATION_REQUEST_SUCCESS: {
                draft.cancellationRequestList = action.tasks;
                draft.fetchCancellationRequestIsLoading = false;
                draft.fetchCancellationRequestError = {
                    hasError: false,
                    description: ""
                };
                break;
            }
            case cancellationRequestActionTypes.FETCH_CANCELLATION_REQUEST_FAILURE: {
                draft.fetchCancellationRequestIsLoading = false;
                draft.fetchCancellationRequestError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            case cancellationRequestActionTypes.REJECT_REQUEST: {
                draft.rejectRequestIsLoading = true;
                break;
            }
            case cancellationRequestActionTypes.REJECT_REQUEST_SUCCESS: {
                draft.rejectSuccess = true;
                draft.rejectRequestIsLoading = false;
                draft.rejectRequestError = { hasError: false, description: "" };
                break;
            }
            case cancellationRequestActionTypes.REJECT_REQUEST_FAILURE: {
                draft.rejectRequestIsLoading = false;
                draft.rejectRequestError = { hasError: true, description: action.err };
                break;
            }
            case cancellationRequestActionTypes.ACCEPT_REQUEST: {
                draft.acceptRequestIsLoading = true;
                break;
            }
            case cancellationRequestActionTypes.ACCEPT_REQUEST_SUCCESS: {
                draft.acceptSuccess = true;
                draft.acceptRequestIsLoading = false;
                draft.acceptRequestError = { hasError: false, description: "" };
                break;
            }
            case cancellationRequestActionTypes.ACCEPT_REQUEST_FAILURE: {
                draft.acceptRequestIsLoading = false;
                draft.acceptRequestError = { hasError: true, description: action.err };
                break;
            }
            default:
                break;
        }
    });
}
