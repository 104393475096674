/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { alertsActionTypes } from "./actions";
var initialState = {
    listenAlertList: [],
    listenAlertIsLoading: false,
    listenAlertError: { hasError: false, description: "" },
    alertList: [],
    fetchAlertIsLoading: false,
    fetchAlertError: { hasError: false, description: "" },
    markAsReadSuccess: false,
    markAsReadIsLoading: false,
    markAsReadError: { description: "", hasError: false }
};
export default function alertsReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case alertsActionTypes.FETCH_ALERTS: {
                draft.fetchAlertIsLoading = true;
                break;
            }
            case alertsActionTypes.FETCH_ALERTS_SUCCESS: {
                draft.alertList = action.alerts;
                draft.fetchAlertIsLoading = false;
                draft.fetchAlertError = { hasError: false, description: "" };
                break;
            }
            case alertsActionTypes.FETCH_ALERTS_FAILURE: {
                draft.fetchAlertIsLoading = false;
                draft.fetchAlertError = { hasError: true, description: action.err };
                break;
            }
            case alertsActionTypes.LISTEN_ALERTS: {
                draft.listenAlertIsLoading = true;
                break;
            }
            case alertsActionTypes.LISTEN_ALERTS_SUCCESS: {
                draft.listenAlertList = action.alerts;
                draft.listenAlertIsLoading = false;
                draft.listenAlertError = { hasError: false, description: "" };
                break;
            }
            case alertsActionTypes.LISTEN_ALERTS_FAILURE: {
                draft.listenAlertIsLoading = false;
                draft.listenAlertError = { hasError: true, description: action.err };
                break;
            }
            case alertsActionTypes.MARK_AS_READ: {
                draft.markAsReadIsLoading = true;
                break;
            }
            case alertsActionTypes.MARK_AS_READ_SUCCESS: {
                draft.markAsReadSuccess = true;
                draft.markAsReadIsLoading = false;
                draft.markAsReadError = { hasError: false, description: "" };
                break;
            }
            case alertsActionTypes.MARK_AS_READ_FAILURE: {
                draft.markAsReadIsLoading = false;
                draft.markAsReadError = { hasError: true, description: action.err };
                break;
            }
            default:
                break;
        }
    });
}
