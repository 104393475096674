export default function getReadableStatus(status) {
    switch (status) {
        case "CheckIn":
            return "Checked In";
        case "CheckOut":
            return "Checked Out";
        case "CoverageOff":
            return "No Coverage";
        case "MovingStopped":
            return "No Movement";
        case "mobile":
            return "Mobile";
        case "web":
            return "Web";
        default:
            return status;
    }
}
