import * as React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import useStyles from "../views/Tasks/styles/styles";
import { PRIMARY_BLUE, PRIMARY_PINK, WHITE } from "../utils/colorCodes";
// @ts-ignore
export default function FilterByPriority(props) {
    var priorityFilter = props.priorityFilter, setPriorityFilter = props.setPriorityFilter, hidden = props.hidden;
    var classes = useStyles();
    return (React.createElement(FormControl, { className: classes.formStatusControl, style: { backgroundColor: hidden ? WHITE : PRIMARY_PINK } }, React.createElement(Select, { disabled: hidden, displayEmpty: true, disableUnderline: true, labelId: "filter-by-priority-label", id: "filter-by-priority", value: priorityFilter, onChange: function (event) { return setPriorityFilter(event.target.value); }, style: {
            textAlign: "left",
            color: PRIMARY_BLUE
        } }, React.createElement(MenuItem, { value: 0, style: { color: PRIMARY_BLUE } }, "Filter by Priority"), React.createElement(MenuItem, { value: 1, style: { color: PRIMARY_BLUE } }, "High"), React.createElement(MenuItem, { value: 2, style: { color: PRIMARY_BLUE } }, "Medium"), React.createElement(MenuItem, { value: 3, style: { color: PRIMARY_BLUE } }, "Low"))));
}
