/* eslint-disable  no-console */
import { NotificationManager } from "react-notifications";
export var announcementsActionTypes;
(function (announcementsActionTypes) {
    announcementsActionTypes["FETCH_ANNOUNCEMENTS"] = "field-workforce-management/cancellation_requests/fetch_announcements";
    announcementsActionTypes["FETCH_ANNOUNCEMENTS_SUCCESS"] = "field-workforce-management/cancellation_requests/fetch_announcements_success";
    announcementsActionTypes["FETCH_ANNOUNCEMENTS_FAILURE"] = "field-workforce-management/cancellation_requests/fetch_announcements_failure";
    announcementsActionTypes["FETCH_SINGLE_ANNOUNCEMENT"] = "field-workforce-management/cancellation_requests/fetch_single_announcement";
    announcementsActionTypes["FETCH_SINGLE_ANNOUNCEMENT_SUCCESS"] = "field-workforce-management/cancellation_requests/fetch_single_announcement_success";
    announcementsActionTypes["FETCH_SINGLE_ANNOUNCEMENT_FAILURE"] = "field-workforce-management/cancellation_requests/fetch_single_announcement_failure";
    announcementsActionTypes["CREATE_ANNOUNCEMENT"] = "field-workforce-management/cancellation_requests/create_announcement";
    announcementsActionTypes["CREATE_ANNOUNCEMENT_SUCCESS"] = "field-workforce-management/cancellation_requests/create_announcement_success";
    announcementsActionTypes["CREATE_ANNOUNCEMENT_FAILURE"] = "field-workforce-management/cancellation_requests/create_announcement_failure";
    announcementsActionTypes["ARCHIVE_ANNOUNCEMENT"] = "field-workforce-management/cancellation_requests/archive_announcement";
    announcementsActionTypes["ARCHIVE_ANNOUNCEMENT_SUCCESS"] = "field-workforce-management/cancellation_requests/archive_announcement_success";
    announcementsActionTypes["ARCHIVE_ANNOUNCEMENT_FAILURE"] = "field-workforce-management/cancellation_requests/archive_announcement_failure";
    announcementsActionTypes["RESET_SUCCESS_STATE"] = "field-workforce-management/cancellation_requests/reset_success_state";
})(announcementsActionTypes || (announcementsActionTypes = {}));
export function fetchAnnouncementAction(startDate, endDate) {
    return {
        type: announcementsActionTypes.FETCH_ANNOUNCEMENTS,
        startDate: startDate,
        endDate: endDate
    };
}
export function fetchAnnouncementSuccessAction(announcements) {
    return {
        type: announcementsActionTypes.FETCH_ANNOUNCEMENTS_SUCCESS,
        announcements: announcements
    };
}
export function fetchAnnouncementFailureAction(err) {
    console.error("fetchAnnouncementFailureAction", err);
    NotificationManager.error("Error occurred while loading announcements. Please try again later.", "Oops! Error");
    return {
        type: announcementsActionTypes.FETCH_ANNOUNCEMENTS_FAILURE,
        err: err
    };
}
export function fetchSingleAnnouncementAction(announcementID, loggedInUserID) {
    return {
        type: announcementsActionTypes.FETCH_SINGLE_ANNOUNCEMENT,
        announcementID: announcementID,
        loggedInUserID: loggedInUserID
    };
}
export function fetchSingleAnnouncementSuccessAction(announcement) {
    return {
        type: announcementsActionTypes.FETCH_SINGLE_ANNOUNCEMENT_SUCCESS,
        announcement: announcement
    };
}
export function fetchSingleAnnouncementFailureAction(err) {
    console.error("fetchSingleAnnouncementFailureAction", err);
    NotificationManager.error("Error occurred while loading announcement. Please try again later.", "Oops! Error");
    return {
        type: announcementsActionTypes.FETCH_SINGLE_ANNOUNCEMENT_FAILURE,
        err: err
    };
}
export function createAnnouncementAction(announcement) {
    return {
        type: announcementsActionTypes.CREATE_ANNOUNCEMENT,
        announcement: announcement
    };
}
export function createAnnouncementSuccessAction() {
    NotificationManager.success("You have successfully created the announcement.");
    return {
        type: announcementsActionTypes.CREATE_ANNOUNCEMENT_SUCCESS
    };
}
export function createAnnouncementFailureAction(err) {
    console.error("createAnnouncementFailureAction", err);
    NotificationManager.error("Error occurred while creating announcement. Please try again later.", "Oops! Error");
    return {
        type: announcementsActionTypes.CREATE_ANNOUNCEMENT_FAILURE,
        err: err
    };
}
export function archiveAnnouncementAction(announcement) {
    return {
        type: announcementsActionTypes.ARCHIVE_ANNOUNCEMENT,
        announcement: announcement
    };
}
export function archiveAnnouncementSuccessAction() {
    NotificationManager.success("You have successfully deleted the announcement.");
    return {
        type: announcementsActionTypes.ARCHIVE_ANNOUNCEMENT_SUCCESS
    };
}
export function archiveAnnouncementFailureAction(err) {
    console.error("archiveAnnouncementFailureAction", err);
    NotificationManager.error("Error occurred while archiveing announcement. Please try again later.", "Oops! Error");
    return {
        type: announcementsActionTypes.ARCHIVE_ANNOUNCEMENT_FAILURE,
        err: err
    };
}
export function resetAnnouncementsStatesAction() {
    return {
        type: announcementsActionTypes.RESET_SUCCESS_STATE
    };
}
