/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce from "immer";
import { homePageActionTypes } from "./actions";
export var workTypeEnum;
(function (workTypeEnum) {
    workTypeEnum["HOME"] = "Home";
    workTypeEnum["OFFICE"] = "Office";
    workTypeEnum["FIELD"] = "Field";
    workTypeEnum["UNKNOWN"] = "Unknown";
})(workTypeEnum || (workTypeEnum = {}));
var initialState = {
    workType: undefined,
    checkInIsLoading: false,
    checkInError: { hasError: false, description: "" },
    checkOutIsLoading: false,
    checkOutError: { hasError: false, description: "" },
    getAttendanceStateIsLoading: true,
    getAttendanceStateError: { hasError: false, description: "" }
};
export default function homePageReducer(state, action) {
    if (state === void 0) {
        state = initialState;
    }
    return produce(state, function (draft) {
        switch (action.type) {
            case homePageActionTypes.MARK_ATTENDANCE_CHECK_IN: {
                draft.checkInIsLoading = true;
                break;
            }
            case homePageActionTypes.MARK_ATTENDANCE_CHECK_IN_SUCCESS: {
                draft.checkInIsLoading = false;
                draft.checkInError = { hasError: false, description: "" };
                break;
            }
            case homePageActionTypes.MARK_ATTENDANCE_CHECK_IN_FAILURE: {
                draft.checkInIsLoading = false;
                draft.checkInError = { hasError: true, description: action.err };
                break;
            }
            case homePageActionTypes.MARK_ATTENDANCE_CHECK_OUT: {
                draft.checkOutIsLoading = true;
                break;
            }
            case homePageActionTypes.MARK_ATTENDANCE_CHECK_OUT_SUCCESS: {
                draft.checkOutIsLoading = false;
                draft.checkOutError = { hasError: false, description: "" };
                break;
            }
            case homePageActionTypes.MARK_ATTENDANCE_CHECK_OUT_FAILURE: {
                draft.checkOutIsLoading = false;
                draft.checkOutError = { hasError: true, description: action.err };
                break;
            }
            case homePageActionTypes.GET_ATTENDANCE_STATUS: {
                draft.getAttendanceStateIsLoading = true;
                break;
            }
            case homePageActionTypes.GET_ATTENDANCE_STATUS_SUCCESS: {
                draft.workType = action.workType;
                draft.getAttendanceStateIsLoading = false;
                draft.getAttendanceStateError = { hasError: false, description: "" };
                break;
            }
            case homePageActionTypes.GET_ATTENDANCE_STATUS_FAILURE: {
                draft.getAttendanceStateIsLoading = false;
                draft.getAttendanceStateError = {
                    hasError: true,
                    description: action.err
                };
                break;
            }
            default:
                break;
        }
    });
}
