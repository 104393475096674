var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select } from "redux-saga/effects";
import firebase from "firebase";
import { changeTaskStatusApiFailureAction, changeTaskStatusApiSuccessAction } from "../actions";
import { TASK_STATE_CANCELLED, TASK_STATE_COMPLETED, TASK_STATE_DISPATCHED, TASK_STATE_QUEUED } from "../../../../utils/remoteConfig/taskStates/taskStateEnum";
import { firestore } from "../../../../utils/firebaseConnector";
import { FIRESTORE_CANCEL_REQUESTS_COLLECTION, FIRESTORE_TASKS_COLLECTION, FIRESTORE_USERS_COLLECTION } from "../../../../utils/firestoreCollections";
import { completeTaskWithoutCancelRequest } from "../../../CancellationRequests";
import { cancelRequestCreator } from "../../../../utils/mapper/cancellationRequestsMapper";
import { taskUpdater } from "../../../../utils/mapper/taskMapper";
function createCancellationRequestDocument(cancellationRequest) {
    return __awaiter(this, void 0, void 0, function () {
        var newCancellationRequestRef;
        return __generator(this, function (_a) {
            newCancellationRequestRef = firestore.collection(FIRESTORE_CANCEL_REQUESTS_COLLECTION).doc();
            return [2 /*return*/, newCancellationRequestRef
                    .set(cancelRequestCreator(cancellationRequest))
                    .then(function () {
                    return newCancellationRequestRef;
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
function updateFirestoreTaskDocument(task) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log(taskUpdater(task));
            return [2 /*return*/, firestore
                    .collection(FIRESTORE_TASKS_COLLECTION)
                    .doc(task.id)
                    .update(taskUpdater(task))
                    .then(function () {
                    return "S1000";
                })
                    .catch(function (e) {
                    throw e;
                })];
        });
    });
}
export default function callChangeTaskStatusSaga(action) {
    var loggedInUser, cancellationRequest, resultOfCancelRequestDocument, task, task, e_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.loginManagement.loggedInUser; })];
            case 1:
                loggedInUser = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 13, , 15]);
                if (!(action.nextState === TASK_STATE_CANCELLED))
                    return [3 /*break*/, 6];
                cancellationRequest = {
                    id: "",
                    task: action.task.id,
                    taskType: action.task.taskType,
                    reason: action.reason,
                    requestedBy: loggedInUser.documentID,
                    requestedAt: new Date().getTime(),
                    handledBy: "",
                    state: 0
                };
                return [4 /*yield*/, call(createCancellationRequestDocument, cancellationRequest)];
            case 3:
                resultOfCancelRequestDocument = _b.sent();
                task = __assign({}, action.task);
                task.cancelRequest = resultOfCancelRequestDocument.id;
                return [4 /*yield*/, call(updateFirestoreTaskDocument, task)];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(changeTaskStatusApiSuccessAction())];
            case 5:
                _b.sent();
                return [3 /*break*/, 12];
            case 6:
                task = __assign({}, action.task);
                task.stateHistory = (_a = task.stateHistory) === null || _a === void 0 ? void 0 : _a.concat([
                    {
                        changedBy: firestore.doc(FIRESTORE_USERS_COLLECTION + "/" + loggedInUser.documentID),
                        location: null,
                        state: action.nextState,
                        timestamp: firebase.firestore.Timestamp.fromMillis(new Date().getTime()),
                        photo: action.task.completionImage ? action.task.completionImage : null,
                        comment: action.task.completionRemarks ? action.task.completionRemarks : null
                    }
                ]);
                task.state = action.nextState;
                if (!(action.nextState === TASK_STATE_COMPLETED))
                    return [3 /*break*/, 9];
                task.completed = new Date().getTime();
                if (!(task.cancelRequest.length > 0))
                    return [3 /*break*/, 8];
                return [4 /*yield*/, call(completeTaskWithoutCancelRequest, task.cancelRequest, loggedInUser.documentID)];
            case 7:
                _b.sent();
                _b.label = 8;
            case 8:
                task.cancelRequest = "";
                _b.label = 9;
            case 9:
                if (action.nextState === TASK_STATE_QUEUED) {
                    task.assignedTo = "";
                }
                if (action.nextState === TASK_STATE_DISPATCHED) {
                    task.assignedTo = loggedInUser.documentID;
                }
                return [4 /*yield*/, call(updateFirestoreTaskDocument, task)];
            case 10:
                _b.sent();
                return [4 /*yield*/, put(changeTaskStatusApiSuccessAction())];
            case 11:
                _b.sent();
                _b.label = 12;
            case 12: return [3 /*break*/, 15];
            case 13:
                e_1 = _b.sent();
                return [4 /*yield*/, put(changeTaskStatusApiFailureAction(e_1.toString()))];
            case 14:
                _b.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
